import React from 'react';
import PropTypes from 'prop-types';
import { TextField, FormControl, useTheme } from '@mui/material';

const CustomTextField = ({
  label,
  value,
  onChange,
  type = 'text',
  error = false,
  helperText = '',
  fullWidth = true,
  required = false,
  styleProp,
  variant = 'outlined',
  ...props
}) => {
  const theme = useTheme();
  return (
    <FormControl fullWidth={fullWidth} error={error} required={required}>
      <TextField
        sx={{
          maxWidth: '175px',
          '& label': {
            top: styleProp.labelTop
          },
          '& .MuiInputLabel-root': {
            color: theme.palette.primary.main
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.primary.main
          },
          '& .MuiOutlinedInput-root': {
            padding: '8px',
            height: styleProp.height,
            border: 'none',
            '& fieldset': {
              borderColor: 'black'
            },
            '&:hover fieldset': {
              border: '2px solid gray'
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main
            }
          }
        }}
        label={label}
        value={value}
        onChange={onChange}
        type={type}
        variant={variant}
        error={error}
        helperText={error ? helperText : ''}
        fullWidth={fullWidth}
        {...props} // Spread the remaining props (e.g., InputProps, InputLabelProps, etc.)
      />
    </FormControl>
  );
};

// Define PropTypes for validation
CustomTextField.propTypes = {
  label: PropTypes.string.isRequired, // label should be a string and required
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // value can be string or number and is required
  onChange: PropTypes.func.isRequired, // onChange should be a function and required
  type: PropTypes.string, // type should be a string (e.g., "text", "password", etc.)
  error: PropTypes.bool, // error should be a boolean
  helperText: PropTypes.string, // helperText should be a string
  fullWidth: PropTypes.bool, // fullWidth should be a boolean
  required: PropTypes.bool, // required should be a boolean
  styleProp: PropTypes.object,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']) // variant should be one of these specific strings
};

// Default props in case none are provided
CustomTextField.defaultProps = {
  type: 'text',
  error: false,
  helperText: '',
  fullWidth: true,
  required: false,
  variant: 'outlined',
  styleProp: {}
};

export default CustomTextField;
