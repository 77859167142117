import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Box } from '@mui/material';

const CustomTooltip = ({ content, bgColor, children, ...props }) => {
  const renderContent = typeof content === 'string' ? <span>{content}</span> : content;

  return (
    <Tooltip
      {...props}
      title={
        <Box
          sx={{
            backgroundColor: bgColor, // Set your tooltip background
            color: '#fff', // Text color
            padding: '4px',
            borderRadius: '8px',
            fontSize: '12px',
            boxShadow: 'none', // Remove any box shadow
            margin: '0',
            visibility: content ? 'visible' : 'hidden'
          }}
        >
          {renderContent}
        </Box>
      }
      arrow
      PopperProps={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'window'
            }
          }
        ]
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: bgColor,
            color: '#fff',
            boxShadow: 'none', // Remove unwanted box shadow
            margin: '0px',
            filter: 'none',
            visibility: content ? 'visible' : 'hidden'
          }
        },
        arrow: {
          sx: {
            color: bgColor
          }
        }
      }}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired
};

CustomTooltip.defaultProps = {
  bgColor: '#5e6269' // Default dark blue background
};

export default CustomTooltip;
