import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CustomTooltip from './CustomToolTip';

function CustomButton({ label, onClick, color, variant, toolTiptext, disabled, style, icon }) {
  return (
    <CustomTooltip content={toolTiptext}>
      <Button
        style={style}
        onClick={onClick}
        color={color}
        variant={variant}
        title={toolTiptext}
        disabled={disabled}
        startIcon={icon}
      >
        {label}
      </Button>
    </CustomTooltip>
  );
}

// PropType validation
CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'inherit']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  toolTiptext: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  icon: PropTypes.object
};

// Default props
CustomButton.defaultProps = {
  color: 'primary',
  variant: 'contained',
  disabled: false,
  style: {},
  icon: <></>
};

export default CustomButton;
