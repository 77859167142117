/* eslint-disable */
/* eslint-disable no-debugger */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file index.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */


/* eslint-disable react/no-unused-prop-types, jsx-a11y/no-static-element-interactions, no-nested-ternary */
import React, { useEffect, useState, useRef, useCallback, memo } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Utilities, MathUtil } from '@aquila/core';
import TurndownService from 'turndown';
import { IconButton, TextField, Snackbar, SnackbarContent, Typography, ClickAwayListener, Tooltip, Button, Dialog } from '@material-ui/core';
import EventBus from 'eventing-bus';
import Draggable from 'react-draggable';
import 'mathlive';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { NewImageViewerPreviewComponent } from '@aquila/media';
import Header from './Header';
import Clarify from '../../Widgets/ClarifyContainer/Clarify';
import SummaryListContainer from '../../Widgets/Summary/SummaryListContainer';
import StreamingSummaryListContainer from '../../Widgets/Summary/StreamingSummaryListContainer';
import CtaButtons from './CtaButtons';
import Footer from './Footer';
// import BotIcon from '../../Common/icons/BotIcon';
import messages from '../../defaultMessages';
import * as constants from '../../Common/constants';
import QuizInteractive from '../../Widgets/QuizContainer/QuizInteractive';
import Utils from '../../Common/Utils';
import WelcomeMessage from './WelcomeMessage';
import SummaryOptions from '../../Widgets/ClarifyContainer/SummaryOptions';
import Discuss from '../../Widgets/Discuss';
import StreamingDiscuss from '../../Widgets/StreamingDiscuss';
import QuizAnswerComponent from '../../Widgets/QuizContainer/QuizAnswerComponent';
import NoMoreQuestions from '../../Widgets/QuizContainer/NoMoreQuestions';
import NextQuestionPrompt from '../../Widgets/QuizContainer/NextQuestionPrompt';
import QuizOptions from '../../Widgets/QuizContainer/QuizOptions';
import { eventAction, gaEvents } from '../../analytics/analytics';
// import ContentSelectedInput from '../../Widgets/ContentSelectedInput';
import CustomInput from '../../Widgets/Summary/CustomInput';
import ContactIntent from '../../Common/contactIntent';
import LoaderIcon from '../../Common/icons/LoaderIcon';
import FreeResponseQuiz from '../../Widgets/QuizContainer/FreeResponseQuiz';
import ErrorBoundary from '../../Common/ErrorBoundary';
import UserMessage from '../../Widgets/UserMessage';
import FreeResponseQuizResult from '../FeedbackComponent/FreeResponseQuizResult';
import GenerateTopics from '../../Widgets/ClarifyContainer/GenerateTopics';
import SingleTopic from '../../Widgets/ClarifyContainer/SingleTopic';
import ChatComponentStyles from './ChatComponentStyles';
import ChapterTopics from '../../Widgets/ClarifyContainer/ChapterTopics';
import SuggestionPills from '../../Widgets/SuggestionPills';
import '../../main.scss';
import DelayedResponse from '../../Widgets/DelayedResponse';
import AfterStop from '../../Widgets/AfterStop';
import WelcomeModal from '../../Widgets/WelcomeModal/WelcomeModal';
import TutorEyes from '../../Common/icons/TutorEyes';
import TutorEyesHover from '../../Common/icons/TutorEyesHover';
import FeedbackSubmitMessage from '../FeedbackComponent/FeedbackSubmitMessage';
import TutorEyesSleep from '../../Common/icons/TutorEyesSleep';
import DownArrow from '../../Common/icons/DownArrow';
import ChatHistory from '../ChatHistory/ChatHistory';
import globalContext from '../../Context/globalContext';
import {
  closeChatHistoryGAEvent,
  eReaderABTestExperienceGAEvent,
  openChatHistoryGAEvent,
  openMathKeyboardGAEvent,
  onSendMathTextGAEvent,
  fetchChannelsSearchResultsGAEvent,
  clickTopicSuggestionsEvent,
  clickOnReferenceLink,
  clickMediaCardGAEvent,
  clickSuggestionGAEvent
} from '../../analytics/analyticsEvents';
import MathLive from '../../Common/MathLive';
import MathIcon from '../../Common/icons/MathIcon';
import CloseIcon from '../../Common/icons/CloseIcon';
import Recommendations from './Recommendations';
import ImageContext from './ImageContext';
import BerlinServices from '../Services/BerlinServices'
import ChannelServices from '../Services/ChannelServices';

let optionSelected = 'discuss';
let selectedContent = '';
let quizQuestionsData = [];
let isNewTopic = false;
let quizPageIds = [];
let streamingContent = '';
let streamingApiContent = '';
let noUpdateToSteps = false;
let identifierType = '';
let incrementQuizCounter = '';
let quizStreamStatus = '';
let nextQuestionSetInterval = '';
let isCannedMessage = false;
let topic = '';
let sleepTimer = '';
let isSleepModeOnHover = false;
let isSleepModeOnClick = false;
let isExplainClicked = false;
let chapterData = {
  chapterId: '',
  chapterTitle: '',
  chapterNumber: 0
};
let quizStreamRequestId = '';
let quizStreamResponseId = '';
let totalQuestionCount = 0;
let isSummaryQuiz = false;
let isExplainQuiz = false;
let summaryQuizSelectedOption = '';
let summaryQuizPageIds = [];
let stopQuiz = false;
let isExplainCYUMode;
let followUpEventData = {};
let outputSuccessGAEvent = {};
let showExplainQuizFollowOptionEvent = {};
let quizRequestId = '';
let contentRangeValue = '';
let scrollDownClicked = false;
let historyScrollTop = 1;
let historyScrollToCount = 0;
let scrollTimer = -1;
let isFirstTimeLaunch = true;
let isFromRecommendationPill = false;
let promptVersionForContentImage = null;
let imageCopilotInitialization = false;
const SEARCH = 'SEARCH';

const Chat = ({
  classes,
  context,
  isPageLoaded,
  intl,
  pageList,
  handleChatGPTCustomNote,
  handleChatGPTFlashCard,
  users,
  chatGPTClose,
  handleGAEvents,
  updateAITutorUserSettings,
  masterTheme,
  theme,
  selectedText,
  openBotViaContentSelector,
  closeContentSelector,
  handleBotBackNavigation,
  topicTimeOut,
  enableStreamingAPI,
  AITutorContext,
  getCurrentSection,
  getBerlinClientAPI,
  channelId,
  delayMessageTimerInSecs,
  getAuthToken,
  handleChatbotOpened,
  enableShowMore,
  enableAISleepMode,
  showAISleepModeAB,
  onLDTrack,
  showAIChannelVideos,
  showSummaryQuiz,
  executeCommand,
  initiatedPoint,
  enableProblemSolveFeature,
  openAITutor,
  onBotClose,
  enableExplainQuizOptions,
  onCollapseABBot,
  AIChatPlaceHolderText,
  showChatBotIcon,
  enableTopicInQuizFlow,
  botInitiated,
  aiTutorAutoOpenCommands,
  aitutorWelcomeScreenCohorts,
  aiTutorAutoOpen,
  aiTutorAutoOpenCount,
  threadDeleteTimeOut,
  setAITutorAutoOpen,
  isBotOpenedFromContentSelector,
  showUserHistory,
  openBotContainer,
  isBotFromDrawer,
  isBotFromInlineContent,
  isBotFromLightBox,
  toggleBotContainer,
  channelsVideoEsScore,
  env,
  enableMathMLKeyboard,
  showStarterPrompt,
  enableAIShortAnswer,
  hideChannelsPopout,
  onPageNavigation,
  showSourceReferencePage,
  playList,
  showOptoutLink,
  showOptInLink,
  updateChannelsOptIn,
  updateChannelsOptOut,
  AIEnableMarkdown,
  imageContextData,
  AIEnableImageContext,
  isChannelsBundleSubscriber,
  showSummary,
  showPractice,
  disableDrag,
  showRecommendedPrompts,
  enableNewMathConfig,
  showImageCopilot,
  tutorName,
  welcomeMessage,
  expandMode
}) => {
  const [inputValue, setInputValue] = useState('');
  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 });
  const [optionType, setOptionType] = useState('');
  const pageId = window.localStorage.getItem('use_print_page_no') === 'true' && context.printPageNumber && context.printPageNumber !== '-' ? [context.printPageNumber] : [context.pageId];
  const { chapterList, contentType } = context;
  const [isExpanded, setIsExpanded] = useState(false);
  const isFromContentSelector = useRef(false);
  const rotateRef = useRef('');
  const [dragInprogress, setDragInprogress] = useState(false);
  const [animationClass, setAnimationClass] = useState('chatBOTClose');
  const quizRefId = useRef('');
  const nextQuestion = useRef('');
  const isLastPage = useRef(false);
  const isPausedModeOn = useRef(false);
  const firstTimeUser = useRef(users.isFirstTimeAITutorUser);
  const explainFlowMessage = useRef('Here is an explanation for that topic:');
  let selectedOptionType = '';
  const loaderStep = {
    id: constants.LOADER
  };
  const errorData = {
    id: constants.ERROR_MESSAGE
  };
  // const suggestionData = {
  //   id: constants.SUGGESTION_CONTENT
  // };
  const [showBotContainer, setBotContainer] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const currentQuestionIndex = useRef(0);
  const { formatMessage } = intl;
  const [steps, setSteps] = useState([]);
  const [contentSelectorSelectedText, setContentSelectorSelectedText] = useState('');
  const [showSnackBar, setShowSnackBar] = useState({ isOpen: false, message: '', duration: 6000 });
  const hasFeedbackId = useRef('');
  const isContentSelectorFirstTime = useRef(false);
  const [botOpenedTime, setBotOpenedTime] = useState(0);
  const [contentSelectorBotOpen, setContentSelectorBotOpen] = useState(false);
  const [startNewConversationGaEvent, setStartNewConversationGaEvent] = useState(false);
  const [isStopStreaming, setIsStopStreaming] = useState(false);
  const [isTextBoxDisable, setIsTextBoxDisable] = useState(false);
  const eventSourceRef = useRef(null);
  const recommendationEventRef = useRef(null);
  const currentStreamResponseId = useRef('');
  const inputRef = useRef(null);
  const [botRotate, setBotRotate] = useState(false);
  const [isAPICalled, setIsAPICalled] = useState(false);
  const [showModal,setShowModal] = useState(aiTutorAutoOpenCount === 1 || users.isFirstTimeAITutorUser);
  const [ariaAssertive,setAriaAssertive] = useState('off');
  const [ariaLiveStatus,setAriaLiveStatus] = useState('off');
  const [ariaDisable,setAriaDisable] = useState(true);
  const [maxCharValidationMessage,setMaxCharValidationMessage] = useState('');
  const [isSendBtnDisabled,setIsSendBtnDisable] = useState(false);
  const [showFeedbackMessagePopup,setShowFeedbackMessagePopup] = useState(false);
  const [feedbackIconFocusId,setFeedbackIconFocusId] = useState({ });
  const [streamingScroll,setStreamingScroll] = useState(false);
  const [chatContainerScrollBar,setChatContainerScrollBar] = useState({});
  const [isClickAway, setIsClickAway] = useState(false);
  const [quizStreamEnd, setQuizStreamEnd] = useState(false);
  const [botFromLeftPanel, setBotFromLeftPanel] = useState(false);
  const [botFromInlineContent, setBotOpenFromInlineContent] = useState(false);
  const [botFromLightBox, setBotOpenFromLightBox] = useState(false);
  const [aiStudyToolAutoOpen, setAiStudyToolAutoOpen] = useState(false);
  const [botAutoOpen, setBotAutoOpen] = useState(aiTutorAutoOpen);
  const botRef= useRef(null);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [isTitleGenerated, setIsTitleGenerated] = useState(false);
  const [openMathKeyboard, setOpenMathKeyboard] = useState(false);
  const quizLoader = {
    id: constants.QUIZ_LOADER,
    message: formatMessage(messages.quizLoaderMessage)
  };
  const isContentSelectFlow= useRef(false);
  const scrollContainerRef = useRef(null);
  const [showHistory, setShowHistory] = useState(false);
  const [historyDrawerAnimationClass, setHistoryDrawerAnimationClass] = useState(classes.openHistoryContainer);
  const { berlinTenantObject, tenantObj } = context || {};
  const {
    tenantId,
    tenantKey
  } = berlinTenantObject;
  const [historyPage, setHistoryPage] = useState(false);
  const [historyOffset, setHistoryOffset] = useState(0);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [enableOptoutLink, setEnableOptoutLink] = useState(showOptoutLink);
  const [enableOptInLink, setEnableOptInLink] = useState(showOptInLink);
  const [showChannelVideos, setShowChannelVideos] = useState(showAIChannelVideos);
  const [recommendedPrompts, setRecommendedPrompts] = useState([]);
  const [recommendationsPromptData, setRecommendationsPromptData] = useState(null);
  const [isRecommendations, setRecommendations] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  
  const [openLightbox, setOpenLightbox] = useState(false);
  const [lightboxImageData, setLightboxImageData] = useState({});
  const berlinService = new BerlinServices();
  const channelService = new ChannelServices();

  useEffect(() => {
    EventBus.on(constants.OPEN_FULL_VIEW, (botData) => {
      if (botData && botData.botOpen) {
        setIsExpanded(!botData.isCollapsed);
        setBotFromLeftPanel(true);
      }
      if (botData && botData.autoOpen) {
        setAiStudyToolAutoOpen(true);
      }
      handleDisplayBot(botData && botData.botOpen);
    });
  }, []);

  useEffect(() => {
    setEnableOptInLink(showOptInLink);
  }, [showOptInLink]);
  
  useEffect(() => {
    setEnableOptoutLink(showOptoutLink);
  }, [showOptoutLink]);

  useEffect(() => {
    setShowChannelVideos(showAIChannelVideos);
  }, [showAIChannelVideos]);

  useEffect(() => {
    if(showRecommendedPrompts && context && context.pageId && openBotContainer) {
      if (steps.length) {
        setRecommendations(true);
      }
      setRecommendedPrompts([]);
      const recommendationPayload = {
        bookId: context.bookID,
        pageId: context.pageId,
        tenantId: tenantObj && tenantObj.tenantId,
        indexId: context.indexId
      }
      getAuthToken((tokenKey) => {
        berlinService.getRecommendations(
          recommendationPayload,
          tokenKey,
          (data) => {
            let recommendedPromptArray = [];
            if(data && data.useStream && data.requestId) {
              setRecommendationsPromptData(data);
              recommendationEventRef.current = new EventSourcePolyfill(`${constants.BERLIN_BASE_URL[globalContext.env]}/3.0/generate/stream?requestId=${data.requestId}`, {
                headers: {
                Authorization: `Bearer ${tokenKey}`,
                formatstream: 'Y',
                'thread-id': globalContext.threadId,
                'x-berlin-tenant-id': tenantId,
                'x-berlin-tenant-key': tenantKey
              }, 
              heartbeatTimeout: 180000
              });
              let recommendedPromptCount = 0;
              recommendationEventRef.current.onmessage = (e) => {
                if (e.data) {
                  const formattedResponse = JSON.parse(e.data);
                  if (formattedResponse.streamStatus === constants.START) {
                    recommendedPromptArray = [];
                    recommendedPromptCount = 0;
                  } else if (formattedResponse.streamStatus === constants.STREAMING || formattedResponse.streamStatus === constants.DONE) {
                    if (formattedResponse.streamStatus === constants.DONE && recommendationEventRef && recommendationEventRef.current && recommendationEventRef.current.close) {
                      recommendationEventRef.current.close();
                    }
                    if (formattedResponse.content && recommendedPromptCount < constants.RECOMMENDED_PROMPT_LIMIT) {
                      recommendedPromptArray.push(formattedResponse.content);
                      recommendedPromptCount = recommendedPromptCount + 1;
                      if (recommendedPromptCount === constants.RECOMMENDED_PROMPT_LIMIT) {
                        setRecommendedPrompts(recommendedPromptArray);
                        if (recommendationEventRef && recommendationEventRef.current && recommendationEventRef.current.close) {
                          recommendationEventRef.current.close();
                        }
                        if(steps && steps.length) {
                          Utils.scrollToView(5,  'recommendation_prompt_container', false, true); // Scroll to Recommendation Prompt
                        }
                      }
                    }
                  } else if (formattedResponse.streamStatus === constants.KEEP_ALIVE) {
                    console.log('Connection is Alive', e.data);
                  }
                }
              };
              recommendationEventRef.current.onerror = (error) => {
                console.log('Error in recommendation Streaming response', error);
                if (error?.target?.close) {
                  error.target.close();
                }
                if (recommendationEventRef?.current?.close) {
                  recommendationEventRef.current.close();
                }
              };
            } else {
              if (data && data.recommendations && data.recommendations.length) {
                setRecommendedPrompts(data.recommendations.slice(0, constants.RECOMMENDED_PROMPT_LIMIT));
                if(steps && steps.length) {
                  Utils.scrollToView(5,  'recommendation_prompt_container', false, true); // Scroll to Recommendation Prompt
                }
              }
            }
        });
      })
      
    }
  }, [context && context.pageId, openBotContainer]);

  useEffect(() => {
    setBotContainer(openBotContainer);
    setBotFromLeftPanel(isBotFromDrawer);
    setBotOpenFromInlineContent(isBotFromInlineContent);
    setBotOpenFromLightBox(isBotFromLightBox);
    if (!openBotContainer) {
      if (showBotContainer) {
        setAnimationClass('chatBOTClose');
      }
      Utils.pauseVideoOnBotClose();
    }
    if (isBotFromDrawer) {
      if (users.isFirstTimeAITutorUser && isFirstTimeLaunch) {
        isFirstTimeLaunch = false;
        firstTimeUser.current = false;
        updateAITutorUserSettings({ isFirstTimeAITutorUser: false });
      }
      setIsExpanded(false);
    }
    setShowHistory(false);
  }, [openBotContainer]);
  useEffect(() => {
    botInitiated();
    Utils.loadExternalJS(constants.MARKDOWN_URL);
    MathUtil.loadMathJax();
  }, []);
  useEffect(() => {
    if (aiTutorAutoOpenCount === 1) {
      setShowModal(true);
    }
    if (aiTutorAutoOpenCount > 0 && aiTutorAutoOpen) {
      setAiStudyToolAutoOpen(true);
    }
  }, [aiTutorAutoOpenCount]);
  useEffect(() => {
    const stepLength = steps.length;
    const lastStep = steps[stepLength - 1];
    if((!isExplainCYUMode || enableExplainQuizOptions !== constants.EXPLAIN_QUIZ_OPTION_B)) {
      if (!streamingScroll && stepLength === 1) {
        Utils.scrollToView(5);
      } else {
        if (!streamingScroll && lastStep && !(
          lastStep.id === constants.CLARIFY)) {
          Utils.scrollToView(5, (historyPage && historyScrollTop === 0 && historyScrollToCount) ? `step_${historyScrollToCount}` : `step_${stepLength - 1}`);
        } else if(!streamingScroll && lastStep && lastStep.id === constants.CLARIFY){
          Utils.scrollToView(5, `step_${stepLength - 2}`);
        }
      }
      historyScrollToCount = 0;
    }
    if(lastStep && (
      (lastStep.id === constants.SUMMARY_CONTENT) ||
      (lastStep.isDiscussFeature))) {
      inputRef.current.focus();
    }
    lastStep?.id === constants.LOADER ? setLoading(true) : setLoading(false);
  }, [steps]);

  useEffect(() => {
    setBotAutoOpen(aiTutorAutoOpen);
  }, [aiTutorAutoOpen]);

  useEffect(()=>{
    if(isStopStreaming) {
      document.addEventListener("wheel", handleWheelScroll);
      setAriaAssertive('assertive');
    }
    else {
      setAriaAssertive('off');
      setStreamingScroll(false);
      eventSourceRef.current = null;
    }
    return ()=> {
      document.removeEventListener("wheel", handleWheelScroll);
      setAriaAssertive('off');
    }
    
  }, [isStopStreaming]);

  useEffect(() => {
    setIsExpanded(expandMode);
  }, [expandMode]);

  const handleClickAction = (type, linkCount,) => {
    const { src, alt, dataset} = (event && event.target) || {};
    const { caption, pageid } = dataset || {};
    if(type === constants.IMAGE) {
      setLightboxImageData({
        src,
        alt,
        caption
      });
      setOpenLightbox(true);
      clickMediaCardGAEvent(handleGAEvents, context.bookID, src.split('/').pop(), promptVersionForContentImage);
    } else {
      if (pageid) {
        onPageNavigation(pageid);
        const targetUrl = Utils.targetUrlLink(pageid);
        clickOnReferenceLink(handleGAEvents, globalContext.threadId, linkCount, targetUrl, context.bookID);
      }
    }
  };
  useEffect(() => {
    if(quizStreamEnd) {
      steps.forEach((step, stepIndex) => {
        if (quizStreamRequestId && quizStreamResponseId && step.requestId === quizStreamRequestId && step.responseId === quizStreamResponseId && (step.id === constants.QUIZ_CONTENT || step.id === constants.FREE_RESPONSE_QUIZ)) {
          totalQuestionCount = quizQuestionsData && quizQuestionsData.length;
          steps[stepIndex].data.totalQuestionCount = quizQuestionsData && quizQuestionsData.length;
        }
      });
      isLastPage.current = true
      setSteps(steps);
    }
  }, [quizStreamEnd]);

  const getVersionName = (promptVersionName, promptVersion) => {
    let versionName = '';
    switch (promptVersionName) {
      case constants.PROMPT_VERSION_KEYWORDS.EXPLAIN_CASE:
        versionName = constants.PROMPT_VERSION_KEYWORDS.Explain_Name;
        return { prompt_version: `${versionName}_${promptVersion}` };
      //! The below conditions are for backward compatibility of API responses which used to send GA in future(NOT YET IMPLEMENTED)!
      case constants.PROMPT_VERSION_KEYWORDS.SUMMARYTOPIC_CASE:
        versionName = constants.PROMPT_VERSION_KEYWORDS.SummaryTopic_Name;
        return { prompt_version: `${versionName}_${promptVersion}` };
      case constants.PROMPT_VERSION_KEYWORDS.PROBLEMFIXER_CASE:
        versionName = constants.PROMPT_VERSION_KEYWORDS.ProblemFixer_Name;
        return { prompt_version: `${versionName}_${promptVersion}` };
      case constants.PROMPT_VERSION_KEYWORDS.SUMMARY_CASE:
        versionName = constants.PROMPT_VERSION_KEYWORDS.SummaryReg_Name;
        return { prompt_version: `${versionName}_${promptVersion}` };
      case constants.PROMPT_VERSION_KEYWORDS.MCQTOPIC_CASE:
        versionName = constants.PROMPT_VERSION_KEYWORDS.MCQTopic_Name;
        return { prompt_version: `${versionName}_${promptVersion}` };
      case constants.PROMPT_VERSION_KEYWORDS.SHORTANSTOPIC_CASE:
        versionName = constants.PROMPT_VERSION_KEYWORDS.ShortAnsTopic_Name;
        return { prompt_version: `${versionName}_${promptVersion}` };
      case constants.PROMPT_VERSION_KEYWORDS.MCQCYU:
        versionName = constants.PROMPT_VERSION_KEYWORDS.MCQCYU;
        return { prompt_version: `${versionName}_${promptVersion}` };
      case constants.PROMPT_VERSION_KEYWORDS.SUMMARYTOPIC_CASE:
        versionName = constants.PROMPT_VERSION_KEYWORDS.SummaryTopic_Name;
        return { prompt_version: `${versionName}_${promptVersion}` };
      default:
        return {};
    }
  }

  const getPromptVersion = (responseData, intent) => {
    if (!responseData || !responseData.prompts || !responseData.prompts.length || !intent) {
      return {};
    }
    const promptVersionData = responseData.prompts.find(
      (item) => item.name !== constants.PROMPT_VERSION_KEYWORDS.MAIN_INTENT
    );
    const promptVersionMainData = responseData.prompts.find(
      (item) => item.name === constants.PROMPT_VERSION_KEYWORDS.MAIN_INTENT
    );
    if (!promptVersionData || !promptVersionMainData) {
      return {};
    }
    const promptVersionName = promptVersionData.name;
    const promptVersion = promptVersionData.version;
    const promptVersionMainDataNullCheck = promptVersionMainData.version && promptVersionMainData.name;
    if (intent === constants.PROMPT_VERSION_KEYWORDS.TRIGGER_INTENTION) {
      return promptVersionMainDataNullCheck ? {prompt_version: `${constants.PROMPT_VERSION_KEYWORDS.MainIntent}_${promptVersionMainData.version}`} : {};
    } else if (intent === constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS) {
      return promptVersionData ? getVersionName(promptVersionName, promptVersion) : {};
    } else if (intent === constants.PROMPT_VERSION_KEYWORDS.ANSWER_PRACTICE_QUESTION && promptVersionName === constants.PROMPT_VERSION_KEYWORDS.SHORTANS_CASE) {
      return (promptVersionData ? {prompt_version: `${constants.PROMPT_VERSION_KEYWORDS.ShortAns_Name}_${promptVersion}`} : {});
    } else if (intent === constants.PROMPT_VERSION_KEYWORDS.CLICK_TOPIC_SUGGESTION && promptVersionName === constants.PROMPT_VERSION_KEYWORDS.TOPICEXTRACT_CASE) {
      return (promptVersionData ? {prompt_version: `${constants.PROMPT_VERSION_KEYWORDS.TopicExtract_Name}_${promptVersion}`} : {});
    }
    return {};
  }


  const handleCloseModal= () =>{
    setShowModal(false);
  }

  const handleContentRange = (contentRangeType) => {
    if (contentRangeType === constants.CURRENT_SECTION || contentRangeType === constants.SECTION || contentRangeType === constants.TOPIC_MODULE || contentRangeType === constants.TOPIC_SLATE || contentRangeType === constants.TOPIC_SUBMODULE) {
      return constants.SECTION.charAt(0).toUpperCase() + constants.SECTION.slice(1);
    } else if (contentRangeType === constants.CURRENT_CHAPTER || contentRangeType === constants.CHAPTER) {
      return constants.CHAPTER.charAt(0).toUpperCase() + constants.CHAPTER.slice(1);
    } else if (contentRangeType === constants.CURRENT_PAGE || contentRangeType === constants.GPT_EVENTS.PAGE.toLowerCase()) {
      return constants.GPT_EVENTS.PAGE;
    }
    return constants.GPT_EVENTS.TOPIC;
  }

  const handleFormatType = (formatResponseText, formatResponseOption) => {
    let defaultFormat = constants.GPT_EVENTS.BULLET;
    if (formatResponseText && formatResponseText.includes('<p>') && formatResponseText.includes('<ul>') && formatResponseText.includes('<li>')) {
      defaultFormat = constants.GPT_EVENTS.MIX;
    } else if (formatResponseOption === constants.BULLET) {
      defaultFormat = constants.GPT_EVENTS.BULLET;
    } else if (formatResponseOption === constants.PARAGRAPH || formatResponseOption === constants.GPT_EVENTS.PARAGRAPH.toLowerCase()) {
      defaultFormat = constants.GPT_EVENTS.PARAGRAPH;
    }
    return defaultFormat;
  }

  const handleGptCreateNote = (text, chapterId, chapterTitle) => {
    const turndownService = new TurndownService();
    const markdownNote = turndownService.turndown(text || '');
    if (markdownNote) {
      handleChatGPTCustomNote(markdownNote, chapterId, chapterTitle);
    } else {
      setTimeout(()=>{
        setShowSnackBar({ isOpen: true, message: formatMessage(messages.somethingWentWrong), duration: 3000 });
      },100);
    }
  }
  const startTimer = (callback) => {
    let timmer = setTimeout(() => {
      callback();
    }, delayMessageTimerInSecs);
    return timmer;
  }

  const handleCancelRequestAPI = () => {
    setIsAPICalled(false);
    berlinService.handleCancelRequest();
    if (steps && steps.length) {
      const stepLength = steps.length;
      const lastStep = steps[stepLength - 1];
      if (lastStep.id !== constants.QUIZ_CONTENT && lastStep.id !== constants.FREE_RESPONSE_QUIZ && lastStep.id !== constants.FREE_RESPONSE_QUIZ_VALIDATE && lastStep.id !== constants.QUIZ_ANSWER) {
        optionSelected = '';
      }
      isExplainCYUMode = '';
      // remove the loader if it is last step
      if (lastStep.id === constants.LOADER) {
        steps.pop();
        setSteps([...steps]);
      }
    }
  };

  const handleShowWaitingMessage = () => {
    const responseData = {
      id: constants.ERROR_MESSAGE,
      message: 'Okay, let\'s wait it out.'
    };
    steps.splice(-2, 1);
    steps.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      if (item.id === constants.DELAYED_RESPONSE) item.showOptions = true;
    });
    steps.push(responseData);
    steps.push(loaderStep);
    setSteps([...steps]);
    const additionalFields = {
      event_label: 'wait',
      event_value: null,
      event_action: eventAction.longerTimeResponse
    };
    gaEvents(handleGAEvents, additionalFields);
  }

  const handleStopRequestShowOptions = () => {
    handleCancelRequestAPI();
    if(eventSourceRef.current !== null) abortStreamConnection();
    if (steps[steps.length - 1].id === 'delay') {
      steps.pop();
      steps.pop();
    }
    const responseData = {
      id: constants.AFTER_STOP,
      data: {
        isFromStopQuiz: false,
        isFromUnknownIntentType: false,
        isMove: false
      }
    };
    setIsTextBoxDisable(false);
    steps.push(responseData);
    setSteps([...steps]);
    const additionalFields = {
      event_label: 'stop',
      event_value: null,
      event_action: eventAction.longerTimeResponse
    };
    gaEvents(handleGAEvents, additionalFields);
  }

  const abortStreamConnection = () => {
    if (Object.keys(outputSuccessGAEvent).length) {
      gaEvents(handleGAEvents, outputSuccessGAEvent);
      isContentSelectFlow.current= false;
      isExplainClicked = false;
      isFromRecommendationPill = false;
    }
    quizStreamStatus = '';
    setIsStreaming(false);
    setIsStopStreaming(false);
    setIsTextBoxDisable(false);
    eventSourceRef && eventSourceRef.current && eventSourceRef.current.close && eventSourceRef.current.close();
    recommendationEventRef && recommendationEventRef.current && recommendationEventRef.current.close && recommendationEventRef.current.close();
    const filteredSteps = steps.find(obj => obj.requestId === currentStreamResponseId.current);
    if (filteredSteps) {
      filteredSteps.streamStatus = 'done';
      filteredSteps.data.showExplainQuiz = false;
    }
    setSteps(steps);
    currentStreamResponseId.current = '';
  };

  const handleWheelScroll = useCallback((event) => {
    if(!streamingScroll && eventSourceRef.current !== null
      && ((event.type === 'keydown' && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) || (event.type === 'wheel'))) {
        setStreamingScroll(true);
    }
  }, [])

  const handleMouseDown = useCallback((event) => {
    if(!streamingScroll && eventSourceRef.current !== null && event.target && event.target.id && event.target.clientWidth && event.target.offsetWidth && event.target.scrollTop)
    event.preventDefault();
    setChatContainerScrollBar({
      clientWidth: event.target.clientWidth,
      offsetWidth: event.target.offsetWidth,
      scrollTop: event.target.scrollTop
    });
  }, [])

  const handleMouseUp = useCallback((event) => {
    if(!streamingScroll && eventSourceRef.current !== null && event.target && event.target.clientWidth && event.target.offsetWidth && event.target.scrollTop){
      if(chatContainerScrollBar.clientWidth === event.target.clientWidth && chatContainerScrollBar.offsetWidth === event.target.offsetWidth && chatContainerScrollBar.scrollTop !== event.target.scrollTop){
        event.preventDefault();
        setStreamingScroll(pre => !pre);
        setChatContainerScrollBar({});
      }
    }
  }, [])

  useEffect(() => {
    if (isBotOpenedFromContentSelector) {
      isFromContentSelector.current  = true;
    }
  }, [isBotOpenedFromContentSelector]);

  const handleBotOpenLocation = () => {
    let locationInApp = constants.GPT_EVENTS.FREESTANDING_ICON;
    if (isFromContentSelector.current) {
      locationInApp = constants.GPT_EVENTS.CONTENT_SELECTOR.toLowerCase();
    } else if (botFromLeftPanel && botFromInlineContent) {
      locationInApp = constants.GPT_EVENTS.CONTENT_MEDIA;
    } else if (botFromLeftPanel && botFromLightBox) {
      locationInApp = constants.GPT_EVENTS.LIGHTBOX;
    } else if (botFromLeftPanel) {
      locationInApp = constants.GPT_EVENTS.NAVIGATION_DRAWER;
    }
    return locationInApp;
  }

  useEffect(() => {
    if (showBotContainer) {
      setAriaDisable(false);
      setBotRotate(false);
      handleChatbotOpened({
        event: constants.CHATBOT_OPEN
      });
      if (!window.sessionStorage.getItem('isFirstTimeSession')) {
        window.sessionStorage.setItem('isFirstTimeSession', true);
      }
      if (!contentSelectorBotOpen) setContentSelectorBotOpen(true);
      setAnimationClass('chatBOTOpen');
      let additionalFields = {
        event_label: aiStudyToolAutoOpen ? constants.GPT_EVENTS.SYSTEM : constants.GPT_EVENTS.CLICKED,
        event_value: null,
        event_action: eventAction.clickChatGpt,
        location_in_app: handleBotOpenLocation(),
        utm_source: null,
        utm_campaign: null
      };
      if (openAITutor.isOpen) {
        const campaignData = openAITutor.eventData;
        additionalFields.utm_source = campaignData.utm_source;
        additionalFields.utm_campaign = campaignData.utm_campaign;
      }
      if(!initiatedPoint) {
        gaEvents(handleGAEvents, additionalFields);
      }
      eReaderABTestExperienceGAEvent(handleGAEvents, botFromLeftPanel);
      setBotOpenedTime(new Date().getTime());
    }
    handleBotBackNavigation(showBotContainer);
  }, [showBotContainer]);

  useEffect(() => {
    const handleCardCreation = EventBus.on(constants.CHATGPT_CARD_CREATED, (data) => {
      let snackMessage = null;
      if (data.type === constants.CHATGPT_CUSTOM_NOTE_CREATED && data.payLoad) {
        snackMessage = messages.noteCardCreated;
      } else {
        snackMessage = messages.somethingWentWrong;
      }
      setShowSnackBar({ isOpen: true, message: formatMessage(snackMessage), duration: 3000 });
    });
    return () => {
      handleCardCreation();
    };
  }, []);
  /* Sleep Mode Changes Start --- */
  const sleep = () => {
    isSleepModeOnHover = true;
    const chatbot = document.getElementById('chatbot');
    if(chatbot) {
      chatbot.classList.remove('hover');
      chatbot.classList.add('sleep');
      if (!showAISleepModeAB) {
        chatbot.classList.add('removeDisableStyle');
      }
    }
  };
  /** onMouse hover of botIcon*/
  const hanbleBotMouseOver = (event) => {
    if (isSleepModeOnHover) {
      isSleepModeOnClick = true;
      onLDTrack(constants.CHATBOT_HOVER);
    }
    if (enableAISleepMode) wakeup();
  }
  /* Invoke sleep mode Start--- */
    const wakeup = () => {
      clearTimeout(sleepTimer);
      isSleepModeOnHover = false;
      const chatbot = document.getElementById('chatbot');
      if (chatbot) {
        chatbot.classList.remove('sleep');
        chatbot.classList.remove('removeDisableStyle');
        sleepTimer = setTimeout(sleep, constants.SLEEP_MODE_TIME_OUT);
      }
    };
  /* Sleep Mode Changes End --- */

  useEffect(() => {
    if (isPageLoaded && showChatBotIcon) {
      document.body.classList.add('botRotateBodyOverFlow');
      setBotRotate(true);
      rotateRef && rotateRef.current && rotateRef.current.classList && rotateRef.current.classList.add(classes.botRotate);
      setTimeout(() => {
        rotateRef && rotateRef.current && rotateRef.current.classList && rotateRef.current.classList.remove(classes.botRotate);
        document.body.classList.remove('botRotateBodyOverFlow');
        setBotRotate(false);
        EventBus.publish(constants.AI_TUTOR_LOADED, {loaded: true}); // Event publish to display Braze Campaign popup
      }, 1500);
      if (enableAISleepMode) {
        sleepTimer = setTimeout(sleep, constants.SLEEP_MODE_TIME_OUT);
      }
    }
  }, [isPageLoaded]);

  useEffect(() => {
    if (openBotViaContentSelector) {
      isExplainCYUMode = '';
      handleCancelRequestAPI();
      abortStreamConnection();
      if (users.isFirstTimeAITutorUser) {
        firstTimeUser.current = false;
        updateAITutorUserSettings({ isFirstTimeAITutorUser: false });
      }
      if (!window.sessionStorage.getItem('isFirstTimeSession')) {
        window.sessionStorage.setItem('isFirstTimeSession', true);
        isContentSelectorFirstTime.current = true;
      } else {
        isContentSelectorFirstTime.current = false;
      }
      isFromContentSelector.current = true;
      selectedContent = selectedText.data.selectedText;
      setShowHistory(false);
      setBotContainer(true);
      setShowOptions(false);
      const selectedTextFromContentSelector = selectedText.data.selectedText;
      if (steps && steps.length) {
        const lastStep = steps[steps.length - 1];
        if(lastStep && lastStep.id === constants.STREAMING_DISCUSS) {
          lastStep.data.showExplainQuiz = false;
        }
        if (lastStep.id === constants.DISCUSS || lastStep.id === constants.STREAMING_DISCUSS) {
            // eslint-disable-next-line no-param-reassign
            lastStep.data.disableOptions = true;
          }
            // eslint-disable-next-line no-param-reassign
        if (lastStep.id === constants.FREE_RESPONSE_QUIZ || lastStep.id === constants.QUIZ_CONTENT) {
          // eslint-disable-next-line no-param-reassign
          lastStep.data.disableOptions = true;
          // eslint-disable-next-line no-param-reassign
          lastStep.data.showOptions = false;
        }
        if (lastStep.id === constants.FREE_RESPONSE_QUIZ_VALIDATE) {
          // eslint-disable-next-line no-param-reassign
          lastStep.data.isReadyNext = false;
        }
        if (lastStep.id === constants.QUIZ_ANSWER) {
          // eslint-disable-next-line no-param-reassign
          lastStep.data.disableOptions = true;
        }
        if (lastStep.id === constants.CHAPTER_TOPIC_OPTION) {
          lastStep.data.disableOptions = true;
        }
        if (lastStep.id === constants.GENERATE_TOPIC) lastStep.data.disbaleTopics = true;
        if (lastStep.id === constants.AFTER_STOP || lastStep.id === constants.WELCOME_MESSAGE) steps.pop();
        if (lastStep.id === constants.SUMMARY_CONTENT && isSummaryQuiz) {
          steps[steps.length - 1].data.summaryQuizButton = false;
        }
        if (lastStep.id === constants.STREAMING_DISCUSS && isExplainQuiz) {
          steps[steps.length - 1].data.explainQuizButton = false;
        }
        if (lastStep && lastStep.id === constants.IMAGE_CONTEXT) {
          lastStep.disableOptions = true;
        }
      }
      const contentSelectorInput = {
        id: constants.CUSTOM_INPUT,
        data: {
          inputValue: selectedTextFromContentSelector
        }
      };
      steps.push(contentSelectorInput);
      if (!startNewConversationGaEvent) {
        startNewConversationGA();
        setStartNewConversationGaEvent(true);
      }
      setContentSelectorSelectedText(selectedTextFromContentSelector);
      onContentSelectionOptionClick(constants.DISCUSS, selectedTextFromContentSelector);
    }
  }, [openBotViaContentSelector]);

  useEffect(() => {
    if (AIEnableImageContext && showImageCopilot && imageContextData && imageContextData.contextUrl) {
      globalContext.imageCopilotMode = true;
      globalContext.imageContextData = imageContextData;
      imageCopilotInitialization = true;
      optionSelected = 'discuss';
      if (steps && steps.length && steps[steps.length - 1].id === constants.IMAGE_CONTEXT) {
        steps[steps.length - 1].disableOptions = true;
      }
      abortStreamConnection();
      if (users.isFirstTimeAITutorUser) {
        firstTimeUser.current = false;
        updateAITutorUserSettings({ isFirstTimeAITutorUser: false });
      }
      setShowHistory(false);
      setShowOptions(false);
      if (steps && steps.length) {
        const lastStep = steps[steps.length - 1];
        if(lastStep && lastStep.id === constants.STREAMING_DISCUSS) {
          lastStep.data.showExplainQuiz = false;
        }
        if (lastStep.id === constants.DISCUSS || lastStep.id === constants.STREAMING_DISCUSS) {
          // eslint-disable-next-line no-param-reassign
          lastStep.data.disableOptions = true;
        }
            // eslint-disable-next-line no-param-reassign
        if (lastStep.id === constants.FREE_RESPONSE_QUIZ ||
          lastStep.id === constants.QUIZ_CONTENT || lastStep.id === constants.QUIZ_ANSWER ||
          lastStep.id === constants.CLARIFY || lastStep.id === constants.CHAPTER_TOPIC_OPTION ||
          lastStep.id === constants.SUMMARY_OPTIONS || lastStep.id === constants.QUIZ_OPTIONS
        ) {
          // eslint-disable-next-line no-param-reassign
          lastStep.data.disableOptions = true;
          // eslint-disable-next-line no-param-reassign
          lastStep.data.showOptions = false;
        }
        if (lastStep.id === constants.FREE_RESPONSE_QUIZ_VALIDATE) {
          // eslint-disable-next-line no-param-reassign
          lastStep.data.isReadyNext = false;
        }
        if (lastStep.id === constants.GENERATE_TOPIC) lastStep.data.disbaleTopics = true;
        if (lastStep.id === constants.AFTER_STOP || lastStep.id === constants.WELCOME_MESSAGE) steps.pop();
        if (lastStep.id === constants.SUMMARY_CONTENT && isSummaryQuiz) {
          steps[steps.length - 1].data.summaryQuizButton = false;
        }
        if (lastStep.id === constants.STREAMING_DISCUSS && isExplainQuiz) {
          steps[steps.length - 1].data.explainQuizButton = false;
        }
      }
      steps.push({
        id: constants.IMAGE_CONTEXT,
        imageContextData
      });
      setSteps([...steps]);
    }
  }, [showImageCopilot]);

  useEffect(() => {
    if (executeCommand) {
      abortStreamConnection();
      if (users.isFirstTimeAITutorUser) {
        firstTimeUser.current = false;
        updateAITutorUserSettings({ isFirstTimeAITutorUser: false });
      }
      setShowHistory(false);
      setBotContainer(true);
      setShowOptions(false);
      if (steps && steps.length) {
        const lastStep = steps[steps.length - 1];
        if(lastStep && lastStep.id === constants.STREAMING_DISCUSS) {
          lastStep.data.showExplainQuiz = false;
        }
        if (lastStep && (lastStep.id === constants.LOADER || lastStep.id === constants.AFTER_STOP || lastStep.id === constants.WELCOME_MESSAGE)) {
          steps.pop();
        }
        /** Disable Previous Step CTA Button Start */
          
          if (steps.slice(-1)[0].id &&
            (steps.slice(-1)[0].id === constants.CLARIFY ||
              steps.slice(-1)[0].id === constants.CHAPTER_TOPIC_OPTION ||
              steps.slice(-1)[0].id === constants.SUMMARY_OPTIONS ||
              steps.slice(-1)[0].id === constants.QUIZ_OPTIONS)) {
            steps.slice(-1)[0].data.disableOptions = true;
          } else if (steps.slice(-1)[0].id && steps.slice(-1)[0].id === constants.GENERATE_TOPIC) {
            steps.slice(-1)[0].data.disbaleTopics = true;
          } else if (steps.slice(-1)[0].id &&
            (steps.slice(-1)[0].id === constants.WELCOME_MESSAGE || steps.slice(-1)[0].id === constants.AFTER_STOP)) {
            steps.pop();
          }
          if (steps[steps.length - 1].id === constants.SUMMARY_CONTENT && isSummaryQuiz) {
            steps[steps.length - 1].data.summaryQuizButton = false;
          }
          if (steps[steps.length - 1].id === constants.STREAMING_DISCUSS && isExplainQuiz) {
            steps[steps.length - 1].data.explainQuizButton = false;
          }
          if (steps.slice(-1)[0].id && steps.slice(-1)[0].id === constants.IMAGE_CONTEXT) {
            steps.slice(-1)[0].disableOptions = true;
          }
        /** Disable Previous Step CTA Button End */
      }
      
      const glossaryStep = {
        id: constants.USER_MESSAGE,
        data: {
          text: executeCommand
        }
      };
      steps.push(glossaryStep);
      setSteps([...steps]);
      if(initiatedPoint === constants.GLOSSARY) {
        gaEvents(handleGAEvents, {
          event_action: eventAction.ClickGlossaryTerm,
          event_label: "Tell me more",
          event_value: null,
          chat_type: constants.GPT_EVENTS.EXPLAIN,
        });
      }
      
      setTimeout(()=> {
        optionSelected = '';
        isExplainCYUMode = '';
        handleSend({}, executeCommand, false, false, false, false, false, false, initiatedPoint === constants.GLOSSARY ? true : false);
      }, 100);
    }
  }, [executeCommand]);

  useEffect(() => {
    if (openAITutor.isOpen) {
      if (users.isFirstTimeAITutorUser) {
        firstTimeUser.current = false;
        updateAITutorUserSettings({ isFirstTimeAITutorUser: false });
      }
      setBotContainer(true);
      setShowOptions(true);
    }
  }, [openAITutor]);

  useEffect(() => {
    globalContext.enableNewMathConfig = enableNewMathConfig;
  }, [enableNewMathConfig]);

  useEffect(() => {
    const { userId } = users;
    if(userId) {
      globalContext.userId = userId;
    }
  }, [users?.userId]);

  useEffect(() => {
    const { berlinTenantObject: {tenantId, tenantKey} } = context || {};
    if(tenantId && tenantKey) {
      globalContext.tenantId = tenantId;
      globalContext.tenantKey = tenantKey;
    }
  }, [context?.berlinTenantObject?.tenantId, context?.berlinTenantObject?.tenantKey]);

  useEffect(() => {
    if(env) {
      globalContext.env = env.toLowerCase();
    }
  }, [env]);

  const startNewConversationGA = () => {
    // const additionalFields = {
    //   event_label: null,
    //   event_value: null,
    //   chat_type: 'conversation',
    //   event_action: eventAction.startChatbotConversation
    // };
    // gaEvents(handleGAEvents, additionalFields);
  };

  const handleImagePromptClick = (promptObject) => {
    if (steps && steps.length && steps[steps.length - 1].id === constants.IMAGE_CONTEXT) {
      steps[steps.length - 1].disableOptions = true;
    }
    const userCommandMessage = {
      id: constants.USER_MESSAGE,
      data: {
        text: promptObject.title
      }
    };
    steps.push(userCommandMessage);
    setSteps([...steps]);
    optionSelected = constants.DISCUSS;
    identifierType = '';
    isSummaryQuiz = false;
    isExplainQuiz = false;
    clickSuggestionGAEvent(handleGAEvents, context.bookID || context.secondaryBookId, promptObject.title);
    if(promptObject.userAction === constants.IMAGE_SOMETHING_ELSE) {
      const elseCannedMessage = {
        id: constants.CANNED_MESSAGE,
        message: formatMessage(messages.imageSomethingElseCannedMessage),
        intentType: constants.IMAGE_SOMETHING_ELSE
      }
      steps.push(elseCannedMessage);
      setSteps([...steps]);
    } else {
      handleSend({}, promptObject.title, false, false, false, false, false, false, false, '', false, false, promptObject.userAction);
    }
  };

  const errorMessageGAEvent = (errorCode) => {
    if(errorCode === 400 || errorCode === 500){
      const errorMessageFields = {
        event_label: null,
        event_value: null,
        event_action: eventAction.aiErrorMessage,
        error_code: 'not_support'
      };
      gaEvents(handleGAEvents, errorMessageFields);
    }
  };

  const handleCloseFeedbackMessagePopup = () => {
    setShowFeedbackMessagePopup(false);
    setIsClickAway(false);
    setFeedbackIconFocusId({...feedbackIconFocusId, status: true});
  }
  const handleClickAway = () => {
    setIsClickAway(true);
    setTimeout(() => {
      handleCloseFeedbackMessagePopup();
    }, 200);
  };

  const handleMathKeyboard = () => {
    if (openMathKeyboard) {
      mathVirtualKeyboard.visible = false;
    } else {
      openMathKeyboardGAEvent(handleGAEvents, context.bookID || context.secondaryBookId,
      globalContext.threadId);
    }
    setOpenMathKeyboard(!openMathKeyboard);
  }

  const onMathInputKeyUp = (e, value = '') => {
    const userInputString = e.target ? e.target.innerText : value;
    setInputValue(e.target.innerHTML);
    if (e.keyCode === 13 && userInputString.trim().length) {
      handleSend(e);
    }
  }

  const onMathInput = (value, mathField, MathMLCode) => {
    onSendMathTextGAEvent(handleGAEvents, context.bookID || context.secondaryBookId, globalContext.threadId);
    const editableInputElement = document.getElementById('editableMathInput');
    const mathElement = document.createElement('span');
    mathElement.classList.add('mathField');
    mathElement.innerHTML = mathField;
    mathElement.setAttribute('contentEditable', false);
    mathElement.setAttribute('data-mathLatex', value);
    setInputValue('Math code inserted');
    editableInputElement.appendChild(mathElement);
    setOpenMathKeyboard(false);
  }


  const handleCloseSnackBar = () => {
    setShowSnackBar({ isOpen: false, message: '', duration: 6000 });
  };
  const handleChange = (event) => {  
    let inputVal = event.target.value;
    if(inputVal.length <= constants.maxInputCharLength){
      setInputValue(inputVal);
      setMaxCharValidationMessage('');
      setIsSendBtnDisable(false);
    } else {
      inputVal = inputVal.slice(0, constants.maxInputCharLength);
      setInputValue(inputVal);
      setIsSendBtnDisable(false);
      setMaxCharValidationMessage( `Max char. limit: ${constants.maxInputCharLength}`);
    }
    // setIsFromContentSelector(false);
  };

  const handleSubmitFeedback = useCallback((payloadInfo, handleClose, showFeedbackSnackbar = true, feedbackIdRef = '') => {
    hasFeedbackId.current = ''
    if(feedbackIdRef){
      hasFeedbackId.current = feedbackIdRef;
    }
    const encodedData = payloadInfo.data && Utils.base64Encode(payloadInfo.data);
    const payload = {
      bookId: context.bookID,
      type: payloadInfo.type,
      requestId: payloadInfo.requestId,
      responseId: payloadInfo.responseId,
      feedback: payloadInfo.feedbackType,
      comments: payloadInfo.userComment.trim(),
      reasonForDisLike: payloadInfo.userSelectedOptions,
      content: { data: encodedData },
      ...(hasFeedbackId.current !== '' && { feedbackId: hasFeedbackId.current })
    };
    getAuthToken((tokenKey) => {
      berlinService.postFeedbackData(
        payload, hasFeedbackId.current, tokenKey,
        (data, error) => {
          if (data) {
            if (showFeedbackSnackbar) setShowFeedbackMessagePopup(true); setFeedbackIconFocusId({id: data.feedbackId, type: payloadInfo.feedbackType});
            handleClose(payloadInfo.feedbackType === 'LIKE' ? 'positiveFeedback' : 'negativeFeedback', true, data.feedbackId);
            hasFeedbackId.current = '';
          } else if (error) {
            if (showFeedbackSnackbar) setShowSnackBar({ isOpen: true, message: 'error', duration: 6000 });
          }
        }
      ).catch((error) => {
        handleClose(payloadInfo.feedbackType === 'LIKE' ? 'positiveFeedback' : 'negativeFeedback', true);
        if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
        if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
        if (error.response === undefined && error.code === 'ECONNABORTED') {
          errorData.message = Utils.getErrorMessage(error.code);
        } else {
          errorData.message = Utils.getErrorMessage(error.response.status);
        }
        const errorString = error.toString();
        if (errorString && errorString.indexOf('timeout') !== -1) {
          errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
        }
      }
      else {
        errorData.message = Utils.getErrorMessage();
      }
      steps.push(errorData);
      setSteps([...steps]);
      });
    });
  }, []);
  const removeLastStep = (stepId) => {
    const lastStepObject = steps && steps.length && steps[steps.length -1];
    if(lastStepObject && lastStepObject.id === stepId) {
      steps.pop();
    }
  };
  const handleQuizStop = useCallback((isLastQuestion = false) => {
    removeLastStep(constants.WELCOME_MESSAGE);
    if(isRecommendations) {
      setRecommendations(false);
    }
    stopQuiz = true;
    optionSelected = '';
    isFromContentSelector.current = false;
    nextQuestion.current = '';
    quizRefId.current = '';
    currentQuestionIndex.current = 0;
    quizQuestionsData = [];
    quizStreamRequestId = '';
    quizStreamResponseId = '';
    totalQuestionCount = 0;
    setQuizStreamEnd(false);
    isLastPage.current = false;
    isSummaryQuiz = false;
    isExplainQuiz = false;
    isExplainCYUMode = '';
    contentRangeValue = '';
    quizRequestId = '';
    summaryQuizSelectedOption = '';
    summaryQuizPageIds = [];
    const welcomeMessage = {
      id: constants.WELCOME_MESSAGE,
      data: {
        isFromContentSelector: isFromContentSelector.current,
        isFirstTimeAITutorUser: users.isFirstTimeAITutorUser,
        isNewTopic: true,
        isLastQuestion,
        isFromStopQuiz: true
      }
    };
    if(eventSourceRef.current !== null) {
      abortStreamConnection();
    }
    clearInterval(nextQuestionSetInterval);
    steps.push(welcomeMessage);
    setSteps([...steps]);
    setTimeout(()=>{setAriaAssertive('off');}, 1000);
  }, [steps]);

  const handleNextQuizQuestion = useCallback((selectedOptionValue, selectedAnswerIndexId, answerId, explanation, correctAnswer, skipQuestion, pageIds, isFreeResponse, commandSourceCode, chapterData, totalQuestionCount, isExplainCYUMode, responseData) => {
    if (isExplainCYUMode) {
      onLDTrack(constants.CYU_ANSWER_SUBMITTED);
    }
    removeLastStep(constants.WELCOME_MESSAGE);
    if(isRecommendations) {
      setRecommendations(false);
    }
    steps.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      if (item.id === constants.FREE_RESPONSE_QUIZ || item.id === constants.QUIZ_CONTENT) item.data.disableOptions = true;
    });
    if (skipQuestion || !answerId) {
      // eslint-disable-next-line no-use-before-define
      // if (skipQuestion) {
      //   const skipMessage = {
      //     id: constants.INVALID,
      //     data: {
      //       message: 'Here\'s the next one'
      //     }
      //   };
      //   steps.push(skipMessage);
      //   setSteps([...steps]);
      // }
      handleShowNextQuestion(pageIds, isFreeResponse, chapterData);
      if (skipQuestion) {
        const additionalFields = {
          event_label: null,
          event_value: null,
          command_source_code: 'click',
          event_action: eventAction.skipButton
        };
        gaEvents(handleGAEvents, additionalFields);
      }
    } else {
      /** ****** Show the selected radio option ********************* */
      // const userSelectedans = {
      //   id: 'userresponse',
      //   message: selectedOptionValue,
      //   user: true,
      //   displayAvatar: false
      // };
      // steps.push(userSelectedans);
      // setSteps(steps);
      const gaIntentOptions = [constants.SUMMARY_INTENT, constants.EXPLAIN_INTENT];
      let chatType = constants.GPT_EVENTS.PRACTICE;

      if (gaIntentOptions.includes(responseData && responseData.intentType)) {
        chatType = (responseData.intentType === constants.SUMMARY_INTENT ? constants.GPT_EVENTS.SUMMARY : constants.GPT_EVENTS.EXPLAIN);
      }
      const additionalFields = {
        event_label: null,
        event_value: null,
        correctness: (selectedAnswerIndexId === answerId) ? '1' : '0',
        event_action: eventAction.answerPracticeQuestion,
        question_type: isFreeResponse ? constants.GPT_EVENTS.FREE_RESPONSE : constants.GPT_EVENTS.MULTI_CHOICE,
        ...getPromptVersion(responseData, constants.PROMPT_VERSION_KEYWORDS.ANSWER_PRACTICE_QUESTION),
        command_source_code: commandSourceCode,
        content_range: handleContentRange(contentRangeValue),
        chat_type: chatType
      };
      gaEvents(handleGAEvents, additionalFields);
      /** ****handle logic to check if answer is correct ****** */
      const validatedAns = {
        id: constants.QUIZ_ANSWER,
        data: {
          isCorrect: selectedAnswerIndexId === answerId,
          correctAnswer,
          answerExplanation: explanation,
          quizData: quizQuestionsData[(currentQuestionIndex.current)],
          pageIds,
          disableOptions: (currentQuestionIndex.current + 1) === quizQuestionsData.length,
          selectedAnswerIndexId,
          chapterData
        }
      };

      steps.push(validatedAns);
      setSteps([...steps]);

      // check if there is next question and show prompt accordingly
      if ((currentQuestionIndex.current + 1) < quizQuestionsData.length) {
        // const readyForNextQuestion = {
        //   id: constants.NEXT_QUESTION,
        //   data: {
        //     pageIds
        //   }
        // };
        // steps.push(readyForNextQuestion);
      } else if((currentQuestionIndex.current + 1) >= quizQuestionsData.length && (quizStreamStatus === constants.STREAMING || quizStreamStatus === constants.KEEP_ALIVE)) {
        handleShowNextQuestion(pageId, isFreeResponse, chapterData);
      } else {
        optionSelected = 'discuss';
        handleQuizStop(totalQuestionCount > 1 ? true : false);
        // const noQuestionsLeft = {
        //   id: constants.NO_QUESTION_LEFT
        // };
        // steps.push(noQuestionsLeft);
        // suggestionData.optionSelected = 'MULTIPLE_CHOICE';
        // suggestionData.currentContext = contextData;
        // steps.push(suggestionData);
        // quizQuestionsData = [];
      }
      setSteps([...steps]);
    }
  }, [steps]);
  const generateThreadTitle = (threadId) => {
    /** API to generate Thread Title */
      if(threadId) {
        const threadPayload = {
          threadId
        }
        getAuthToken((tokenKey) => {
          berlinService.getThreadTitle(threadPayload, tokenKey,
            (data) => {
              // Success
            }
          ).catch((error) => {
            // Failure
          });
        });
      }
    /** API to generate Thread Title */
    
  };
  const clearHistory = (isDeleteTitle) => {
    const { threadId } = globalContext || {};
    if(threadId && !historyPage && !isDeleteTitle && !isTitleGenerated) {
      generateThreadTitle(threadId);
    }
    const clearPayLoad = {
      bookId: context.bookID
    };
    getAuthToken((tokenKey) => {
      berlinService.clearChatHistory(
        clearPayLoad,
        tokenKey,
        (data) => {
          // Success
        }
      ).catch((error) => {
        // Failure
      });
    });
  };

  const handleClear = (event, isDeleteTitle) => {
    setRecommendations(false);
    setIsStreaming(false);
    setAITutorAutoOpen();
    handleCancelRequestAPI();
    if (enableMathMLKeyboard) {
      const editableMathInput = document.getElementById('editableMathInput');
      editableMathInput.innerHTML = '';
    }
    if(eventSourceRef.current !== null || recommendationEventRef.current !== null) {
      abortStreamConnection();
    }
    clearInterval(nextQuestionSetInterval);
    clearHistory(isDeleteTitle);
    isExplainClicked = false;
    const additionalFields = {
      event_label: null,
      event_value: null,
      event_action: eventAction.clickClearChat,
      command_source_code: 'click'
    };
    gaEvents(handleGAEvents, additionalFields);
    const clearStep = [];
    optionSelected = 'discuss';
    isFromContentSelector.current = false;
    nextQuestion.current = '';
    quizRefId.current = '';
    identifierType = ''
    currentQuestionIndex.current = 0;
    isNewTopic = true;
    isExplainCYUMode = '';
    quizQuestionsData = [];
    topic = '';
    if (closeContentSelector) {
      closeContentSelector();
    }
    setSteps(clearStep);
    setInputValue('');
    setShowOptions(true);
    setAriaLiveStatus('assertive');
    setContentSelectorSelectedText('');
    selectedContent = '';
    explainFlowMessage.current = 'Here is an explanation for that topic:';
    optionSelected = 'discuss';
    isFromContentSelector.current = false;
    isPausedModeOn.current = false;
    isContentSelectFlow.current = false;
    quizStreamRequestId = '';
    quizStreamResponseId = '';
    totalQuestionCount = 0;
    setQuizStreamEnd(false);
    isLastPage.current = false;
    isSummaryQuiz = false;
    isExplainQuiz = false;
    summaryQuizSelectedOption = '';
    summaryQuizPageIds = [];
    stopQuiz = false;
    isExplainCYUMode = '';
    quizRequestId = '';
    contentRangeValue = '';
    setShowScrollArrow(false);
    setHistoryPage(false);
    setIsTitleGenerated(false);
    historyScrollTop = 1;
    historyScrollToCount = 0;
    scrollTimer = -1;
    promptVersionForContentImage = null;
    globalContext.imageCopilotMode = false;
    globalContext.imageContextData = {};
    imageCopilotInitialization = false;
  };

  const handleMoreQuiz = useCallback((action) => {
    if (action) {
      const payload = {
        bookId: context.bookID,
        pageIds: pageId,
        productModel: context.productModel,
        indexId: context.indexId
      };
      setAriaAssertive('assertive');
      getAuthToken((tokenKey) => {
        berlinService.fetchTutorData(
          payload,
          constants.QUIZ,
          null,
          tokenKey,
          (data) => {
            if (data) {
              const quizData = {
                id: constants.QUIZ_CONTENT,
                data: {
                  quizData: data.questions && data.questions[0],
                  responseData: data,
                  pageIds: data.pageIds,
                  showSummary: false,
                  currentQuestionIndex: currentQuestionIndex.current
                }
              };
              steps.push(quizData);
              setSteps([...steps]);
            }
            setTimeout(()=>{setAriaAssertive('off');}, 1000);
          }
        ).catch((error) => {
          if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
          if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
          if (error.response === undefined && error.code === 'ECONNABORTED') {
            errorData.message = Utils.getErrorMessage(error.code);
          } else {
            errorData.message = Utils.getErrorMessage(error.response.status);
          }
          const errorString = error.toString();
          if (errorString && errorString.indexOf('timeout') !== -1) {
            errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
          }
          steps.push(errorData);
          setSteps([...steps]);
        }
        });
      });
    } else {
      setInputValue('');
      setShowOptions(true);
    }
  }, []);
  const getQuizResponseMessage = useCallback((type, subType, pageRange, selectedChapter, totalQuestion, pageNums, isFromCSMenu, selectedOption, quizTitle, pagesRange = [], sourceType) => {
    const quizType = (subType === constants.MULTIPLE_CHOICE) ? 'multiple choice' : 'short answer';
    let typeMessage = isFromCSMenu ? 'the current eTextbook you selected' : 'the current eTextbook page you are on';
    if (type === constants.PAGE_RANGE) {
      typeMessage = `pages ${pageRange.startIndex}-${pageRange.endIndex}`;
    } else if (type === constants.CHAPTERS && selectedChapter) {
      typeMessage = `chapter${(selectedChapter.length > 1) ? 's' : ''} ${Utils.concatChapterTitle(selectedChapter)}`;
    } else if (pageNums && Array.isArray(pageNums) && pageNums.length && !(pagesRange && pagesRange.length) && sourceType) {
      typeMessage = `${sourceType} ${pageNums.length > 1 ? 's' : ''} ${pageNums.join()}`;
    } else if (pageNums && Array.isArray(pageNums) && pageNums.length && !(pagesRange && pagesRange.length)) {
      typeMessage = `page${pageNums.length > 1 ? 's' : ''} ${pageNums.join()}`;
    } else if (pagesRange && pagesRange.length && pagesRange[0] && pagesRange[0].start && pagesRange[0].end) {
      typeMessage = `pages ${pagesRange[0].start} - ${pagesRange[0].end}`;
    } else if (selectedOption === constants.CURRENT_SECTION) {
      typeMessage = 'your current section';
    } else if (selectedOption === constants.CURRENT_CHAPTER) {
      typeMessage = 'the whole chapter you are on';
    } else if (quizTitle) {
      typeMessage = `the ${quizTitle} eTextbook chapter are here`;
    }
    // ${Utils.convertDigitNumberToWord(totalQuestion)} - dynamic numbers to letter
    // const quizMessage = totalQuestionCount ? `I have ${Utils.convertNumberToWords(totalQuestionCount)} questions for you:` : 'I have few questions for you:';
    const quizMessage = "Okay, let's get started.";

    // const quizMessage = isFromContentSelector.current ?
    //   `Here are ${(subType === constants.MULTIPLE_CHOICE) ? totalQuestion : 'the'}
    //     ${quizType} practice questions based on the content you selected:` :
    //   `Here are ${subType === constants.MULTIPLE_CHOICE ? totalQuestion : 'some short answer'}
    //   ${(subType === constants.MULTIPLE_CHOICE) ? 'multiple choice' : ''} practice questions from ${typeMessage}.
    //   ${subType === constants.MULTIPLE_CHOICE ?
    // 'Skip a question or end the quiz whenever you like!' :
    // formatMessage(messages.freeResponseCopyText)}`;
    return quizMessage;
  }, []);

  const handleExplainFormatChange = (format, userContent) => {
    const additionalFields = {
      event_label: format === 'bullet_points' ? 'bullet points' : 'paragraph',
      event_value: null,
      event_action: eventAction.clickFormatIcon
    };
    gaEvents(handleGAEvents, additionalFields);
    steps.push(loaderStep);
    setSteps([...steps]);
    const payload = {
      userCommand: userContent,
      bookId: context.bookID,
      productModel: context.productModel,
      indexId: context.indexId,
      pageIds: pageId,
      option: format
    };
    if (context.isPpmTextExtracted || (window.localStorage.getItem('use_print_page_no') === 'true' && context.printPageNumber && context.printPageNumber !== '-')) {
      payload.isPpmTextExtracted = true;
    }
    let headerParams = null;
    if (enableStreamingAPI) {
      headerParams = {
        headers: {
          isStream: true
        }
      };
    }
    // if (enableStreamingAPI) { setIsTextBoxDisable(true); }
    setIsTextBoxDisable(true);
    setIsAPICalled(true);
    setAriaAssertive('assertive');
    getAuthToken((tokenKey) => {
      berlinService.fetchTutorData(
      payload,
      constants.CHAT,
      headerParams,
      tokenKey,
      (data) => {
        if ((data.response && data.response.isNotValidAIResponse) || (data.isNotValidAIResponse)) {
          const responseData = {
            id: constants.ERROR_MESSAGE,
            message: data.response && data.response.text.replaceAll('###', '')
          };
          setIsTextBoxDisable(false);
          setIsAPICalled(false);
          steps.pop();
          steps.push(responseData);
          setSteps([...steps]);
        } else {
        if (enableStreamingAPI && data.useStream && data.requestId) {
          const streamIntentType = data && data.intentType;
          if (streamIntentType !== constants.EXPLAIN_INTENT) {
            explainFlowMessage.current = '';
          } else {
            explainFlowMessage.current = 'Here is an explanation for that topic:';
          }
          eventSourceRef.current = new EventSourcePolyfill(`${constants.BERLIN_BASE_URL[globalContext.env]}/3.0/generate/stream?requestId=${data.requestId}`, {
            headers: {
              Authorization: `Bearer ${tokenkey}`,
              'thread-id': globalContext.threadId,
              'x-berlin-tenant-id': tenantId,
              'x-berlin-tenant-key': tenantKey
            },
            heartbeatTimeout: 180000
          });

          eventSourceRef.current.onopen = () => {
            steps.pop();
            setIsStopStreaming(true);
            const gaIntentOptions = [constants.SUMMARY_INTENT, constants.QUIZ_INTENT, constants.EXPLAIN_INTENT];
            if (gaIntentOptions.includes(data && data.intentType)) {
              let chatType = '';
              if (data.intentType === constants.SUMMARY_INTENT) {
                chatType = 'summary';
              } else if (data.intentType === constants.QUIZ_INTENT) {
                chatType = 'quiz';
              } else if (data.intentType === constants.EXPLAIN_INTENT) {
                chatType = 'explain';
              }


              gaEvents(handleGAEvents, {
                event_action: eventAction.outputSuccess,
                content_categories: null,
                command_source_code: constants.GPT_EVENTS.CLICK,
                chat_type: chatType === constants.QUIZ ? constants.GPT_EVENTS.PRACTICE : chatType.charAt(0).toUpperCase() + chatType.slice(1),
                format: format === constants.BULLET ? constants.GPT_EVENTS.BULLET : constants.GPT_EVENTS.PARAGRAPH,
                content_range: handleContentRange(),
                initiated_point: constants.GPT_EVENTS.FORMAT_BUTTON,
                event_label: null,
                event_value: null,
                video_count: 0,
                asset_ids_returned: null,
                links: Utils.pageNavigationAnchorLinks(data?.requestId),
                chat_id: globalContext.threadId,
                result_index: null,
                location_in_app: constants.GPT_EVENTS.GPT,
                ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                topic: null
              });
            }
          };
          eventSourceRef.current.onmessage = (event) => {
            if (event.data) {
              const formattedResponse = JSON.parse(event.data);
              currentStreamResponseId.current = formattedResponse.requestId;
              if (formattedResponse.streamStatus === constants.START) {
                streamingContent = '';
                const summaryStream = {
                  id: constants.STREAMING_DISCUSS,
                  requestId: formattedResponse.requestId,
                  responseId: formattedResponse.responseId,
                  content: '',
                  streamStatus: formattedResponse.streamStatus,
                  data: {
                    optionSelected: data.option,
                    inputValue
                  }
                };
                const isStepExist = steps.find(obj => obj.requestId === formattedResponse.requestId);
                if (!isStepExist) {
                  steps.push(summaryStream);
                  setSteps([...steps]);
                }
              } else if (formattedResponse.streamStatus === constants.STREAMING || formattedResponse.streamStatus === constants.DONE) {
                const stepsArray = JSON.parse(JSON.stringify(steps));
                const filteredSteps = stepsArray.find(obj => obj.requestId === formattedResponse.requestId);
                if (filteredSteps) {
                  if (formattedResponse.content) {
                    streamingContent += formattedResponse.content;
                  }
                  filteredSteps.content = streamingContent;
                }
                filteredSteps.streamStatus = formattedResponse.streamStatus;
                setSteps(stepsArray);
                if (formattedResponse.streamStatus === constants.DONE) {
                  eventSourceRef.current.close();
                  setIsStopStreaming(false);
                  // eventSource.close();
                  setIsTextBoxDisable(false);
                  inputRef.current.focus();
                }
              } else if (formattedResponse.streamStatus === constants.KEEP_ALIVE) {
                console.log('Connection is Alive', event.data);
              }
            }
          };
          eventSourceRef.current.onerror = (error) => {
            console.log('Error in stream response discuss', error);
            if (error?.target?.close) {
              error.target.close();
            }
            steps.pop();
            eventSourceRef?.current?.close();
            if (error && error.status) errorMessageGAEvent(error.status);
            errorData.message = Utils.getErrorMessage(error && error.status);
            steps.push(errorData);
            setSteps([...steps]);
            setIsStopStreaming(false);
            // eventSource.close();
            setIsTextBoxDisable(false);
          };
        } else {
          setIsTextBoxDisable(false);
          setIsAPICalled(false);
          steps.pop();
          if (data) {
            let responseData = {};
            if (data.response && data.response.isNotValidAIResponse) {
              responseData = {
                id: constants.ERROR_MESSAGE,
                message: data.response && data.response.text.replaceAll('###', '')
              };
            } else {
              responseData = {
                id: data.intentType === constants.UNKNOWN ? constants.WELCOME_MESSAGE : constants.DISCUSS,
                data: {
                  botResponse: data && data,
                  showFormat: false,
                  isUnknownIntentType: data.intentType === constants.UNKNOWN
                }
              };
            }
            steps.push(responseData);
            setSteps([...steps]);
          }
        }
      }
      setTimeout(()=>{setAriaAssertive('off');}, 1000);
      }
      ).catch((error) => {
        setIsTextBoxDisable(false);
        if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
        if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
        if (error.response === undefined && error.code === 'ECONNABORTED') {
          errorData.message = Utils.getErrorMessage(error.code);
        } else {
          errorData.message = Utils.getErrorMessage(error.response.status);
        }
        const errorString = error.toString();
        if (errorString && errorString.indexOf('timeout') !== -1) {
          errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
        }
        steps.pop();
        steps.push(errorData);
        setSteps([...steps]);
      }
      });
    });
  };

  const getNextSetOfQuiz = useCallback((updateQuestions, data) => {
    const responseData = data;
    const nextQuizPayload = {
      bookId: context.bookID,
      productModel: context.productModel,
      indexId: context.indexId,
      quizRefId: quizRefId.current,
      option: optionSelected
    };
    if(quizRequestId) {
      nextQuizPayload.requestId = quizRequestId;
    }
    setAriaAssertive('assertive');
    getAuthToken((tokenKey) => {
      berlinService.fetchTutorData(
        nextQuizPayload,
        constants.NEXT_QUESTIONS,
        null,
        tokenKey,
        (nextQuestionData) => {
          quizRefId.current = nextQuestionData.quizRefId;
          isLastPage.current = nextQuestionData.isLastPage;
          if (updateQuestions) {
            if (nextQuestionData && nextQuestionData.questions && nextQuestionData.questions.length) {
              nextQuestionData.questions.forEach((questionObject) => {
                questionObject.quizRefId = nextQuestionData.quizRefId;
                questionObject.responseId = nextQuestionData.responseId;
                questionObject.requestId = nextQuestionData.requestId;
                quizQuestionsData.push(questionObject);
              });
              responseData.data = {
                ...(data.data),
                questionsLength: quizQuestionsData && quizQuestionsData.length,
                quizData: quizQuestionsData && quizQuestionsData[0],
                message: quizQuestionsData && quizQuestionsData[0] && (quizQuestionsData[0].question || quizQuestionsData[0].question_stem)
              };
              if (!nextQuestionData.isLastPage && !stopQuiz) {
                getNextSetOfQuiz();
              }
              steps.push(responseData);
              setSteps([...steps]);
            } else if (!nextQuestionData.isLastPage && !(nextQuestionData && nextQuestionData.questions && nextQuestionData.questions.length) && !stopQuiz) {
              getNextSetOfQuiz(true, data);
            } else if(nextQuestionData.isLastPage && !(nextQuestionData && nextQuestionData.questions && nextQuestionData.questions.length)) {
              responseData.data = {
                ...(data.data),
                questionsLength: nextQuestionData.questions && nextQuestionData.questions.length,
                quizData: nextQuestionData.questions && nextQuestionData.questions[0],
                message: nextQuestionData.questions && nextQuestionData.questions[0] && (nextQuestionData.questions[0].question || nextQuestionData.questions[0].question_stem)
              };
              steps.push(responseData);
              setSteps([...steps]);
              optionSelected = 'discuss';
            }
          } else {
            if (nextQuestionData && nextQuestionData.questions && nextQuestionData.questions.length) {
              nextQuestionData.questions.forEach((questionObject) => {
                questionObject.quizRefId = nextQuestionData.quizRefId;
                questionObject.responseId = nextQuestionData.responseId;
                questionObject.requestId = nextQuestionData.requestId;
                quizQuestionsData.push(questionObject);
              });
            }
            if (!nextQuestionData.isLastPage && !stopQuiz) {
              getNextSetOfQuiz();
            }
          }
          setTimeout(()=>{setAriaAssertive('off');}, 1000);
        }
      ).catch((error) => {
        console.log('error fetching next set of quiz');
      });
    });
  }, [berlinService, quizRefId, isLastPage]);

  const handleFormatChange = (format, pageRange, selectedChapter, pageIdfromResponse, pageNumsfromResponse, userSelectedContent, selectedType, pageRangefromResponse, sourceTypeFromResponse) => {
    const additionalFields = {
      event_label: format === 'bullet_points' ? 'bullet points' : 'paragraph',
      event_value: null,
      event_action: eventAction.clickFormatIcon
    };
    gaEvents(handleGAEvents, additionalFields);
    steps.push(loaderStep);
    setSteps([...steps]);
    const payload = {
      bookId: context.bookID,
      pageIds: pageIdfromResponse || pageId,
      productModel: context.productModel,
      indexId: context.indexId,
      option: format,
      pageNums: pageNumsfromResponse
    };

    // if (userSelectedContent && !(pageNumsfromResponse && pageNumsfromResponse.length)) {
    //   payload.userSelectedContent = userSelectedContent;
    // }

    const pageIdArray = [];
    if (pageRange && pageRange.startIndex && pageRange.endIndex) {
      const startIndex = pageRange.startIndex;
      const endIndex = pageRange.endIndex;
      const indexArray = [];
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      for (let i = Number(startIndex); i <= Number(endIndex); i++) {
        indexArray.push(i.toString());
      } // TODO Need to revisit this logic
      indexArray.forEach((elementValue) => {
        pageList.forEach((pageObject) => {
          if (contentType === constants.PDF && pageObject && (pageObject.pageNo === elementValue) && pageObject.pageUri) {
            const pageUriArray = pageObject.pageUri.split('.pdf');
            if (pageUriArray && pageUriArray.length) {
              pageIdArray.push(pageUriArray[0]);
            }
          } else if (pageObject && (pageObject.pageNo === elementValue) && pageObject.pageId) {
            pageIdArray.push(pageObject.pageId);
          }
        });
      });
      payload.pageIds = pageIdArray;
    }
    if (selectedChapter && selectedChapter.length) {
      let updatedPageIds = [];
      // eslint-disable-next-line array-callback-return
      selectedChapter.map((chapter) => {
        const pageIds = chapterList.find(list => list.chapterTitle === chapter).pageIds;
        updatedPageIds = [...updatedPageIds, ...pageIds];
      });
      payload.pageIds = updatedPageIds;
    }
    setAriaAssertive('assertive');
    getAuthToken((tokenKey) => {
      berlinService.fetchTutorData(
        payload,
        constants.SUMMARY,
        null,
        tokenKey,
        (data) => {
          steps.pop();
          //Currently N0t Used- format change
          if ((data.response && data.response.isNotValidAIResponse) || (data.isNotValidAIResponse)) {
            const responseData = {
              id: constants.ERROR_MESSAGE,
              message: data.response && data.response.text.replaceAll('###', '')
            };
          } else {
            gaEvents(handleGAEvents, {
              event_action: eventAction.outputSuccess,
              content_categories: null,
              command_source_code: constants.GPT_EVENTS.CLICK,
              chat_type: constants.SUMMARY.charAt(0).toUpperCase() + constants.SUMMARY.slice(1),
              format: (data.option === constants.BULLET) ? constants.GPT_EVENTS.BULLET : constants.GPT_EVENTS.PARAGRAPH,
              content_range: handleContentRange(selectedType || selectedOptionType),
              initiated_point: constants.GPT_EVENTS.FORMAT_BUTTON,
              event_label: null,
              event_value: null,
              video_count: 0,
              asset_ids_returned: null,
              links: Utils.pageNavigationAnchorLinks(data?.requestId),
              chat_id: globalContext.threadId,
              result_index: null,
              location_in_app: constants.GPT_EVENTS.GPT,
              ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
              topic: null
            });
          const responseData = {
            id: constants.SUMMARY_CONTENT,
            data: {
              summaryData: data,
              optionSelected: format,
              pageRange,
              selectedChapter,
              pageId,
              selectedOptionType: selectedType || selectedOptionType,
              chapterList,
              pageList,
              showFormat: false,
              pageNums: pageNumsfromResponse,
              pagesRange: pageRangefromResponse,
              sourceType: sourceTypeFromResponse
            }
          };
          if (userSelectedContent && userSelectedContent.length && !(data.pageNums && data.pageNums.length)) {
            responseData.data.userSelectedContent = userSelectedContent;
            responseData.data.isFromCSMenu = true;
          }
          }
          steps.push(responseData);
          setSteps([...steps]);
          setTimeout(()=>{setAriaAssertive('off');}, 1000);
        }
      ).catch((error) => {
        if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
        if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
        if (error.response === undefined && error.code === 'ECONNABORTED') {
          errorData.message = Utils.getErrorMessage(error.code);
        } else {
          errorData.message = Utils.getErrorMessage(error.response.status);
        }
        const errorString = error.toString();
        if (errorString && errorString.indexOf('timeout') !== -1) {
          errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
        }
        steps.pop();
        steps.push(errorData);
        setSteps([...steps]);
      }
      });
    });
  };

  const handleRangeSubmit = (type, pageRange = {}, isRegenerate = false, selectedChapter = [], pageIdsFromResponse, pageNumsFromResponse, isFromBotResponse = false, topicTitle, pageRangeFromResponse, sourceTypeFromResponse, clickPracticeQuizEvent = false, sourceType, chapterDataFromResponse, summaryBtnQuizClicked = false, summaryTypedQuiz = false, isExplainBtnQuizClicked = false, explainTypedQuiz = false, explainQuizVariantB = false, requestIdFromResponse) => {
    if ((AITutorContext.enableParagraph || topicTitle) && !(type === constants.SECTION || type === constants.CHAPTER)) {
      steps.pop();
    }
    if(isRecommendations) {
      setRecommendations(false);
    }
    stopQuiz = false;
    if (summaryBtnQuizClicked || isExplainBtnQuizClicked) optionSelected = constants.MULTIPLE_CHOICE;
    setOptionType(type);
    selectedOptionType = type;
    const hasPageRange = pageRange && Object.keys(pageRange).length !== 0;
    const rangeClick = {
      id: constants.USER_MESSAGE,
      data: {
        text: (type === constants.PAGE_RANGE || hasPageRange) ? `Use the content from pages ${pageRange.startIndex}-${pageRange.endIndex}` : 'Use the content from the current eTextbook page I am on'
      }
    };
    if (type === constants.CHAPTERS && selectedChapter) {
      let chapterTitle = `Use the content from chapter${(selectedChapter.length > 1) ? 's' : ''} `;
      chapterTitle += Utils.concatChapterTitle(selectedChapter);
      rangeClick.data.text = chapterTitle;
    }
    let optionBasedURI = constants.ANSWER;
    const summaryType = optionSelected === constants.PARAGRAPH ? 'paragraph format' : 'bullet points';
    // const summaryText = type === constants.CURRENT_CHAPTER ? 'Use the content from the whole chapter I am on' : 'Use the content from the current section I am on';

    if (optionSelected === constants.PARAGRAPH || optionSelected === constants.BULLET || optionSelected === constants.SUMMARY) {
      optionBasedURI = constants.SUMMARY;
      rangeClick.data.text = `Use ${summaryType} for my summary`;
      // rangeClick.data.text = summaryText;
    }
    if (optionSelected === constants.QUIZ || optionSelected === constants.MULTIPLE_CHOICE || optionSelected === constants.FREE_RESPONSE) {
      optionBasedURI = constants.QUIZ;
    }
    if (!topicTitle && (optionSelected === constants.QUIZ || optionSelected === constants.MULTIPLE_CHOICE || optionSelected === constants.FREE_RESPONSE)) {
      optionBasedURI = constants.QUIZ;
      const message = (optionSelected === constants.MULTIPLE_CHOICE) ? 'Multiple choice' : 'short answer';
      rangeClick.data.text = `${message} format`;
    }
    if (optionSelected !== constants.DISCUSS) {
      if ((optionSelected === constants.QUIZ || optionSelected === constants.MULTIPLE_CHOICE || optionSelected === constants.FREE_RESPONSE) && AITutorContext.enableFreeResponse && !topicTitle) {
        const lastStep = steps.slice(-1);
        if (lastStep[0].id === 'userMessage' &&
          (lastStep[0].data.text === 'Use content from the current section.' ||
            lastStep[0].data.text === 'Use content from the whole chapter.')) {
          steps.pop();
          const chapterSection = (type === constants.CURRENT_SECTION) ? 'current section' : 'whole chapter';
          const formatChoice = (optionSelected === constants.MULTIPLE_CHOICE) ? 'multiple choice' : 'short answer';
          rangeClick.data.text = `Use content from the ${chapterSection} in ${formatChoice} format.`;
        } else if (lastStep[0].id === constants.QUIZ_OPTIONS) {
          steps.pop();
        }
        if (!summaryBtnQuizClicked && !isExplainBtnQuizClicked) {
          steps.push(rangeClick);
          setSteps([...steps]);
        }
      } else if (!isFromBotResponse && (optionSelected === constants.PARAGRAPH || optionSelected === constants.BULLET || optionSelected === constants.SUMMARY) && AITutorContext.enableParagraph) {
        steps.push(rangeClick);
        setSteps([...steps]);
      }
    }
    steps.push(loaderStep);
    setSteps([...steps]);
    const payload = {
      bookId: context.bookID,
      pageId: pageIdsFromResponse && pageIdsFromResponse.length ? pageIdsFromResponse : pageId,
      productModel: context.productModel,
      indexId: context.indexId
    };
    const pageIdArray = [];
    if (isRegenerate) {
      payload.isRegenerate = true;
    }
    if (optionSelected === constants.DISCUSS) {
      payload.question = inputValue || 'Explain about current page';
    }
    if (optionSelected === constants.QUIZ || optionSelected === constants.MULTIPLE_CHOICE ||
      optionSelected === constants.FREE_RESPONSE || optionSelected === constants.PARAGRAPH || optionSelected === constants.BULLET) {
      payload.option = optionSelected;
    }
    if (isFromContentSelector.current && contentSelectorSelectedText && contentSelectorSelectedText.length) {
      // payload.userSelectedContent = selectedContent;
      isFromContentSelector.current = false;
    }
    if (type === constants.PAGE_RANGE || hasPageRange) {
      const startIndex = pageRange.startIndex;
      const endIndex = pageRange.endIndex;
      const indexArray = [];
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      for (let i = Number(startIndex); i <= Number(endIndex); i++) {
        indexArray.push(i.toString());
      } // TODO Need to revisit this logic
      indexArray.forEach((value) => {
        pageList.forEach((pageObject) => {
          if (contentType === constants.PDF && pageObject && (pageObject.pageNo === value) && pageObject.pageUri) {
            const pageUriArray = pageObject.pageUri.split('.pdf');
            if (pageUriArray && pageUriArray.length) {
              pageIdArray.push(pageUriArray[0]);
            }
          } else if (pageObject && (pageObject.pageNo === value) && pageObject.pageId) {
            pageIdArray.push(pageObject.pageId);
          }
        });
      });
      payload.pageId = pageIdArray;
    }
    const responseSourceType = sourceTypeFromResponse || sourceType;
    if ((type === constants.CURRENT_SECTION || type === constants.CURRENT_CHAPTER) ||
      (responseSourceType === constants.SECTION || responseSourceType === constants.CHAPTER)) {
      const sourceTypeValue = type === constants.CURRENT_SECTION || responseSourceType === constants.SECTION ? constants.SECTION : constants.CHAPTER;
      payload.sourceType = sourceTypeValue;
      payload.pageId = pageId.toString();
    }
    if (type === constants.CHAPTERS && selectedChapter) {
      let updatedPageIds = [];
      // eslint-disable-next-line array-callback-return
      selectedChapter.map((chapter) => {
        const pageIds = chapterList.find(list => list.chapterTitle === chapter).pageIds;
        updatedPageIds = [...updatedPageIds, ...pageIds];
      });
      payload.pageId = updatedPageIds;
    }
    let headerParams = null;
    if (optionSelected === constants.SUMMARY || optionSelected === constants.BULLET || optionSelected === constants.PARAGRAPH) {
      payload.option = optionSelected === constants.PARAGRAPH ? optionSelected : constants.BULLET;
      optionBasedURI = constants.SUMMARY;
      headerParams = {
        headers: {
          isStream: enableStreamingAPI
        }
      };
      // TODO Currently AI supporting only one pageId, Once API changes done we need to revert this code
      if (enableStreamingAPI) {
        payload.pageId = context.pageId;
      }
    }
    // if (enableStreamingAPI) { setIsTextBoxDisable(true); }
    setIsTextBoxDisable(true);

    const delayedResponseStep = () => {
      const responseData = {
        id: constants.DELAYED_RESPONSE,
      };
      steps.push(responseData);
      setSteps([...steps]);
    };
    chapterData = { ...chapterDataFromResponse };
    if(!(chapterData && chapterData.chapterId)) {
      const {
        chapterId,
        chapterTitle,
        chapterNumber
      } = context;
      chapterData = {
        chapterId,
        chapterTitle,
        chapterNumber
      };
    }
    // if enable explain option is B/c, 
    // Update payload with below details
    if(isExplainBtnQuizClicked || explainTypedQuiz) {
      isExplainCYUMode = constants.EXPLANATION_CYU;
      payload.userMode = constants.EXPLANATION_CYU;
      payload.pageId = '';
      setIsTextBoxDisable(false);
    }
    if(requestIdFromResponse) {
      quizRequestId = requestIdFromResponse
      payload.requestId = requestIdFromResponse;
    }
    let summaryTimmer = startTimer(delayedResponseStep);
    setIsAPICalled(true);
    setAriaAssertive('assertive');
    getAuthToken((tokenKey) => {
      berlinService.fetchTutorData(
        payload,
        optionBasedURI,
        headerParams,
        tokenKey,
        (data) => {
          identifierType = '';
          setIsAPICalled(false);
          if (steps[steps.length - 1].id === 'delay') {
            steps.pop();
          }
          noUpdateToSteps = false;
          clearTimeout(summaryTimmer);
          if ((data.response && data.response.isNotValidAIResponse) || (data.isNotValidAIResponse)) {
            optionSelected = 'discuss';
            const responseData = {
              id: constants.ERROR_MESSAGE,
              message: data.response && data.response.text.replaceAll('###', '')
            };
            setIsTextBoxDisable(false);
            steps.pop();
            steps.push(responseData);
            setSteps([...steps]);
          } else {
          if ((optionSelected === constants.SUMMARY || optionSelected === constants.BULLET || optionSelected === constants.PARAGRAPH) && data.requestId && enableStreamingAPI && data.useStream) {
            eventSourceRef.current = new EventSourcePolyfill(`${constants.BERLIN_BASE_URL[globalContext.env]}/3.0/generate/stream?requestId=${data.requestId}`,
            {
              headers: {
                Authorization: `Bearer ${tokenKey}`,
                'thread-id': globalContext.threadId,
                'x-berlin-tenant-id': tenantId,
                'x-berlin-tenant-key': tenantKey
              },
              heartbeatTimeout: 180000
            });
            eventSourceRef.current.onopen = () => {
              steps.pop();
              setIsStopStreaming(true);
              const gaIntentOptions = [constants.SUMMARY_INTENT, constants.QUIZ_INTENT, constants.EXPLAIN_INTENT];
              if (gaIntentOptions.includes(data.intentType)) {
                let chatType = '';
                if (data.intentType === constants.SUMMARY_INTENT) {
                  chatType = 'summary';
                } else if (data.intentType === constants.QUIZ_INTENT) {
                  chatType = 'quiz';
                } else if (data.intentType === constants.EXPLAIN_INTENT) {
                  chatType = 'explain';
                }


                gaEvents(handleGAEvents, {
                  event_action: eventAction.outputSuccess,
                  content_categories: null,
                  command_source_code: !clickPracticeQuizEvent ? constants.GPT_EVENTS.TYPE : constants.GPT_EVENTS.CLICK,
                  chat_type: chatType === constants.QUIZ ? constants.GPT_EVENTS.PRACTICE : chatType.charAt(0).toUpperCase() + chatType.slice(1),
                  format: optionSelected === constants.BULLET ? constants.GPT_EVENTS.BULLET : constants.GPT_EVENTS.PARAGRAPH,
                  content_range: handleContentRange(isFromBotResponse ? sourceType : type),
                  initiate_point: !clickPracticeQuizEvent ? constants.GPT_EVENTS.COMMAND_LINE : constants.GPT_EVENTS.UI_QUICK_ACTION,
                  event_label: null,
                  event_value: null,
                  video_count: 0,
                  asset_ids_returned: null,
                  links: Utils.pageNavigationAnchorLinks(data?.requestId),
                  chat_id: globalContext.threadId,
                  result_index: null,
                  location_in_app: constants.GPT_EVENTS.GPT,
                  ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                  topic: null
                });
              }
            };
            eventSourceRef.current.onmessage = (event) => {
              if (event.data) {
                const formattedResponse = JSON.parse(event.data);
                currentStreamResponseId.current = formattedResponse.requestId;
                if (formattedResponse.streamStatus === constants.START) {
                  streamingContent = '';
                  streamingApiContent = '';
                  const summaryStream = {
                    id: constants.STREAMING_SUMMARY_CONTENT,
                    requestId: formattedResponse.requestId,
                    responseId: formattedResponse.responseId,
                    content: '',
                    apiContent: '',
                    streamStatus: formattedResponse.streamStatus,
                    data: {
                      optionSelected: data.option,
                      pageRange,
                      selectedChapter,
                      pageId: pageIdsFromResponse && pageIdsFromResponse.length ? pageIdsFromResponse : pageId,
                      selectedOptionType,
                      chapterList,
                      pageList,
                      pageNums: pageNumsFromResponse,
                      pagesRange: pageRangeFromResponse,
                      topicTitle,
                      sourceType: sourceTypeFromResponse,
                      chapterData
                    }
                  };
                  const isStepExist = steps.find(obj => obj.requestId === formattedResponse.requestId);
                  if (!isStepExist) {
                    steps.push(summaryStream);
                    setSteps([...steps]);
                  }
                } else if (formattedResponse.streamStatus === constants.STREAMING || formattedResponse.streamStatus === constants.DONE) {
                  const stepsArray = JSON.parse(JSON.stringify(steps));
                  const filteredSteps = stepsArray.find(obj => obj.requestId === formattedResponse.requestId);
                  if (filteredSteps) {
                    if (formattedResponse.content) {
                      streamingApiContent += formattedResponse.content;
                      streamingContent += formattedResponse.content;
                    }
                    filteredSteps.apiContent = streamingApiContent;
                    filteredSteps.content = streamingContent;
                  }
                  filteredSteps.streamStatus = formattedResponse.streamStatus;
                  setSteps(stepsArray);
                  if (formattedResponse.streamStatus === constants.DONE) {
                    eventSourceRef.current.close();
                    setIsStopStreaming(false);
                    // eventSource.close();
                    setIsTextBoxDisable(false);
                    inputRef.current.focus();
                  }
                } else if (formattedResponse.streamStatus === constants.KEEP_ALIVE) {
                  console.log('Connection is Alive', event.data);
                }
              }
            };
            eventSourceRef.current.onerror = (error) => {
              console.log('Error in stream response summary', error);
              if (error?.target?.close) {
                error.target.close();
              }
              steps.pop();
              eventSourceRef?.current?.close();
              if (error && error.status) errorMessageGAEvent(error.status);
              errorData.message = Utils.getErrorMessage(error && error.status);
              steps.push(errorData);
              setSteps([...steps]);
              setIsStopStreaming(false);
              // eventSource.close();
              setIsTextBoxDisable(false);
            };
          } else {
            setIsTextBoxDisable(false);
            const gaIntentOptions = [constants.SUMMARY, constants.QUIZ];
            if (gaIntentOptions.includes(optionBasedURI)) {
              if(!clickPracticeQuizEvent && (optionSelected === constants.SUMMARY || optionSelected === constants.BULLET || optionSelected === constants.PARAGRAPH)) {
                gaEvents(handleGAEvents, {
                  event_action: eventAction.clickSummaryFormat,
                  event_label: (data.option === constants.BULLET ) ? 'Bullet Points' : 'Paragraph',
                  event_value: null,
                  command_source_code: 'type'
                });
              }
            }
            steps.pop();
            setSteps([...steps]);
            if (data) {
              let responseData = {
                id: constants.DISCUSS,
                data: {
                  botResponse: data && data
                }
              };
              if (optionSelected === constants.MULTIPLE_CHOICE || optionSelected === constants.FREE_RESPONSE) {
                if(explainQuizVariantB && showPractice) {
                  // init GA event explain quiz variant B
                  gaEvents(handleGAEvents, {
                    event_action: eventAction.continuousSuggestionShown,
                    event_label: constants.GPT_EVENTS.AUTOMATICALLY,
                    event_value: null,
                    chat_type: constants.GPT_EVENTS.EXPLAIN,
                  });
                }
                if (enableExplainQuizOptions !== constants.EXPLAIN_QUIZ_OPTION_B && showPractice) {
                  gaEvents(handleGAEvents, {
                    event_action: eventAction.outputSuccess,
                    content_categories: null,
                    command_source_code: (!clickPracticeQuizEvent ||  summaryTypedQuiz || explainTypedQuiz)? constants.GPT_EVENTS.TYPE : constants.GPT_EVENTS.CLICK,
                    chat_type: summaryBtnQuizClicked || isExplainBtnQuizClicked ? constants.GPT_EVENTS.FOLLOWUP_PRACTICE : constants.GPT_EVENTS.PRACTICE,
                    format: optionSelected === constants.MULTIPLE_CHOICE ? constants.GPT_EVENTS.MULTIPLE_CHOICE : constants.GPT_EVENTS.SHORT_ANSWER,
                    content_range: handleContentRange(contentRangeValue ? contentRangeValue : (isFromBotResponse ? sourceType : type)),
                    initiated_point: summaryBtnQuizClicked || isExplainBtnQuizClicked ? constants.GPT_EVENTS.ENGAGEMENT_SUGGESTION : !clickPracticeQuizEvent ? constants.GPT_EVENTS.COMMAND_LINE : constants.GPT_EVENTS.UI_QUICK_ACTION,
                    event_label: null,
                    event_value: null,
                    video_count: 0,
                    asset_ids_returned: null,
                    links: Utils.pageNavigationAnchorLinks(data?.requestId),
                    chat_id: globalContext.threadId,
                    result_index: null,
                    location_in_app: constants.GPT_EVENTS.GPT,
                    ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                    topic: null
                  });
                }
                if (enableExplainQuizOptions === constants.EXPLAIN_QUIZ_OPTION_B && showPractice) {
                  // init ga event once the quiz options visible to user
                  followUpEventData = {
                    event_action: eventAction.outputSuccess,
                    content_categories: null,
                    command_source_code: (!clickPracticeQuizEvent ||  summaryTypedQuiz || explainTypedQuiz)? constants.GPT_EVENTS.TYPE : constants.GPT_EVENTS.CLICK,
                    chat_type: summaryBtnQuizClicked || isExplainBtnQuizClicked ? constants.GPT_EVENTS.FOLLOWUP_PRACTICE : constants.GPT_EVENTS.PRACTICE,
                    format: optionSelected === constants.MULTIPLE_CHOICE ? constants.GPT_EVENTS.MULTIPLE_CHOICE : constants.GPT_EVENTS.SHORT_ANSWER,
                    content_range: handleContentRange(isFromBotResponse ? sourceType : type),
                    initiated_point: summaryBtnQuizClicked || isExplainBtnQuizClicked ? constants.GPT_EVENTS.ENGAGEMENT_SUGGESTION : !clickPracticeQuizEvent ? constants.GPT_EVENTS.COMMAND_LINE : constants.GPT_EVENTS.UI_QUICK_ACTION,
                    event_label: null,
                    event_value: null,
                    video_count: 0,
                    asset_ids_returned: null,
                    links: Utils.pageNavigationAnchorLinks(data?.requestId),
                    chat_id: globalContext.threadId,
                    result_index: null,
                    location_in_app: constants.GPT_EVENTS.GPT,
                    ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                    topic: null
                  };
                }
                // setQuizQuestionsData(data.questions);
                if (!clickPracticeQuizEvent && showPractice) {
                  gaEvents(handleGAEvents, {
                    event_action: eventAction.clickPacticeFormat,
                    event_label: optionSelected === constants.FREE_RESPONSE ? 'Free Response' : 'Multi choice',
                    event_value: null,
                    command_source_code: 'type'
                  });
                }
                quizRequestId = data.requestId; // assign requestId to global variable
                quizPageIds = data.pageIds;
                data.questions && data.questions.length && data.questions.forEach((quiz) => {
                  quiz.quizRefId = data.quizRefId;
                  quiz.responseId = data.responseId;
                  quiz.requestId = data.requestId;
                  // quiz.question = quiz.question_stem || quiz.question;
                });
                quizQuestionsData = data.questions;
                totalQuestionCount = data.questionsCount;
                quizRefId.current = (data && data.quizRefId);
                isLastPage.current = data.isLastPage;
                if (!(data.questions && data.questions.length) && isLastPage.current) optionSelected = 'discuss';
                if ((data.questions && data.questions.length) || data.isLastPage) {
                  responseData = {
                    id: optionSelected === constants.FREE_RESPONSE ? constants.FREE_RESPONSE_QUIZ : constants.QUIZ_CONTENT,
                    data: {
                      type,
                      optionSelected,
                      pageRange,
                      selectedChapter,
                      responseData: data,
                      questionsLength: data.questions && data.questions.length,
                      isLastPage: isLastPage.current,
                      pageIds: pageIdsFromResponse && pageIdsFromResponse.length ? pageIdsFromResponse : data.pageIds,
                      quizData: data.questions && data.questions[0],
                      message: data.questions && data.questions[0] && (data.questions[0].question || data.questions[0].question_stem),
                      showSummary: true,
                      isReadyNext: false,
                      isLastQuestion: false,
                      pageNums: pageNumsFromResponse,
                      pagesRange: pageRangeFromResponse,
                      selectedOptionType,
                      quizTitle: topicTitle,
                      showOptions: optionSelected === constants.FREE_RESPONSE,
                      currentQuestionIndex: currentQuestionIndex.current,
                      chapterData,
                      totalQuestionCount,
                      isExplainCYUModeB: enableExplainQuizOptions === constants.EXPLAIN_QUIZ_OPTION_B ? isExplainCYUMode : null,
                      isExplainCYUMode: ((enableExplainQuizOptions === constants.EXPLAIN_QUIZ_OPTION_B || enableExplainQuizOptions === constants.EXPLAIN_QUIZ_OPTION_C) && (isExplainBtnQuizClicked || explainTypedQuiz || explainQuizVariantB)) || false
                    }
                  };
                  if (!data.isLastPage) {
                    getNextSetOfQuiz();
                  }
                } else if (!(data.questions && data.questions.length) && !data.isLastPage) {
                  const quizObj = {
                    id: optionSelected === constants.FREE_RESPONSE ? constants.FREE_RESPONSE_QUIZ : constants.QUIZ_CONTENT,
                    data: {
                      type,
                      optionSelected,
                      pageRange,
                      selectedChapter,
                      responseData: data,
                      pageIds: pageIdsFromResponse && pageIdsFromResponse.length ? pageIdsFromResponse : data.pageIds,
                      showSummary: true,
                      isReadyNext: false,
                      isLastQuestion: false,
                      isLastPage: isLastPage.current,
                      pageNums: pageNumsFromResponse,
                      pagesRange: pageRangeFromResponse,
                      selectedOptionType,
                      quizTitle: topicTitle,
                      showOptions: optionSelected === constants.FREE_RESPONSE,
                      currentQuestionIndex: currentQuestionIndex.current,
                      chapterData,
                      totalQuestionCount
                    }
                  };
                  getNextSetOfQuiz(true, quizObj);
                  noUpdateToSteps = true;
                }

                if (contentSelectorSelectedText && contentSelectorSelectedText.length) {
                  responseData.data.userSelectedContent = contentSelectorSelectedText;
                  responseData.data.isFromCSMenu = true;
                }
              }
              if (optionSelected === constants.PARAGRAPH || optionSelected === constants.BULLET || optionSelected === constants.SUMMARY) {

                gaEvents(handleGAEvents, {
                  event_action: eventAction.outputSuccess,
                  content_categories: null,
                  command_source_code: !clickPracticeQuizEvent ? constants.GPT_EVENTS.TYPE : constants.GPT_EVENTS.CLICK,
                  chat_type: constants.GPT_EVENTS.SUMMARY,
                  format: optionSelected === constants.PARAGRAPH ? constants.GPT_EVENTS.PARAGRAPH : constants.GPT_EVENTS.BULLET,
                  content_range: handleContentRange(isFromBotResponse ? (sourceTypeFromResponse || sourceType) : type),
                  initiated_point: !clickPracticeQuizEvent ? constants.GPT_EVENTS.COMMAND_LINE : constants.GPT_EVENTS.UI_QUICK_ACTION,
                  event_label: null,
                  event_value: null,
                  video_count: 0,
                  asset_ids_returned: null,
                  links: Utils.pageNavigationAnchorLinks(data?.requestId),
                  chat_id: globalContext.threadId,
                  result_index: null,
                  location_in_app: constants.GPT_EVENTS.GPT,
                  ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                  topic: null
                });
                // eslint-disable-next-line no-param-reassign
                if (showSummaryQuiz && showPractice) {
                  isSummaryQuiz = showSummaryQuiz;
                  summaryQuizSelectedOption= (type === constants.CURRENT_SECTION || type === constants.CURRENT_CHAPTER) ? type : (sourceTypeFromResponse || sourceType);
                  summaryQuizPageIds = payload.pageId;
                  gaEvents(handleGAEvents, {
                    event_action: eventAction.continuousSuggestionShown,
                    event_label: constants.GPT_EVENTS.CLICKTOSHOW,
                    event_value: null,
                    chat_type: constants.GPT_EVENTS.SUMMARY,
                  });
                }
                responseData = {
                  id: constants.SUMMARY_CONTENT,
                  data: {
                    summaryData: data,
                    optionSelected: optionSelected === constants.SUMMARY ? constants.BULLET : optionSelected,
                    pageRange,
                    selectedChapter,
                    pageId: pageIdsFromResponse && pageIdsFromResponse.length ? pageIdsFromResponse : pageId,
                    selectedOptionType,
                    chapterList,
                    pageList,
                    pageNums: pageNumsFromResponse,
                    pagesRange: pageRangeFromResponse,
                    showFormat: AITutorContext.enableParagraph,
                    topicTitle,
                    sourceType: sourceTypeFromResponse || sourceType,
                    chapterData
                  }
                };
                // suggestionData.optionSelected = optionSelected;
                // suggestionData.currentContext = type;
                if (contentSelectorSelectedText && contentSelectorSelectedText.length) {
                  responseData.data.userSelectedContent = contentSelectorSelectedText;
                  responseData.data.isFromCSMenu = true;
                }
              }
              !noUpdateToSteps && steps.push(responseData);
              // if (suggestionData && suggestionData.optionSelected) steps.push(suggestionData);
              setSteps(steps);
              setTimeout(() => {
                if (isExplainCYUMode) {
                  optionSelected = '';
                  isExplainCYUMode = '';
                }
              }, 1);
            }
          }
        }
        if (!(optionSelected === constants.QUIZ || optionSelected === constants.MULTIPLE_CHOICE || optionSelected === constants.FREE_RESPONSE)) {
          setTimeout(()=>{setAriaAssertive('off');}, 1000);
        }
        }
      ).catch((error) => {
        clearTimeout(summaryTimmer);
        setIsTextBoxDisable(false);
        optionSelected = constants.DISCUSS;
        quizQuestionsData = [];
        identifierType = '';
        if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
        if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
        if (error.response === undefined && error.code === 'ECONNABORTED') {
          errorData.message = Utils.getErrorMessage(error.code);
        } else {
          errorData.message = Utils.getErrorMessage(error.response.status);
        }
        const errorString = error.toString();
        if (errorString && errorString.indexOf('timeout') !== -1) {
          errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
        }
        steps.pop();
        if(steps[steps.length - 1].id === constants.LOADER){
          steps.pop();
        }
        steps.push(errorData);
        setSteps([...steps]);
      }
      });
    });
  };

  const handleRegenerate = (pageRange) => {
    handleRangeSubmit(optionType, pageRange, true);
    const additionalFields = {
      event_label: null,
      event_value: null,
      event_action: eventAction.clickRegenerate,
      command_source_code: 'click'
    };
    handleGAEvents(constants.BOT_EVENTS, additionalFields);
  };
  const handleSummaryOptionClick = (type, responseType, pageRange, selectedChapter, pageIdsFromResponse, pageNumsFromResponse, pageRangeFromResponse, sourceTypeFromResponse, clickPracticeQuizEvent = false) => {
    optionSelected = responseType;
    let gaEventAction = '';
    let gaEventLabel = '';
    if (responseType === constants.BULLET || responseType === constants.PARAGRAPH) {
      gaEventAction = eventAction.clickSummaryFormat;
      gaEventLabel = responseType === constants.BULLET ? 'Bullet Points' : 'Paragraph';
    } else if (responseType === constants.MULTIPLE_CHOICE || responseType === constants.FREE_RESPONSE) {
      gaEventAction = eventAction.clickPacticeFormat;
      gaEventLabel = responseType === constants.MULTIPLE_CHOICE ? 'Multi choice' : 'Free Response';
    }
    const additionalFields = {
      event_action: gaEventAction,
      event_label: gaEventLabel,
      command_source_code: 'click',
      event_value: null
    };
    gaEvents(handleGAEvents, additionalFields);
    handleRangeSubmit(type, pageRange, false, selectedChapter, pageIdsFromResponse, pageNumsFromResponse, false, '', pageRangeFromResponse, sourceTypeFromResponse, clickPracticeQuizEvent);
  };

  const nextQuestionInterval = (pageIds, isFreeResponse, chapterData) => {
    nextQuestionSetInterval = setInterval(() => {
      if ((currentQuestionIndex.current + 1) < quizQuestionsData.length) {
        steps.pop();
        setSteps([...steps]);
        handleShowNextQuestion(pageIds, isFreeResponse, chapterData);
      }
    }, 2000);
  };
  const handleShowNextQuestion = useCallback((pageIds, isFreeResponse, chapterData) => {
    setTimeout(() => {
      /** **********check if next question is available *************** */
      if ((currentQuestionIndex.current + 1) < quizQuestionsData.length) {
        clearInterval(nextQuestionSetInterval);
        currentQuestionIndex.current += 1;
        nextQuestion.current = '';
        const quizData = {
          id: isFreeResponse ? constants.FREE_RESPONSE_QUIZ : constants.QUIZ_CONTENT,
          requestId: quizStreamRequestId,
          responseId: quizStreamResponseId,
          data: {
            quizData: quizQuestionsData[(currentQuestionIndex.current)],
            isLastPage: isLastPage.current,
            pageIds,
            message: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && (quizQuestionsData[currentQuestionIndex.current].question || quizQuestionsData[currentQuestionIndex.current].question_stem),
            isReadyNext: false,
            showSummary: false,
            isLastQuestion: false,
            displayQuestion: true,
            showOptions: isFreeResponse,
            currentQuestionIndex: currentQuestionIndex.current,
            chapterData,
            totalQuestionCount
          }
        };
        if (isFreeResponse === constants.MULTIPLE_CHOICE) {
          quizData.data.currentQuestion = quizQuestionsData && quizQuestionsData[currentQuestionIndex.current];
        }
        // steps.push(quizData);
        // setSteps([...steps]);
        setSteps((pre) => [...pre, quizData]);
      } else if(((currentQuestionIndex.current + 1) >= quizQuestionsData.length) && (quizStreamStatus === constants.STREAMING || quizStreamStatus === constants.KEEP_ALIVE)) {
        // steps.push(quizLoader);
        // setSteps([...steps]);
        setSteps((pre) => [...pre, quizLoader]);
        nextQuestionInterval(pageIds, isFreeResponse, chapterData);
      } else { // reset
        optionSelected = 'discuss';
        handleQuizStop(true);
        // const noQuestionsLeft = {
        //   id: constants.NO_QUESTION_LEFT
        // };
        // steps.push(noQuestionsLeft);
        // suggestionData.optionSelected = 'MULTIPLE_CHOICE';
        // suggestionData.currentContext = contextData;
        // steps.push(suggestionData);
        // setSteps([...steps]);
        currentQuestionIndex.current = 0;
        // quizQuestionsData = [];
        // setQuizQuestionsData([]);
      }
    }, 50);
  }, [steps]);

  const handleSendCallback = (type, selectedOption, pageRange, selectedChapter, selectedQuizOption, clickPracticeQuizEvent = false) => {
    contentRangeValue = selectedOption;
    if((optionSelected === constants.MULTIPLE_CHOICE || optionSelected === constants.FREE_RESPONSE) && selectedQuizOption) {
      optionSelected = type;
    };
    if(isRecommendations) {
      setRecommendations(false);
    }
    const optionsArray = [
      constants.BULLET,
      constants.PARAGRAPH,
      constants.MULTIPLE_CHOICE,
      constants.FREE_RESPONSE
    ];
    if (selectedOption === constants.SPECIFIC_TOPIC) { //BRLN-257 Topic Quiz flow
      const topicUserMessage = {
        id: constants.USER_MESSAGE,
        data: {
          text: formatMessage(messages.specificTopicUserMessage)
        }
      };
      steps.pop();
      steps.push(topicUserMessage);
      const cannedMessage = {
        id: constants.CANNED_MESSAGE,
        message: formatMessage(messages.topicFollowUpText)
      };
      steps.push(cannedMessage);
      setSteps([...steps]);
     } else if (optionsArray.includes(optionSelected)) {
      handleRangeSubmit(selectedOption, pageRange, false, selectedChapter, null, null, null, null, null, null, clickPracticeQuizEvent);
    } else {
      const rangeClick = {
        id: constants.USER_MESSAGE,
        data: {
          text: 'Use the content from the current eTextbook page I am on'
        }
      };
      const lastStepObject = steps && steps.length && steps[steps.length -1];
      if(lastStepObject && lastStepObject.id === constants.WELCOME_MESSAGE) {
        steps.splice(steps.length - 2 , 2);
      } else {
        steps.pop();
      }
      selectedOptionType = selectedOption;
      if (selectedOption === constants.PAGE_RANGE) {
        rangeClick.data.text = `Use the content from pages ${pageRange.startIndex}-${pageRange.endIndex}`;
      } else if (selectedOption === constants.CHAPTERS && selectedChapter) {
        let chapterTitle = `Use the content from chapter${(selectedChapter.length > 1) ? 's' : ''} `;
        chapterTitle += Utils.concatChapterTitle(selectedChapter);
        rangeClick.data.text = chapterTitle;
      } else if (selectedOption === constants.CURRENT_SECTION) {
        rangeClick.data.text = 'Use content from the current section.';
      } else if (selectedOption === constants.CURRENT_CHAPTER) {
        rangeClick.data.text = 'Use content from the whole chapter.';
      }
      steps.push(rangeClick);
      setSteps([...steps]);
      const summaryOptionStep = {
        id: constants.SUMMARY_OPTIONS,
        data: {
          type: selectedOption,
          pageRange,
          selectedChapter
        }
      };  
      if (selectedQuizOption || type === constants.QUIZ) {
        optionSelected = selectedQuizOption || constants.MULTIPLE_CHOICE;
        handleRangeSubmit(selectedOption, null, false, null, null, null, null, null, null, null, clickPracticeQuizEvent);
      } else {
        if (type === constants.QUIZ && AITutorContext.enableFreeResponse) {
          summaryOptionStep.id = constants.QUIZ_OPTIONS;
        } else if (type === constants.QUIZ && !AITutorContext.enableFreeResponse) {
          optionSelected = constants.MULTIPLE_CHOICE;
          handleRangeSubmit(selectedOption, pageRange, false, selectedChapter, null, null, null, null, null, null, clickPracticeQuizEvent);
        }
        if (type === constants.SUMMARY && !AITutorContext.enableParagraph) {
          optionSelected = constants.SUMMARY;
          handleRangeSubmit(selectedOption, pageRange, false, selectedChapter, null, null, null, null, null, null, clickPracticeQuizEvent);
        }
        if ((type === constants.SUMMARY && AITutorContext.enableParagraph) || (type === constants.QUIZ && AITutorContext.enableFreeResponse)) {
          steps.push(summaryOptionStep);
          setSteps([...steps]);
          // Utils.scrollToView(5);
        }
      }
    }
  };

  const setTopicsToDiscuss = (value) => {
    steps.push(loaderStep);
    setSteps([...steps]);
    const payload = {
      bookId: context.bookID,
      productModel: context.productModel,
      pageIds: pageId,
      indexId: context.indexId,
      topicTimeOut
    };
    if (isFromContentSelector.current && value && value.length) {
      payload.userSelectedContent = value;
    }
    setIsTextBoxDisable(true);
    setIsAPICalled(true);
    setAriaAssertive('assertive');
    getAuthToken((tokenKey) => {
      berlinService.fetchTutorData(
        payload,
        constants.GENERATE_TOPIC,
        null,
        tokenKey,
        (data) => {
          steps.pop();
          setIsTextBoxDisable(false);
          setIsAPICalled(false);
          if ((data.response && data.response.isNotValidAIResponse) || (data.isNotValidAIResponse)) {
            const responseData = {
              id: constants.ERROR_MESSAGE,
              message: data.response && data.response.text.replaceAll('###', '')
            };
            steps.push(responseData);
            setSteps([...steps]);
          } else {
          if (data && data.topic_count > 0) {
            if (data.topic_count === 1) {
              const singleTopic = {
                id: constants.SINGLE_TOPIC,
                data: {
                  topic: data.topics[0],
                  isContentSelectorFirstTime: isContentSelectorFirstTime.current
                }
              };
              const topic = data.topics[0];
              steps.push(singleTopic);
              handleSend({}, `Explain ${topic.toLowerCase()}`, true, null, null, null, null, true, false, data.requestId);
            } else {
              const discussTopics = {
                id: constants.GENERATE_TOPIC,
                data: {
                  type: constants.GENERATE_TOPIC,
                  topics: data.topics,
                  data: data,
                  requestId: data.requestId,
                  disbaleTopics: false,
                  isContentSelectorFirstTime: isContentSelectorFirstTime.current,
                  contentSelectedText: value.length
                }
              };
              steps.push(discussTopics);
            }
            setSteps([...steps]);
          } else {
            const errorMessageFeed = {
              id: constants.ERROR_MESSAGE,
              message: formatMessage(messages.insufficientContentFeed)
            };
            // const errorMessage = {
            //   id: constants.ERROR_MESSAGE,
            //   message: formatMessage(messages.insufficientContent)
            // };
            steps.push(errorMessageFeed);
            setSteps([...steps]);
            }
          }
          setTimeout(()=>{setAriaAssertive('off');}, 1000);
        }
      ).catch((error) => {
        if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
        if (error) {
          const errorString = error.toString();
          if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
          if (error.response === undefined && error.code === 'ECONNABORTED') {
            errorData.message = Utils.getErrorMessage(error.code);
          } else {
            errorData.message = Utils.getErrorMessage(error.response.status);
          }
          if (errorString && errorString.indexOf('timeout') !== -1) {
            errorData.message = 'I see that you selected some content. Let me know how can I help!';
          }
          steps.pop();
          steps.push(errorData);
          setSteps([...steps]);
        }
      }
      });
    });
  };

  const onContentSelectionOptionClick = (type, value) => {
    if (type === constants.SUMMARY || type === constants.QUIZ) {
      // let eventLabelType = messages.summaryDescriptionForContentSelector.defaultMessage;
      let chatType = 'Summary';
      if (type === constants.QUIZ) {
        // eventLabelType = messages.quizDescriptionForContentSelector.defaultMessage;
        chatType = 'Practice';
      }

    }
    // setContentSelectionOption(false);
    if (type === constants.DISCUSS) {
      handleOptionClick(type, false, value, true);
    } else if (type === constants.QUIZ) {
      // eslint-disable-next-line no-use-before-define
      const csMessage = {
        id: constants.USER_MESSAGE,
        data: {
          text: 'I need practice questions from the content'
        }
      };
      const csQuizOption = {
        id: constants.QUIZ_OPTIONS,
        data: {
          type: constants.QUIZ
        }
      };
      steps.push(csMessage);
      if (AITutorContext.enableFreeResponse) {
        steps.push(csQuizOption);
      }
      setSteps([...steps]);
      // Utils.scrollToView(5);
      if (!AITutorContext.enableFreeResponse) {
        steps.push(loaderStep);
        setSteps([...steps]);
        const payload = {
          bookId: context.bookID,
          pageIds: pageId,
          productModel: context.productModel,
          indexId: context.indexId,
          option: constants.MULTIPLE_CHOICE
        };
        // if (isFromContentSelector.current && value) {
        //   payload.userSelectedContent = value;
        // }
        setIsTextBoxDisable(true);
        setIsAPICalled(true);
        setAriaAssertive('assertive');
        getAuthToken((tokenKey) => {
          berlinService.fetchTutorData(
            payload,
            constants.QUIZ,
            null,
            tokenKey,
            (data) => {
              steps.pop();
              setIsTextBoxDisable(false);
              setIsAPICalled(false);
              if ((data.response && data.response.isNotValidAIResponse) || (data.isNotValidAIResponse)) {
                const responseData = {
                  id: constants.ERROR_MESSAGE,
                  message: data.response && data.response.text.replaceAll('###', '')
                };
                steps.push(responseData);
                setSteps([...steps]);
              } else {
              if (data) {
                quizQuestionsData = data.questions;
                const responseData = {
                  id: constants.QUIZ_CONTENT,
                  data: {
                    type,
                    optionSelected: constants.MULTIPLE_CHOICE,
                    pageRange: {},
                    selectedChapter: [],
                    responseData: data,
                    questionsLength: data.questions && data.questions.length,
                    isLastPage: isLastPage.current,
                    quizData: data.questions && data.questions[0],
                    showSummary: true,
                    pageIds: data.pageIds,
                    currentQuestionIndex: currentQuestionIndex.current,
                    chapterData
                  }
                };
                steps.push(responseData);
                setSteps([...steps]);
                }
              }
              setTimeout(()=>{setAriaAssertive('off');}, 1000);
            }
          ).catch((error) => {
            if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
            if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
            if (error.response === undefined && error.code === 'ECONNABORTED') {
              errorData.message = Utils.getErrorMessage(error.code);
            } else {
              errorData.message = Utils.getErrorMessage(error.response.status);
            }
            const errorString = error.toString();
            if (errorString && errorString.indexOf('timeout') !== -1) {
              errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
            }
            steps.pop();
            steps.push(errorData);
            setSteps([...steps]);
          }
        });
      });
    }
    } else {
      steps.push(loaderStep);
      setSteps([...steps]);
      const payload = {
        bookId: context.bookID,
        pageIds: pageId,
        productModel: context.productModel,
        indexId: context.indexId,
        option: 'bullet_points'
      };
      // if (isFromContentSelector.current && value) {
      //   payload.userSelectedContent = value;
      // }
      setIsTextBoxDisable(true);
      setIsAPICalled(true);
      setAriaAssertive('assertive');
      getAuthToken((tokenKey) => {
        berlinService.fetchTutorData(
          payload,
          constants.SUMMARY,
          null,
          tokenKey,
          (data) => {
            steps.pop();
            setIsTextBoxDisable(false);
            setIsAPICalled(false);
            if ((data.response && data.response.isNotValidAIResponse) || (data.isNotValidAIResponse)) {
              const responseData = {
                id: constants.ERROR_MESSAGE,
                message: data.response && data.response.text.replaceAll('###', '')
              };
              steps.push(responseData);
              setSteps([...steps]);
            } else {
            if (data) {
              const responseData = {
                id: constants.SUMMARY_CONTENT,
                data: {
                  summaryData: data,
                  optionSelected: constants.BULLET,
                  pageId,
                  selectedOptionType,
                  chapterList,
                  pageList,
                  isFromCSMenu: type
                }
              };
              steps.push(responseData);
              setSteps([...steps]);
              }
            }
            setTimeout(()=>{setAriaAssertive('off');}, 1000);
          }
        ).catch((error) => {
          if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
          if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
          if (error.response === undefined && error.code === 'ECONNABORTED') {
            errorData.message = Utils.getErrorMessage(error.code);
          } else {
            errorData.message = Utils.getErrorMessage(error.response.status);
          }
          const errorString = error.toString();
          if (errorString && errorString.indexOf('timeout') !== -1) {
            errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
          }
          steps.pop();
          steps.push(errorData);
          setSteps([...steps]);
        }
        });
      });
    }
  };

  const onPredefinedQueryClick = (command, isAutoOpen = true) => {
    setRecommendations(false);
    if(isAutoOpen) {
      setAITutorAutoOpen();
      steps.pop();
    } else {
      if (steps && steps.length && steps.slice(-1)[0].id &&
      (steps.slice(-1)[0].id === constants.CLARIFY ||
        steps.slice(-1)[0].id === constants.CHAPTER_TOPIC_OPTION ||
        steps.slice(-1)[0].id === constants.SUMMARY_OPTIONS ||
        steps.slice(-1)[0].id === constants.QUIZ_OPTIONS)) {
        steps.slice(-1)[0].data.disableOptions = true;
      } else if (steps && steps.length && steps.slice(-1)[0].id &&
        steps.slice(-1)[0].id === constants.WELCOME_MESSAGE) {
        steps.pop();
      }
      if (steps && steps.length && steps[steps.length - 1].id === constants.SUMMARY_CONTENT && isSummaryQuiz) {
        steps[steps.length - 1].data.summaryQuizButton = false;
      }
      if (steps && steps.length && steps[steps.length - 1].id === constants.STREAMING_DISCUSS && isExplainQuiz) {
        steps[steps.length - 1].data.explainQuizButton = false;
      }
    }
    const userCommandMessage = {
      id: constants.USER_MESSAGE,
      data: {
        text: command
      }
    };
    steps.push(userCommandMessage);
    setSteps([...steps]);
    optionSelected = constants.DISCUSS;
    identifierType = '';
    isSummaryQuiz = false;
    isExplainQuiz = false;
    isFromRecommendationPill = true;
    clickTopicSuggestionsEvent(handleGAEvents, constants.GPT_EVENTS.PAGE_SUGGESTIONS, context.bookID, recommendationsPromptData);
    handleSend({}, command);
  }

  const handleOptionClick = (type, isFromStopQuiz, value, isValueFromContentSelector = false, isFromUnknownIntentType = false) => {
    if (isFromStopQuiz || isFromUnknownIntentType) steps.pop();
    if(isRecommendations) {
      setRecommendations(false);
    }
    setShowOptions(false);
    setAITutorAutoOpen();
    const optionStep = {
      id: constants.USER_MESSAGE,
      data: {
        text: 'I need summary using bullet points'
      }
    };
    // let eventLabelType = messages.summaryDescription.defaultMessage;
    isExplainClicked = false;
    let chatType = 'Summary';
    identifierType = constants.SUMMARY_MODE;
    if (type === constants.QUIZ) {
      // eventLabelType = messages.quizDescription.defaultMessage;
      identifierType = constants.QUIZ_MODE;
      chatType = 'Practice';
    } else if (type === constants.DISCUSS) {
      // eventLabelType = messages.discussDescription.defaultMessage;
      identifierType = '';
      chatType = 'Explain';
      if (!isValueFromContentSelector) {
        isExplainClicked = true;
      }
    }
    if (!isValueFromContentSelector) {
      const additionalFields = {
        event_action: eventAction.clickChatType,
        event_label: chatType,
        event_value: null,
        chat_type: chatType, // TODO : "SummaryNext", "PracticeNext","ExplainNext"
        command_source_code: 'click'
      };
      gaEvents(handleGAEvents, additionalFields);
      switch (type) {
        case constants.QUIZ:
          optionStep.data.text = formatMessage(messages.practiceQuestions);
          break;
        case constants.SUMMARY:
          optionStep.data.text = formatMessage(messages.summaryOfCurrentPage);
          break;
        case constants.DISCUSS:
          explainFlowMessage.current = 'Here is an explanation for that topic:';
          optionStep.data.text = 'I need something explained';
          break;
        default:
          optionStep.data.text = 'I would like to discuss';
          break;
      }
      steps.push(optionStep);
    }
    optionSelected = type;
    if (optionSelected !== constants.DISCUSS) {
      const chooseOptionStep = {
        id: constants.CLARIFY,
        data: {
          type,
          chapterList
        }
      };
      steps.push(chooseOptionStep);
    } else if (optionSelected === constants.DISCUSS && isFromContentSelector.current && value) {
      if (isContentSelectorFirstTime.current || !contentSelectorBotOpen) {
        const messageForFirstTimeUser = (isContentSelectorFirstTime.current && users.isFirstTimeAITutorUser) ? formatMessage(messages.welcomeMessage) : formatMessage(messages.welcomeBackMessage);
        const welcomeMessageForContentSelectorUser = {
          id: constants.ERROR_MESSAGE,
          message: messageForFirstTimeUser,
          bookTitle: isContentSelectorFirstTime.current
        };
        steps.push(welcomeMessageForContentSelectorUser);
      }
      setContentSelectorBotOpen(true);
      setTopicsToDiscuss(value);
    } else {
      const discussFollowUpText = {
        id: constants.FREE_RESPONSE_QUIZ,
        isDiscussFeature: true,
        data: {
          message: formatMessage(messages.discussFollowUpText),
          isReadyNext: false,
          showSummary: false,
          isLastQuestion: false,
          displayQuestion: false,
          showOptions: false,
          currentQuestionIndex: currentQuestionIndex.current,
          chapterData
        }
      };
      steps.push(discussFollowUpText);
    }
    setSteps([...steps]);
  };

  const handleChapterOptionSend = (pageIds, selectedOption, intentSelected, title, csgPageId, type, sourceType, requestIdFromResponse) => {
    optionSelected = selectedOption || (intentSelected === constants.SUMMARY_INTENT ? constants.BULLET : constants.MULTIPLE_CHOICE);
    const pageIdsBasedOnCsg = type.toLowerCase() === 'chapter' ? getChapterList(csgPageId) : getCurrentSection(csgPageId);
    handleRangeSubmit(selectedOption || (intentSelected === constants.SUMMARY_INTENT ? constants.BULLET : constants.MULTIPLE_CHOICE), {}, false, [], pageIdsBasedOnCsg && pageIdsBasedOnCsg.pageIds && pageIdsBasedOnCsg.pageIds.length ? pageIdsBasedOnCsg.pageIds : pageIds, [], true, title, null, null, false, sourceType, ((pageIdsBasedOnCsg && pageIdsBasedOnCsg.chapterData) || chapterData), false, false, false, false, false, requestIdFromResponse);
  };

  const handleSuggestion = (suggestionType, currentContext) => {
    if (suggestionType === constants.DISCUSS) {
      const discussFollowUpText = {
        id: constants.FREE_RESPONSE_QUIZ,
        isDiscussFeature: true,
        data: {
          message: formatMessage(messages.discussFollowUpText),
          isReadyNext: false,
          showSummary: false,
          isLastQuestion: false,
          displayQuestion: false,
          showOptions: false,
          currentQuestionIndex: currentQuestionIndex.current,
          chapterData
        }
      };
      steps.push(discussFollowUpText);
      setSteps([...steps]);
    } else {
      optionSelected = suggestionType === constants.SUMMARY ? constants.BULLET : constants.MULTIPLE_CHOICE;
      handleRangeSubmit(currentContext);
    }
  };

  const getChapterList = (csgId) => {
    const filteredChapter = chapterList.find(list => list.chapterId === csgId);
    let chapterObject = {
    };
    if(filteredChapter) {
      const {
        pageIds,
        chapterId,
        chapterTitle,
        chapterNumber
      } = filteredChapter;
      chapterObject = {
        pageIds,
        chapterData: {
          chapterId,
          chapterTitle,
          chapterNumber
        }
      };
    }
    return chapterObject;
  }

  const prepareMathMLPayload = (html, userAnswer) => {
    var fragment = document.createElement('div');
    fragment.innerHTML = html || userAnswer;
    for (let span of fragment.querySelectorAll('span.mathField'))
      span.innerHTML = `${span.getAttribute('data-mathLatex')}`;
    return fragment.innerText;
  }

  const handleOptInLinkDisable = () => {
    const stepLength = steps?.length;
    const lastStep = steps[stepLength - 1];
    if(lastStep?.id === constants.STREAMING_DISCUSS && lastStep?.data?.isOptInLinkEnabled) {
      lastStep.data.isOptInLinkEnabled = false;
    }
  }

  const handleSend = (event, userAnswer, isTopics = false, showMoreDetails, fromDictionary, typedEvent = false, tellMeMore = false, contentSelectorclicked = false, fromGlossary = false, requestIdfromResponse, isProblemSolveRectifier = false, isProblemSolveCalculation = false, imagePromptAction = '') => {
    if ((isExplainCYUMode || fromGlossary) && (enableExplainQuizOptions === constants.EXPLAIN_QUIZ_OPTION_B || enableExplainQuizOptions === constants.EXPLAIN_QUIZ_OPTION_C)) {
      handleCancelRequestAPI();
    }
    handleOptInLinkDisable();
    let editableMathInput;
    if (enableMathMLKeyboard) editableMathInput = document.getElementById('editableMathInput');
    if(contentSelectorclicked){
      isContentSelectFlow.current = true;
    }
    stopQuiz = false;
    if (((event.key === 'Enter' || event.type === 'click') && !isTextBoxDisable) || (event && Object.keys(event).length === 0) || showMoreDetails) {
    setMaxCharValidationMessage('');
    if (!fromGlossary) {
      if (steps && steps.length && steps.slice(-1)[0].id &&
      (steps.slice(-1)[0].id === constants.CLARIFY ||
        steps.slice(-1)[0].id === constants.CHAPTER_TOPIC_OPTION ||
        steps.slice(-1)[0].id === constants.SUMMARY_OPTIONS ||
        steps.slice(-1)[0].id === constants.QUIZ_OPTIONS)) {
        steps.slice(-1)[0].data.disableOptions = true;
      } else if (steps && steps.length && steps.slice(-1)[0].id && steps.slice(-1)[0].id === constants.GENERATE_TOPIC) {
        steps.slice(-1)[0].data.disbaleTopics = true;
      } else if (steps && steps.length && steps.slice(-1)[0].id &&
        (steps.slice(-1)[0].id === constants.WELCOME_MESSAGE || steps.slice(-1)[0].id === constants.AFTER_STOP)) {
        steps.pop();
      }
      if (steps && steps.length && steps[steps.length - 1].id === constants.SUMMARY_CONTENT && isSummaryQuiz) {
        steps[steps.length - 1].data.summaryQuizButton = false;
      }
      if (steps && steps.length && steps[steps.length - 1].id === constants.STREAMING_DISCUSS && isExplainQuiz) {
        steps[steps.length - 1].data.explainQuizButton = false;
      }
    }
    
    const additionalFields = {
      event_label: null,
      event_value: null,
      event_action: eventAction.clickSend,
      command_source_code: (event && event.key === 'Enter') ? 'type' : 'click',
      location_in_app: isFromContentSelector.current ? 'content selector' : constants.GPT_EVENTS.GPT
    };
    gaEvents(handleGAEvents, additionalFields);
    if (!startNewConversationGaEvent) {
      startNewConversationGA();
      setStartNewConversationGaEvent(true);
    }
    if (isTopics) {
      isFromContentSelector.current = false;
    }
    if (isFromContentSelector.current) {
      steps.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        if (item.id === constants.GENERATE_TOPIC) item.data.disbaleTopics = true;
      });
    }
    let lastStepIntentType = '';
    let lastStepQuestion = '';
    const lastStepObject = steps && steps.length && steps[steps.length - 1];
    const lastStepId = lastStepObject && lastStepObject.id;
    if(globalContext.imageCopilotMode) {
      // removeLastStep(constants.IMAGE_CONTEXT);
      if (lastStepId && lastStepId === constants.IMAGE_CONTEXT) {
        steps[steps.length - 1].disableOptions = true;
      }
      if(lastStepId && (lastStepId === constants.STREAMING_DISCUSS || lastStepId === constants.CANNED_MESSAGE)) {
        lastStepIntentType = lastStepObject?.intentType;
        lastStepQuestion = lastStepObject?.content;
      }
    }

    if ((inputValue && inputValue.trim()) || userAnswer || showMoreDetails) {
      setShowOptions(false);
      setRecommendations(false);
      const updatedStep = {
        id: constants.USER_MESSAGE,
        data: {
          text: !isTopics ? (enableMathMLKeyboard ? editableMathInput.innerHTML.replace(/[<]br[^>]*[>]/gi,"") : inputValue) : userAnswer
        }
      };
      if (showMoreDetails && identifierType) {
        updatedStep.data.text = formatMessage(messages.tellMore);
      }
      let payload = {
        userCommand: enableMathMLKeyboard ? prepareMathMLPayload(editableMathInput.innerHTML, userAnswer) : inputValue || userAnswer,
        bookId: context.bookID,
        productModel: context.productModel,
        indexId: context.indexId,
        pageIds: pageId
      };
      steps.forEach((item) => {
        if (item.id === constants.DISCUSS || item.id === constants.STREAMING_DISCUSS) {
          // eslint-disable-next-line no-param-reassign
          item.data.disableOptions = true;
        }
        // eslint-disable-next-line no-param-reassign
        if (item.id === constants.FREE_RESPONSE_QUIZ || item.id === constants.QUIZ_CONTENT) {
          // eslint-disable-next-line no-param-reassign
          item.data.disableOptions = true;
          // eslint-disable-next-line no-param-reassign
          item.data.showOptions = false;
        }
        if (item.id === constants.FREE_RESPONSE_QUIZ_VALIDATE) {
          // eslint-disable-next-line no-param-reassign
          item.data.isReadyNext = false;
        }
        if (item.id === constants.QUIZ_ANSWER) {
          // eslint-disable-next-line no-param-reassign
          item.data.disableOptions = true;
        }
      });
      if ((optionSelected === constants.FREE_RESPONSE || optionSelected === constants.MULTIPLE_CHOICE)) {
        payload = {
          bookId: context.bookID,
          productModel: context.productModel,
          userAnswer: nextQuestion.current ? `${nextQuestion.current}${inputValue}` : inputValue,
          question: nextQuestion.current ? '' : (quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && (quizQuestionsData[currentQuestionIndex.current].question || quizQuestionsData[currentQuestionIndex.current].question_stem)),
          quizRefId: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && quizQuestionsData[currentQuestionIndex.current].quizRefId || quizRefId.current,
          option: optionSelected
        };

        if (optionSelected === constants.FREE_RESPONSE) {
          payload.questionId = nextQuestion.current ? '' : (quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && (quizQuestionsData[currentQuestionIndex.current].questionId));
        }

        if (optionSelected === constants.MULTIPLE_CHOICE) {
          const currentQuizQuestion = quizQuestionsData && quizQuestionsData[currentQuestionIndex.current];
          const customCurrentQuestion = {...currentQuizQuestion}
          if (customCurrentQuestion) {
            delete customCurrentQuestion.requestId;
            delete customCurrentQuestion.responseId;
          }
          payload.question = nextQuestion.current ? '' : customCurrentQuestion;
        }
        if (isPausedModeOn.current) {
          payload.isPauseMode = true;
        }
      }
      // if (isFromContentSelector.current && contentSelectorSelectedText) {
      //   payload.userSelectedContent = contentSelectorSelectedText;
      // }
      const headerParams = {
        headers: {}
      };
      if (enableStreamingAPI) {
        headerParams.headers.isStream = true;
        // payload.userCommand = 'give summary of this page in bullet points';
      }
      if ((!userAnswer || isTopics || showMoreDetails) && !fromDictionary) {
        steps.push(updatedStep);
      }
      steps.push(loaderStep);
      setSteps([...steps]);
      setInputValue('');
      if (context.isPpmTextExtracted || (window.localStorage.getItem('use_print_page_no') === 'true' && context.printPageNumber && context.printPageNumber !== '-')) {
        payload.isPpmTextExtracted = true;
      }
      // if (enableStreamingAPI) { setIsTextBoxDisable(true); }
      setIsTextBoxDisable(true);

      const delayedResponseStep = () => {
        const responseData = {
          id: constants.DELAYED_RESPONSE,
        };
        steps.push(responseData);
        setSteps([...steps]);
      };

      let chatTimmer = startTimer(delayedResponseStep);
      let userCommandUri = (((optionSelected === constants.FREE_RESPONSE || optionSelected === constants.MULTIPLE_CHOICE) && quizQuestionsData && quizQuestionsData.length) || lastStepIntentType === constants.IMAGE_CYU_INTENT) ? constants.QUIZ_VALIDATE : constants.CHAT;
      const topicTitle = topic && topic.length && topic[0] && topic[0].title;
      if (showMoreDetails) {
        payload.showMoreSummary = true;
        payload.userCommand = topicTitle;
      }
      if (!showMoreDetails && identifierType) {
        payload = {
          userCommand: enableMathMLKeyboard ? prepareMathMLPayload(editableMathInput.innerHTML, userAnswer) : inputValue || userAnswer,
          bookId: context.bookID,
          productModel: context.productModel,
          indexId: context.indexId,
          pageIds: pageId,
          userMode: identifierType
        };
      }
      if (enableMathMLKeyboard) {
        editableMathInput.innerHTML = '';
      }
      if (identifierType === constants.SHOW_MORE_IDENTIFIER && !typedEvent) {
        payload.userTopic = topicTitle;
        payload.userMode = constants.SUMMARIZE_EXPLAIN_MODE;
      }
      if (enableShowMore && userCommandUri === constants.CHAT) {
        headerParams.headers.summaryExplain = true;
      }
      if ((isSummaryQuiz || isExplainQuiz) && showPractice && userCommandUri === constants.CHAT){
        payload.userMode = constants.USER_SELECTION_MODE;
        payload.userActions = [
          {
            label: formatMessage(messages.summaryQuizMe),
            intent: 'yes_quiz_me'
          },
          {
            label: 'No, let\'s move on',
            intent: constants.MOVE_ON_INTENT
          }
        ];
      }
      if (requestIdfromResponse) {
        payload.requestId = requestIdfromResponse;
      }
      if (isProblemSolveRectifier) {
        payload.userMode = constants.PROBLEM_SOLVE_RECTIFIER;
      }
      if (isProblemSolveCalculation) {
        payload.userMode = constants.PROBLEM_SOLVE_CALCULATION;
      }
      if(AIEnableImageContext && imageContextData && imageContextData.contextUrl && userCommandUri === constants.CHAT) {
        payload = {...payload, additionalContext: [
          {
            contextUrl : imageContextData.contextUrl,
            contextId: imageContextData.contextId,
            contextType: imageContextData.contextType
          }
        ]};
      }
      
      setIsAPICalled(true);
      setAriaAssertive('assertive');
      if(imagePromptAction) { // Image Explain, CYU Flow
        payload.userAction = imagePromptAction;
        delete payload.userCommand;
      }
      if(globalContext.imageCopilotMode) {
        payload.userMode = constants.IMAGE_COPILET_MODE;
        if(globalContext.imageContextData && globalContext.imageContextData.contextUrl && userCommandUri === constants.CHAT) {
          const additionalContextObject = {
            contextUrl : globalContext.imageContextData.contextUrl,
            contextId: globalContext.imageContextData.contextId,
            contextType: globalContext.imageContextData.contextType
          };
          if(imageCopilotInitialization) {
            additionalContextObject.initialization = true;
          }
          payload = {...payload, additionalContext: [additionalContextObject]};
          imageCopilotInitialization = false;
        }
      }
      if(lastStepIntentType === constants.IMAGE_CYU_INTENT) { // Image CYU follow up
        payload.question = lastStepQuestion;
        payload.userAnswer = payload.userCommand;
        delete payload.userCommand;
      }

      getAuthToken((tokenKey) => {
        berlinService.fetchTutorData(
          payload,
          userCommandUri,
          headerParams,
          tokenKey,
          (data) => {
            let topicsQuizMode = identifierType;
            identifierType = '';
            if (data.sourceType) contentRangeValue = data.sourceType;
            if ((data.intentType || data.quizIntentType) && !isContentSelectFlow.current && !(data.intentType === constants.SUMMARY_INTENT && data.useStream )) {
              let intentTypeName;
              switch (data.intentType || data.quizIntentType) {
                case constants.CONTACT_INTENT:
                  intentTypeName = constants.CONTACT_INTENT;
                  break;
                case constants.PROBLEM_SOLVE_FEATURE:
                  intentTypeName = constants.PROBLEM_SOLVE_FEATURE;
                  break;
                case constants.SUMMARY_INTENT:
                  intentTypeName = constants.SUMMARY;
                  break;
                case constants.EXPLAIN_INTENT:
                  intentTypeName = constants.GPT_EVENTS.EXPLAIN_TRIGGER_EVENT;
                  break;
                case constants.QUIZ_INTENT:
                  intentTypeName = constants.GPT_EVENTS.PRACTICE_QUIZ;
                  break;
                case constants.STOP_QUIZ_FEATURE:
                  intentTypeName = constants.GPT_EVENTS.STOP_QUIZ;
                  break;
                case constants.ANSWER_FEATURE:
                  intentTypeName = constants.ANSWER;
                  break;
                case constants.PAUSED_QUIZ_FEATURE:
                  intentTypeName = constants.GPT_EVENTS.PAUSED_QUIZ;
                  break;
                case constants.NEXT_QUIZ_FEATURE:
                  intentTypeName = constants.GPT_EVENTS.NEXT_QUIZ;
                  break;
                case constants.SWITCH_CHAT_FEATURE:
                  intentTypeName = constants.GPT_EVENTS.SWITCH_CHAT;
                  break;
                case constants.UNKNOWN:
                  intentTypeName = constants.GPT_EVENTS.UNKNOWN_INTENT;
                  break;
              }
              gaEvents(handleGAEvents, {
                event_action: eventAction.triggerIntention,
                event_label: intentTypeName || data.intentType,
                event_value: null,
                ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.TRIGGER_INTENTION)
              });
            }
            topic = (data && data.response && data.response.topic) || data.topic;
            let intentsToBeSkipped = [constants.CONTACT_INTENT, constants.PROBLEM_SOLVE_FEATURE, constants.UNKNOWN, constants.INVALID];
            if (enableProblemSolveFeature) {
              intentsToBeSkipped = [constants.CONTACT_INTENT, constants.UNKNOWN, constants.INVALID];
            }
            setIsAPICalled(false);
            if (steps[steps.length - 1].id === 'delay') {
              steps.pop();
            }
            clearTimeout(chatTimmer);
            if(data.sourceType === constants.TOPIC && data.currentContext === constants.TOPIC && payload.userMode === constants.QUIZ_MODE) { // BRLN-257 Topic Quiz flow chat support
              steps.pop();
              errorData.message = formatMessage(messages.topicFollowUpText);
              steps.push(errorData);
              setSteps([...steps]);
              setIsTextBoxDisable(false);
              identifierType = constants.QUIZ_MODE;

            } else if (data.sourceType === null && data.currentContext === constants.TOPIC) {
              steps.pop();
              setSteps([...steps]);
              switch(data.intentType) {
                case constants.TELL_MORE_INTENT:
                  gaEvents(handleGAEvents, {
                    event_action: eventAction.clickChatContinuation,
                    event_label: 'Tell me more',
                    event_value: null,
                    command_source_code: 'type'
                  });
                  handleSend({}, userAnswer, false, true, true, true);
                  break;
                case constants.UNKNOWN:
                  handleSend({}, userAnswer, false, false, true);
                  break;
                case constants.MOVE_ON_INTENT:
                  gaEvents(handleGAEvents, {
                    event_action: eventAction.clickChatContinuation,
                    event_label: `Let's move on`,
                    event_value: null,
                    command_source_code: 'type'
                  });
                  const moveData = {
                    id: constants.AFTER_STOP,
                    data: {
                      isFromStopQuiz: false,
                      isFromUnknownIntentType: false,
                      isMove: true
                    }
                  };
                  steps.push(moveData);
                  setSteps([...steps]);
                  setIsTextBoxDisable(false);
                  topic = '';
                  identifierType= '';
                  break;
              }
            } else if (data.currentContext === constants.USER_SELECTION) {
              gaEvents(handleGAEvents, {
                event_action: eventAction.selectContinuousSuggestion,
                event_label: 'NA',
                event_value: null,
                initiated_point: constants.GPT_EVENTS.COMMAND_LINE,
                chat_type: isExplainQuiz ? constants.GPT_EVENTS.EXPLAIN : constants.GPT_EVENTS.SUMMARY,
                intention: data.intentType === constants.YES_QUIZ_ME_INTENT ? constants.GPT_EVENTS.YES : constants.GPT_EVENTS.NO,
                content_range: isExplainQuiz ? constants.GPT_EVENTS.TOPIC : (summaryQuizSelectedOption === constants.CURRENT_SECTION || summaryQuizSelectedOption === constants.SECTION) ? constants.GPT_EVENTS.SECTION : constants.GPT_EVENTS.CHAPTER,
                format: (data.option === constants.BULLET) ? constants.GPT_EVENTS.BULLET : constants.GPT_EVENTS.PARAGRAPH,
              });
              steps.pop();
              setSteps([...steps]);
              switch(data.intentType) {
                case constants.YES_QUIZ_ME_INTENT:
                  handleRangeSubmit(null, null, null, null, summaryQuizPageIds, null, null, null, null, null, true, null, chapterData, isSummaryQuiz, isSummaryQuiz, isExplainQuiz, isExplainQuiz);
                  break;
                case constants.MOVE_ON_INTENT:
                  const moveData = {
                    id: constants.AFTER_STOP,
                    data: {
                      isFromStopQuiz: false,
                      isFromUnknownIntentType: false,
                      isMove: true
                    }
                  };
                  steps.push(moveData);
                  setSteps([...steps]);
                  setIsTextBoxDisable(false);
                  break;
              }
              summaryQuizSelectedOption = '';
              summaryQuizPageIds = [];
              isExplainQuiz = false;
              isSummaryQuiz = false;
            } else {
              if ((data.response && data.response.isNotValidAIResponse) || (data.isNotValidAIResponse)) {
                const responseData = {
                  id: constants.ERROR_MESSAGE,
                  message: data.response && data.response.text.replaceAll('###', '')
                };
                setIsTextBoxDisable(false);
                steps.pop();
                steps.push(responseData);
                setSteps([...steps]);
              } else {
                if (enableStreamingAPI && data.useStream && data.requestId && !(intentsToBeSkipped.includes(data.intentType))) {
                  if (data && data.pageIds && data.pageIds.length) {
                    const getCurrentSectionData = getCurrentSection(data.pageIds[0]);
                    chapterData = getCurrentSectionData && getCurrentSectionData.chapterData;
                  }
                  const streamIntentType = data && data.intentType;
                  setIsStreaming(true);
                  if (streamIntentType !== constants.EXPLAIN_INTENT) {
                    explainFlowMessage.current = '';
                  } else {
                    explainFlowMessage.current = 'Here is an explanation for that topic:';
                  }
                  if(data.option === constants.MULTIPLE_CHOICE || data.option === constants.FREE_RESPONSE || data.intentType === constants.QUIZ_INTENT) {
                    if (data && data.sourceReferencePages && data.sourceReferencePages.length) {
                      const currentSectionData = getCurrentSection(data.sourceReferencePages[0]);
                      chapterData = currentSectionData && currentSectionData.chapterData;
                    }
                    setAriaAssertive('assertive');
                    steps.pop();
                    steps.push(quizLoader);
                    setSteps([...steps]);
                  }
                  /*** Fetch Videos only If the IntentType is Explain Start */
                    let videoArray = [];
                    if (showAIChannelVideos && showChannelVideos && data.intentType === constants.EXPLAIN_INTENT && channelId && data.userTopic) {
                      const payload = {
                        channelId,
                        userTopic: data.userTopic,
                        token: tokenKey
                      };
                      channelService.fetchVideo(
                        payload,
                        (videoData) => {
                          let bestEScorce = 0;
                          let videoResultCount = 0;
                          if (videoData && videoData.assets && videoData.assets.results && videoData.assets.results.length) {
                            const esScoreValue = String(channelsVideoEsScore) || constants.CHANNELS_VIDEOS_ES_SCORE;
                            const premiumVideo = Utils.maxEsScorePremiumVideo(videoData.assets.results, esScoreValue);
                            bestEScorce = Math.max(...videoData?.assets?.results?.map((item) => item.esScore) || 0);
                            videoResultCount = videoData.assets.results.length
                            if (premiumVideo) {
                              premiumVideo.channelId = channelId
                              videoArray.push(premiumVideo);
                            }
                          }
                          // GA event - 656
                          fetchChannelsSearchResultsGAEvent(handleGAEvents, bestEScorce, data.userTopic, videoResultCount);
                        }
                      ).catch((error) => {
                        console.log("Channel API error", error);
                      });
                    }
                  /*** Fetch Video only If the IntentType is Explain End */
                  eventSourceRef.current = new EventSourcePolyfill(`${constants.BERLIN_BASE_URL[globalContext.env]}/3.0/generate/stream?requestId=${data.requestId}`, {
                    headers: {
                    Authorization: `Bearer ${tokenKey}`,
                    formatstream: 'Y',
                    'thread-id': globalContext.threadId,
                    'x-berlin-tenant-id': tenantId,
                    'x-berlin-tenant-key': tenantKey
                  }, 
                  heartbeatTimeout: 180000
                  });
                  let chatType = '';
                  eventSourceRef.current.onopen = (e) => {
                    steps.pop();
                    if(!(data.option === constants.MULTIPLE_CHOICE || data.option === constants.FREE_RESPONSE || data.intentType === constants.QUIZ_INTENT)) {
                      setIsStopStreaming(true);
                    }
                    const gaIntentOptions = [constants.SUMMARY_INTENT, constants.QUIZ_INTENT, constants.EXPLAIN_INTENT, constants.PROBLEM_SOLVE_FEATURE, constants.IMAGE_EXPLAIN_INTENT, constants.IMAGE_CYU_INTENT];
                    if (gaIntentOptions.includes(data.intentType)) {
                      if (data.intentType === constants.SUMMARY_INTENT) {
                        chatType = 'summary';
                      } else if (data.intentType === constants.QUIZ_INTENT || data.intentType === constants.IMAGE_CYU_INTENT) {
                        chatType = 'quiz';
                      } else if (data.intentType === constants.EXPLAIN_INTENT || data.intentType === constants.IMAGE_EXPLAIN_INTENT) {
                        chatType = 'explain';
                      } else if (data.intentType === constants.PROBLEM_SOLVE_FEATURE) {
                        chatType = 'Problem solve'
                      }
                    }
                  };
                  eventSourceRef.current.onmessage = (e) => {
                    if (e.data) {
                      const formattedResponse = JSON.parse(e.data);
                      currentStreamResponseId.current = formattedResponse.requestId;
                      if(data.option === constants.MULTIPLE_CHOICE || data.option === constants.FREE_RESPONSE || data.intentType === constants.QUIZ_INTENT) {
                        optionSelected = data.option;
                        quizStreamStatus = formattedResponse.streamStatus;
                        if (formattedResponse.streamStatus === constants.START) {
                          incrementQuizCounter = 0;
                          gaEvents(handleGAEvents, {
                            event_action: eventAction.outputSuccess,
                            content_categories: null,
                            command_source_code: (!typedEvent) ? constants.GPT_EVENTS.TYPE : constants.GPT_EVENTS.CLICK,
                            chat_type: chatType === constants.QUIZ ? constants.GPT_EVENTS.PRACTICE : chatType.charAt(0).toUpperCase() + chatType.slice(1),
                            format: constants.GPT_EVENTS.MULTIPLE_CHOICE,
                            content_range: data && data.sourceType ? data.sourceType.charAt(0).toUpperCase() + data.sourceType.slice(1) : constants.GPT_EVENTS.TOPIC,
                            initiate_point: (!typedEvent && topicsQuizMode !== constants.QUIZ_MODE) ? constants.GPT_EVENTS.COMMAND_LINE : constants.GPT_EVENTS.UI_QUICK_ACTION,
                            event_label: null,
                            event_value: null,
                            video_count: 0,
                            asset_ids_returned: null,
                            links: Utils.pageNavigationAnchorLinks(data?.requestId),
                            chat_id: globalContext.threadId,
                            result_index: null,
                            location_in_app: constants.GPT_EVENTS.GPT,
                            ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                            topic: null
                          });
                          topicsQuizMode = ''; // Reset the mode to default in Topic Mode Quiz
                        } else if (formattedResponse.streamStatus === constants.STREAMING || formattedResponse.streamStatus === constants.DONE) {
                          quizStreamRequestId = formattedResponse.requestId;
                          quizStreamResponseId = formattedResponse.responseId;
                          if (formattedResponse.streamStatus === constants.DONE) {
                            setIsStreaming(false);
                            if (eventSourceRef && eventSourceRef.current && eventSourceRef.current.close) {
                              eventSourceRef.current.close();
                            }
                            setIsTextBoxDisable(false);
                            if (formattedResponse.requestId && formattedResponse.responseId) {
                              setQuizStreamEnd(true);
                            }
                          }
                          if (formattedResponse.content && Object.keys(formattedResponse.content).length !== 0) {
                            const questionObject = {...formattedResponse.content, quizRefId: formattedResponse.quizRefId, requestId: formattedResponse.requestId,
                              responseId: formattedResponse.responseId};
                            quizQuestionsData.push(questionObject);
                            const quizStream = {
                              id: constants.QUIZ_CONTENT,
                              requestId: formattedResponse.requestId,
                              responseId: formattedResponse.responseId,
                              content: '',
                              apiContent: '',
                              streamStatus: formattedResponse.streamStatus,
                              data: {
                                type: data.option,
                                responseData: data,
                                optionSelected,
                                pageRange: [],
                                selectedChapter: [],
                                questionsLength: quizQuestionsData && quizQuestionsData.length,
                                isLastPage: isLastPage.current,
                                pageIds: data.pageIds || [],
                                quizData: quizQuestionsData && quizQuestionsData[0],
                                message: quizQuestionsData && quizQuestionsData[0] && (quizQuestionsData[0].question || quizQuestionsData[0].question_stem),
                                showSummary: true,
                                isReadyNext: false,
                                isLastQuestion: false,
                                pageNums: [],
                                pagesRange: [],
                                selectedOptionType: data.option,
                                quizTitle: '',
                                showOptions: data.option === constants.FREE_RESPONSE,
                                currentQuestionIndex: currentQuestionIndex.current,
                                chapterData
                              }
                            };
                            if (incrementQuizCounter === 0) {
                              steps.push(quizStream);
                              setSteps([...steps]);
                              setIsTextBoxDisable(false);
                            }
                            incrementQuizCounter = incrementQuizCounter + 1;
                          }
                        } else if (formattedResponse.streamStatus === constants.KEEP_ALIVE) {
                          console.log('Connection is Alive', e.data);
                        }
                      } else {
                        if (formattedResponse.streamStatus === constants.START) {
                          chatType = formattedResponse.hasMoreSummary ? constants.GPT_EVENTS.SUMMARY_EXPLANATION : chatType;
                          if ((chatType === constants.GPT_EVENTS.SUMMARY_EXPLANATION || chatType === constants.SUMMARY) && !isContentSelectFlow.current) {
                            gaEvents(handleGAEvents, {
                              event_action: eventAction.triggerIntention,
                              event_label: chatType,
                              event_value: null,
                              ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.TRIGGER_INTENTION)
                            });
                          }
                          streamingContent = '';
                          streamingApiContent = '';
                          isCannedMessage = false;
                          let payloadType = constants.DISCUSS;
                          
                          if (data.intentType === constants.PROBLEM_SOLVE_FEATURE && payload.userMode === constants.PROBLEM_SOLVE_RECTIFIER) {
                            payloadType = constants.PROBLEM_SOLVE_RECTIFIER;
                            if (steps && steps.length) {
                              const problemSolveApiContent = (steps[steps.length - 2] && steps[steps.length - 2].apiContent) || ''; // Getting previous Problem solve intent response
                              if (problemSolveApiContent) {
                                streamingApiContent = `${problemSolveApiContent} <div>${formatMessage(messages.problemSolveLoaderMessage)}</div>`;
                              }
                            }
                          } else if(data.intentType === constants.PROBLEM_SOLVE_FEATURE && payload.userMode === constants.PROBLEM_SOLVE_CALCULATION && payload.userMode !== constants.PROBLEM_SOLVE_RECTIFIER) {
                            payloadType = constants.PROBLEM_SOLVE_CALCULATION;
                            if (steps && steps.length) {
                              const mathProblemSolverApiContent = (steps[steps.length - 1] && steps[steps.length - 1].apiContent) || ''; // Getting previous Problem solve intent response
                              if (mathProblemSolverApiContent) {
                                streamingApiContent = mathProblemSolverApiContent;
                              }
                            }
                          } else if (data.intentType === constants.PROBLEM_SOLVE_FEATURE) {
                            payloadType = constants.PROBLEM_SOLVE;
                          }
                          const summaryStream = {
                            id: constants.STREAMING_DISCUSS,
                            requestId: formattedResponse.requestId,
                            responseId: formattedResponse.responseId,
                            content: '',
                            apiContent: '',
                            streamStatus: formattedResponse.streamStatus,
                            hasMoreSummary: formattedResponse.hasMoreSummary,
                            isCannedMessage: formattedResponse.isCannedMessage,
                            useRectifier: formattedResponse.useRectifier,
                            category: formattedResponse.category,
                            pageList: (data && data.response && data.response.sourceReferencePages) || [],
                            payloadType,
                            data: {
                              optionSelected: data.option,
                              inputValue: inputValue || userAnswer,
                              videos: videoArray,
                              bookID: context.bookID,
                              bookTitle: context.bookTitle,
                              isOptInLinkEnabled: enableOptInLink,
                              showPremiumVideo: showOptoutLink || isChannelsBundleSubscriber,
                              showOptInPremiumMessage: false
                            },
                            chatType,
                            intentType: data.intentType,
                            isContentMedia: globalContext.imageCopilotMode
                          };
                          const isStepExist = steps.find(obj => (obj.requestId === formattedResponse.requestId && obj.responseId === formattedResponse.responseId));
                          if (!isStepExist) {
                            steps.push(summaryStream);
                            setSteps([...steps]);
                          }
                        } else if (formattedResponse.streamStatus === constants.STREAMING || formattedResponse.streamStatus === constants.DONE) {
                          const filteredSteps = steps.find(obj => (obj.requestId === formattedResponse.requestId && obj.responseId === formattedResponse.responseId));
                          if (filteredSteps) {
                            if (formattedResponse.content) {
                              streamingApiContent += formattedResponse.content; 
                              streamingContent += formattedResponse.content;
                            }
                            filteredSteps.apiContent = streamingApiContent;
                            filteredSteps.content = streamingContent;
                            if(!isCannedMessage) {
                              isCannedMessage = formattedResponse.isCannedMessage;
                              filteredSteps.isCannedMessage = formattedResponse.isCannedMessage
                            } else {
                              filteredSteps.isCannedMessage = isCannedMessage;
                            }
                          }
                          filteredSteps.streamStatus = formattedResponse.streamStatus;
                          filteredSteps.hasMoreSummary = formattedResponse.hasMoreSummary;
                          filteredSteps.useRectifier = formattedResponse.useRectifier;
                          filteredSteps.category = formattedResponse.category;
                          identifierType = formattedResponse.hasMoreSummary ? constants.SHOW_MORE_IDENTIFIER : '';
                          setSteps([...steps]);
                          promptVersionForContentImage = { ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS) };
                          outputSuccessGAEvent = {
                            event_action: eventAction.outputSuccess,
                            content_categories: Utils.contentMediaImages(data?.requestId),
                            command_source_code: (!tellMeMore && ((event && event.key === 'Enter') || typedEvent)) ? constants.GPT_EVENTS.TYPE : constants.GPT_EVENTS.CLICK,
                            chat_type: chatType === constants.QUIZ ? constants.GPT_EVENTS.PRACTICE : chatType.charAt(0).toUpperCase() + chatType.slice(1),
                            format: (data.response && data.response.text && ((data.response && data.response.option) || data.option)) ? handleFormatType((data.response && data.response.text), (data.response && data.response.option)) : handleFormatType((data.response && data.response.text), data.option),
                            content_range: handleContentRange(),
                            initiate_point: globalContext.imageCopilotMode ? constants.GPT_EVENTS.CONTENT_MEDIA : isFromRecommendationPill ? constants.GPT_EVENTS.ENGAGEMENT_SUGGESTION : ((!isExplainClicked && !tellMeMore && ((event && event.key === 'Enter') || typedEvent)) ? constants.GPT_EVENTS.COMMAND_LINE : (isContentSelectFlow.current ? constants.GPT_EVENTS.CONTENT_SELECTOR: constants.GPT_EVENTS.UI_QUICK_ACTION)),
                            event_label: null,
                            event_value: null,
                            video_count: (videoArray && videoArray.length) || 0,
                            asset_ids_returned: ((videoArray && videoArray.length && videoArray.map(({ id }) => id)) || []).toString() || null,
                            links: Utils.pageNavigationAnchorLinks(data?.requestId),
                            chat_id: globalContext.threadId,
                            result_index: null,
                            location_in_app: constants.GPT_EVENTS.GPT,
                            ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                            topic: (data && data.userTopic) || ''
                          };

                          if (formattedResponse.streamStatus === constants.DONE) {
                            setIsStreaming(false);
                            eventSourceRef.current.close();
                            gaEvents(handleGAEvents, outputSuccessGAEvent);
                            outputSuccessGAEvent = {};
                            isContentSelectFlow.current= false;
                            isExplainClicked = false;
                            isFromRecommendationPill = false;
                            setIsStopStreaming(false);
                            // eventSource.close();
                            setIsTextBoxDisable(false);
                            inputRef.current.focus();
                            if(data.intentType === constants.EXPLAIN_INTENT && enableExplainQuizOptions === constants.EXPLAIN_QUIZ_OPTION_B && !isCannedMessage && showPractice) {
                              isExplainCYUMode = constants.EXPLANATION_CYU;
                              setTimeout(()=> {
                                handleRangeSubmit(null, null, null, null, null, null, null, null, null, null, true, null, chapterData, false, false, true, false, true);
                              }, 100);
                            }
                            if(data.intentType === constants.EXPLAIN_INTENT && enableExplainQuizOptions === constants.EXPLAIN_QUIZ_OPTION_C && !isCannedMessage && showPractice) {
                              isExplainQuiz = true;
                              showExplainQuizFollowOptionEvent = {
                                event_action: eventAction.continuousSuggestionShown,
                                event_label: constants.GPT_EVENTS.CLICKTOSHOW,
                                event_value: null,
                                chat_type: constants.GPT_EVENTS.EXPLAIN,
                              };
                            }
                            if (data.intentType === constants.PROBLEM_SOLVE_FEATURE && payload.userMode !== constants.PROBLEM_SOLVE_CALCULATION && formattedResponse.category === constants.CALCULATION) {
                              handleSend({}, payload.userCommand, false, false, false, false, false, false, false, data.requestId, false, true);
                            } else {
                              if(data.intentType === constants.PROBLEM_SOLVE_FEATURE && payload.userMode !== constants.PROBLEM_SOLVE_RECTIFIER && formattedResponse.useRectifier) {
                                const problemSolveLoader = {
                                  id: constants.PROBLEM_SOLVE_LOADER_MESSAGE,
                                  message: formatMessage(messages.problemSolveLoaderMessage)
                                };
                                steps.push(problemSolveLoader);
                                setSteps([...steps]);
                                handleSend({}, payload.userCommand, false, false, false, false, false, false, false, data.requestId, true);
                              }
                            }
                          }
                        } else if (formattedResponse.streamStatus === constants.KEEP_ALIVE) {
                          console.log('Connection is Alive', e.data);
                        }
                      }
                      }
                  };
                  eventSourceRef.current.onerror = (error) => {
                    console.log('Error in stream response discuss chat', error);
                    if (error?.target?.close) {
                      error.target.close();
                    }
                    if(steps && steps.length && steps.slice(-1)[0].id && (steps.slice(-1)[0].id === constants.LOADER || steps.slice(-1)[0].id === constants.QUIZ_LOADER)) {
                      steps.pop();
                    }
                    setIsStreaming(false);
                    eventSourceRef?.current?.close();
                    if (error && error.status) errorMessageGAEvent(error.status);
                    errorData.message = Utils.getErrorMessage(error && error.status);
                    steps.push(errorData);
                    setSteps([...steps]);
                    if(!(data.option === constants.MULTIPLE_CHOICE || data.option === constants.FREE_RESPONSE || data.intentType === constants.QUIZ_INTENT)) {
                      setIsStopStreaming(false);
                    }
                    // eventSource.close();
                    setIsTextBoxDisable(false);
                  };
                } else if (data && ((!(data.bulletPoints && data.bulletPoints.length) && !data.paragraph && !data.summaryText) || (!(data.questions && data.questions.length))) && (data.topic && data.topic.length && (data.intentType === constants.SUMMARY_INTENT || data.intentType === constants.QUIZ_INTENT))) {
                // setIsTextBoxDisable(false);
                const gaIntentOptions = [constants.SUMMARY_INTENT, constants.QUIZ_INTENT, constants.EXPLAIN_INTENT];
                if (gaIntentOptions.includes(data.intentType) && data.sourceType) {
                  let chatType = '';
                  if (data.intentType === constants.SUMMARY_INTENT) {
                    chatType = 'summary';
                  } else if (data.intentType === constants.QUIZ_INTENT) {
                    chatType = 'quiz';
                  } else if (data.intentType === constants.EXPLAIN_INTENT) {
                    chatType = 'explain';
                  }
                }
                const requestId = data && data.requestId;
                if (data.topic.length === 1) {
                  handleChapterOptionSend(data.topic[0].pageIds, data.option, data.intentType, data.topic[0].title, data.topic[0].id, data.topic[0].type, data.sourceType, requestId);
                } else {
                  const topicsData = data.topic;
                  const sourceType = data && data.sourceType;
                  const chapterArray = topicsData.filter(arr => arr.type === constants.TOPIC_CHAPTER);
                  const moduleArray = topicsData.filter(arr => arr.type === constants.TOPIC_MODULE);
                  const subModuleArray = topicsData.filter(arr => arr.type === constants.TOPIC_SUBMODULE);
                  const slateArray = topicsData.filter(arr => arr.type === constants.TOPIC_SLATE);
                  let sortedTopics = [...chapterArray, ...moduleArray, ...subModuleArray, ...slateArray];
                  if(sourceType === constants.CHAPTER && chapterArray) {
                    if(chapterArray.length === 1) {
                      handleChapterOptionSend(chapterArray[0].pageIds, data.option, data.intentType, chapterArray[0].title, chapterArray[0].id, chapterArray[0].type, sourceType, requestId);
                    } else if(chapterArray.length > 1) {
                      setIsTextBoxDisable(false);
                      steps.pop();
                      sortedTopics = chapterArray;
                      const chapterOption = {
                        id: constants.CHAPTER_TOPIC_OPTION,
                        data: {
                          topics: sortedTopics,
                          optionSelected: data.option,
                          intentSelected: data.intentType,
                          requestId
                        }
                      };
                      steps.push(chapterOption);
                    } else {
                      setIsTextBoxDisable(false);
                      steps.pop();
                      const chapterOption = {
                        id: constants.CHAPTER_TOPIC_OPTION,
                        data: {
                          topics: sortedTopics,
                          optionSelected: data.option,
                          intentSelected: data.intentType,
                          requestId
                        }
                      };
                      steps.push(chapterOption);
                    }
                  } else if(sourceType === constants.SECTION && (moduleArray || subModuleArray || slateArray)) {
                    if(moduleArray.length === 1) {
                      handleChapterOptionSend(moduleArray[0].pageIds, data.option, data.intentType, moduleArray[0].title, moduleArray[0].id, moduleArray[0].type, sourceType, requestId);
                    } else if(moduleArray.length > 1) {
                      setIsTextBoxDisable(false);
                      steps.pop();
                      sortedTopics = [...moduleArray, ...subModuleArray, ...slateArray];
                      const moduleOption = {
                        id: constants.CHAPTER_TOPIC_OPTION,
                        data: {
                          topics: sortedTopics,
                          optionSelected: data.option,
                          intentSelected: data.intentType,
                          requestId
                        }
                      };
                      steps.push(moduleOption);
                    } else if(subModuleArray.length === 1) {
                      handleChapterOptionSend(subModuleArray[0].pageIds, data.option, data.intentType, subModuleArray[0].title, subModuleArray[0].id, subModuleArray[0].type, sourceType, requestId);
                    } else if(subModuleArray.length > 1) {
                      setIsTextBoxDisable(false);
                      steps.pop();
                      sortedTopics = [...moduleArray, ...subModuleArray, ...slateArray];;
                      const subModuleOption = {
                        id: constants.CHAPTER_TOPIC_OPTION,
                        data: {
                          topics: sortedTopics,
                          optionSelected: data.option,
                          intentSelected: data.intentType,
                          requestId
                        }
                      };
                      steps.push(subModuleOption);
                    } else if(slateArray.length === 1) {
                      handleChapterOptionSend(slateArray[0].pageIds, data.option, data.intentType, slateArray[0].title, slateArray[0].id, slateArray[0].type, sourceType, requestId);
                    } else if(slateArray.length > 1) {
                      setIsTextBoxDisable(false);
                      steps.pop();
                      sortedTopics = [...moduleArray, ...subModuleArray, ...slateArray];;
                      const slateOption = {
                        id: constants.CHAPTER_TOPIC_OPTION,
                        data: {
                          topics: sortedTopics,
                          optionSelected: data.option,
                          intentSelected: data.intentType,
                          requestId
                        }
                      };
                      steps.push(slateOption);
                    } else {
                      setIsTextBoxDisable(false);
                      steps.pop();
                      const chapterOption = {
                        id: constants.CHAPTER_TOPIC_OPTION,
                        data: {
                          topics: sortedTopics,
                          optionSelected: data.option,
                          intentSelected: data.intentType,
                          requestId
                        }
                      };
                      steps.push(chapterOption);
                    }
                  } else {
                    setIsTextBoxDisable(false);
                    steps.pop();
                    const chapterOption = {
                      id: constants.CHAPTER_TOPIC_OPTION,
                      data: {
                        topics: sortedTopics,
                        optionSelected: data.option,
                        intentSelected: data.intentType,
                        requestId
                      }
                    };
                    steps.push(chapterOption);
                  }
                }
                setSteps([...steps]);
              } else {
                setIsTextBoxDisable(false);
                let chatType = '';
                const gaIntentOptions = [constants.SUMMARY_INTENT, constants.EXPLAIN_INTENT];
                if (gaIntentOptions.includes(data.intentType) && data.sourceType) {
                  if (data.intentType === constants.SUMMARY_INTENT) {
                    chatType = 'summary';
                  } else if (data.intentType === constants.QUIZ_INTENT) {
                    chatType = 'quiz';
                  } else if (data.intentType === constants.EXPLAIN_INTENT) {
                    chatType = 'explain';
                  }
                }
                steps.pop();
                let responseData = {};
                if (data && (data.type === constants.FREE_RESPONSE || optionSelected === constants.MULTIPLE_CHOICE) && quizQuestionsData && quizQuestionsData.length) {
                  setAriaAssertive('assertive');
                  if (data.quizIntentType === constants.ANSWER_FEATURE) {
                    chatType = constants.QUIZ;
                    isPausedModeOn.current = false;
                    if (((currentQuestionIndex.current + 1) === quizQuestionsData.length) && isLastPage.current) {
                      gaEvents(handleGAEvents, {
                        event_label: null,
                        event_value: null,
                        correctness: (data && data.result && data.result.score && `${data.result.score}`) || (data && data.result && data.result.mcq && data.result.mcq.answer && data.result.mcq.answer === 'TRUE' ? '1' : '0'),
                        event_action: eventAction.answerPracticeQuestion,
                        question_type: (data && data.type === constants.FREE_RESPONSE) ? constants.GPT_EVENTS.FREE_RESPONSE : constants.GPT_EVENTS.MULTI_CHOICE,
                        ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.ANSWER_PRACTICE_QUESTION),
                        command_source_code: (event && event.key === 'Enter') ? 'type' : 'click',
                        content_range: handleContentRange(contentRangeValue),
                        chat_type: chatType === constants.QUIZ ? constants.GPT_EVENTS.PRACTICE : chatType && (chatType.charAt(0).toUpperCase() + chatType.slice(1))
                      });
                      responseData = {
                        id: constants.FREE_RESPONSE_QUIZ_VALIDATE,
                        data: {
                          summaryData: data,
                          isReadyNext: false,
                          isLastQuestion: true,
                          showSummary: false,
                          isFromMultipleChoiceFlow: optionSelected !== constants.FREE_RESPONSE,
                          currentQuestion: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && quizQuestionsData[currentQuestionIndex.current],
                          chapterData
                        }
                      };
                      steps.push(responseData);
                      // suggestionData.optionSelected = optionSelected;
                      // suggestionData.currentContext = contextData;
                      // steps.push(suggestionData);
                      setSteps([...steps]);
                      quizRefId.current = '';
                      nextQuestion.current = '';
                      currentQuestionIndex.current = 0;
                      optionSelected = 'discuss';
                      isLastPage.current = false;
                    } else if (data.question) {
                      if (((currentQuestionIndex.current + 1) === quizQuestionsData.length) && !isLastPage.current) {
                        const nextQuizPayload = {
                          bookId: context.bookID,
                          productModel: context.productModel,
                          indexId: context.indexId,
                          quizRefId: quizRefId.current,
                          option: optionSelected
                        };
                        responseData = {
                          id: constants.FREE_RESPONSE_QUIZ,
                          data: {
                            message: data && data.response && data.response.text,
                            isReadyNext: true,
                            isLastQuestion: false,
                            showSummary: false,
                            showOptions: false,
                            currentQuestionIndex: currentQuestionIndex.current,
                            chapterData
                          }
                        };
                        setAriaAssertive('assertive');
                        getAuthToken((tokenKey) => {
                          berlinService.fetchTutorData(
                            nextQuizPayload,
                            constants.NEXT_QUESTIONS,
                            headerParams,
                            tokenKey,
                            (nextQuestionData) => {
                              quizRefId.current = nextQuestionData.quizRefId;
                              isLastPage.current = nextQuestionData.isLastPage;
                              if (nextQuestionData && nextQuestionData.questions && nextQuestionData.questions.length) {
                                nextQuestion.current = formatMessage(messages.readyForTheNextQuestion);
                                nextQuestionData.questions.forEach((questionObject) => {
                                  questionObject.quizRefId = nextQuestionData.quizRefId;
                                  questionObject.requestId = nextQuestionData.requestId;
                                  questionObject.responseId = nextQuestionData.responseId;
                                  // questionObject.question = questionObject.question_stem || questionObject.question;
                                  quizQuestionsData.push(questionObject);
                                });
                                steps.push(responseData);
                                setSteps([...steps]);
                              }
                              setTimeout(()=>{setAriaAssertive('off');}, 1000);
                            }
                          ).catch((error) => {
                            setIsTextBoxDisable(false);
                            if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
                            if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
                            if (error.response === undefined && error.code === 'ECONNABORTED') {
                              errorData.message = Utils.getErrorMessage(error.code);
                            } else {
                              errorData.message = Utils.getErrorMessage(error.response.status);
                            }
                            const errorString = error.toString();
                            if (errorString && errorString.indexOf('timeout') !== -1) {
                              errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
                            }
                            steps.pop();
                            if (steps[steps.length - 1].id === constants.LOADER) {
                              steps.pop();
                            }
                            steps.push(errorData);
                            setSteps([...steps]);
                          }
                          });
                        });
                      } else {
                        const gaIntentOptions = [constants.SUMMARY_INTENT, constants.EXPLAIN_INTENT];
                        let chatType = constants.GPT_EVENTS.PRACTICE;

                        if (gaIntentOptions.includes(data && data.intentType)) {
                          chatType = (data.intentType === constants.SUMMARY_INTENT ? constants.GPT_EVENTS.SUMMARY : constants.GPT_EVENTS.EXPLAIN);
                        }
                        gaEvents(handleGAEvents, {
                          event_label: null,
                          event_value: null,
                          correctness: (data && data.result && data.result.score && `${data.result.score}`) || (data && data.result && data.result.mcq && data.result.mcq.answer && data.result.mcq.answer === "TRUE" ? "1" : "0"),
                          event_action: eventAction.answerPracticeQuestion,
                          question_type: (data && data.type === constants.FREE_RESPONSE) ? constants.GPT_EVENTS.FREE_RESPONSE : constants.GPT_EVENTS.MULTI_CHOICE,
                          ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.ANSWER_PRACTICE_QUESTION),
                          command_source_code: (event && event.key === 'Enter') ? 'type' : 'click',
                          content_range: handleContentRange(contentRangeValue),
                          chat_type: chatType
                        });
                        responseData = {
                          id: constants.FREE_RESPONSE_QUIZ_VALIDATE,
                          data: {
                            summaryData: data,
                            isReadyNext: true,
                            pageIds: quizPageIds,
                            isFromMultipleChoiceFlow: optionSelected !== constants.FREE_RESPONSE,
                            currentQuestion: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && quizQuestionsData[currentQuestionIndex.current],
                            chapterData
                          }
                        };
                        nextQuestion.current = formatMessage(messages.readyForTheNextQuestion);
                        steps.push(responseData);
                        setSteps([...steps]);
                      }
                    } else {
                      currentQuestionIndex.current += 1;
                      responseData = {
                        id: constants.FREE_RESPONSE_QUIZ,
                        data: {
                          message: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && (quizQuestionsData[currentQuestionIndex.current].question || quizQuestionsData[currentQuestionIndex.current].question_stem),
                          isReadyNext: false,
                          isLastQuestion: false,
                          showSummary: false,
                          currentQuestion: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && quizQuestionsData[currentQuestionIndex.current],
                          pageIds: quizPageIds,
                          currentQuestionIndex: currentQuestionIndex.current,
                          chapterData
                        }
                      };
                      nextQuestion.current = '';
                      steps.push(responseData);
                      setSteps([...steps]);
                    }
                  } else if (data.quizIntentType === constants.NEXT_QUIZ_FEATURE ||
                    data.quizIntentType === constants.NEXT_QUESTION_FEATURE ||
                    data.quizIntentType === constants.READY_FOR_NEXT_QUESTION_FEATURE ||
                    data.quizIntentType === constants.READY_FOR_NEXT_FEATURE) {
                    isPausedModeOn.current = false;
                    nextQuestion.current = '';
                    if (((currentQuestionIndex.current + 1) === quizQuestionsData.length) && isLastPage.current) {
                      optionSelected = 'discuss';
                      quizStreamRequestId = '';
                      quizStreamResponseId = '';
                      totalQuestionCount = 0;
                      responseData = {
                        id: constants.FREE_RESPONSE_QUIZ,
                        data: {
                          isLastQuestion: true,
                          isReadyNext: false,
                          showSummary: false,
                          showOptions: false,
                          currentQuestionIndex: currentQuestionIndex.current,
                          chapterData
                        }
                      };
                      steps.push(responseData);
                      setSteps([...steps]);
                      quizRefId.current = '';
                      nextQuestion.current = '';
                      currentQuestionIndex.current = 0;
                    } else if (((currentQuestionIndex.current + 1) === quizQuestionsData.length) && !isLastPage.current) {
                      const nextQuestionPayload = {
                        bookId: context.bookID,
                        productModel: context.productModel,
                        indexId: context.indexId,
                        quizRefId: quizRefId.current
                      };
                      setAriaAssertive('assertive');
                      getAuthToken((tokenKey) => {
                        berlinService.fetchTutorData(
                          nextQuestionPayload,
                          constants.NEXT_QUESTIONS,
                          headerParams,
                          tokenKey,
                          (nextQuestionData) => {
                            quizRefId.current = nextQuestionData.quizRefId;
                            isLastPage.current = nextQuestionData.isLastPage;
                            currentQuestionIndex.current += 1;
                            if (nextQuestionData && nextQuestionData.questions && nextQuestionData.questions.length) {
                              nextQuestionData.questions.forEach((questionObject) => {
                                questionObject.quizRefId = nextQuestionData.quizRefId;
                                questionObject.requestId = nextQuestionData.requestId;
                                questionObject.responseId = nextQuestionData.responseId;
                                // questionObject.question = questionObject.question_stem || questionObject.question;
                                quizQuestionsData.push(questionObject);
                              });
                              responseData = {
                                id: constants.FREE_RESPONSE_QUIZ,
                                data: {
                                  message: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && (quizQuestionsData[currentQuestionIndex.current].question || quizQuestionsData[currentQuestionIndex.current].question_stem),
                                  isReadyNext: false,
                                  isLastQuestion: false,
                                  showSummary: false,
                                  currentQuestion: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && quizQuestionsData[currentQuestionIndex.current],
                                  pageIds: quizPageIds,
                                  showOptions: optionSelected === constants.FREE_RESPONSE,
                                  currentQuestionIndex: currentQuestionIndex.current,
                                  chapterData
                                }
                              };
                              steps.push(responseData);
                              setSteps([...steps]);
                            }
                            setTimeout(()=>{setAriaAssertive('off');}, 1000);
                          }
                        ).catch((error) => {
                          if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
                          if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
                          if (error.response === undefined && error.code === 'ECONNABORTED') {
                            errorData.message = Utils.getErrorMessage(error.code);
                          } else {
                            errorData.message = Utils.getErrorMessage(error.response.status);
                          }
                          const errorString = error.toString();
                          if (errorString && errorString.indexOf('timeout') !== -1) {
                            errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
                          }
                          steps.pop();
                          if (steps[steps.length - 1].id === constants.LOADER) {
                            steps.pop();
                          }
                          steps.push(errorData);
                          setSteps([...steps]);
                        }
                        });
                      });
                    } else {
                      currentQuestionIndex.current += 1;
                      responseData = {
                        id: constants.FREE_RESPONSE_QUIZ,
                        requestId: quizStreamRequestId,
                        responseId: quizStreamResponseId,
                        data: {
                          message: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && (quizQuestionsData[currentQuestionIndex.current].question || quizQuestionsData[currentQuestionIndex.current].question_stem),
                          isReadyNext: false,
                          isLastQuestion: false,
                          showSummary: false,
                          currentQuestion: quizQuestionsData && quizQuestionsData[currentQuestionIndex.current] && quizQuestionsData[currentQuestionIndex.current],
                          pageIds: quizPageIds,
                          showOptions: optionSelected === constants.FREE_RESPONSE,
                          currentQuestionIndex: currentQuestionIndex.current,
                          chapterData,
                          totalQuestionCount
                        }
                      };
                      steps.push(responseData);
                      setSteps([...steps]);
                    }
                  } else if (data.quizIntentType === constants.STOP_QUIZ_FEATURE ||
                    data.quizIntentType === constants.NOT_READY_FEATURE ||
                    data.quizIntentType === constants.NOT_READY_FOR_NEXT_FEATURE) {
                    optionSelected = 'discuss';
                    quizRefId.current = '';
                    nextQuestion.current = '';
                    currentQuestionIndex.current = 0;
                    isLastPage.current = true;
                    isPausedModeOn.current = false;
                    handleQuizStop();
                  } else if (data.quizIntentType === constants.PAUSED_QUIZ_FEATURE) {
                    isPausedModeOn.current = true;
                    nextQuestion.current = '';
                    responseData = {
                      id: constants.FREE_RESPONSE_QUIZ,
                      data: {
                        message: 'No problem, just let me know when you are ready to proceed. Type \'Quit\' if you want to end the quiz',
                        isReadyNext: false,
                        isLastQuestion: false,
                        showSummary: false,
                        displayQuestion: false,
                        showOptions: false,
                        currentQuestionIndex: currentQuestionIndex.current,
                        chapterData
                      }
                    };
                    steps.push(responseData);
                    setSteps([...steps]);
                  } else if (data.quizIntentType === constants.SWITCH_CHAT_FEATURE) {
                    optionSelected = 'discuss';
                    isPausedModeOn.current = false;
                    handleSend({}, data.userAnswer);
                  }
                } else {
                  if (data && !data.intentType) {
                    gaEvents(handleGAEvents, {
                      event_action: eventAction.clickSummaryFormat,
                      event_label: (data.option === constants.BULLET ) ? constants.BULLET : constants.PARAGRAPH,
                      event_value: null,
                      command_source_code: 'type'
                    });
                    data.response = data && data.response ? data.response : data;
                    responseData = {
                      id: constants.DISCUSS,
                      data: {
                        botResponse: data,
                        inputValue: inputValue || userAnswer,
                        showformat: true
                      }
                    };
                  } else if (data && data.intentType) {
                    const eligibleForIntentOptionChange = [constants.SUMMARY_INTENT, constants.QUIZ_INTENT];
                    if (data && eligibleForIntentOptionChange.includes(data.intentType) && (!(data.bulletPoints && data.bulletPoints.length) &&
                      !data.paragraph && !data.summaryText && !(data.questions && data.questions.length)) && !(data.response && data.response.text)) {
                      if ((data.intentType === constants.SUMMARY_INTENT || data.intentType === constants.QUIZ_INTENT) && data.pageIds && data.pageIds.length) {
                        if (isFromContentSelector.current) isFromContentSelector.current = false;
                        optionSelected = (data.intentType === constants.SUMMARY_INTENT) ? constants.SUMMARY : (data.option || constants.MULTIPLE_CHOICE);
                        if (data.intentType === constants.SUMMARY_INTENT || data.intentType === constants.QUIZ_INTENT) {
                          // steps.push(loaderStep);
                          // setSteps([...steps]);
                          const type = (data.intentType === constants.SUMMARY_INTENT) ? constants.BULLET : (data.option || constants.MULTIPLE_CHOICE);
                          handleRangeSubmit(type, {}, false, [], data.pageIds, data.pageNums, true, false, data.pageRange, data.sourceType, false, false, false, false, false, false, false, false, data.requestId);
                        } else {
                          const summaryOptionStep = {
                            id: data.intentType === constants.QUIZ_INTENT ? constants.QUIZ_OPTIONS : constants.SUMMARY_OPTIONS,
                            data: {
                              type: constants.CURRENT_PAGE,
                              pageIds: data.pageIds,
                              pageNums: data.pageNums,
                              pagesRange: data.pageRange
                            }
                          };

                          gaEvents(handleGAEvents, {
                            event_action: eventAction.clickContentRange,
                            event_label: data.pageNums && (data.pageNums === 'current') ? 'Current Page' : 'Page Range',
                            event_value: null,
                            command_source_code: 'type'
                          });
                          steps.push(summaryOptionStep);
                          setSteps([...steps]);
                        }
                      } else if ((data.intentType === constants.SUMMARY_INTENT || data.intentType === constants.QUIZ_INTENT) && !(data.pageIds && data.pageIds.length) && (data.currentContext === constants.CHAPTER || data.currentContext === constants.SECTION)) {
                        optionSelected = data.intentType === constants.SUMMARY_INTENT ? constants.SUMMARY : data.option || constants.MULTIPLE_CHOICE;
                        let contentType = 'Current Section';
                        let currentContext = constants.CURRENT_SECTION;
                        if(data.currentContext === constants.CHAPTER){
                          contentType = 'Whole chapter';
                          currentContext = constants.CURRENT_CHAPTER;
                        }
                        gaEvents(handleGAEvents, {
                          event_action: eventAction.clickContentRange,
                          event_label: contentType,
                          event_value: null,
                          command_source_code: 'type'
                        });
                        handleRangeSubmit(currentContext, {}, false, [], [], [], false, false, [], '', false, false, false, false, false, false, false, false, data.requestId);
                      } else {
                        const contentSelectorFlow = isFromContentSelector.current && contentSelectorSelectedText;
                        if (contentSelectorFlow && data.pageIds && data.pageIds.length) {
                          isFromContentSelector.current = false;
                        }
                        let intentType = constants.DISCUSS;
                        if (data.intentType === constants.SUMMARY_INTENT) {
                          intentType = constants.SUMMARY;
                        } else if (data.intentType === constants.QUIZ_INTENT) {
                          intentType = constants.QUIZ;
                        }
                        if (data.option && ((data.intentType === constants.QUIZ_INTENT && data.questions && data.questions.length) || data.intentType !== constants.QUIZ_INTENT)) {
                          optionSelected = data.option;
                        }
                        if (enableStreamingAPI && data.intentType === constants.SUMMARY_INTENT) {
                          optionSelected = constants.SUMMARY;
                        }
                        if (data.sourceType === 'page' && !(pageList && pageList.length) && !(data.pageIds && data.pageIds.length)) {
                          responseData = {
                            id: constants.ERROR_MESSAGE,
                            message: 'This eTextbook doesn\'t have page numbers. Can you use the section number instead?'
                          };
                        } else {
                          optionSelected = (data.intentType === constants.SUMMARY_INTENT) ? constants.SUMMARY : (data.option || constants.MULTIPLE_CHOICE); 
                          identifierType = data.intentType === constants.QUIZ_INTENT ? constants.QUIZ_MODE : constants.SUMMARY_MODE;
                          responseData = {
                            id: constants.CLARIFY,
                            data: {
                              type: intentType,
                              chapterList,
                              hasPrintPageAvailable: pageList.length > 0,
                              pageIds: data.pageIds,
                              lessContent: !(data.questions && data.questions.length) && !(data.quiz && data.quiz.length) && data.response && data.response.text,
                              quizData: data.questions && data.questions[0],
                              message: data.questions && data.questions[0] && data.questions[0].question,
                              showSummary: true,
                              isReadyNext: false,
                              isLastQuestion: false,
                              quizRefId: data.quizRefId,
                              pageNums: data.pageNums,
                              pagesRange: data.pageRange,
                              selectedOptionType: selectedOptionType || data.option,
                              optionSelected: optionSelected || data.option
                            }
                          };
                        }
                      }
                    } else {
                      switch (data.intentType) {
                        case constants.SUMMARY_INTENT:
                          if (data && data.pageIds && data.pageIds.length) {
                            const currentSectionData = getCurrentSection(data.pageIds[0]);
                            chapterData = currentSectionData && currentSectionData.chapterData;
                          }
                          const summaryResponseText =  !(data.bulletPoints && data.bulletPoints.length) && !(data.summaryText) && data.response && data.response.text;
                          if (showSummaryQuiz && !summaryResponseText && data.summaryText) {
                            isSummaryQuiz = showSummaryQuiz;
                            summaryQuizSelectedOption= selectedOptionType;
                            summaryQuizPageIds = data && data.pageIds;
                            gaEvents(handleGAEvents, {
                              event_action: eventAction.continuousSuggestionShown,
                              event_label: constants.GPT_EVENTS.CLICKTOSHOW,
                              event_value: null,
                              chat_type: constants.GPT_EVENTS.SUMMARY,
                            });
                          }
                          responseData = {
                            id: constants.SUMMARY_CONTENT,
                            data: {
                              summaryData: data,
                              lessContent: summaryResponseText,
                              optionSelected: (data.option === constants.BULLET ) ? constants.BULLET : constants.PARAGRAPH,
                              pageId,
                              selectedOptionType,
                              chapterList,
                              pageList,
                              showFormat: AITutorContext.enableParagraph,
                              pageIds: data.pageIds,
                              pageNums: data.pageNums,
                              pagesRange: data.pageRange,
                              chapterData
                            }
                          };
                          if (isFromContentSelector.current && contentSelectorSelectedText) {
                            responseData.data.userSelectedContent = contentSelectorSelectedText;
                            responseData.data.isFromCSMenu = true;
                            if (contentSelectorSelectedText.length > 500) {
                              gaEvents(handleGAEvents, {
                                event_action: eventAction.clickSummaryFormat,
                                event_label: (data.option === constants.BULLET) ? constants.BULLET : constants.PARAGRAPH,
                                event_value: null,
                                command_source_code: 'type'
                              });
                            }
                          } else if (!isFromContentSelector.current) {
                            gaEvents(handleGAEvents, {
                              event_action: eventAction.clickSummaryFormat,
                              event_label: (data.option === constants.BULLET) ? constants.BULLET : constants.PARAGRAPH,
                              event_value: null,
                              command_source_code: 'type'
                            });
                          }

                          gaEvents(handleGAEvents, {
                            event_action: eventAction.outputSuccess,
                            content_categories: null,
                            command_source_code: constants.GPT_EVENTS.TYPE,
                            chat_type: constants.SUMMARY.charAt(0).toUpperCase() + constants.SUMMARY.slice(1),
                            format: (data.option === constants.BULLET ) ? constants.GPT_EVENTS.BULLET : constants.GPT_EVENTS.PARAGRAPH,
                            content_range: handleContentRange('type'),
                            initiated_point: constants.GPT_EVENTS.COMMAND_LINE,
                            event_label: null,
                            event_value: null,
                            video_count: 0,
                            asset_ids_returned: null,
                            links: Utils.pageNavigationAnchorLinks(data?.requestId),
                            chat_id: globalContext.threadId,
                            result_index: null,
                            location_in_app: constants.GPT_EVENTS.GPT,
                            ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                            topic: null
                          });
                          break;
                        case constants.EXPLAIN_INTENT:
                          quizQuestionsData = [];
                          currentQuestionIndex.current = 0;
                          nextQuestion.current = '';
                          if (data.response && data.response.isNotValidAIResponse) {
                            responseData = {
                              id: constants.ERROR_MESSAGE,
                              message: data.response && data.response.text.replaceAll('###', '')
                            };
                          } else {
                            responseData = {
                              id: constants.DISCUSS,
                              data: {
                                botResponse: data && data,
                                inputValue: inputValue || userAnswer,
                                showformat: true
                              }
                            };
                          }

                          gaEvents(handleGAEvents, {
                            event_action: eventAction.outputSuccess,
                            content_categories: null,
                            command_source_code: (!tellMeMore && ((event && event.key === 'Enter') || typedEvent)) ? constants.GPT_EVENTS.TYPE : constants.GPT_EVENTS.CLICK,
                            chat_type: constants.GPT_EVENTS.EXPLAIN.charAt(0).toUpperCase() + constants.GPT_EVENTS.EXPLAIN.slice(1),
                            format: (data.response && data.response.text && data.response.option) ? handleFormatType(data.response.text, data.response.option) : constants.GPT_EVENTS.BULLET,
                            content_range: handleContentRange(),
                            initiated_point: (!isExplainClicked && !tellMeMore && ((event && event.key === 'Enter') || typedEvent)) ? constants.GPT_EVENTS.COMMAND_LINE : (isContentSelectFlow.current ? constants.GPT_EVENTS.CONTENT_SELECTOR: constants.GPT_EVENTS.UI_QUICK_ACTION),
                            event_label: null,
                            event_value: null,
                            video_count: 0,
                            asset_ids_returned: null,
                            links: Utils.pageNavigationAnchorLinks(data?.requestId),
                            chat_id: globalContext.threadId,
                            result_index: null,
                            location_in_app: constants.GPT_EVENTS.GPT,
                            ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                            topic: null
                          });
                          isContentSelectFlow.current=false;  
                          isExplainClicked = false;
                          break;
                        case constants.QUIZ_INTENT:
                          data.questions && data.questions.length && data.questions.forEach((quiz) => {
                            quiz.quizRefId = data.quizRefId;
                            quiz.responseId = data.responseId;
                            quiz.requestId = data.requestId;
                            // quiz.question = quiz.question_stem || quiz.question;
                          });
                          quizQuestionsData = data.questions;
                          optionSelected = data.option;
                          totalQuestionCount = data.questionsCount;
                          quizRefId.current = (data && data.quizRefId);
                          isLastPage.current = data.isLastPage;
                          quizRequestId = data.requestId;
                          const lessContent = !(data.questions && data.questions.length) && !(data.quiz && data.quiz.length) && data.response && data.response.text;
                          if (lessContent) {
                            responseData = {
                              id: data.option === constants.FREE_RESPONSE ? constants.FREE_RESPONSE_QUIZ : constants.QUIZ_CONTENT,
                              data: {
                                questionsLength: data.questions && data.questions.length,
                                pageIds: data.pageIds,
                                responseData: data,
                                lessContent,
                                quizData: data.questions && data.questions[0],
                                message: data.questions && data.questions[0] && (data.questions[0].question || data.questions[0].question_stem),
                                isLastPage: isLastPage.current,
                                showSummary: true,
                                isReadyNext: false,
                                isLastQuestion: false,
                                quizRefId: data.quizRefId,
                                pageNums: data.pageNums,
                                pagesRange: data.pageRange,
                                selectedOptionType: selectedOptionType || data.option,
                                optionSelected: optionSelected || data.option,
                                sourceType: data.sourceType,
                                showOptions: (optionSelected || data.option) === constants.FREE_RESPONSE,
                                currentQuestionIndex: currentQuestionIndex.current,
                                chapterData
                              }
                            };
                            identifierType = '';
                            optionSelected = 'discuss';
                            quizRequestId = '';
                          } else if ((data.questions && data.questions.length) || data.isLastPage) {
                            responseData = {
                              id: data.option === constants.FREE_RESPONSE ? constants.FREE_RESPONSE_QUIZ : constants.QUIZ_CONTENT,
                              data: {
                                questionsLength: data.questions && data.questions.length,
                                isLastPage: isLastPage.current,
                                pageIds: data.pageIds,
                                responseData: data,
                                lessContent,
                                quizData: data.questions && data.questions[0],
                                message: data.questions && data.questions[0] && (data.questions[0].question || data.questions[0].question_stem),
                                showSummary: true,
                                isReadyNext: false,
                                isLastQuestion: false,
                                quizRefId: data.quizRefId,
                                pageNums: data.pageNums,
                                pagesRange: data.pageRange,
                                selectedOptionType: selectedOptionType || data.option,
                                optionSelected: optionSelected || data.option,
                                sourceType: data.sourceType,
                                showOptions: (optionSelected || data.option) === constants.FREE_RESPONSE,
                                currentQuestionIndex: currentQuestionIndex.current,
                                chapterData,
                                totalQuestionCount
                              }
                            };
                            if (!data.isLastPage) {
                              getNextSetOfQuiz();
                            }
                          } else if (!(data.questions && data.questions.length) && !data.isLastPage) {
                            const quizObject = {
                              id: data.option === constants.FREE_RESPONSE ? constants.FREE_RESPONSE_QUIZ : constants.QUIZ_CONTENT,
                              data: {
                                questionsLength: data.questions && data.questions.length,
                                isLastPage: isLastPage.current,
                                responseData: data,
                                lessContent,
                                pageIds: data.pageIds,
                                quizData: data.questions && data.questions[0],
                                message: data.questions && data.questions[0] && (data.questions[0].question || data.questions[0].question_stem),
                                showSummary: true,
                                isReadyNext: false,
                                isLastQuestion: false,
                                quizRefId: data.quizRefId,
                                pageNums: data.pageNums,
                                pagesRange: data.pageRange,
                                selectedOptionType: selectedOptionType || data.option,
                                optionSelected: optionSelected || data.option,
                                sourceType: data.sourceType,
                                showOptions: (optionSelected || data.option) === constants.FREE_RESPONSE,
                                currentQuestionIndex: currentQuestionIndex.current,
                                chapterData,
                                totalQuestionCount
                              }
                            };
                            getNextSetOfQuiz(true, quizObject);
                            noUpdateToSteps = true;
                          }
                          
                          gaEvents(handleGAEvents, {
                            event_action: eventAction.clickPacticeFormat,
                            event_label: data.option === constants.FREE_RESPONSE ? 'Free Response' : 'Multi choice',
                            event_value: null,
                            command_source_code: 'type'
                          });

                          gaEvents(handleGAEvents, {
                            event_action: eventAction.outputSuccess,
                            content_categories: null,
                            command_source_code: constants.GPT_EVENTS.TYPE,
                            chat_type: constants.GPT_EVENTS.PRACTICE,
                            format: data.option === constants.FREE_RESPONSE ? constants.GPT_EVENTS.SHORT_ANSWER : constants.GPT_EVENTS.MULTIPLE_CHOICE,
                            content_range: handleContentRange(data.sourceType),
                            initiated_point: constants.GPT_EVENTS.COMMAND_LINE,
                            event_label: null,
                            event_value: null,
                            video_count: 0,
                            asset_ids_returned: null,
                            links: Utils.pageNavigationAnchorLinks(data?.requestId),
                            chat_id: globalContext.threadId,
                            result_index: null,
                            location_in_app: constants.GPT_EVENTS.GPT,
                            ...getPromptVersion(data, constants.PROMPT_VERSION_KEYWORDS.OUTPUT_SUCCESS),
                            topic: null
                          });
                          break;
                        case constants.CONTACT_INTENT:
                          responseData = {
                            id: constants.CONTACT_INTENT
                          };

                          break;
                        case constants.UNKNOWN:
                          quizQuestionsData = [];
                          currentQuestionIndex.current = 0;
                          nextQuestion.current = '';
                          const identifiersAvailable = [constants.SUMMARY_IDENTIFIER, constants.QUIZ_IDENTIFIER];
                          if (identifiersAvailable.includes(userCommandUri)) {
                            identifierType = '';
                            handleSend({}, inputValue);
                          } else {
                            responseData = {
                              id: constants.WELCOME_MESSAGE,
                              data: {
                                isUnknownIntentType: true
                              }
                            };
                          }
                          break;
                        case constants.INVALID:
                          responseData = {
                            id: constants.INVALID,
                            data: {
                              message: data.response && data.response.text
                            }
                          };
                          break;
                        case constants.PROBLEM_SOLVE_FEATURE:
                          responseData = {
                            id: constants.FREE_RESPONSE_QUIZ,
                            data: {
                              message: formatMessage(messages.problemSolvingFeatureIntentTypeMessage),
                              isReadyNext: false,
                              showSummary: false,
                              isLastQuestion: false,
                              displayQuestion: false,
                              showOptions: false,
                              currentQuestionIndex: currentQuestionIndex.current,
                              chapterData
                            }
                          };

                          break;
                        case constants.SALUTATION_FEATURE:
                          responseData = {
                            id: constants.ERROR_MESSAGE,
                            message: data.response && data.response.text.replaceAll('###', '')
                          };
                          break;
                        default:
                          if (data.response && data.response.isNotValidAIResponse) {
                            responseData = {
                              id: constants.ERROR_MESSAGE,
                              message: data.response && data.response.text.replaceAll('###', '')
                            };
                          } else {
                            responseData = {
                              id: constants.DISCUSS,
                              data: {
                                botResponse: data && data,
                                inputValue,
                                showformat: true
                              }
                            };
                          }
                          break;
                      }
                      if (isFromContentSelector.current) isFromContentSelector.current = false;
                    }
                  }
                  if (responseData && responseData.id) {
                    if (!noUpdateToSteps) {
                      steps.push(responseData);
                    }
                  }
                  setSteps([...steps]);
                  }
                }
              }
            }
            if(!(data.option === constants.MULTIPLE_CHOICE || data.option === constants.FREE_RESPONSE || data.intentType === constants.QUIZ_INTENT)) {
              setTimeout(()=>{setAriaAssertive('off');}, 1000);
            }
          }
        ).catch((error) => {
          setIsTextBoxDisable(false);
          isContentSelectFlow.current= false;
          clearTimeout(chatTimmer);
          identifierType = '';
          if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
          if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
          if (error.response === undefined && error.code === 'ECONNABORTED') {
            errorData.message = Utils.getErrorMessage(error.code);
          } else {
            errorData.message = Utils.getErrorMessage(error.response.status);
          }
          const errorString = error.toString();
          if (errorString && errorString.indexOf('timeout') !== -1) {
            errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
          }
          steps.pop();
          if (steps[steps.length - 1].id === constants.LOADER) {
            steps.pop();
          }
          steps.push(errorData);
          setSteps([...steps]);
        }
        });
      });
    }
    }
  };

  // const onDiscussOptionClick = (type) => {
  //   handleSend(type);
  // };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend(event);
    }
  };

  const handleDisplayBot = (isOpen) => {
    if (showBotContainer) {
      setAnimationClass('chatBOTClose');
    }
    !botFromLeftPanel && toggleBotContainer();
    // setBotContainer(prevState => !prevState);
    isFromContentSelector.current = false;
    if (users.isFirstTimeAITutorUser && isOpen) {
      firstTimeUser.current = false;
      updateAITutorUserSettings({ isFirstTimeAITutorUser: false });
    }
    if (chatGPTClose && botFromLeftPanel) {
      chatGPTClose();
    }
    if (closeContentSelector) {
      closeContentSelector();
    }
    if(isOpen){
      stopQuiz = false;
      setAriaDisable(false);
      onLDTrack(constants.CHATBOT_CLICKED);
      if (isSleepModeOnClick) {
        isSleepModeOnClick = false;
      }
    } else if(!isOpen){
      stopQuiz = true;
      setTimeout(()=>{
        setAriaDisable(true);
        if(botRef && botRef.current){
          botRef.current.focus();
        }
        if(enableAISleepMode) {
          wakeup();
        }
        setBotFromLeftPanel(false);
        setBotOpenFromInlineContent(false);
        setBotOpenFromLightBox(false);
      }, 500);
      aiStudyToolAutoOpen && setAiStudyToolAutoOpen(false);
      onBotClose(false);
      Utils.pauseVideoOnBotClose();
    }
    setDeltaPosition({ x: 0, y: 0 });
  };

  const handleExpandCollapse = useCallback(() => {
    if(botFromLeftPanel) {
      onCollapseABBot(isExpanded);
    }
    setIsExpanded(!isExpanded);
    const additionalFields = {
      event_label: isExpanded ? 'restore' : 'expand',
      event_value: null,
      event_action: eventAction.clickExpandOrRestore
    };
    gaEvents(handleGAEvents, additionalFields);
  }, [isExpanded]);

  const handleDrag = useCallback((event, ui) => {
    event.preventDefault();
    setAnimationClass('');
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY
    });
    if (!dragInprogress) {
      setDragInprogress(true);
    }
  }, [deltaPosition, dragInprogress]);

  const handleDragStop = useCallback((event) => {
    event.preventDefault();
    if (dragInprogress) {
      setDragInprogress(false);
      const additionalFields = {
        event_label: 'ChatGPT',
        event_value: null,
        event_action: eventAction.dragObject,
        object_end_coordinates: `(${deltaPosition.x}, ${deltaPosition.y})`
      };
      gaEvents(handleGAEvents, additionalFields);
    }
  }, [dragInprogress]);

  const getAnimationClass = () => {
    if (animationClass === 'chatBOTOpen') {
      return botFromLeftPanel ? '' : classes.chatBotOpen;
    } else if (animationClass === 'chatBOTClose') {
      return botFromLeftPanel ? classes.abChatBotClose : classes.chatBotClose;
    }
    return null;
  };
  const handleSummaryFlashCardClick = (summaryData, summaryPageId, chapterData) => {
    const {
      chapterId,
      chapterTitle,
      chapterNumber
    } = chapterData;
    const encodedSummaryData = summaryData && Utils.base64Encode(summaryData);
    const payload = {
      bookId: context.bookID,
      secondaryBookId: context.secondaryBookId,
      pageIds: summaryPageId,
      productModel: context.productModel,
      summaryData: encodedSummaryData,
      chapterId,
      chapterTitle,
      chapterNumber
    };

    getAuthToken((tokenKey) => {
      berlinService.postFlashCardData(
        payload,
        true,
        context.tenantObj,
        tokenKey,
        () => {
          setShowSnackBar({ isOpen: true, message: formatMessage(messages.flashcardCreated), duration: 3000 });
          EventBus.publish(constants.REFRESH_DRAWER, { type: constants.REFRESH_AI_DECK });
          EventBus.publish(constants.REFRESH_DRAWER, { type: constants.REFRESH_DECK });
        }
      ).catch((error) => {
        if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
        if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
        if (error.response === undefined && error.code === 'ECONNABORTED') {
          errorData.message = Utils.getErrorMessage(error.code);
        } else {
          errorData.message = Utils.getErrorMessage(error.response.status);
        }
        const errorString = error.toString();
        if (errorString && errorString.indexOf('timeout') !== -1) {
          errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
        }
      }
      else {
        errorData.message = Utils.getErrorMessage();
      }
      steps.push(errorData);
      setSteps([...steps]);
      });
    });
  };

  const handleQuizFlashCardClick = (questionData, pageIds, chapterData) => {
    const {
      chapterId,
      chapterTitle,
      chapterNumber
    } = chapterData;
    const flashCardPayloadObj = JSON.parse(JSON.stringify(questionData));
    if (flashCardPayloadObj) {
      const regexAnswer = /^Answer: [a-dA-D]\)\s/;
      const regexQuestion = /^[a-dA-D]\)\s/;
      if (regexAnswer.test(flashCardPayloadObj.answer && flashCardPayloadObj.answer.answer)) {
        // eslint-disable-next-line no-param-reassign
        flashCardPayloadObj.answer.answer = flashCardPayloadObj.answer.answer.substr(11);
      }
      if (flashCardPayloadObj.options && flashCardPayloadObj.options.length) {
        flashCardPayloadObj.options.forEach((item) => {
          if (regexQuestion.test(item.option)) {
            // eslint-disable-next-line no-param-reassign
            item.option = item.option.substr(3);
          }
        });
      }
    }
    const encodedQuizData = flashCardPayloadObj && Utils.base64Encode([flashCardPayloadObj]);
    const payload = {
      bookId: context.bookID,
      secondaryBookId: context.secondaryBookId,
      productModel: context.productModel,
      questions: encodedQuizData,
      pageIds,
      chapterId,
      chapterTitle,
      chapterNumber
    };

    getAuthToken((tokenKey) => {
      berlinService.postFlashCardData(
        payload,
        false,
        context.tenantObj,
        tokenKey,
        () => {
          setShowSnackBar({ isOpen: true, message: formatMessage(messages.flashcardCreated), duration: 3000 });
          EventBus.publish(constants.REFRESH_DRAWER, { type: constants.REFRESH_AI_DECK });
          EventBus.publish(constants.REFRESH_DRAWER, { type: constants.REFRESH_DECK });
        }
      ).catch((error) => {
        if(error && (error.response || error.code || error.toString() && error.toString().indexOf('timeout') !== -1)){
          if (error.response && error.response.status) errorMessageGAEvent(error.response.status);
          if (error.response === undefined && error.code === 'ECONNABORTED') {
            errorData.message = Utils.getErrorMessage(error.code);
          } else {
            errorData.message = Utils.getErrorMessage(error.response.status);
          }
          const errorString = error.toString();
          if (errorString && errorString.indexOf('timeout') !== -1) {
            errorData.message = 'Hmm. I\'m not sure what to do with that request. Try another one.';
          }
        } else {
          errorData.message = Utils.getErrorMessage();
        }
        steps.push(errorData);
        setSteps([...steps]);
      });
    });
  };
  const handleCopy = () => {
    setTimeout(() => {
      setShowSnackBar({ isOpen: true, message: formatMessage(messages.textCopiedMessage), duration: 3000 });
    }, 100);
  };

  const handleMove = () => {
    identifierType = '';
    topic = '';
    setIsTextBoxDisable(false);
    if(isRecommendations) {
      setRecommendations(false);
    }
    gaEvents(handleGAEvents, {
      event_action: eventAction.clickChatContinuation,
      event_label: `Let's move on`,
      event_value: null,
      command_source_code: 'click'
    });
    const moveUserData = {
      id: constants.USER_MESSAGE,
      data: {
        text: formatMessage(messages.moveOn)
      }
    };
    const moveData = {
      id: constants.AFTER_STOP,
      data: {
        isFromStopQuiz: false,
        isFromUnknownIntentType: false,
        isMove: true
      }
    };
    steps.push(moveUserData, moveData);
    setSteps([...steps]);
  }
  const handleSummaryQuiz = (type, data, pageIds, summarySourceType) => {
    removeLastStep(constants.WELCOME_MESSAGE);
    const quizUserMessage = {
      id: constants.USER_MESSAGE,
      data: {
        text: formatMessage(messages.summaryQuizMe)
      }
    };
    steps.push(quizUserMessage);
    setSteps([...steps]);
    handleRangeSubmit(type, null, null, null, pageIds, null, null, null, null, null, true, summarySourceType, chapterData, true);
    gaEvents(handleGAEvents, {
      event_action: eventAction.selectContinuousSuggestion,
      event_label: formatMessage(messages.summaryQuizMe),
      event_value: null,
      initiated_point: constants.GPT_EVENTS.UI_QUICK_ACTION,
      chat_type: constants.GPT_EVENTS.SUMMARY,
      intention: constants.GPT_EVENTS.YES,
      content_range: (summaryQuizSelectedOption === constants.CURRENT_SECTION || summaryQuizSelectedOption === constants.SECTION) ? constants.GPT_EVENTS.SECTION : constants.GPT_EVENTS.CHAPTER,
      format: (data.option === constants.BULLET) ? constants.GPT_EVENTS.BULLET : constants.GPT_EVENTS.PARAGRAPH,
    });
  };

  const handleSummaryMoveOn = (type, data) => {
    removeLastStep(constants.WELCOME_MESSAGE);
    if(isRecommendations) {
      setRecommendations(false);
    }
    const moveUserData = {
      id: constants.USER_MESSAGE,
      data: {
        text: formatMessage(messages.moveOn)
      }
    };
    const moveData = {
      id: constants.AFTER_STOP,
      data: {
        isFromStopQuiz: false,
        isFromUnknownIntentType: false,
        isMove: true
      }
    };
    steps.push(moveUserData, moveData);
    setSteps([...steps]);
    gaEvents(handleGAEvents, {
      event_action: eventAction.selectContinuousSuggestion,
      event_label: formatMessage(messages.summaryMoveOn),
      event_value: null,
      initiated_point: constants.GPT_EVENTS.UI_QUICK_ACTION,
      chat_type: constants.GPT_EVENTS.SUMMARY,
      intention: constants.GPT_EVENTS.NO,
      content_range: (summaryQuizSelectedOption === constants.CURRENT_SECTION || summaryQuizSelectedOption === constants.SECTION) ? constants.GPT_EVENTS.SECTION : constants.GPT_EVENTS.CHAPTER,
      format: (data.option === constants.BULLET) ? constants.GPT_EVENTS.BULLET : constants.GPT_EVENTS.PARAGRAPH,
    });
    isSummaryQuiz = false;
    isExplainQuiz = false;
    summaryQuizSelectedOption = '';
    summaryQuizPageIds = [];
  }

  const handleExplainQuiz = () => {
    removeLastStep(constants.WELCOME_MESSAGE);
    const quizUserMessage = {
      id: constants.USER_MESSAGE,
      data: {
        text: formatMessage(messages.explainQuizMe)
      }
    };
    steps.push(quizUserMessage);
    setSteps([...steps]);
    isExplainQuiz = false;
    handleRangeSubmit(null, null, null, null, null, null, null, null, null, null, true, null, chapterData, true, false, true);
    gaEvents(handleGAEvents, {
      event_action: eventAction.selectContinuousSuggestion,
      event_label: formatMessage(messages.explainQuizMe),
      event_value: null,
      initiated_point: constants.GPT_EVENTS.UI_QUICK_ACTION,
      chat_type: constants.GPT_EVENTS.EXPLAIN,
      intention: constants.GPT_EVENTS.YES,
      content_range: constants.GPT_EVENTS.TOPIC,
      format: constants.GPT_EVENTS.PARAGRAPH,
    });
  };

  const handleExplainMoveOn = () => {
    removeLastStep(constants.WELCOME_MESSAGE);
    if(isRecommendations) {
      setRecommendations(false);
    }
    const moveUserData = {
      id: constants.USER_MESSAGE,
      data: {
        text: formatMessage(messages.moveOn)
      }
    };
    const moveData = {
      id: constants.AFTER_STOP,
      data: {
        isFromStopQuiz: false,
        isFromUnknownIntentType: false,
        isMove: true
      }
    };
    isExplainQuiz = false;
    steps.push(moveUserData, moveData);
    setSteps([...steps]);
    gaEvents(handleGAEvents, {
      event_action: eventAction.selectContinuousSuggestion,
      event_label: formatMessage(messages.summaryMoveOn),
      event_value: null,
      initiated_point: constants.GPT_EVENTS.UI_QUICK_ACTION,
      chat_type: constants.GPT_EVENTS.EXPLAIN,
      intention: constants.GPT_EVENTS.NO,
      content_range: constants.GPT_EVENTS.TOPIC,
      format: constants.GPT_EVENTS.PARAGRAPH,
    });
  }

  const initExplainQuizEvent = useCallback(() => {
    gaEvents(handleGAEvents, followUpEventData);
  }, []);

  const initSuggestToQuizEvent = () => {
    gaEvents(handleGAEvents, showExplainQuizFollowOptionEvent);
  }

  const toggleHistory = () => {
    if(showHistory) { // Close
      closeChatHistoryGAEvent(handleGAEvents, context.bookID);
      setHistoryDrawerAnimationClass(classes.closeHistoryContainer);
      setTimeout(()=> {
        setShowHistory(false);
      }, 150);
    } else { // Open
      openChatHistoryGAEvent(handleGAEvents, context.bookID);
      const { threadId } = globalContext || {};
      if(threadId && !historyPage && !isTitleGenerated) {
        generateThreadTitle(threadId);
        setIsTitleGenerated(true);
      }
      setHistoryDrawerAnimationClass(classes.openHistoryContainer);
      setShowHistory(true);
      setHistoryPage(historyPage);
      historyScrollTop = 1;
      historyScrollToCount = 0;
      scrollTimer = -1;
    }
  };
  const getUserHistory = (payLoad) => {
    getAuthToken((tokenKey) => {
      berlinService.getChatHistory(
        payLoad,
        tokenKey,
        (data) => {
          if (data && data.response.length) {
            const responseArray = data.response.reverse();
            const formattedSteps = Utils.stepsMapper(responseArray, getCurrentSection, AIEnableMarkdown);
            if(formattedSteps && formattedSteps.length) {
              historyScrollToCount = formattedSteps.length - 1;
              setHistoryOffset(data.offset);
              setHistoryTotal(data.total);
              const customSteps = (payLoad.offset === 0) ? formattedSteps : formattedSteps.concat(steps);
              setSteps(customSteps);
            };
          } else {
            if(payLoad.offset === 0) {
              setSteps([]);
              setShowOptions(true);
            }
          }
        }
      );
    });
  }
  const handleRenderHistory = (threadId) => {
    optionSelected = constants.DISCUSS; // To avoid Answer API
    toggleHistory();
    setShowOptions(false);
    setShowScrollArrow(false);
    const historySteps = [];
    historySteps.push(loaderStep);
    setSteps(historySteps);
    setHistoryPage(true);
    const historyPayLoad = {
      threadId,
      offset: 0,
      limit: constants.CHAT_HISTORY_MESSAGE_LIMIT,
      bookId: context.bookID
    };
    getUserHistory(historyPayLoad);
  };

  const handleChatDelete = (threadId) => {
    if (threadId === globalContext.threadId) {
      handleClear({}, true);
    }
  }
  const handleHideChannelVideo = () => {
    updateChannelsOptOut();
    const stepLength = steps.length;
    const lastStep = steps[stepLength - 1];
    if(lastStep.id === constants.STREAMING_DISCUSS) {
      setEnableOptoutLink(false);
      setEnableOptInLink(true);
      lastStep.data.videos = [];
      const hideVideoUserMessage = {
        id: constants.USER_MESSAGE,
        data: {
          text: formatMessage(messages.hideVideoUserMessage)
        }
      };
      const hideVideoCannedMessage = {
        id: constants.CANNED_MESSAGE,
        message: formatMessage(messages.hideVideoCannedMessage)
      };
      steps.push(hideVideoUserMessage, hideVideoCannedMessage);
      setSteps(steps);
    }
  };
  const handleShowChannelVideo = () => {
    updateChannelsOptIn();
    const stepLength = steps?.length;
    const lastStep = steps[stepLength - 1];
    if(lastStep?.id === constants.STREAMING_DISCUSS) {
      lastStep.data.isOptInLinkEnabled = false;
      lastStep.data.showOptInPremiumMessage = true;
      lastStep.data.showPremiumVideo = true;
    }
    Utils.scrollToView(5, '', false, true); // Scroll to premium opt-in videos
  };

  const getMessages = useCallback((step, stepIndex) => {
    let chatComponent;
    let visuallyHiddenLoader = document.getElementById('visually-hidden-loader');
    if(visuallyHiddenLoader) visuallyHiddenLoader.innerText = '';
    switch (step.id) {
      case constants.LOADER:
        const loaderMessage = (messages && messages.retrievingData && messages.retrievingData.defaultMessage) || "";
        chatComponent = <LoaderIcon loderLabel={loaderMessage}/>;
        visuallyHiddenLoader.innerText = loaderMessage;
        break;
      case constants.DELAYED_RESPONSE:
        chatComponent = (
          <ErrorBoundary>
            <DelayedResponse
              handleShowWaitingMessage={handleShowWaitingMessage}
              handleStopRequestShowOptions={handleStopRequestShowOptions}
              masterTheme={masterTheme}
              theme={theme}
              disableOption={step.showOptions}
            />
          </ErrorBoundary>
        );
        break;
      case constants.AFTER_STOP:
        chatComponent = (
          <ErrorBoundary>
            <AfterStop
              onOptionClick={handleOptionClick}
              onOptionClickTriggerGA={startNewConversationGA}
              isFromStopQuiz={step.data.isFromStopQuiz}
              isFromUnknownIntentType={step.data.isUnknownIntentType}
              isMove={step.data.isMove}
              masterTheme={masterTheme}
              theme={theme}
              isExpanded={isExpanded}
              showSummary={showSummary}
              showPractice={showPractice}
            />
          </ErrorBoundary>
        );
        break;
      case constants.USER_MESSAGE:
        chatComponent = (<ErrorBoundary>
          <UserMessage
            key={constants.USER_MESSAGE}
            message={Utilities.textFromContent(Utils.sanitizeMessage(step.data.text), globalContext.enableNewMathConfig)}
            initials={users.initials}
            masterTheme={masterTheme}
            theme={theme}
          />
        </ErrorBoundary>);
        break;
      case constants.CLARIFY:
        chatComponent = (<ErrorBoundary>
          <Clarify
            onRangeSubmit={handleRangeSubmit}
            onSend={handleSendCallback}
            type={step.data.type}
            chapterList={step.data.chapterList}
            handleGAEvents={handleGAEvents}
            disableOptions={step.data.disableOptions}
            hasPrintPageAvailable={pageList.length > 0}
            masterTheme={masterTheme}
            theme={theme}
            enableTopicInQuizFlow={enableTopicInQuizFlow}
            enableAIShortAnswer={enableAIShortAnswer}
          />
        </ErrorBoundary>);
        break;
      case constants.SUMMARY_OPTIONS:
        chatComponent = (<ErrorBoundary>
          <SummaryOptions
            onSummaryOptionClick={handleSummaryOptionClick}
            type={step.data.type}
            disableOptions={step.data.disableOptions}
            pageRange={step.data.pageRange}
            selectedChapter={step.data.selectedChapter}
            masterTheme={masterTheme}
            theme={theme}
            pageIds={step.data.pageIds}
            pageNums={step.data.pageNums}
            pagesRange={step.data.pagesRange}
          />
        </ErrorBoundary>);
        break;
      case constants.GENERATE_TOPIC:
        chatComponent = (
          <ErrorBoundary>
            <GenerateTopics
              onGenerateTopicsClick={handleSend}
              topics={step.data.topics}
              getPromptVersion={getPromptVersion}
              requestId={step.data.requestId}
              type={step.data.type}
              data={step.data.data}
              bookId={context.bookID || context.secondaryBookId}
              disbaleTopics={step.data.disbaleTopics}
              isContentSelectorFirstTime={step.data.isContentSelectorFirstTime}
              contentSelectedText={step.data.contentSelectedText}
              handleGAEvents={handleGAEvents}
              masterTheme={masterTheme}
              theme={theme}
            />
          </ErrorBoundary>);
        break;
      case constants.SINGLE_TOPIC:
        chatComponent = (
          <ErrorBoundary>
            <SingleTopic
              topic={step.data.topic}
              masterTheme={masterTheme}
              theme={theme}
            />
          </ErrorBoundary>
        );
        break;
      case constants.QUIZ_OPTIONS:
        chatComponent = (
          <ErrorBoundary>
            <QuizOptions
              onSummaryOptionClick={handleSummaryOptionClick}
              type={step.data.type}
              disableOptions={step.data.disableOptions}
              pageRange={step.data.pageRange}
              selectedChapter={step.data.selectedChapter}
              masterTheme={masterTheme}
              theme={theme}
              pageIds={step.data.pageIds}
              pageNums={step.data.pageNums}
              pagesRange={step.data.pagesRange}
              enableAIShortAnswer={enableAIShortAnswer}
            />
          </ErrorBoundary>);
        break;
      case constants.SUMMARY_CONTENT:
        chatComponent = (
          <ErrorBoundary>
            <SummaryListContainer
              summaryData={step.data.summaryData}
              botData={step.data.botData}
              lessContent={step.data.lessContent}
              optionSelected={step.data.optionSelected}
              handleChatGPTCustomNote={handleGptCreateNote}
              handleChatGPTFlashCard={handleChatGPTFlashCard}
              pageRange={step.data.pageRange}
              selectedChapter={step.data.selectedChapter}
              pageId={step.data.pageId}
              selectedContent={step.data.userSelectedContent}
              selectedOptionType={step.data.selectedOptionType}
              chapterList={step.data.chapterList}
              pageList={step.data.pageList}
              // eslint-disable-next-line no-use-before-define
              onRegenerate={handleRegenerate}
              chapterId={context.chapterId}
              chapterTitle={context.chapterTitle}
              handleGAEvents={handleGAEvents}
              handleSubmitFeedback={handleSubmitFeedback}
              feedbackIconFocusId={feedbackIconFocusId}
              masterTheme={masterTheme}
              theme={theme}
              onFormatChange={handleFormatChange}
              showFormat={step.data.showFormat}
              showHeader={step.data.showHeader}
              // isFromCSMenu={step.data.isFromCSMenu}
              onFlashCardClick={handleSummaryFlashCardClick}
              onCopy={handleCopy}
              pageNums={step.data.pageNums}
              pagesRange={step.data.pagesRange}
              topicTitle={step.data.topicTitle}
              sourceType={step.data.sourceType}
              chapterData={step.data.chapterData}
              onSummaryQuiz={handleSummaryQuiz}
              onSummaryMoveOn={handleSummaryMoveOn}
              showSummaryQuiz={step.fromHistory ? false : showSummaryQuiz}
              summaryQuizButton={step.data.summaryQuizButton}
              isExpanded={isExpanded}
              AIEnableMarkdown={AIEnableMarkdown}
              showPractice={showPractice}
              onClickCallback={handleClickAction}
            />
          </ErrorBoundary>
        );
        break;
      case constants.QUIZ_CONTENT:
        chatComponent = (
          <ErrorBoundary>
            <div>
              {
                (step.data.quizData && step.data.showSummary && !step.data.lessContent & !step.data.isExplainCYUMode) ? (
                  <Typography variant="paragraph" className={classes.quizMessage}>
                    {
                      getQuizResponseMessage(step.data.type, step.data.optionSelected, step.data.pageRange, step.data.selectedChapter, step.data.questionsLength, step.data.pageNums, step.data.isFromCSMenu, step.data.selectedOptionType, step.data.quizTitle, step.data.pagesRange, step.data.sourceType)
                    }
                  </Typography>
                ) : ''
              }
              <QuizInteractive
                quizData={step.data.quizData}
                responseData={step.data.responseData}
                lessContent={step.data.lessContent}
                pageIds={step.data.pageIds}
                onMoreQuiz={handleMoreQuiz}
                handleNextQuizQuestion={handleNextQuizQuestion}
                handleSubmitFeedback={handleSubmitFeedback}
                feedbackIconFocusId={feedbackIconFocusId}
                theme={theme}
                masterTheme={masterTheme}
                disableOptions={step.data.disableOptions}
                handleQuizStop={handleQuizStop}
                currentQuizQstnIndex={step.data.currentQuestionIndex}
                isLastPage={step.data.isLastPage}
                chapterData={step.data.chapterData}
                totalQuestionCount={step.data.totalQuestionCount}
                isExplainCYUModeB={step.data.isExplainCYUModeB}
                isExplainCYUMode={step.data.isExplainCYUMode}
                initExplainQuizEvent={initExplainQuizEvent}
              />
            </div>
          </ErrorBoundary>
        );
        break;
      case constants.NEXT_QUESTION:
        chatComponent = (
          <ErrorBoundary>
            <NextQuestionPrompt
              onQuizStop={handleQuizStop}
              onShowNextQuestion={handleShowNextQuestion}
              pageIds={step.data.pageIds}
              theme={theme}
              masterTheme={masterTheme}
            />
          </ErrorBoundary>);
        break;
      case constants.QUIZ_ANSWER:
        chatComponent = (
          <ErrorBoundary>
            <QuizAnswerComponent
              isCorrect={step.data.isCorrect}
              correctAnswer={step.data.correctAnswer}
              answerExplanation={step.data.answerExplanation}
              chapterId={context.chapterId}
              chapterTitle={context.chapterTitle}
              handleChatGPTCustomNote={handleGptCreateNote}
              handleChatGPTFlashCard={handleChatGPTFlashCard}
              handleGAEvents={handleGAEvents}
              handleSubmitFeedback={handleSubmitFeedback}
              feedbackIconFocusId={feedbackIconFocusId}
              quizData={step.data.quizData}
              pageIds={step.data.pageIds}
              theme={theme}
              masterTheme={masterTheme}
              onFlashCardClick={handleQuizFlashCardClick}
              onCopy={handleCopy}
              handleNextQuizQuestion={handleNextQuizQuestion}
              handleQuizStop={handleQuizStop}
              disableOptions={step.data.disableOptions}
              selectedAnswerIndexId={step.data.selectedAnswerIndexId}
              chapterData={step.data.chapterData}
            />
          </ErrorBoundary>
        );
        break;
      case constants.CUSTOM_INPUT:
        chatComponent = (
          <ErrorBoundary>
            <CustomInput
              inputValue={Utils.sanitizeMessage(step.data.inputValue)}
              masterTheme={masterTheme}
              theme={theme}
              initials={users.initials}
            />
          </ErrorBoundary>
        );
        break;
      case constants.NO_QUESTION_LEFT:
        chatComponent = (
          <ErrorBoundary>
            <NoMoreQuestions
              theme={theme}
              handleQuizStop={handleQuizStop}
              masterTheme={masterTheme}
            />
          </ErrorBoundary>);
        break;
      case constants.ERROR_MESSAGE:
      case constants.PROBLEM_SOLVE_LOADER_MESSAGE:
      case constants.CANNED_MESSAGE:
        chatComponent = (
          <ErrorBoundary>
            <Typography variant="p" className={classes.errorMessage}>
              {Utilities.textFromContent(step.message, globalContext.enableNewMathConfig)} {step.bookTitle ? <React.Fragment><strong>{context.bookTitle}</strong>.</React.Fragment> : ''}</Typography>
          </ErrorBoundary>);
        break;
      case constants.QUIZ_LOADER:
        chatComponent = (
          <ErrorBoundary>
            <div>
              <Typography variant="p" className={classes.quizErrorMessage} tabIndex={0}>
                {Utilities.textFromContent(step.message, globalContext.enableNewMathConfig)}
              </Typography>
              <LoaderIcon />
            </div>
          </ErrorBoundary>
        );
        break;
      case constants.DISCUSS:
        chatComponent = (
          <ErrorBoundary>
            <Discuss
              botResponse={step.data.botResponse}
              handleChatGPTCustomNote={handleGptCreateNote}
              handleChatGPTFlashCard={handleChatGPTFlashCard}
              // eslint-disable-next-line no-use-before-define
              onRegenerate={handleRegenerate}
              chapterId={context.chapterId}
              chapterTitle={context.chapterTitle}
              handleGAEvents={handleGAEvents}
              handleSubmitFeedback={handleSubmitFeedback}
              feedbackIconFocusId={feedbackIconFocusId}
              theme={theme}
              masterTheme={masterTheme}
              onCopy={handleCopy}
              onMove={handleMove}
              onSend={handleSend}
              onFormatChange={handleExplainFormatChange}
              inputValue={step.data.inputValue}
              showFormat={false}
              explainFlowMessage={explainFlowMessage.current}
              disableOptions={step.data.disableOptions}
            />
          </ErrorBoundary>
        );
        break;
      case constants.STREAMING_DISCUSS:
        chatComponent = (
          <ErrorBoundary>
            <StreamingDiscuss
              user={users}
              channelId={channelId}
              env={env}
              onLDTrack={onLDTrack}
              channelsVideoEsScore={channelsVideoEsScore}
              discussData={{
                content: step.content,
                apiContent: step.apiContent,
                requestId: step.requestId,
                responseId: step.responseId,
                streamStatus: step.streamStatus,
                hasMoreSummary: step.hasMoreSummary, 
                fromHistory: step.fromHistory, 
                isCannedMessage: step.isCannedMessage,
                useRectifier: step.useRectifier,
                category: step.category,
                payloadType: step.payloadType
              }}
              handleChatGPTCustomNote={handleGptCreateNote}
              handleChatGPTFlashCard={handleChatGPTFlashCard}
              // eslint-disable-next-line no-use-before-define
              onRegenerate={handleRegenerate}
              chapterId={context.chapterId}
              chapterTitle={context.chapterTitle}
              handleGAEvents={handleGAEvents}
              handleSubmitFeedback={handleSubmitFeedback}
              feedbackIconFocusId={feedbackIconFocusId}
              theme={theme}
              masterTheme={masterTheme}
              onCopy={handleCopy}
              onSend={handleSend}
              onMove={handleMove}
              onFormatChange={handleExplainFormatChange}
              inputValue={step.data.inputValue}
              videos={step.data.videos}
              bookId={step.data.bookID}
              bookTitle={step.data.bookTitle}
              showFormat={false}
              explainFlowMessage={explainFlowMessage.current}
              responseOptionType={step.data.optionSelected}
              disableOptions={step.data.disableOptions}
              chatType={step.chatType}
              enableExplainQuizOptions={step.intentType === constants.EXPLAIN_INTENT && enableExplainQuizOptions}
              onExplainQuiz={handleExplainQuiz}
              onExplainMoveOn={handleExplainMoveOn}
              explainQuizButton={step.data.explainQuizButton}
              showExplainQuiz={step.data.showExplainQuiz}
              initSuggestToQuizEvent={initSuggestToQuizEvent}
              isExpanded={isExpanded}
              hideChannelsPopout={hideChannelsPopout}
              pageList={step.pageList}
              onPageNavigation={onPageNavigation}
              showSourceReferencePage={showSourceReferencePage}
              playList={playList}
              showOptoutLink={(stepIndex + 1) === (steps && steps.length) ? enableOptoutLink : false}
              onHideChannelVideo={handleHideChannelVideo}
              onShowChannelVideo={handleShowChannelVideo}
              isChannelsBundleSubscriber={isChannelsBundleSubscriber}
              productId={context.secondaryBookId}
              showPractice={showPractice}
              onClickCallback={handleClickAction}
              initials={users.initials}
              isOptInLinkEnabled={step.data.isOptInLinkEnabled}
              showPremiumVideo={step.data.showPremiumVideo}
              showOptInPremiumMessage={step.data.showOptInPremiumMessage}
              intentType={step.intentType}
              isContentMedia={step.isContentMedia}
            />
          </ErrorBoundary>
        );
        break;
      case constants.FREE_RESPONSE_QUIZ:
        chatComponent = (
          <ErrorBoundary>
            <div>
              {
                (step.data.showSummary && !step.data.lessContent) && (
                  <Typography variant="paragraph" className={classes.quizMessage}>
                    {
                      getQuizResponseMessage(step.data.type, step.data.optionSelected, step.data.pageRange, step.data.selectedChapter, step.data.questionsLength, step.data.pageNums, step.data.isFromCSMenu, selectedOptionType || step.data.selectedOptionType, '', step.data.pagesRange, step.data.sourceType)
                    }
                  </Typography>
                )
              }
              <FreeResponseQuiz
                message={step.data.message}
                lessContent={step.data.lessContent}
                isReadyNext={step.data.isReadyNext}
                displayQuestion={step.data.displayQuestion}
                isLastQuestion={step.data.isLastQuestion}
                masterTheme={masterTheme}
                theme={theme}
                currentQuestion={step.data.currentQuestion}
                pageIds={step.data.pageIds}
                handleNextQuizQuestion={handleNextQuizQuestion}
                disableOptions={step.data.disableOptions}
                handleQuizStop={handleQuizStop}
                showOptions={step.data.showOptions}
                currentQuizQstnIndex={step.data.currentQuestionIndex}
                chapterData={step.data.chapterData}
                totalQuestionCount={step.data.totalQuestionCount}
              />
            </div>
          </ErrorBoundary>
        );
        break;
      case constants.WELCOME_MESSAGE:
        chatComponent = (
          <ErrorBoundary>
            <WelcomeMessage
              bookTitle={context.bookTitle}
              onOptionClick={handleOptionClick}
              onOptionClickTriggerGA={startNewConversationGA}
              isFromContentSelector={step.data.isFromContentSelector}
              isFirstTimeAITutorUser={step.data.isFirstTimeAITutorUser}
              isNewTopic={step.data.isNewTopic}
              isLastQuestion={step.data.isLastQuestion}
              isFromStopQuiz={step.data.isFromStopQuiz}
              handleGAEvents={handleGAEvents}
              isFromUnknownIntentType={step.data.isUnknownIntentType}
              masterTheme={masterTheme}
              theme={theme}
              aiTutorAutoOpenCommands={aiTutorAutoOpenCommands}
              onPredefinedQueryClick={onPredefinedQueryClick}
              botAutoOpen={botAutoOpen}
              showStarterPrompt={showStarterPrompt}
              isExpanded={isExpanded}
              showSummary={showSummary}
              showPractice={showPractice}
              showRecommendedPrompts={showRecommendedPrompts}
              recommendedPrompts={recommendedPrompts}
            />
          </ErrorBoundary>
        );
        break;
      case constants.CONTACT_INTENT:
        chatComponent = (
          <ErrorBoundary>
            <ContactIntent theme={theme} masterTheme={masterTheme} />
          </ErrorBoundary>
        );
        break;
      case constants.FREE_RESPONSE_QUIZ_VALIDATE:
        chatComponent = (
          <ErrorBoundary>
            <FreeResponseQuizResult
              summaryData={step.data.summaryData}
              handleChatGPTCustomNote={handleGptCreateNote}
              handleChatGPTFlashCard={handleChatGPTFlashCard}
              // eslint-disable-next-line no-use-before-define
              onRegenerate={handleRegenerate}
              chapterId={context.chapterId}
              chapterTitle={context.chapterTitle}
              handleGAEvents={handleGAEvents}
              handleSubmitFeedback={handleSubmitFeedback}
              feedbackIconFocusId={feedbackIconFocusId}
              theme={theme}
              masterTheme={masterTheme}
              isReadyNext={step.data.isReadyNext}
              isLastQuestion={step.data.isLastQuestion}
              onCopy={handleCopy}
              onQuizStop={handleQuizStop}
              onShowNextQuestion={handleShowNextQuestion}
              pageIds={step.data.pageIds}
              onFlashCardClick={handleQuizFlashCardClick} // added
              isFromMultipleChoiceFlow={step.data.isFromMultipleChoiceFlow}
              currentQuestion={step.data.currentQuestion || {}}
              handleNextQuizQuestion={handleNextQuizQuestion}
              chapterData={step.data.chapterData}
            />
          </ErrorBoundary>
        );
        break;
      case constants.INVALID:
        chatComponent = (
          <ErrorBoundary>
            <Typography variant="span" className={classes.quizMessage}>
              {step.data.message}
            </Typography>
          </ErrorBoundary>
        );
        break;
      case constants.STREAMING_SUMMARY_CONTENT:
        chatComponent = (
          <ErrorBoundary>
            <StreamingSummaryListContainer
              masterTheme={masterTheme}
              streamStatus={step.streamStatus}
              theme={theme}
              summaryData={{
                content: step.content,
                apiContent: step.apiContent,
                requestId: step.requestId,
                responseId: step.responseId,
                streamStatus: step.streamStatus
              }}
              handleChatGPTCustomNote={handleGptCreateNote}
              handleChatGPTFlashCard={handleChatGPTFlashCard}
              // eslint-disable-next-line no-use-before-define
              onRegenerate={handleRegenerate}
              chapterId={context.chapterId}
              chapterTitle={context.chapterTitle}
              handleGAEvents={handleGAEvents}
              handleSubmitFeedback={handleSubmitFeedback}
              feedbackIconFocusId={feedbackIconFocusId}
              onFormatChange={handleFormatChange}
              onFlashCardClick={handleSummaryFlashCardClick}
              onCopy={handleCopy}
              optionSelected={step.data.optionSelected}
              pageRange={step.data.pageRange}
              selectedChapter={step.data.selectedChapter}
              pageId={step.data.pageId}
              selectedContent={step.data.userSelectedContent}
              selectedOptionType={step.data.selectedOptionType}
              chapterList={step.data.chapterList}
              pageList={step.data.pageList}
              showFormat={step.data.showFormat}
              showHeader={step.data.showHeader}
              // isFromCSMenu={step.data.isFromCSMenu}
              pageNums={step.data.pageNums}
              pagesRange={step.data.pagesRange}
              topicTitle={step.data.topicTitle}
              sourceType={step.data.sourceType}
              chapterData={step.data.chapterData}
            />
          </ErrorBoundary>
        );
        break;
      case constants.CHAPTER_TOPIC_OPTION:
        chatComponent = (<ErrorBoundary>
          <ChapterTopics
            onSend={handleChapterOptionSend}
            topics={step.data.topics}
            requestId={step.data.requestId}
            disableOptions={step.data.disableOptions}
            optionSelected={step.data.optionSelected}
            intentSelected={step.data.intentSelected}
            handleGAEvents={handleGAEvents}
            theme={theme}
            masterTheme={masterTheme}
          />
        </ErrorBoundary>);
        break;
      case constants.SUGGESTION_CONTENT:
        chatComponent = (<ErrorBoundary>
          <SuggestionPills
            currentContext={step.currentContext}
            optionSelected={step.optionSelected}
            theme={theme}
            masterTheme={masterTheme}
            onCallback={handleSuggestion}
            isExpanded={isExpanded}
          />
        </ErrorBoundary>);
        break;
      case constants.IMAGE_CONTEXT:
        chatComponent = (
          <ErrorBoundary>
            <ImageContext 
              masterTheme={masterTheme}
              theme={theme} 
              imageContextData={step.imageContextData}
              onImagePromptClick={handleImagePromptClick}
              onPageNavigation={onPageNavigation}
              disableOptions={step.disableOptions}
            />
          </ErrorBoundary>
        );
          break;
      default:
        chatComponent = '';
        break;
    }
    return <div className={(step.id === constants.PROBLEM_SOLVE_LOADER_MESSAGE || (step.payloadType === constants.PROBLEM_SOLVE && step.useRectifier)) ? classes.psStepAlignmentBottom : classes.stepAlignmentBottom}>
      <span className={`visuallyHidden ${classes.visuallyHidden}`}>{chatComponent && chatComponent.props && chatComponent.props.children && chatComponent.props.children.key === constants.USER_MESSAGE ? constants.USER_SAYS : constants.TUTOR_SAYS}</span>
      {chatComponent}
      </div>;
  }, [steps, classes]);
  const handleScroll = useCallback((e) => {
    setTimeout(() => {
      const { scrollTop, scrollHeight, clientHeight } = e?.target || {};
      historyScrollTop = scrollTop;
      const reachedBottom = scrollTop >= (scrollHeight - clientHeight - 10);
      let displayScrollIcon = (reachedBottom) ? false : true;
      /* Manual user scroll & scrollTo Bottom both cases this function getting called,
      This meanwhile duration Scroll visible so we have addded the below logic */
      if (displayScrollIcon && scrollDownClicked) {
        displayScrollIcon = false;
      } else if (displayScrollIcon) {
        displayScrollIcon = true;
      } else {
        displayScrollIcon = false;
        scrollDownClicked = false;
      } 
      setShowScrollArrow(displayScrollIcon);
      /** User History pagination Start */
      if (scrollTimer != -1) {
        clearTimeout(scrollTimer);
      }
      scrollTimer = window.setTimeout(() => {
        if (historyScrollTop === 0 && historyPage && historyTotal > (historyOffset +constants.CHAT_HISTORY_MESSAGE_LIMIT)) {
          const historyPayLoad = {
            threadId: globalContext.threadId,
            offset: historyOffset + constants.CHAT_HISTORY_MESSAGE_LIMIT,
            limit: constants.CHAT_HISTORY_MESSAGE_LIMIT,
            bookId: context.bookID
          };
          getUserHistory(historyPayLoad);
        }
      }, 500);
      /** User History pagination End */
    }, 200); // Added timeout to avoid displaying Arrow while updating steps
  }, []);

  const handleScrollBottom = useCallback(() => {
    scrollDownClicked = true;
    setShowScrollArrow(false);
    if(scrollContainerRef && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: "smooth"
      });
    }
  }, []);

  const handleLightboxClose = () => {
    setOpenLightbox(false);
    setLightboxImageData({});
  };
  return (
    <div className={classes.botContainer}>
      <span id="visually-hidden-loader" class="visually-hidden"></span>
      {isPageLoaded && !openBotContainer && showChatBotIcon && (
        <div ref={rotateRef} className={classes.iconWrapper}>
          <Tooltip
            placement="top-center"
            title={formatMessage(messages.aiLearningAssistant)}
            arrow
            classes={{
              tooltip: classes.tooltipStyle,
              arrow: classes.arrowStyle
            }}
          >
            <IconButton
              id="ai-button"
              onClick={() => handleDisplayBot (true) }
              className="bot-icon-button-wrapper"
              aria-label={messages.aiLearningAssistant.defaultMessage}
              aria-haspopup="dialog"
              buttonRef={botRef}
              onMouseOver={hanbleBotMouseOver}
            >
              <div className='chatbot-cover' id="chatbot">
                <div class="face-outer-ring hover" />
                <div class="face-outer-ring" />
                <div className="bot-eyes">
                  <TutorEyes />
                </div>
                <div className="bot-eyes hover">
                  <TutorEyesHover />
                </div>
                <div className="bot-eyes asleep">
                  <TutorEyesSleep />
                </div>
              </div>
            </IconButton>
          </Tooltip>
        </div>
      )
      }
      {
        (
          <ClickAwayListener
        onClickAway={closeContentSelector}
      >
        <Draggable position={deltaPosition} handle="#tutorHeader" bounds="body" onDrag={handleDrag} onStop={handleDragStop} cancel=".buttonClickable" aria-label="Tutor chatbot" role="dialog">
          <div role="dialog" aria-labelledby="aiStudyToolBot" id="bot_container_id" className={isExpanded ? `${classes.container}  ${classes.expandContainer} ${showModal ? classes.containerPadding : ''} ${ariaDisable ? classes.hideChatbotContainer : ''} ${botFromLeftPanel ? `${classes.fullScreenStyle} ${classes.removeShadow}` : ''} ${getAnimationClass()} ${openLightbox ? classes.botWindowStyle : ''}` : `${classes.container} ${showModal ? classes.containerPadding : ''} ${ariaDisable ? classes.hideChatbotContainer : ''} ${botFromLeftPanel ? classes.leftPanelNormalScreenStyle : ''} ${getAnimationClass()} ${openLightbox ? classes.botWindowStyle : ''}`} >
            <div id="tutorHeader" className={`${classes.header} ${(botFromLeftPanel || disableDrag) ? `${classes.disableDrag} ${classes.leftPanelHeader}` : ''} ${showHistory ? classes.addBlur : ''}`}>
              <Header
                onClose={()=> handleDisplayBot(false)}
                botOpenedTime={botOpenedTime}
                isExpanded={isExpanded}
                onExpandCollapse={handleExpandCollapse}
                aiStudyToolAutoOpen={aiStudyToolAutoOpen}
                handleGAEvents={handleGAEvents}
                masterTheme={masterTheme}
                theme={theme}
                isSendButtonEnabled={(inputValue && (inputValue.length > 0))}
                isNewTopicEnabled={(steps && (steps.length > 0))}
                showHistory={showHistory}
                botFromLeftPanel={botFromLeftPanel}
                tutorName={tutorName}
              />
            </div>
            {
              (showModal && showBotContainer)
            ? <WelcomeModal onNext={handleCloseModal} isExpanded={isExpanded} aitutorWelcomeScreenCohorts={aitutorWelcomeScreenCohorts} aiTutorAutoOpenCount={aiTutorAutoOpenCount} />
              : <React.Fragment>
              {
              showOptions && (
                <WelcomeMessage
                  bookTitle={context.bookTitle}
                  ariaLiveStatus={ariaLiveStatus}
                  onOptionClick={handleOptionClick}
                  onOptionClickTriggerGA={startNewConversationGA}
                  isFromContentSelector={isFromContentSelector.current}
                  isFirstTimeAITutorUser={users.isFirstTimeAITutorUser}
                  isNewTopic={isNewTopic}
                  isFromUnknownIntentType={false}
                  masterTheme={masterTheme}
                  theme={theme}
                  aiTutorAutoOpenCommands ={aiTutorAutoOpenCommands}
                  onPredefinedQueryClick={onPredefinedQueryClick}
                  botAutoOpen={botAutoOpen}
                  showHistory={showHistory}
                  showStarterPrompt={showStarterPrompt}
                  isExpanded={isExpanded}
                  showSummary={showSummary}
                  showPractice={showPractice}
                  showRecommendedPrompts={showRecommendedPrompts}
                  recommendedPrompts={recommendedPrompts}
                  welcomeMessage={welcomeMessage}
                />
              )
            }
            {
            (steps.length > 0) && (
              <div onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onKeyDown={handleWheelScroll} onScroll={handleScroll} className={`${classes.messageContainer} ${showHistory ? classes.addBlur : ''}`} id="ai_message_container" ref={scrollContainerRef} tabIndex={0} aria-label="chat history" role="region" aria-live={ariaAssertive}>
                <div id="message_block">
                {
                  steps.map((step, index) => (<div id={`step_${index}`} key={`step_${index}`}>{getMessages(step, index)}</div>))
                }
                </div>
                {
                  (isRecommendations && !isStreaming) && (
                    <Recommendations
                      recommendedPrompts={recommendedPrompts}
                      showRecommendedPrompts={showRecommendedPrompts}
                      aiTutorAutoOpenCommands ={aiTutorAutoOpenCommands}
                      onPredefinedQueryClick={onPredefinedQueryClick}
                      masterTheme={masterTheme}
                      theme={theme}
                    />
                  )
                }
              </div>
            )
          }
            <div className={`${classes.inputTextBox} ${showHistory ? classes.addBlur : ''}`}>
              <div className={`${classes.chatInputTextBoxContainer} ${enableMathMLKeyboard ? classes.mathChatInputContainer : ''}`}>
              {
                enableMathMLKeyboard ? (
                  <React.Fragment>
                    <div ref={inputRef} contentEditable="true" id="editableMathInput" onKeyUp={onMathInputKeyUp} className={classes.mathInput} placeholder={AIChatPlaceHolderText || formatMessage(messages.inputPlaceHolder)}></div>
                    <IconButton onClick={handleMathKeyboard} className={classes.functionIconStyle}>
                      <MathIcon
                        masterTheme={masterTheme}
                        theme={theme}
                        style={classes.mathIconStyle}
                      />
                    </IconButton>
                  </React.Fragment>
                ) : (
                  <TextField
                    id="inputTextBox"
                    InputProps={{
                      classes: {
                        root: classes.textBoxStyle,
                        input: classes.textBoxInputStyle,
                        underline: classes.underlineStyle
                      }
                    }}
                    error={Boolean(maxCharValidationMessage)}
                    helperText={maxCharValidationMessage}
                    FormHelperTextProps={{
                      className: classes.validationMessageStyle
                    }}
                    multiline
                    classes={{ root: classes.inputStyle }}
                    minRows={1}
                    fullWidth
                    placeholder={AIChatPlaceHolderText || formatMessage(messages.inputPlaceHolder)}
                    value={inputValue}
                    onKeyPress={event => handleKeyDown(event)}
                    onChange={event => handleChange(event)}
                    aria-label="Type a message"
                    role="Textbox"
                    inputRef={inputRef}
                  />
                )
              }
              </div>
              {isStopStreaming ?
                (
                  <Button
                    id="stopRegeneration"
                    autoFocus
                    className={classes.stopRegenerationBtn}
                    onClick={abortStreamConnection}
                    aria-description="data download has started. you can stop it using this button"
                  >
                    {/* <Crop54Icon fontSize="small" /> Stop Regenerating */}
                    Stop generating
                  </Button>) : ''}
                  {
                    (showScrollArrow && !isStopStreaming && !isLoading) && (
                      <IconButton
                        id="scroll_arrow_button"
                        className={classes.scrollArrowButtonStyle}
                        onClick={handleScrollBottom}
                        aria-label="Scroll to bottom"
                        role="button"
                      >
                        <DownArrow />
                      </IconButton>
                    )
                  }
            </div>
            <div className={`${classes.ctaButtons} ${showHistory ? classes.addBlur : ''}`}>
              {
                openMathKeyboard && (
                  <Dialog open={openMathKeyboard} onClose={handleMathKeyboard} style={{zIndex: 1302, top: '-120px'}}>
                    <MathLive
                      onMathInput={onMathInput}
                      onClose={handleMathKeyboard}
                      masterTheme={masterTheme}
                      theme={theme}
                      bookId={context.bookID || context.secondaryBookId}
                      handleGAEvents={handleGAEvents}
                    />
                  </Dialog>
                )
              }
              <CtaButtons
                inputValue={inputValue}
                isTextBoxDisable={isTextBoxDisable}
                handleSend={handleSend}
                handleClear={handleClear}
                handleGAEvents={handleGAEvents}
                masterTheme={masterTheme}
                theme={theme}
                disableNewTopic={(steps && steps.length <= 0)}
                isCharLimitExceeded={isSendBtnDisabled}
                isExpanded={isExpanded}
                onHistoryClick={toggleHistory}
                showHistoryIcon={showUserHistory}
              />
            </div>
            <div className={`${classes.footer} ${showHistory ? classes.addBlur : ''}`}>
              <Footer
                handleGAEvents={handleGAEvents}
                masterTheme={masterTheme}
                theme={theme}
                isExpanded={isExpanded}
                isSendButtonEnabled={(inputValue && (inputValue.length > 0))}
                isNewTopicEnabled={(steps && (steps.length > 0))}
              />
            </div>
            {
              showHistory && (
                <div className={`${classes.historyWrapper} ${historyDrawerAnimationClass} ${isExpanded ? classes.expandedHistoryWrapper : ''}`}>
                  <ChatHistory
                    onClose={toggleHistory}
                    onRenderHistory={handleRenderHistory}
                    onDeleteTitle={handleChatDelete}
                    threadDeleteTimeOut={threadDeleteTimeOut || constants.DELETE_THREAD_TIMEOUT}
                    masterTheme={masterTheme}
                    theme={theme}
                    getAuthToken={getAuthToken}
                    bookId={context.bookID}
                    handleGAEvents={handleGAEvents}
                  />
                </div>
              )
            }
            { showHistory && <div id="blurEffect" onClick={toggleHistory} className={`${classes.blurEffect} ${historyDrawerAnimationClass}`}></div> }
            
            {showFeedbackMessagePopup &&
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleClickAway}
              >
                <FeedbackSubmitMessage
                  handleCloseFeedbackMessagePopup={handleCloseFeedbackMessagePopup}
                  masterTheme={masterTheme}
                  theme={theme}
                  isClickAway={isClickAway}
                />
              </ClickAwayListener>
            }
            {
              openLightbox && (
                <NewImageViewerPreviewComponent
                  standAloneMode
                  data={lightboxImageData}
                  locale="en"
                  onClose={handleLightboxClose}
                />
              )
            }
            <Snackbar
              open={showSnackBar.isOpen}
              autoHideDuration={showSnackBar.duration}
              onClose={handleCloseSnackBar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <SnackbarContent
                style={{
                  backgroundColor:
                    showSnackBar.message !== 'error' ? 'rgb(22,28,41)' : 'red'
                }}
                message={<span id="client-snackbar">{showSnackBar.message}</span>}
                action={(
                  <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>)}
              />
            </Snackbar>
            </React.Fragment>
          }
          </div>
        </Draggable>
      </ClickAwayListener>
        )
      }
    </div>
  );
};


Chat.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string,
  users: PropTypes.object.isRequired,
  pageList: PropTypes.array,
  context: PropTypes.object,
  handleChatGPTCustomNote: PropTypes.func,
  handleChatGPTFlashCard: PropTypes.func,
  chatGPTClose: PropTypes.func,
  isPageLoaded: PropTypes.bool,
  handleGAEvents: PropTypes.func,
  updateAITutorUserSettings: PropTypes.func,
  openBotViaContentSelector: PropTypes.bool.isRequired,
  selectedText: PropTypes.object,
  closeContentSelector: PropTypes.func,
  handleBotBackNavigation: PropTypes.func,
  topicTimeOut: PropTypes.number,
  enableStreamingAPI: PropTypes.bool,
  AITutorContext: PropTypes.object,
  getCurrentSection: PropTypes.func.isRequired,
  channelId: PropTypes.string,
  env: PropTypes.string,
  delayMessageTimerInSecs: PropTypes.number.isRequired,
  getAuthToken: PropTypes.func.isRequired,
  handleChatbotOpened: PropTypes.func.isRequired,
  enableAISleepMode: PropTypes.bool,
  showAISleepModeAB: PropTypes.bool,
  onLDTrack: PropTypes.func,
  showAIChannelVideos: PropTypes.bool,
  showSummaryQuiz: PropTypes.bool,
  initiatedPoint: PropTypes.string,
  executeCommand: PropTypes.string,
  enableProblemSolveFeature: PropTypes.bool,
  openAITutor: PropTypes.object,
  onBotClose: PropTypes.func,
  enableExplainQuizOptions: PropTypes.number,
  onCollapseABBot: PropTypes.func,
  AIChatPlaceHolderText: PropTypes.string,
  showChatBotIcon: PropTypes.bool,
  enableTopicInQuizFlow: PropTypes.bool,
  isBotOpenedFromContentSelector: PropTypes.bool,
  botInitiated: PropTypes.func,
  aitutorWelcomeScreenCohorts: PropTypes.string.isRequired,
  aiTutorAutoOpenCommands: PropTypes.object.isRequired,
  aiTutorAutoOpenCount: PropTypes.number.isRequired,
  channelsVideoEsScore: PropTypes.number,
  setAITutorAutoOpen: PropTypes.func,
  showUserHistory: PropTypes.bool,
  openBotContainer: PropTypes.bool.isRequired,
  isBotFromDrawer: PropTypes.bool.isRequired,
  isBotFromInlineContent: PropTypes.bool.isRequired,
  toggleBotContainer: PropTypes.func.isRequired,
  enableMathMLKeyboard: PropTypes.bool.isRequired,
  showStarterPrompt: PropTypes.bool,
  enableAIShortAnswer: PropTypes.bool.isRequired,
  hideChannelsPopout: PropTypes.bool,
  onPageNavigation: PropTypes.func,
  showSourceReferencePage: PropTypes.bool,
  playList: PropTypes.array,
  showOptoutLink: PropTypes.bool,
  showOptInLink: PropTypes.bool,
  updateChannelsOptIn: PropTypes.func,
  updateChannelsOptOut: PropTypes.func,
  AIEnableMarkdown: PropTypes.bool,
  imageContextData: PropTypes.object,
  AIEnableImageContext: PropTypes.bool,
  showSummary: PropTypes.bool,
  showPractice: PropTypes.bool,
  disableDrag: PropTypes.bool,
  showRecommendedPrompts: PropTypes.bool,
  enableNewMathConfig: PropTypes.bool,
  showImageCopilot: PropTypes.bool,
  tutorName: PropTypes.string,
  welcomeMessage: PropTypes.string,
  expandMode: PropTypes.bool
};

Chat.defaultProps = {
  theme: 'default',
  masterTheme: 'plus',
  pageList: [],
  handleChatGPTCustomNote: () => { },
  handleChatGPTFlashCard: () => { },
  chatGPTClose: () => { },
  updateAITutorUserSettings: () => { },
  handleBotBackNavigation: () => { },
  handleGAEvents: () => {},
  closeContentSelector: () => {},
  setAITutorAutoOpen: () => {},
  selectedText: {},
  topicTimeOut: 2000,
  isPageLoaded: false,
  AITutorContext: {
    enableFreeResponse: true,
    enableParagraph: false
  },
  delayMessageTimerInSecs: 60000,
  onLDTrack: () => {},
  channelId: '',
  env: '',
  showAIChannelVideos: false,
  showSummaryQuiz: false,
  initiatedPoint: '',
  executeCommand: '',
  enableStreamingAPI: true,
  enableProblemSolveFeature: false,
  openAITutor: {isOpen: false, eventData: {utm_campaign:'',utm_source:''}},
  onBotClose: () => {},
  enableExplainQuizOptions: 1,
  onCollapseABBot: () => {},
  AIChatPlaceHolderText: '',
  showChatBotIcon : true,
  enableTopicInQuizFlow: false,
  isBotOpenedFromContentSelector: false,
  channelsVideoEsScore: '',
  botInitiated: () => {},
  showUserHistory: false,
  showStarterPrompt: false,
  hideChannelsPopout: false,
  onPageNavigation: () => {},
  showSourceReferencePage: false,
  playList: [],
  showOptoutLink: false,
  showOptInLink: false,
  updateChannelsOptIn: () => {},
  updateChannelsOptOut: () => {},
  AIEnableMarkdown: false,
  imageContextData: {},
  AIEnableImageContext: false,
  showSummary: true,
  showPractice: true,
  disableDrag: false,
  showRecommendedPrompts: false,
  enableNewMathConfig: false,
  showImageCopilot: false,
  enableAISleepMode: false,
  showAISleepModeAB: false,
  tutorName: '',
  welcomeMessage: '',
  expandMode: false
};

export default memo(withStyles(ChatComponentStyles)(injectIntl(Chat)));
