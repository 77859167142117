import React from 'react';
import { shapes } from '@greenville/framework';
import { PropTypes } from 'prop-types';
import { Typography } from '@mui/material';
import SubTypeResponse from '../../../models/SubTypeModel';
import ExpandableCard from './ExpandableCard';
import * as constants from '../../../../../common/constants';
/* eslint-disable */
const DynamicPromt = ({ isFeedback, dataValue, filterPromt, renderResponsePrompts, ChatUtilitySubTypeResponse }) => {
  const { SubTypeData } = ChatUtilitySubTypeResponse;
  return (
    <>
      {!isFeedback && dataValue && (
        <ExpandableCard
          header={constants.CHAT_PROMPT_CONTENT}
          body={
            <>
              {dataValue &&
                dataValue.content &&
                Array.isArray(dataValue.content) &&
                dataValue.content.map((val, index) => (
                  <div style={{ margin: '8px 0' }} key={index}>
                    {dataValue.type === 'summary' ? <Typography variant="h6">Chunk {index + 1}</Typography> : ''}
                    <Typography variant="body2">{val}</Typography>
                  </div>
                ))}

              {dataValue && dataValue.content && dataValue.content.length === 0 ? (
                <Typography variant="body2">
                  There is no data {dataValue.type === 'answer' ? 'context' : 'content'} available for this record.
                </Typography>
              ) : (
                <Typography variant="body2">{dataValue.content}</Typography>
              )}
              <br />

              {renderResponsePrompts()}

              {filterPromt.includes(dataValue.type) && dataValue.prompt && typeof dataValue.prompt === 'object' ? (
                dataValue.prompt.map((data) => {
                  return (
                    <>
                      <Typography variant="body2" fontWeight="bold" textAlign="center">
                        {`Role: ${data.role}`}
                      </Typography>

                      {data.prompt && (
                        <>
                          <Typography variant="body2" fontWeight="bold">
                            {constants.CHAT_PROMPT}:
                          </Typography>
                          <Typography variant="body2">{data.prompt.toString().replace(/\n/g, '\\n')}</Typography>
                          <br />
                        </>
                      )}

                      {data.safety && (
                        <>
                          <Typography variant="body2" fontWeight="bold">
                            {constants.CHAT_SAFETY}:
                          </Typography>
                          <Typography variant="body2">{data.safety}</Typography>
                          <br />
                        </>
                      )}

                      {data.userTopic && (
                        <>
                          <Typography variant="body2" fontWeight="bold">
                            {constants.CHAT_USERTOPIC}:
                          </Typography>
                          <Typography variant="body2">{data.userTopic}</Typography>
                          <br />
                        </>
                      )}
                    </>
                  );
                })
              ) : (
                <Typography variant="body2">
                  {dataValue && dataValue.prompt && dataValue.prompt.toString().replace(/\n/g, '\\n')}
                </Typography>
              )}
            </>
          }
        />
      )}
      {SubTypeData &&
        SubTypeData.map((typeData) => {
          if (
            typeData &&
            (typeData.type === constants.PROBLEM_SOLVE_VERIFIER ||
              typeData.type === constants.PROBLEM_SOLVE_RECTIFIER ||
              typeData.type === constants.PROBLEM_SOLVE)
          ) {
            return (
              <ExpandableCard
                maxHeight="300px"
                header={`${constants.CHAT_PROMPT_CONTENT} - Problem Solve ${
                  typeData.type === constants.PROBLEM_SOLVE_VERIFIER
                    ? 'Verifier'
                    : typeData.type === constants.PROBLEM_SOLVE_RECTIFIER
                    ? 'Rectifier'
                    : ''
                }`}
                body={
                  <>
                    {typeData.prompt &&
                      typeof typeData.prompt === 'object' &&
                      typeData.prompt.map((data) => {
                        return (
                          <div style={{ marginBottom: '16px' }}>
                            <Typography variant="body2" fontWeight="bold" textAlign="center">
                              {`Role: ${data.role}`}
                            </Typography>
                            {data.prompt && (
                              <>
                                <Typography variant="body2" fontWeight="bold">
                                  {constants.CHAT_PROMPT}:
                                </Typography>
                                <Typography variant="body2">{data.prompt.toString().replace(/\n/g, '\\n')}</Typography>
                              </>
                            )}
                          </div>
                        );
                      })}
                  </>
                }
              />
            );
          }
          return <></>;
        })}
    </>
  );
};

DynamicPromt.propTypes = {
  isFeedback: PropTypes.bool.isRequired,
  dataValue: PropTypes.object,
  filterPromt: PropTypes.object,
  renderResponsePrompts: PropTypes.func.isRequired,
  ChatUtilitySubTypeResponse: shapes.modelOf(SubTypeResponse).isRequired
};

DynamicPromt.defaultProps = {
  filterPromt: [],
  dataValue: {}
};

export default DynamicPromt;
