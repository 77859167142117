/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Grid, Typography, withStyles, Paper, styled, Box, Button, Select, MenuItem } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import { TableOfContentsComponent } from '@vega/drawer';
import _, { parseInt } from 'lodash';
import { inject, observer } from 'mobx-react';
import Framework, { shapes } from '@greenville/framework';
import { Switch, Route } from 'react-router-dom';
import { Rating, TextField } from '@mui/material';
import { toJS } from 'mobx';
import { FormattedMessage } from 'react-intl';
import McqEditQuestionDialog from './tocdialogues/McqEditQuestionDialog';
import SummaryEditDialog from './tocdialogues/SummaryEditDialog';
import TocAddDialog from './tocdialogues/TocAddDialog';
import McqDialogConfirms from './tocdialogues/McqDialogConfirms';
// import Loader from '../../../common/Loader';
import Toc from '../models/Toc';
import Summary from '../models/Summary';
import FileData from '../models/FileData';
import useDebounce from '../common/components/UseDebounce';
import ValidatePermissions from '../../users/ValidatePermissions';
import * as constants from '../../../common/constants';
import UsersPermissions from '../../users/models/UsersPermissions';
import TooltipCustom from '../../../common/components/TooltipCustom';
import LinkMenu from '../../../common/components/linkMenu';
import utils from '../../../common/utils';
import MathJaxWrapper from '../common/components/MathJaxWrapper';
import MathJaxRender from '../common/components/MathJaxRender';

const styles = () => ({
  tocGrid: {
    height: '80vh',
    overflowY: 'auto'
  },
  quizGrid: {
    paddingLeft: '1.5rem',
    height: '78vh',
    overflowY: 'auto'
  },
  feedbackTextStyle: {
    padding: '20px'
  },
  tocHeading: {
    fontSize: 'large',
    fontWeight: 550,
    paddingTop: '0.5rem'
  },
  chapterHeading: {
    fontSize: 'large',
    fontWeight: 550
  },
  conceptHeading: {
    paddingTop: '0.7rem',
    fontWeight: 600
  },
  quesInfo: {
    fontSize: '0.8rem'
  },
  sumInfo: {
    fontSize: '0.8rem'
  },
  ques: {
    fontSize: '1rem',
    fontWeight: 500
  },
  quesOptions: {
    fontSize: '0.9rem',
    fontWeight: 400
  },
  feedback: {
    fontSize: '0.8rem',
    padding: '0.5rem 0rem 0.5rem 1rem'
  },
  buttons: {
    padding: '5px 0px',
    marginRight: '0.5rem'
  },
  lastText: {
    color: 'green'
  },
  lastGreenText: {
    color: 'red'
  },
  visHidden: {
    visibility: 'hidden'
  },
  textHeading: {
    textAlign: 'center'
  }
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: '0px',
  padding: '0px',
  border: '1px solid black'
}));

const breadcrumbStyles = {
  color: '#252525',
  fontSize: '16px',
  fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
  fontWeight: '400',
  lineHeight: '1.25'
};

const activeBreadcrumbStyles = {
  fontWeight: 'bold',
  color: '#252525'
};

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    height: '2rem',
    fieldalignItems: 'center'
  },
  '& .MuiInputBase-input': { padding: '0.5rem 0', textAlign: 'center', appearance: 'none' }
});

let isExpanded = false;
let pageInfo = '';
let currentPageNumber = 0;

const TOCDisplay = React.memo((props) => {
  const showDashboardEnabled = false;
  const [open, setOpen] = useState(false);
  const [openAdd, setAddOpen] = useState(false);
  const {
    classes,
    chatgptTocUtility,
    chatgptSummaryUtility,
    assetTOC,
    tocBookId,
    userPermissions,
    fileData,
    language,
    viewType
  } = props;
  const mathBlock = useRef(null);
  const initialPageId = assetTOC.getTocJSON().content.list[0].id;
  const [currentPageId, setCurrentPageId] = useState(initialPageId);
  const [productId, setProductId] = useState('');
  const [chapterTitle, setChapterTitle] = useState('');
  const [summaryChapterTitle, setSummaryChapterTitle] = useState('');
  const [newPageID, setNewPageId] = useState('');
  const [sectionPageIds, setPageIds] = useState([]);
  const { data } = chatgptTocUtility;
  const [difficultyLevel, setDifficultyLevel] = useState(1);
  const [titleCommon, setTitleCommon] = useState('');
  const [actionType, setActionType] = useState('');
  // const [loaderStatus] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSummaryParagraph, setIsSummaryParagraph] = useState(false);
  const [summaryParagraph, setSummaryParagraph] = useState({});
  const [questionData, setQuestionData] = React.useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [totalQuestionNumber, setTotalQuestionNumber] = useState(0);
  const [totalSummaryNumbers, setTotalSummaryNumbers] = useState(0);
  const [summaryData, setSummaryData] = useState({});
  const permissionsList = toJS(userPermissions.permissionList.permissions);
  const [permissionName, setPermissionName] = useState('');
  const [lifeCycleStatusList, setLifeCycleStatusList] = useState([]);
  const [questionArrayList, setQuestionArrayList] = useState([]);
  const [goToQuestionNumber, setGoToQuestionNumber] = useState(1);
  const debouncedValue = useDebounce(goToQuestionNumber, 300);

  useEffect(() => {
    if (!window.MathJax) {
      utils.loadMathJaxV3();
    }
  }, []);

  useEffect(() => {
    // chatgptTocUtility.fetch({ bookId: '2NFUJ4O6CN', sourceId: 'a08bc2f394d8577ffb0677a1b54e352a78f84a3cb' });
    Framework.getEventManager().on(constants.SET_CHATGPT_TOC_DATA, () => {
      if (data && data.length > 0) {
        let indexValue = goToQuestionNumber - 1;
        if (currentPageNumber < data.length) {
          indexValue = currentPageNumber;
        } else {
          currentPageNumber = indexValue;
        }
        const statusList = data.reduce((previous, current) => {
          return [...previous, current.lifeCycleStatus];
        }, []);
        const sortedData = data.sort((item1, item2) => item1?.questionId < item2?.questionId);
        setQuestionArrayList(sortedData);
        setLifeCycleStatusList([...new Set(statusList)]);
        setQuestionData(sortedData[indexValue]);
        setTotalQuestionNumber(sortedData.length);
        setGoToQuestionNumber(indexValue + 1);
        setPageNumber(indexValue);
        const quesDifficultyLevel = sortedData[indexValue]?.difficultyLevel;
        setDifficultyLevel(quesDifficultyLevel);
      } else {
        setQuestionData({});
      }
    });
    Framework.getEventManager().on(constants.SET_UPDATE_MCQ_QUESTION_DATA, () => {
      chatgptTocUtility.fetch({ bookId: tocBookId, sourceId: pageInfo });
    });
  }, [chatgptTocUtility]);

  useEffect(() => {
    const unsubscribeSummary = Framework.getEventManager().on(constants.GET_CHATGPT_SUMMARY_DATA, () => {
      if (chatgptSummaryUtility && chatgptSummaryUtility.data && chatgptSummaryUtility.data.length) {
        const summaryTextData = chatgptSummaryUtility.data.filter((sumText) => sumText.type === 'bullet');
        // const summaryTextData = chatgptSummaryUtility.data;
        // setTotalSummaryNumbers(summaryTextData.length);
        setSummaryData(summaryTextData);
      }
    });
    return () => {
      unsubscribeSummary();
    };
  }, [chatgptSummaryUtility, viewType]);

  useEffect(() => {
    const pageNumberConversion = parseInt(goToQuestionNumber);
    let pageNumberValue = '';
    if (
      debouncedValue !== '' &&
      pageNumberConversion > 0 &&
      pageNumberConversion <= questionArrayList?.length &&
      questionArrayList[pageNumberConversion - 1]
    ) {
      setQuestionData(questionArrayList[pageNumberConversion - 1]);
      setDifficultyLevel(questionArrayList[pageNumberConversion - 1]?.difficultyLevel);
      pageNumberValue = pageNumberConversion;
    } else if (pageNumberConversion > questionArrayList?.length && questionArrayList?.length > 0) {
      setQuestionData(questionArrayList[questionArrayList?.length - 1]);
      setDifficultyLevel(questionArrayList[questionArrayList?.length - 1]?.difficultyLevel);
      pageNumberValue = questionArrayList?.length;
    } else if (pageNumberConversion <= 0 && questionArrayList?.length > 0) {
      setQuestionData(questionArrayList[0]);
      setDifficultyLevel(questionArrayList[0]?.difficultyLevel);
      pageNumberValue = 1;
    }
    if (pageNumberValue) {
      setGoToQuestionNumber(pageNumberValue);
      setPageNumber(pageNumberValue - 1);
      currentPageNumber = pageNumberValue - 1;
    }
  }, [debouncedValue]);

  const handleSummaryData = (testdata) => {
    setSummaryData(testdata);
  };

  const handlePageChange = (event) => {
    const { value } = event.target;
    setGoToQuestionNumber(value);
  };

  const bookDetails = {
    bookId: tocBookId,
    sourceId: currentPageId,
    productDataId: productId,
    productPageId: newPageID
  };

  const handleBreadcrumbClick = (path) => {
    Framework.redirectTo(path);
  };

  const homeIcon = (
    <>
      <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.HOME')} />}>
        <IconButton
          size="large"
          color="primary"
          aria-label="HOME"
          onClick={() => handleBreadcrumbClick('/chatgptutility')}
        >
          <HomeIcon sx={{ fontSize: '30px' }} />
        </IconButton>
      </TooltipCustom>
    </>
  );
  const paths = [{ title: '/  Titlesearch', path: '/chatgptutility/aistudytools/titlesearch' }, { title: 'view' }];

  const handleOpen = () => {
    setOpen(true);
    Framework.redirectTo(`/chatgptutility/aistudytools/mcq/edit/${tocBookId}`);
  };
  const handleSummaryEditOpen = () => {
    setOpen(true);
    Framework.redirectTo(`/chatgptutility/aistudytools/summary/edit/${tocBookId}`);
  };

  const handleSummaryClose = () => {
    pageInfo = currentPageId;
    setOpen(false);
    Framework.redirectTo(`/chatgptutility/aistudytools/summary/view/${tocBookId}`);
  };
  const handleClose = () => {
    pageInfo = currentPageId;
    setOpen(false);
    Framework.redirectTo(`/chatgptutility/aistudytools/mcq/view/${tocBookId}`);
  };

  const handleAddOpen = () => {
    Framework.redirectTo(`/chatgptutility/aistudytools/mcq/add/${tocBookId}`);
    setAddOpen(true);
  };

  const handleADDClose = () => {
    pageInfo = currentPageId;
    setAddOpen(false);
    // Framework.redirectTo('/chatgptutility/mcq/view');
    Framework.redirectTo(`/chatgptutility/aistudytools/mcq/view/${tocBookId}`);
  };

  const handleDelete = (value, type) => {
    if (value) {
      setTitleCommon('Are you sure you want to delete?');
      setDialogOpen(true);
      setActionType(type);
    }
  };

  const handlePublish = (value, type) => {
    if (value) {
      if (type === 'publish') {
        setTitleCommon('Are you sure you want to publish?');
      } else {
        setTitleCommon(`Are you sure you want to publish all book ${viewType}?`);
      }
      setDialogOpen(true);
      setActionType(type);
    }
  };

  const handleUnPublish = (value, type) => {
    if (value) {
      setTitleCommon(constants.GPT_UNPUBLISH_DIALOG_TEXT);
      setDialogOpen(true);
      setActionType(type);
    }
  };

  const handleNextClick = () => {
    if (pageNumber < totalQuestionNumber || (viewType === 'summary' && pageNumber < 1)) {
      setPageNumber(pageNumber + 1);
      setGoToQuestionNumber(goToQuestionNumber + 1);
      currentPageNumber = pageNumber + 1;
      if (viewType === 'quiz') {
        setQuestionData(questionArrayList[pageNumber + 1]);
        setDifficultyLevel(questionArrayList[pageNumber + 1].difficultyLevel);
      } else {
        setSummaryData(chatgptSummaryUtility.data[pageNumber + 1]);
      }
    }
  };

  const handlePreviousClick = () => {
    if (pageNumber > 0) {
      currentPageNumber = pageNumber - 1;
      setPageNumber(pageNumber - 1);
      setGoToQuestionNumber(goToQuestionNumber - 1);
      if (viewType === 'quiz') {
        setQuestionData(questionArrayList[pageNumber - 1]);
        setDifficultyLevel(questionArrayList[pageNumber - 1].difficultyLevel);
      } else {
        setSummaryData(chatgptSummaryUtility.data[pageNumber - 1]);
      }
    }
  };

  const viewStatus = (status) => {
    let content = '';
    switch (status) {
      case 'published':
        content = 'Live in Production';
        break;
      case 'draft':
        content = 'DRAFT';
        break;
      case 'review':
        content = 'REVIEW';
        break;
      default:
        content = '';
    }
    return content;
  };

  const handleClosedialog = () => {
    setDialogOpen(false);
  };

  const checkMathJax = (mathJaxData) => {
    let response = mathJaxData;
    if (!_.isEmpty(response)) {
      if (utils.containsMarkDownText(response)) {
        response = utils.convertToMarkedDownString(response);
      }
      if (response.includes('`') || response.includes('</math>') || response.includes('\\')) {
        const regesXmlns = /<math([^>]*)>/g;
        const res = utils.containsMathML(response)
          ? response.replace(regesXmlns, '<math xmlns="http://www.w3.org/1998/Math/MathML">')
          : response;
        return (
          <MathJaxWrapper>
            {/* eslint-disable */}
            <span dangerouslySetInnerHTML={{ __html: res }} />
            {/* eslint-disable */}
          </MathJaxWrapper>
        );
      }
    }
    return (
      <>
        {/* eslint-disable-next-line */}
        <span dangerouslySetInnerHTML={{ __html: response }} />
      </>
    );
  };

  const changeToFirst = (text, choices) => {
    let convertedText;
    let words = text?.split(' ');
    let appendText = null;

    if (
      words &&
      (words[0].toLowerCase() === 'correct' ||
        words[0].toLowerCase() === 'incorrect' ||
        words[0].toLowerCase() === 'correct!' ||
        words[0].toLowerCase() === 'incorrect.')
    ) {
      words = words.slice(1);
    }
    if (choices && choices.toLowerCase() === 'yes') {
      appendText = <span className={classes.lastText}>(Correct!)</span>;
    } else {
      appendText = <span className={classes.lastGreenText}>(Incorrect)</span>;
    }

    if (words) {
      const wordsString = words.join(' ');
      convertedText = (
        <span style={{ display: 'inline-block' }}>
          <MathJaxRender content={wordsString} />
        </span>
      );
    } else {
      convertedText = (
        <span style={{ display: 'inline-block' }}>
          <MathJaxRender content={text} />
        </span>
      );
    }

    return (
      <>
        {convertedText}
        {appendText}
      </>
    );
  };

  const handleConfirmAction = (confirmed) => {
    if (confirmed) {
      const { bookId, sourceId, questionId, productModel } = questionData;
      if (questionData && actionType === 'publish') {
        chatgptTocUtility.updateMcqQuestionStatus({
          bookId,
          sourceId,
          questionId,
          productModel,
          lifeCycleStatus: constants.PUBLISHED_STATUS
        });
      } else if (questionData && actionType === 'un-publish') {
        chatgptTocUtility.updateMcqQuestionStatus({
          bookId,
          sourceId,
          questionId,
          productModel,
          lifeCycleStatus: 'DRAFT'
        });
      } else if (bookId && actionType === 'publishAll') {
        chatgptTocUtility.updateAllMcqQuestionStatus({
          bookId,
          viewType,
          lifeCycleStatus: constants.PUBLISHED_ALL_STATUS
        });
      } else {
        chatgptTocUtility.DeleteMcqQuestion({
          bookId,
          sourceId,
          questionId,
          lifeCycleStatus: constants.DELETED_STATUS,
          productModel
        });
      }
    }
  };
  const handleSummaryConfirmAction = async (confirmed) => {
    if (summaryData && confirmed) {
      const { bookId, pageId, productModel } = summaryData;
      if (actionType === 'publish' || actionType === 'delete') {
        await chatgptSummaryUtility.updateSummaryStatus({
          bookId,
          pageId,
          productModel,
          lifeCycleStatus: actionType === 'publish' ? constants.PUBLISHED_STATUS : constants.DELETED_STATUS
        });
        handleSummaryData({
          ...summaryData,
          lifeCycleStatus: actionType === 'publish' ? constants.PUBLISHED_STATUS : constants.DELETED_STATUS
        });
      } else if (bookId && actionType === 'publishAll') {
        await chatgptSummaryUtility.updatePublishAllSummaryStatus({
          bookId,
          viewType,
          lifeCycleStatus: constants.PUBLISHED_ALL_STATUS
        });
      } else if (summaryData && actionType === 'un-publish') {
        await chatgptSummaryUtility.updateSummaryStatus({
          bookId,
          pageId,
          productModel,
          lifeCycleStatus: 'DRAFT'
        });
      }
    }
  };

  const flattenToc = (tocDataList) => {
    const tocFlatten = [];

    const tocMapper = (tocList) => {
      tocList.forEach((toc) => {
        tocFlatten.push(toc.id);
        if (toc.children) {
          tocMapper(toc.children);
        }
      });
    };
    tocMapper(tocDataList);

    return tocFlatten;
  };
  const handleOnTocClick = (id) => {
    if (currentPageId !== id) {
      currentPageNumber = 0;
    }
    let isChapter = false;
    setNewPageId(id);
    setCurrentPageId(id);
    // console.log('Page Id', id);
    let pageIds = [];
    let currentChapter = '';
    // console.log('pageids', pageIds[0]);
    const tocChildren = assetTOC.getTocJSON().content.list;
    const tocDrillToDeep = (toc, parentElement) => {
      toc.map((list) => {
        setProductId(list.chapterId);
        if (list.id === id) {
          currentChapter = list.title;
          setChapterTitle(currentChapter);
          pageIds.push(list.id);
          if (list.children && list.children.length && list.type === 'chapter') {
            isChapter = true;
            pageIds = [...pageIds, ...flattenToc(list.children)];
          } else if (list.children && list.children.length && (list.type === 'module' || list.type === 'submodule')) {
            list.children.map((child) => {
              if (child.type === 'slate') {
                pageIds.push(child.id);
              }
            });
          } else if (list.type === 'slate') {
            pageIds.push(parentElement.id);
            parentElement.children.map((child) => {
              if (child.type === 'slate') {
                pageIds.push(child.id);
              }
            });
          }
        } else if (list.id !== id && list.children && list.children.length) {
          tocDrillToDeep(list.children, list);
        }
      });
    };
    tocDrillToDeep(tocChildren);
    // console.log('pageIds', pageIds);
    setPageIds(pageIds);
    if (!isChapter) {
      pageInfo = id;
      if (viewType === 'quiz') {
        chatgptTocUtility.fetch({ bookId: tocBookId, sourceId: id });
      } else if (viewType === 'summary') {
        chatgptSummaryUtility.fetch({ bookId: tocBookId, pageId: id });
      }
    }
    Framework.getEventManager().on(constants.SET_UPDATE_SUMMARY_DATA, () => {
      chatgptSummaryUtility.fetch({ bookId: tocBookId, pageId: pageInfo });
    });
    Framework.getEventManager().on(constants.SET_SUMMARY_DATA, () => {
      if (chatgptSummaryUtility && chatgptSummaryUtility.data && chatgptSummaryUtility.data.length) {
        const summaryTextData = chatgptSummaryUtility.data.filter((sumText) => sumText.type === 'bullet');
        chatgptSummaryUtility.data.forEach((sumParagraph) => {
          if (sumParagraph.type === 'paragraph') {
            setIsSummaryParagraph(true);
            setSummaryParagraph(sumParagraph);
          }
        });
        /*         const paraTextData = chatgptSummaryUtility.data.filter((sumText) => sumText.type === 'paragraph');
        if (paraTextData.length === 0) {
          setIsSummaryParagraph(false);
        } */

        setTotalSummaryNumbers(summaryTextData.length);
        setSummaryData({ ...summaryTextData[pageNumber] });
        setSummaryChapterTitle(currentChapter);
      } else {
        setSummaryData({});
        setSummaryParagraph({});
      }
    });
    /*     Framework.getEventManager().on(constants.SET_CHATGPT_TOC_DATA, () => {
      if (data && data.length > 0) {
        const statusList = data.reduce((previous, current) => {
          return [...previous, current.lifeCycleStatus];
        }, []);
        setQuestionArrayList(data);
        setLifeCycleStatusList([...new Set(statusList)]);
        setQuestionData(data[pageNumber]);
        setPageNumber(pageNumber);
        setTotalQuestionNumber(data.length);
        const difficultyLevel = data[pageNumber]?.difficultyLevel;
        setDifficultyLevel(difficultyLevel);
      }
    }); */
    // return pageIds;
  }; // TODO

  const tocTraverse = (tocList, pageId) => {
    const pageIdRequested = currentPageId;

    tocList.forEach((listItem) => {
      if (listItem.id === pageId) {
        if (listItem.id === pageIdRequested) {
          isExpanded = true;
        } else if (listItem.children && listItem.children.length > 0) {
          tocTraverse(listItem.children, pageIdRequested);
        }
      } else if (listItem.children && listItem.children.length > 0) {
        tocTraverse(listItem.children, pageId);
      }

      return isExpanded;
    });
  };

  const handleTocExpand = (res, pageId, boolValue, expandToc) => {
    const { list } = assetTOC.getTocJSON().content;

    isExpanded = currentPageId === pageId;
    if (!isExpanded) {
      tocTraverse(list, pageId);
    }
    if (expandToc) {
      expandToc(isExpanded);
    }
  };

  const DrawerCallbacks = {
    changeState: handleOnTocClick,
    onActive: handleOnTocClick
  }; // TODO
  const tocCompData = {
    separateToggleIcon: true,
    data: assetTOC.getTocJSON(),
    depth: 4,
    childField: 'children',
    isTocWrapperRequired: false,
    clickTocHandler: handleOnTocClick,
    handleTocExpand
  }; // TODO Need to get the data dynamically once API integration done

  const handleMCQDropDownChange = (event) => {
    const value = event?.target?.value;
    if (value) {
      const filterMatchedValue = data.filter((item) => item.lifeCycleStatus === value);
      setQuestionArrayList(filterMatchedValue);
      setQuestionData(filterMatchedValue[0]);
      setPageNumber(0);
      setTotalQuestionNumber(filterMatchedValue.length);
      const difficultyLevelData = filterMatchedValue[0]?.difficultyLevel;
      setDifficultyLevel(difficultyLevelData);
    }
  };

  const themeObject = {
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },
    visHidden: {
      visibility: 'hidden'
    },
    direction: 'ltr',
    mixins: {
      toolbar: {
        minHeight: 56,
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: 48
        },
        '@media (min-width:600px)': {
          minHeight: 64
        }
      }
    },
    overrides: {
      MuiAppBar: {
        root: {
          maxWidth: 4000
        },
        colorPrimary: {
          backgroundColor: '#ffffff',
          color: '#252525',
          border: '0 none'
        },
        colorSecondary: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          color: '#ffffff',
          border: '0 none'
        },
        colorDefault: {
          backgroundColor: '#ffffff',
          color: '#252525',
          border: '0 none'
        }
      },
      MuiAvatar: {
        root: {
          height: 48,
          width: 48,
          fontSize: '1rem',
          fontWeight: 'bold',
          boxSizing: 'border-box'
        },
        colorDefault: {
          backgroundColor: '#6a7070',

          color: '#ffffff',

          border: '0px solid #6a7070'
        }
      },
      MuiButton: {
        root: {
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 1.25,
          textTransform: 'none'
        },
        label: {
          minWidth: 104
        },
        text: {
          padding: 0,
          textAlign: 'inherit',
          border: '0px',
          '&:hover': {
            backgroundColor: 'inherit'
          },
          '&$focusVisible': {
            '&:after': {
              content: '""',
              boxSizing: 'content-box',
              position: 'absolute',
              top: -6,
              left: -6,
              borderRadius: 1,
              width: 'calc(100% + 8px)',
              height: 'calc(100% + 8px)',
              border: '2px solid #1977D4'
            }
          }
        },
        outlined: {
          padding: '7px 17px',
          boxShadow: 'none',
          borderColor: '#6a7070',
          color: '#6a7070',
          backgroundColor: 'transparent',
          borderRadius: 22,
          '&:hover': {
            color: '#252525',
            backgroundColor: 'transparent',
            borderColor: '#252525'
          },
          '&$disabled': {
            border: '1px solid',
            backgroundColor: '#e9e9e9',
            borderColor: '#e9e9e9'
          }
        },
        textPrimary: {
          color: '#f5f5f5',
          backgroundColor: '#ca4644',
          '&:hover': {
            color: '#ffffff',
            backgroundColor: '#ca4644'
          }
        },
        outlinedPrimary: {
          color: '#ffffff',
          backgroundColor: '#005d83',
          borderColor: '#005d83',
          '&:hover': {
            color: '#ffffff',
            borderColor: '#003558',
            backgroundColor: '#003558'
          },
          '&$disabled': {
            opacity: 0.5,
            backgroundColor: '#005d83',
            border: 'solid 1px rgba(151, 151, 151, 0.07)',
            color: '#ffffff'
          }
        },
        outlinedSecondary: {
          color: '#047a9c',
          borderColor: '#ffffff',
          backgroundColor: '#ffffff',
          '&$focusVisible': {
            '&:after': {
              content: '""',
              boxSizing: 'content-box',
              position: 'absolute',
              top: -6,
              left: -6,
              borderRadius: 36,
              width: 'calc(100% + 8px)',
              height: 'calc(100% + 8px)',
              border: '2px solid #31bbf5'
            }
          },
          '&:hover': {
            backgroundColor: '#ffffff',
            boxShadow: '0 6px 10px 0 rgba(37, 37, 37, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.5)',
            color: '#003558'
          },
          '&:active': {
            backgroundColor: '#f5f5f5',
            boxShadow: 'none'
          }
        },
        contained: {
          padding: '7px 20px',
          fontSize: 14,
          lineHeight: '18px',
          fontWeight: 600,
          borderRadius: 22,
          color: '#252525',
          backgroundColor: '#ffb81c',
          boxShadow: '0 5px 22px 4px rgba(0, 0, 0, 0.03), 0 7px 8px -4px rgba(0, 0, 0, 0.05)',
          '&:hover': {
            backgroundColor: '#ff9b19',
            color: '#252525'
          },
          '&:active': {
            boxShadow: 'none'
          }
        },
        containedPrimary: {
          color: '#f5f5f5',
          backgroundColor: '#ca4644',
          '&:hover': {
            backgroundColor: '#ca4644',
            color: '#ffffff'
          }
        },
        sizeSmall: {
          padding: '5px 24px',
          fontSize: '0.875rem',
          lineHeight: '20px',
          '& span': {
            minWidth: 80
          }
        },
        sizeLarge: {
          padding: '9px 24px',
          fontSize: '16px',
          lineHeight: '20px',
          borderRadius: 32
        }
      },
      MuiButtonBase: {
        root: {
          border: '2px solid rgba(0, 0, 0, 0)',
          '&$focusVisible': {
            '&:after': {
              content: '""',
              boxSizing: 'content-box',
              position: 'absolute',
              top: -6,
              left: -6,
              borderRadius: 36,
              width: 'calc(100% + 8px)',
              height: 'calc(100% + 8px)',
              border: '2px solid #1977D4'
            }
          }
        }
      },
      MuiDivider: {
        root: {
          backgroundColor: '#d9d9d9',
          marginTop: 16,
          marginBottom: 16
        }
      },
      MuiIconButton: {
        root: {
          padding: 6,
          color: '#6a7070',
          '&$disabled': {
            opacity: 0.5
          },
          '&.disableHover': {
            '&:hover': {
              backgroundColor: 'inherit'
            },
            '&:active': {
              backgroundColor: 'inherit'
            }
          },
          '&:hover': {
            backgroundColor: '#d9d9d9A6',
            '& .avatarHover': {
              backgroundColor: '#ffffff33',
              color: '#ffffff'
            }
          },
          '&.pmuiCustomFocusVisible': {
            backgroundColor: '#d9d9d9A6'
          }
        },
        sizeSmall: {
          padding: 4
        },
        colorSecondary: {
          '&:hover': {
            backgroundColor: '#000000CC'
          },
          '&:active': {
            backgroundColor: '#0000004D'
          },
          '&$disabled': {
            opacity: 0.5,
            color: '#ffffff'
          },
          '&.pmuiCustomFocusVisible': {
            '&.disableHover': {
              backgroundColor: 'inherit'
            },
            backgroundColor: '#000000CC',
            '&:after': {
              content: '""',
              boxSizing: 'content-box',
              position: 'absolute',
              top: -6,
              left: -6,
              borderRadius: 36,
              width: 'calc(100% + 8px)',
              height: 'calc(100% + 8px)',
              border: '2px solid #31bbf5'
            }
          }
        }
      },
      MuiListItem: {
        button: {
          '&:hover': {
            backgroundColor: 'inherit',
            '& .applyHover': {
              color: '#047a9c'
            }
          }
        }
      },
      MuiMenuItem: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
          },
          '&.pmuiCustomFocusVisible': {
            '&:after': {
              content: '""',
              boxSizing: 'content-box',
              position: 'absolute',
              top: 1,
              left: 1,
              borderRadius: 8,
              width: 'calc(100% - 6px)',
              height: 'calc(100% - 6px)',
              border: '2px solid #1977D4'
            }
          }
        }
      },
      MuiPaper: {
        root: {
          boxSizing: 'border-box',
          padding: 0,
          marginLeft: 0,
          boxShadow: '0 5px 22px 4px rgba(0, 0, 0, 0.03), 0 7px 8px -4px rgba(0, 0, 0, 0.05)',
          backgroundColor: '#ffffff'
        },
        elevation1: {
          minHeight: 100
        },
        rounded: {
          borderRadius: 8
        }
      },
      MuiPopover: {
        root: {
          borderRadius: '4px',
          padding: '24px',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.4)',
          marginTop: '10px'
        }
      },
      MuiRadio: {
        root: {
          paddingTop: 2,
          '&$checked': {
            '& + span': {
              fontWeight: 700,
              color: '#005d83'
            }
          }
        }
      },
      MuiSlider: {
        track: {
          backgroundColor: '#047a9c',
          height: 8,
          borderRadius: 16,
          '$vertical &': {
            width: 8
          }
        },
        thumb: {
          backgroundColor: '#ffffff',
          border: 'solid 1px #d9d9d9',
          boxShadow: '0 5px 22px 4px rgba(0, 0, 0, 0.03), 0 7px 8px -4px rgba(0, 0, 0, 0.05)',
          width: 20,
          height: 20
        },
        rail: {
          backgroundColor: '#c7c7c7',
          height: 8,
          '$vertical &': {
            width: 8
          }
        }
      },
      MuiSvgIcon: {
        root: {
          fontSize: 18
        },
        colorPrimary: {
          fill: '#f5f5f5'
        },
        colorAction: {
          color: '#252525'
        },

        fontSizeSmall: {
          fontSize: 16
        },

        fontSizeLarge: {
          fontSize: 24
        }
      },

      MuiTab: {
        root: {
          textTransform: 'none',

          marginRight: 10,

          marginLeft: 10,

          '@media (min-width: 960px)': {
            minWidth: 'inherit'
          },

          '&$selected': {
            fontWeight: 600
          }
        },

        textColorInherit: {
          '&.pmuiCustomFocusVisible': {
            '&:after': {
              content: '""',

              boxSizing: 'content-box',

              position: 'absolute',

              top: 0,

              left: 0,

              borderRadius: 6,

              width: 'calc(100% - 4px)',

              height: 'calc(100% - 4px)',

              border: '2px solid #31bbf5'
            }
          }
        },

        textColorPrimary: {
          '&.pmuiCustomFocusVisible': {
            '&:after': {
              content: '""',

              boxSizing: 'content-box',

              position: 'absolute',

              top: 0,

              left: 0,

              borderRadius: 6,

              width: 'calc(100% - 4px)',

              height: 'calc(100% - 4px)',

              border: '2px solid #1977D4'
            }
          }
        },

        textColorSecondary: {
          color: 'rgba(255, 255, 255, 0.8)',

          lineHeight: 1.29,

          textTransform: 'uppercase',

          '&.pmuiCustomFocusVisible': {
            '&:after': {
              content: '""',

              boxSizing: 'content-box',

              position: 'absolute',

              top: 0,

              left: 0,

              borderRadius: 6,

              width: 'calc(100% - 4px)',

              height: 'calc(100% - 4px)',

              border: '2px solid #31bbf5'
            }
          }
        },

        wrapper: {
          paddingLeft: 4,

          paddingRight: 4
        }
      },

      MuiToolbar: {
        root: {
          '& button:not(:first-child)': {
            marginLeft: 18
          }
        },

        dense: {
          height: 50,

          borderBottom: '1px solid #d9d9d9'
        }
      },

      MuiTooltip: {
        tooltip: {
          position: 'relative',

          margin: 0,

          top: 0
        }
      },

      MuiTypography: {
        root: {
          lineHeight: 1.38,

          color: '#252525',

          fontSize: 16
        },

        h1: {
          fontSize: '2.3125em',

          fontWeight: 300,

          lineHeight: 1.33
        },

        h2: {
          fontSize: '1.5em',

          fontWeight: 300,

          lineHeight: 1.25
        },

        h3: {
          fontSize: '1.25em',

          fontWeight: 600,

          fontStyle: 'normal',

          lineHeight: 1.33
        },

        h4: {
          fontSize: '1.125em',

          fontWeight: 400,

          letterSpacing: 'normal',

          lineHeight: 1.33
        },

        h5: {
          fontSize: '1em',

          fontWeight: 600,

          lineHeight: 1.25,

          color: '#252525'
        },

        h6: {
          fontSize: '0.875em',

          fontWeight: 600,

          lineHeight: 1.58
        },

        subtitle1: {
          fontSize: '.75em',

          letterSpacing: 0.15,

          color: '#6a7070',

          lineHeight: 1.5
        },

        subtitle2: {
          fontSize: '.875em',

          fontWeight: 'normal',

          color: '#6a7070',

          letterSpacing: -0.3,

          lineHeight: 1.33
        },

        body1: {
          fontSize: 16,

          lineHeight: 1.25
        },

        body2: {
          fontSize: 14
        }
      },

      MuiVerticalTabs: {
        container: {
          flexGrow: 1,

          display: 'flex',

          minHeight: 224,

          height: '100%',

          padding: '20px',

          backgroundColor: '#f5f5f5'
        },

        root: {
          minWidth: 85
        },

        labelIcon: {
          '& $wrapper > *:first-child': {
            marginBottom: 9
          }
        },

        activeTab: {
          width: 58,

          backgroundColor: 'rgba(255, 255, 255, 0.1)',

          border: '1px solid #33557b',

          borderTopLeftRadius: 8,

          borderBottomLeftRadius: 8
        },

        tab: {
          color: '#ffffff',

          textAlign: 'center',

          margin: 0,

          height: 80,

          fontSize: 10,

          fontWeight: 600,

          minHeight: 60,

          opacity: 1,

          padding: 0,

          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',

            border: '1px solid #33557b',

            borderTopLeftRadius: 8,

            borderBottomLeftRadius: 8
          },

          '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',

            border: '1px solid #33557b',

            borderTopLeftRadius: 8,

            borderBottomLeftRadius: 8
          },

          '&:visited': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',

            border: '1px solid #33557b',

            borderTopLeftRadius: 8,

            borderBottomLeftRadius: 8
          }
        },

        tabContent: {
          width: '100%',

          paddingLeft: '10px'
        },

        wrapper: {
          padding: 0
        }
      }
    },

    palette: {
      common: {
        black: '#000',

        white: '#fff'
      },

      type: 'light',

      primary: {
        light: '#047a9c',

        main: '#005d83',

        dark: '#003558',

        contrastText: '#ffffff'
      },

      secondary: {
        main: '#ffffff',

        dark: '#ffffff',

        contrastText: '#047a9c',

        light: 'rgb(255, 255, 255)'
      },

      error: {
        main: '#ca4644',

        contrastText: '#f5f5f5',

        light: 'rgb(212, 107, 105)',

        dark: 'rgb(141, 49, 47)'
      },

      warning: {
        light: '#ffb74d',

        main: '#ff9800',

        dark: '#f57c00',

        contrastText: 'rgba(0, 0, 0, 0.87)'
      },

      info: {
        light: '#64b5f6',

        main: '#2196f3',

        dark: '#1976d2',

        contrastText: '#fff'
      },

      success: {
        light: '#81c784',

        main: '#4caf50',

        dark: '#388e3c',

        contrastText: 'rgba(0, 0, 0, 0.87)'
      },

      grey: {
        50: '#fafafa',

        100: '#f5f5f5',

        200: '#eeeeee',

        300: '#e0e0e0',

        400: '#bdbdbd',

        500: '#9e9e9e',

        600: '#757575',

        700: '#616161',

        800: '#424242',

        900: '#212121',

        A100: '#d5d5d5',

        A200: '#aaaaaa',

        A400: '#303030',

        A700: '#616161'
      },

      contrastThreshold: 3,

      tonalOffset: 0.2,

      text: {
        primary: '#252525',

        secondary: '#6a7070',

        disabled: '#c7c7c7',

        hint: '#252525'
      },

      divider: 'rgba(0, 0, 0, 0.12)',

      background: {
        paper: '#fff',

        default: '#fafafa'
      },

      action: {
        active: 'rgba(0, 0, 0, 0.54)',

        hover: 'rgba(0, 0, 0, 0.04)',

        hoverOpacity: 0.04,

        selected: 'rgba(0, 0, 0, 0.08)',

        selectedOpacity: 0.08,

        disabled: '#6a7070',

        disabledBackground: '#e9e9e9',

        disabledOpacity: 0.38,

        focus: 'rgba(0, 0, 0, 0.12)',

        focusOpacity: 0.12,

        activatedOpacity: 0.12
      }
    },

    props: {
      MuiButtonBase: {
        disableRipple: true,

        focusVisibleClassName: 'pmuiCustomFocusVisible'
      },

      MuiAppBar: {
        elevation: 0,

        color: 'inherit'
      },

      MuiMenuItem: {
        focusVisibleClassName: 'pmuiCustomFocusVisible'
      },

      MuiSlider: {
        trackSize: 8
      }
    },

    shadows: [
      'none',

      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',

      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',

      '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',

      '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',

      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',

      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',

      '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',

      '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',

      '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',

      '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',

      '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',

      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',

      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',

      '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',

      '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',

      '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',

      '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',

      '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',

      '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',

      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',

      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',

      '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',

      '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',

      '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
    ],

    typography: {
      htmlFontSize: 16,

      fontFamily: ['Open Sans', 'Calibri', 'Tahoma', 'sans-serif'],

      fontSize: 14,

      fontWeightLight: 300,

      fontWeightRegular: 400,

      fontWeightMedium: 500,

      fontWeightBold: 700,

      h1: {
        fontWeight: 300,

        fontSize: '6rem',

        lineHeight: 1.167
      },

      h2: {
        fontWeight: 300,

        fontSize: '3.75rem',

        lineHeight: 1.2
      },

      h3: {
        fontWeight: 400,

        fontSize: '3rem',

        lineHeight: 1.167
      },

      h4: {
        fontWeight: 400,

        fontSize: '2.125rem',

        lineHeight: 1.235
      },

      h5: {
        fontWeight: 400,

        fontSize: '1.5rem',

        lineHeight: 1.334
      },

      h6: {
        fontWeight: 500,

        fontSize: '1.25rem',

        lineHeight: 1.6
      },

      subtitle1: {
        fontWeight: 400,

        fontSize: '1rem',

        lineHeight: 1.75
      },

      subtitle2: {
        fontWeight: 500,

        fontSize: '0.875rem',

        lineHeight: 1.57
      },

      body1: {
        fontWeight: 400,

        fontSize: '1rem',

        lineHeight: 1.5
      },

      body2: {
        fontWeight: 400,

        fontSize: '0.875rem',

        lineHeight: 1.43
      },

      button: {
        fontWeight: 500,

        fontSize: '0.875rem',

        lineHeight: 1.75,

        textTransform: 'uppercase'
      },

      caption: {
        fontWeight: 400,

        fontSize: '0.75rem',

        lineHeight: 1.66
      },

      overline: {
        fontWeight: 400,

        fontSize: '0.75rem',

        lineHeight: 2.66,

        textTransform: 'uppercase'
      },

      useNextVariants: true,

      color: '#252525'
    },

    shape: {
      borderRadius: 4
    },

    transitions: {
      easing: {
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',

        easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',

        easeIn: 'cubic-bezier(0.4, 0, 1, 1)',

        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
      },

      duration: {
        shortest: 150,

        shorter: 200,

        short: 250,

        standard: 300,

        complex: 375,

        enteringScreen: 225,

        leavingScreen: 195
      }
    },

    zIndex: {
      mobileStepper: 1000,

      speedDial: 1050,

      appBar: 1100,

      drawer: 1200,

      modal: 1300,

      snackbar: 1400,

      tooltip: 1500
    },

    activeColor: 'default',

    progress: {
      color: '#047a9c',

      zIndex: 1000,

      margin: '150px 48%',

      position: 'absolute'
    },

    icons: {
      foreColor: '#252525',

      backColor: '#fff'
    },

    slider: {
      selectionColor: '#22b6b4',

      thumbSize: '15px',

      trackSize: '2px',

      trackColor: '#d9d9d9'
    },

    masterTheme: 'plus',

    color: {
      audioAnimationColor: '#F7F9FD',

      background: '#fff',

      cardBackground: '#fff',

      largeTitle: '#05112A',

      smallTitle: '#333333',

      avatarIcon: '#E8EEFA',

      divider: '#E8EEFA',

      textPrimary: '#333333',
      textSecondary: '#FEFEFE',
      subText: '#6A707C',
      activePrimary: 'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
      activePrimaryHover: 'linear-gradient(135deg, #D6492A 0%, #BA015A 63.44%, #940159 100%)',
      activeSecondary: 'linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%)',
      icon: '#05112A',
      activeIcon: '#FEFEFE',
      panelShadow: '1px 3px 10px 0px #D5DBE8A3',
      footerText: '#6D0176',
      beta: '#6D0176',
      moreMenuLink: '#6D0176'
    }
  }; // Need to keep this in separate file
  // console.log('question', questionData);
  // console.log('tocbookid', tocBookId);
  // console.log('newPageID', newPageID);

  return (
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {homeIcon}
        <LinkMenu
          paths={paths}
          separator="/"
          onClick={handleBreadcrumbClick}
          styles={breadcrumbStyles}
          activeStyle={activeBreadcrumbStyles}
        />
      </span>
      <ValidatePermissions givenPermissions="mcq_can_view" showDashboardEnabled={showDashboardEnabled}>
        <Switch>
          <Route
            path={[
              '/chatgptutility/aistudytools/mcq/view/:bookId',
              '/chatgptutility/aistudytools/summary/view/:bookId'
            ]}
            exact
            render={() => (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                  <Box display="flex" style={{ justifyContent: 'flex-end', marginBottom: '10px', width: '70%' }}>
                    <Typography variant="h5" align="center" gutterBottom spacing={9}>
                      Add/Edit {viewType.charAt(0).toUpperCase() + viewType.slice(1)}
                    </Typography>
                  </Box>
                  <Box display="flex" style={{ justifyContent: 'flex-end', width: '30%' }}>
                    <TooltipCustom
                      title={
                        !permissionsList.includes('mcq_can_publish') ? (
                          <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                        ) : (
                          <FormattedMessage {...language.getText('mcq.PUBLISHALL')} />
                        )
                      }
                    >
                      <span>
                        <Button
                          className={classes.buttons}
                          color="primary"
                          variant="outlined"
                          size="small"
                          disabled={
                            viewType === 'quiz'
                              ? Object.keys(questionData).length === 0 || !permissionsList.includes('mcq_can_publish')
                              : Object.keys(summaryData).length === 0 || !permissionsList.includes('mcq_can_publish')
                          }
                          onClick={() => handlePublish('PUBLISHALL', 'publishAll')}
                        >
                          {constants.PUBLISH_ALL}
                        </Button>
                      </span>
                    </TooltipCustom>
                  </Box>
                </Grid>
                <Grid container>
                  <Grid item xs={4} className={classes.quizGrid}>
                    <Item>
                      <MuiThemeProvider theme={createMuiTheme(themeObject)}>
                        <h3>{tocCompData.data.content.mainTitle}</h3>
                        <TableOfContentsComponent
                          selectedPanel="Contents"
                          childField={tocCompData.childField}
                          clickTocHandler={tocCompData.clickTocHandler}
                          currentPageId={currentPageId}
                          data={tocCompData.data}
                          depth={tocCompData.depth}
                          drawerCallbacks={DrawerCallbacks}
                          enableContainerClick
                          enableDisplayRestriction
                          handleTocExpand={tocCompData.handleTocExpand}
                          isTocWrapperRequired={tocCompData.isTocWrapperRequired}
                          locale="en-us"
                          separateToggleIcon={tocCompData.separateToggleIcon}
                          themeColor="#fffff"
                          showAll
                        />
                      </MuiThemeProvider>
                    </Item>
                  </Grid>
                  {viewType === 'quiz' && (
                    <Grid item xs={8} className={classes.quizGrid}>
                      {/* {Object.keys(questionData).length > 0 && ( */}
                      {questionData && Object.keys(questionData).length === 0 && (
                        <Item>
                          <Grid item xs={12}>
                            <br />
                            <br />
                            <Typography variant="h2">{constants.GPT_NOQUESTON}</Typography>
                          </Grid>
                        </Item>
                      )}
                      <Item>
                        {questionData && Object.keys(questionData).length > 0 && (
                          <Box sx={{ padding: '0.5rem 0.8rem 0rem 0.8rem' }}>
                            {/* <Typography className={classes.chapterHeading} variant="h4" color="text.secondary">
                              Chapter 3 Water and Life
                            </Typography> */}
                            <Typography
                              className={classes.conceptHeading}
                              variant="h4"
                              color="text.primary"
                              align="center"
                            >
                              {chapterTitle}
                            </Typography>
                            <br />
                            <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '10px' }}>
                              {/* <Typography className={classes.quesInfo} variant="subtitle1" color="textPrimary" align="left">
                                <b>{constants.GPT_TOTALCHAPTER}</b> :
                              </Typography> */}
                              <Typography
                                className={classes.quesInfo}
                                variant="subtitle1"
                                color="textPrimary"
                                align="left"
                              >
                                <b>{constants.GPT_TOCMODIFIED}</b> : {questionData.modifiedBy}
                              </Typography>
                              <Typography
                                className={classes.quesInfo}
                                variant="subtitle1"
                                color="textPrimary"
                                align="left"
                              >
                                <b>{constants.GPT_STATUS}</b> :{' '}
                                {/* viewStatus(questionData.lifeCycleStatus && questionData.lifeCycleStatus.toLowerCase()) */}
                                {lifeCycleStatusList && (
                                  <Select
                                    value={questionData.lifeCycleStatus}
                                    onChange={(event) => handleMCQDropDownChange(event)}
                                  >
                                    {lifeCycleStatusList.map((item) => (
                                      <MenuItem value={item}>
                                        {item === constants.PUBLISHED_STATUS ? constants.LIVE_IN_PRODUCTION : item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                              </Typography>
                              <Typography
                                className={classes.quesInfo}
                                variant="subtitle1"
                                color="textPrimary"
                                align="left"
                              >
                                <b>{constants.GPT_TOTALPAGE}</b> : {totalQuestionNumber}
                              </Typography>
                              <Typography
                                className={classes.quesInfo}
                                variant="subtitle1"
                                color="textPrimary"
                                align="left"
                              >
                                <b>{constants.GPT_LASTMODIFIED}</b> : {questionData.serverDateTime}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        {questionData && questionData.question && (
                          <Box
                            sx={{
                              border: '1px solid black',
                              margin: '1rem 0.8rem 1rem 0.8rem',
                              textAlign: 'start',
                              padding: '10px',
                              height: '40vh',
                              overflow: 'auto'
                            }}
                          >
                            <Typography className={classes.ques} variant="body2" color="text.primary">
                              <b>Question :</b>{' '}
                              <span style={{ display: 'inline-block' }}>
                                <MathJaxRender content={questionData.question.question_text} />
                              </span>
                            </Typography>
                            <Box sx={{ padding: '20px' }}>
                              {questionData &&
                                questionData.question.options &&
                                questionData.question.options.map((optionItem, index) => {
                                  const modifiedExplanation = changeToFirst(
                                    optionItem.explanation,
                                    optionItem.correct_choice
                                  );
                                  return optionItem ? (
                                    <>
                                      <Typography className={classes.quesOptions} variant="body2" color="text.primary">
                                        {index + 1}.&nbsp;{' '}
                                        <span style={{ display: 'inline-block' }}>
                                          <MathJaxRender content={optionItem.option_text} />
                                        </span>
                                      </Typography>
                                      <Typography className={classes.feedback}>
                                        <b>Feedback :</b> {modifiedExplanation}
                                      </Typography>
                                    </>
                                  ) : null;
                                })}
                            </Box>
                          </Box>
                        )}

                        {/* <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              mb: '20px',
                              mt: '20px'
                            }}
                          > */}
                        {/* {Object.keys(questionData).length > 0 && ( */}
                        {/*  <Typography
                              variant="body2"
                              style={{
                                display: 'flex',
                                visibility: Object.keys(questionData).length === 0 ? 'hidden' : null,
                                // visibility: 'hidden',
                                flexDirection: 'row',
                                marginLeft: '0.8rem'
                              }}
                            >
                              <b>{constants.GPT_DIFFICULTY}</b> :
                              <Rating
                                // sx={{ visibility: Object.keys(questionData).length > 0 ? 'hidden' : null }}
                                name="simple-controlled"
                                value={difficultyLevel}
                                readOnly
                              />
                            </Typography> */}
                        {/* )} */}
                        <Box>
                          <Stack
                            display="flex"
                            direction="row"
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                            mb="10px"
                            mt="10px"
                          >
                            {/* {Object.keys(questionData).length > 0 && ( */}
                            <div
                              style={{
                                visibility: Object.keys(questionData).length === 0 ? 'hidden' : null,
                                flexGrow: 1,
                                textAlign: 'center'
                                // marginLeft: '85px'
                              }}
                            >
                              <TooltipCustom title={<FormattedMessage {...language.getText('mcq.PREVIOUS')} />}>
                                <Button
                                  onClick={() => handlePreviousClick()}
                                  size="small"
                                  style={{ width: '50px' }}
                                  disabled={pageNumber === 0}
                                >
                                  <ChevronLeftIcon />
                                </Button>
                              </TooltipCustom>
                              <span>
                                <CustomTextField
                                  value={goToQuestionNumber}
                                  style={{ width: '50px' }}
                                  onChange={(event) => handlePageChange(event)}
                                />
                              </span>
                              {/* <span color="secondary">{pageNumber + 1}</span> */}
                              <span style={{ padding: '5px', fontSize: '20px' }}>/</span>
                              <span>
                                <CustomTextField
                                  value={totalQuestionNumber}
                                  style={{ width: '50px' }}
                                  disabled
                                  sx={{
                                    '& .MuiInputBase-root': {
                                      '& fieldset': {
                                        border: 'none'
                                      }
                                    }
                                  }}
                                />
                              </span>
                              {/* <span color="secondary"> {totalQuestionNumber}</span> */}
                              <TooltipCustom title={<FormattedMessage {...language.getText('mcq.NEXT')} />}>
                                <Button
                                  onClick={() => handleNextClick()}
                                  size="small"
                                  style={{ width: '50px' }}
                                  disabled={goToQuestionNumber >= totalQuestionNumber}
                                >
                                  <ChevronRightIcon />
                                </Button>
                              </TooltipCustom>
                            </div>

                            {/* )} */}
                            <div
                              style={{
                                textAlign: 'end'
                              }}
                            >
                              <TooltipCustom
                                title={
                                  !permissionsList.includes('mcq_can_publish') ? (
                                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                  ) : (
                                    <FormattedMessage {...language.getText('mcq.PUBLISH')} />
                                  )
                                }
                              >
                                <span>
                                  <Button
                                    className={classes.buttons}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    disabled={
                                      Object.keys(questionData).length === 0 ||
                                      !permissionsList.includes('mcq_can_publish')
                                    }
                                    onClick={() => handlePublish('PUBLISH', 'publish')}
                                  >
                                    {constants.GPT_PUBLISHALL}
                                  </Button>
                                </span>
                              </TooltipCustom>
                              <TooltipCustom
                                title={
                                  !permissionsList.includes('mcq_can_publish') ? (
                                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                  ) : (
                                    <FormattedMessage {...language.getText('mcq.UN_PUBLISH')} />
                                  )
                                }
                              >
                                <span>
                                  <Button
                                    className={classes.buttons}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    disabled={
                                      Object.keys(questionData).length === 0 ||
                                      !permissionsList.includes('mcq_can_publish')
                                    }
                                    onClick={() => handleUnPublish('UN-PUBLISH', 'un-publish')}
                                  >
                                    {constants.GPT_UN_PUBLISH}
                                  </Button>
                                </span>
                              </TooltipCustom>
                              <TooltipCustom
                                title={
                                  !permissionsList.includes('mcq_can_edit') ? (
                                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                  ) : (
                                    <FormattedMessage {...language.getText('mcq.EDIT')} />
                                  )
                                }
                              >
                                <span>
                                  <Button
                                    className={classes.buttons}
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleOpen}
                                    disabled={
                                      Object.keys(questionData).length === 0 ||
                                      !permissionsList.includes('mcq_can_edit')
                                    }
                                    size="small"
                                  >
                                    {constants.GPT_EDITQUESTION}
                                  </Button>
                                </span>
                              </TooltipCustom>
                              <TooltipCustom
                                title={
                                  !permissionsList.includes('mcq_can_add') ? (
                                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                  ) : (
                                    <FormattedMessage {...language.getText('mcq.ADD')} />
                                  )
                                }
                              >
                                <span>
                                  <Button
                                    className={classes.buttons}
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleAddOpen}
                                    size="small"
                                    disabled={!permissionsList.includes('mcq_can_add')}
                                  >
                                    {constants.GPT_ADDQUESTION}
                                  </Button>
                                </span>
                              </TooltipCustom>
                              <TooltipCustom
                                title={
                                  !permissionsList.includes('mcq_can_delete') ? (
                                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                  ) : (
                                    <FormattedMessage {...language.getText('mcq.DELETE')} />
                                  )
                                }
                              >
                                <span>
                                  <Button
                                    className={classes.buttons}
                                    color="primary"
                                    variant="contained"
                                    disabled={
                                      Object.keys(questionData).length === 0 ||
                                      !permissionsList.includes('mcq_can_edit')
                                    }
                                    onClick={() => handleDelete('DELETE', 'delete')}
                                    size="small"
                                  >
                                    {constants.GPT_DELETEQUESTION}
                                  </Button>
                                </span>
                              </TooltipCustom>
                            </div>
                            <McqDialogConfirms
                              open={dialogOpen}
                              handleClose={handleClosedialog}
                              title=""
                              content={titleCommon}
                              handleConfirm={handleConfirmAction}
                            />
                          </Stack>
                        </Box>
                        {/* </Box> */}
                      </Item>
                      {/* )} */}
                    </Grid>
                  )}
                  {viewType === 'summary' && (
                    <Grid item xs={8} className={classes.quizGrid}>
                      {/* {Object.keys(questionData).length > 0 && ( */}
                      {summaryData &&
                        Object.keys(summaryData).length === 0 &&
                        summaryParagraph &&
                        Object.keys(summaryParagraph).length === 0 && (
                          <Item>
                            <Grid item xs={12}>
                              <br />
                              <br />
                              <Typography variant="h2">{constants.GPT_NOSUMMARY}</Typography>
                            </Grid>
                          </Item>
                        )}
                      <Item>
                        {summaryData && Object.keys(summaryData).length > 0 && (
                          <Box sx={{ padding: '0.5rem 0.8rem 0rem 0.8rem' }}>
                            <Typography
                              className={classes.conceptHeading}
                              variant="h4"
                              color="text.primary"
                              align="center"
                            >
                              {summaryChapterTitle}
                            </Typography>
                            <br />
                            <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto auto', gap: '10px' }}>
                              <Typography
                                className={classes.sumInfo}
                                variant="subtitle1"
                                color="textPrimary"
                                align="left"
                              >
                                <b>{constants.GPT_TOCMODIFIED}</b> : {summaryData.modifiedBy}
                              </Typography>
                              <Typography
                                className={classes.sumInfo}
                                variant="subtitle1"
                                color="textPrimary"
                                align="left"
                              >
                                <b>{constants.GPT_STATUS}</b> :{' '}
                                {viewStatus(summaryData.lifeCycleStatus && summaryData.lifeCycleStatus.toLowerCase())}
                              </Typography>
                              <Typography
                                className={classes.sumInfo}
                                variant="subtitle1"
                                color="textPrimary"
                                align="left"
                              >
                                <b>{constants.GPT_LASTMODIFIED}</b> : {summaryData.serverDateTime}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {summaryData && summaryData.summaryText && (
                          <Box
                            sx={{
                              border: '1px solid black',
                              margin: '1rem 0.8rem 1rem 0.8rem',
                              textAlign: 'start',
                              padding: '10px'
                            }}
                          >
                            {summaryData && summaryData.summaryText && (
                              <>
                                {/* <h2 className={classes.textHeading}> Bullets </h2> */}
                                {/* eslint-disable react/no-danger */}
                                <Typography className={classes.ques} variant="body2" color="text.primary">
                                  <>
                                    <MathJaxRender content={summaryData.summaryText} />
                                  </>
                                </Typography>
                              </>
                            )}
                            <br />
                            {/* {isSummaryParagraph && summaryParagraph && summaryParagraph.summaryText && (
                              <>
                                <h2 className={classes.textHeading}> Paragaraph </h2>
                                eslint-disable react/no-danger
                                <Typography className={classes.ques} variant="body2" color="text.primary">
                                  <p dangerouslySetInnerHTML={{ __html: summaryParagraph.summaryText }} />
                                </Typography>
                              </>
                            )} */}
                          </Box>
                        )}
                        <Box>
                          <Stack
                            display="flex"
                            direction="row"
                            style={{
                              justifyContent: 'right',
                              alignItems: 'center'
                            }}
                            mb="10px"
                            mt="10px"
                          >
                            {viewType !== 'summary' && (
                              <div
                                style={{
                                  visibility: Object.keys(summaryData).length === 0 ? 'hidden' : null,
                                  flexGrow: 1,
                                  textAlign: 'center',
                                  marginLeft: '100px'
                                }}
                              >
                                <TooltipCustom title={<FormattedMessage {...language.getText('summary.PREVIOUS')} />}>
                                  <Button
                                    onClick={() => handlePreviousClick()}
                                    size="small"
                                    style={{ width: '50px' }}
                                    disabled={pageNumber === 0}
                                  >
                                    <ChevronLeftIcon />
                                  </Button>
                                </TooltipCustom>
                                <span color="secondary">{pageNumber + 1}</span> /
                                <span color="secondary"> {totalSummaryNumbers}</span>
                                <TooltipCustom title={<FormattedMessage {...language.getText('summary.NEXT')} />}>
                                  <Button
                                    onClick={() => handleNextClick()}
                                    size="small"
                                    style={{ width: '50px' }}
                                    disabled={pageNumber + 1 === totalSummaryNumbers}
                                  >
                                    <ChevronRightIcon />
                                  </Button>
                                </TooltipCustom>
                              </div>
                            )}

                            {/* )} */}
                            <div
                              style={{
                                textAlign: 'end'
                              }}
                            >
                              <TooltipCustom
                                title={
                                  !permissionsList.includes('mcq_can_publish') ? (
                                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                  ) : (
                                    <FormattedMessage {...language.getText('summary.PUBLISH')} />
                                  )
                                }
                              >
                                <span>
                                  <Button
                                    className={classes.buttons}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    disabled={
                                      Object.keys(summaryData).length === 0 ||
                                      !permissionsList.includes('mcq_can_publish')
                                    }
                                    onClick={() => handlePublish('PUBLISH', 'publish')}
                                  >
                                    {constants.GPT_PUBLISHALL}
                                  </Button>
                                </span>
                              </TooltipCustom>
                              <TooltipCustom
                                title={
                                  !permissionsList.includes('mcq_can_publish') ? (
                                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                  ) : (
                                    <FormattedMessage {...language.getText('summary.UN_PUBLISH')} />
                                  )
                                }
                              >
                                <span>
                                  <Button
                                    className={classes.buttons}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    disabled={
                                      Object.keys(summaryData).length === 0 ||
                                      !permissionsList.includes('mcq_can_publish')
                                    }
                                    onClick={() => handleUnPublish('UN-PUBLISH', 'un-publish')}
                                  >
                                    {constants.GPT_UN_PUBLISH}
                                  </Button>
                                </span>
                              </TooltipCustom>
                              <TooltipCustom
                                title={
                                  !permissionsList.includes('mcq_can_edit') ? (
                                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                  ) : (
                                    <FormattedMessage {...language.getText('summary.EDIT')} />
                                  )
                                }
                              >
                                <span>
                                  <Button
                                    className={classes.buttons}
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleSummaryEditOpen}
                                    disabled={
                                      Object.keys(summaryData).length === 0 || !permissionsList.includes('mcq_can_edit')
                                    }
                                    size="small"
                                  >
                                    {constants.GPT_EDITQUESTION}
                                  </Button>
                                </span>
                              </TooltipCustom>
                              <TooltipCustom
                                title={
                                  !permissionsList.includes('mcq_can_delete') ? (
                                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                                  ) : (
                                    <FormattedMessage {...language.getText('summary.DELETE')} />
                                  )
                                }
                              >
                                <span>
                                  <Button
                                    className={classes.buttons}
                                    color="primary"
                                    variant="contained"
                                    disabled={
                                      Object.keys(summaryData).length === 0 ||
                                      !permissionsList.includes('mcq_can_delete')
                                    }
                                    onClick={() => handleDelete('DELETE', 'delete')}
                                    size="small"
                                  >
                                    {constants.GPT_DELETEQUESTION}
                                  </Button>
                                </span>
                              </TooltipCustom>
                            </div>
                            <McqDialogConfirms
                              open={dialogOpen}
                              handleClose={handleClosedialog}
                              title=""
                              content={titleCommon}
                              handleConfirm={handleSummaryConfirmAction}
                            />
                          </Stack>
                        </Box>
                        {/* </Box> */}
                      </Item>
                      {/* )} */}
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
          />
          <Route
            path="/chatgptutility/aistudytools/mcq/edit/:bookId"
            exact
            render={() => (
              <McqEditQuestionDialog
                open={open}
                onClose={handleClose}
                questionData={questionData}
                pageNumber={pageNumber}
                totalPageNumber={totalQuestionNumber}
                handleNextClick={handleNextClick}
                handlePreviousClick={handlePreviousClick}
              />
            )}
          />
          <Route
            path="/chatgptutility/aistudytools/summary/edit/:bookId"
            exact
            render={() => (
              <SummaryEditDialog
                title={summaryChapterTitle}
                open={open}
                onClose={handleSummaryClose}
                summaryData={summaryData}
                handleSummaryData={handleSummaryData}
                pageNumber={pageNumber}
                totalSummaryNumbers={totalSummaryNumbers}
                handleNextClick={handleNextClick}
                handlePreviousClick={handlePreviousClick}
                isSummaryParagraph={isSummaryParagraph}
                summaryParagraph={summaryParagraph}
              />
            )}
          />
        </Switch>
        <Route
          path="/chatgptutility/aistudytools/mcq/add/:bookId"
          exact
          render={() => <TocAddDialog open={openAdd} onClose={handleADDClose} bookDetails={bookDetails} />}
        />
      </ValidatePermissions>
    </>
  );
});

TOCDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  assetTOC: PropTypes.object.isRequired,
  tocBookId: PropTypes.string.isRequired,
  chatgptTocUtility: shapes.modelOf(Toc).isRequired,
  chatgptSummaryUtility: shapes.modelOf(Summary).isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  fileData: shapes.modelOf(FileData).isRequired,
  language: shapes.language.isRequired,
  viewType: PropTypes.string.isRequired
};

export default withStyles(styles)(
  observer(inject('chatgptTocUtility', 'chatgptSummaryUtility', 'language', 'userPermissions', 'fileData')(TOCDisplay))
);
