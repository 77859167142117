import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { shapes } from '@greenville/framework';
import ReportDownloadModel from '../../models/DownloadReport';

const ReportDownloadButton = ({ filters, reportDownloadModel }) => {
  const [loading, setLoading] = useState(false);

  const downloadReport = async (fileName) => {
    setLoading(true);
    await reportDownloadModel.downloadReport(fileName);
    setLoading(false);
  };

  useEffect(() => {
    const fileName = new URLSearchParams(window.location.search).get('fileName');
    if (fileName) {
      downloadReport(fileName);
    }
  }, []);

  const handleGenerateOrDownload = () => {
    setLoading(true);
    reportDownloadModel.generateReport(filters);
    setLoading(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateOrDownload}
        disabled={loading}
        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        sx={{
          width: '180px',
          textTransform: 'uppercase',
          fontSize: '12px',
          borderRadius: '30px',
          backgroundColor: '#005d83',
          '&:hover': {
            backgroundColor: '#003558'
          }
        }}
      >
        {loading ? 'Processing...' : 'Download Report'}
      </Button>
    </Box>
  );
};

ReportDownloadButton.propTypes = {
  filters: PropTypes.object.isRequired,
  reportDownloadModel: shapes.modelOf(ReportDownloadModel).isRequired
};

export default inject('reportDownloadModel')(observer(ReportDownloadButton));
