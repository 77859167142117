import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Typography, Box } from '@mui/material';
import ExpandableCard from './ExpandableCard';
import * as constants from '../../../../../common/constants';
import utils from '../../../../../common/utils';
import CustomSwitchComponent from '../../../common/components/CustomSwitchComponent';
/* eslint-disable */
const AIStudyToolResponse = ({
  isFeedback,
  dataValue,
  filterPromt,
  SubTypeData,
  tenantTitle,
  theme,
  problemSolveFinalResponse,
  aiStudyRawResponse
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [rawResponse, setRawResponse] = useState('');
  useEffect(() => {
    if (!_.isEmpty(dataValue.botResponse)) {
      if (isChecked) {
        setRawResponse(JSON.stringify(dataValue?.botResponse, null, 2).slice(1, -1));
      } else {
        setRawResponse('');
      }
    }
  }, [isChecked]);
  const renderMathJaxResponse = (response) => {
    const regesXmlns = /<math([^>]*)>/g;
    const res = utils.containsMathML(response)
      ? response.replace(regesXmlns, '<math xmlns="http://www.w3.org/1998/Math/MathML">')
      : response;
    return (
      <>
        {/* eslint-disable */}
        <span
          ref={(el) => {
            utils.processMath(el);
          }}
          dangerouslySetInnerHTML={{ __html: res }}
        />
        {/* eslint-disable */}
      </>
    );
  };
  const checkMathJax = (mathJaxData) => {
    let response = mathJaxData;
    if (!isFeedback && !_.isEmpty(response)) {
      if (utils.containsMarkDownText(response)) {
        response = utils.convertToMarkedDownString(response);
      }
      if (response.includes('`') || response.includes('</math>') || response.includes('\\'))
        return renderMathJaxResponse(response);
    }
    if (isFeedback && !_.isEmpty(response)) {
      if (typeof response === 'object') {
        return (
          Object.keys(response).length > 0 &&
          Object.keys(response).map((val) => {
            return (
              <>
                {response[val] &&
                  Array.isArray(response[val]) &&
                  response[val].length > 0 &&
                  response[val].map((arr, index) => (
                    <>
                      <Typography variant="h6">{`Bot Response ${index + 1}`}</Typography>
                      <Typography variant="body2" key={index}>
                        <>
                          {/* eslint-disable-next-line */}
                          <span
                            ref={(el) => {
                              utils.processMath(el);
                            }}
                            dangerouslySetInnerHTML={{ __html: arr }}
                          />
                        </>
                      </Typography>
                    </>
                  ))}
              </>
            );
          })
        );
      }
      if (response.includes('`') || response.includes('</math>') || response.includes('\\')) {
        return renderMathJaxResponse(response);
      }
    }
    return (
      <>
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: response }} />
      </>
    );
  };

  const renderExpertDeskResponse = (data) => {
    if (data && data.flashcards && data.flashcards.length > 0) {
      const flashcardsArr = data.flashcards;
      return flashcardsArr.map((item) => {
        return (
          <>
            {item.type && (
              <Typography align="left" variant="subtitle1">
                type : {item.type}
              </Typography>
            )}
            {item.front_side && (
              <Typography align="left" variant="subtitle1">
                front_side : {item.front_side}
              </Typography>
            )}
            {item.back_side && (
              <Typography align="left" variant="subtitle1">
                back_side : {item.back_side}
              </Typography>
            )}
            {item.distractors && (
              <Typography align="left" variant="subtitle1">
                <Typography align="left" variant="subtitle1">
                  distractors :{' '}
                </Typography>
                <Typography align="left" variant="subtitle1">
                  {item.distractors.map((value) => (
                    <Typography align="left" variant="subtitle1">
                      {value}
                    </Typography>
                  ))}
                </Typography>
              </Typography>
            )}
            <br />
          </>
        );
      });
    }
    return <></>;
  };
  const handleAlterIndex = (index) => {
    let defIndex = '';
    switch (index) {
      case 0:
        defIndex = 'a)';
        break;
      case 1:
        defIndex = 'b)';
        break;
      case 2:
        defIndex = 'c)';
        break;
      case 3:
        defIndex = 'd)';
        break;
      default:
    }
    return defIndex;
  };

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const renderRawResponse = () => {
    const botResValue = dataValue.botResponse;
    if (_.isEmpty(botResValue)) {
      return <></>;
    }
    return (
      <CustomSwitchComponent
        checked={isChecked}
        onChange={handleSwitchChange}
        label="Raw Response"
        color="#ffffff"
        size="small"
      />
    );
  };

  const customCardHeader = (cardheader, isActionEnabled = false) => {
    return (
      <Box
        sx={{
          fontSize: '12px',
          background: theme.palette.background.darkBlue,
          p: 1,
          display: 'flex',
          alignItems: 'center',
          height: '35px',
          fontWeight: 'bold'
        }}
      >
        <Box>{cardheader}</Box>
        {isActionEnabled ? <Box sx={{ paddingLeft: '16px' }}>{renderRawResponse()}</Box> : <></>}
      </Box>
    );
  };

  const customChatIntent = (dataValue) => {
    return dataValue.botResponse.map((data, index) => {
      return (
        <>
          {' '}
          {dataValue.botResponse.length > 1 && (
            <Typography variant="body2">{index === 0 ? '[NORM-GPT]' : '[BAD-GPT]'}</Typography>
          )}
          {Object.entries(data).map(([key, value]) => (
            <Typography variant="body2">
              {key} : {value}
            </Typography>
          ))}
          <br />
        </>
      );
    });
  };

  const quizObjectIteration = (val) => {
    return Object.keys(val).length > 0 && typeof val !== 'string' ? (
      <>
        <Typography variant="body2" color="text.secondary" textAlign="left">
          {`Question : ${val && val.question}`}
          <br />
          <br />
          {val.options &&
            val.options.map((values, index) => {
              const defIndex = handleAlterIndex(index);
              if (
                values.option.startsWith('a)') ||
                values.option.startsWith('b)') ||
                values.option.startsWith('c)') ||
                values.option.startsWith('d)')
              ) {
                // return `${defIndex})${values.option.slice(2)}`;
                return (
                  <Typography variant="body2" color="text.secondary" textAlign="left">
                    {defIndex} {values.option.slice(2)}
                  </Typography>
                );
              }
              return (
                <Typography variant="body2" color="text.secondary" textAlign="left">
                  {defIndex} {values.option} {values.correct_choice === 'Yes' ? '(Correct)' : ''}
                </Typography>
              );
            })}
          <br />
          {val && val.answer && val.answer.length > 0 && (
            <Typography variant="body2" color="text.secondary" textAlign="left">
              {val.answer}
            </Typography>
          )}
          {val &&
            val.feedback &&
            val.feedback.length !== 0 &&
            val.feedback.map((item, index) => {
              const defIndex = handleAlterIndex(index);
              return (
                <Typography variant="body2" color="text.secondary" textAlign="left">
                  Feedback for option
                  {defIndex} {item}
                </Typography>
              );
            })}
        </Typography>
      </>
    ) : (
      <Typography variant="body2" color="text.secondary" textAlign="left">
        {val}
      </Typography>
    );
  };
  return (
    <>
      {!isFeedback ? (
        <ExpandableCard
          maxHeight="300px"
          header={customCardHeader(`${tenantTitle} Response`, true)}
          body={
            <>
              {!isChecked ? (
                <>
                  {dataValue &&
                    dataValue.type === 'chat_intent' &&
                    dataValue.botResponse &&
                    Array.isArray(dataValue.botResponse) &&
                    customChatIntent(dataValue)}

                  {dataValue &&
                    dataValue.type === 'chat_intent' &&
                    dataValue.botResponse &&
                    typeof dataValue.botResponse === 'string' && (
                      <Typography variant="body2">{dataValue.botResponse}</Typography>
                    )}

                  {dataValue &&
                    (dataValue.type === 'problem_solve' || dataValue.type === constants.PROBLEM_SOLVE_RECTIFIER) &&
                    dataValue.botResponse &&
                    typeof dataValue.botResponse === 'string' && (
                      <Typography variant="body2">{checkMathJax(dataValue.botResponse)}</Typography>
                    )}

                  {dataValue && dataValue.type === 'content_filter' && dataValue.finalResponse && (
                    <Typography variant="body2">{dataValue.finalResponse}</Typography>
                  )}

                  {dataValue &&
                    dataValue.type === 'summary' &&
                    Object.keys(dataValue.botResponse).map((val) => {
                      return (
                        val === 'processedSummary' && (
                          <>
                            {dataValue.botResponse[val] &&
                              dataValue.botResponse[val].map((arr, index) => (
                                <>
                                  {val === 'processedSummary' && (
                                    <Typography variant="h6">{`Bot Response ${index + 1}`}</Typography>
                                  )}
                                  <Typography variant="body2" key={index}>
                                    {checkMathJax(arr)}
                                  </Typography>
                                </>
                              ))}
                            <br />
                          </>
                        )
                      );
                    })}

                  {dataValue &&
                    dataValue.type === 'summary' &&
                    Object.keys(dataValue.botResponse).map((val) => {
                      return (
                        val === 'botSummary' && (
                          <>
                            <Typography variant="h6">Final Summary</Typography>
                            {dataValue.botResponse[val] &&
                              dataValue.botResponse[val].map((arr, index) => (
                                <>
                                  <Typography variant="body2" key={index}>
                                    {checkMathJax(arr)}
                                  </Typography>
                                </>
                              ))}
                            <br />
                          </>
                        )
                      );
                    })}

                  {dataValue &&
                    dataValue.type === 'flashcards' &&
                    dataValue.botResponse &&
                    dataValue.botResponse.map((data, index) => (
                      <Typography variant="body2" key={index}>
                        {data.question}
                        <br />
                        {data.answer.map((ans, indexKey) => (
                          <Typography variant="body2" key={indexKey}>
                            {checkMathJax(ans)}
                            <br />
                          </Typography>
                        ))}
                        <br />
                      </Typography>
                    ))}

                  {dataValue &&
                    filterPromt.includes(dataValue.type) &&
                    dataValue.type !== 'chat_intent' &&
                    dataValue.type !== 'problem_solve' &&
                    dataValue.type !== constants.PROBLEM_SOLVE_RECTIFIER &&
                    dataValue.type !== 'explain_stand_alone' &&
                    dataValue.type !== constants.ANSWER_CYU_MCQ &&
                    dataValue.type !== 'salutation' &&
                    dataValue.type !== 'chat_enrichment' &&
                    dataValue.type !== 'flashcards' &&
                    dataValue.type !== 'quiz_mcq' &&
                    dataValue.type !== 'quiz' &&
                    dataValue.type !== 'content_classifier' &&
                    dataValue.type !== 'context_identifier_user_selection' &&
                    dataValue.type !== constants.SUMMARY_EXPLAIN &&
                    dataValue.type !== constants.EXPERTDESK_MCQ &&
                    dataValue.type !== constants.EXPERTDESK_FREE_RESPONSE &&
                    dataValue.botResponse &&
                    Array.isArray(dataValue.botResponse) &&
                    dataValue.botResponse.map((data, index) => (
                      <Typography variant="body2" key={index}>
                        {checkMathJax(data)}
                      </Typography>
                    ))}

                  {dataValue.type === constants.GENERATE_PYTHON ? (
                    <Typography variant="body2" color="theme.palette.background.darkGray" textAlign="left">
                      {problemSolveFinalResponse?.botResponse?.problemSolveFinalResponse &&
                        checkMathJax(problemSolveFinalResponse?.botResponse?.problemSolveFinalResponse)}
                    </Typography>
                  ) : (
                    constants.commonAuditTypesResponse.includes(dataValue.type) &&
                    dataValue.botResponse &&
                    Object.keys(dataValue.botResponse).length > 0 &&
                    Object.values(dataValue.botResponse).map((item) => {
                      if (Array.isArray(item) && item.length > 0) {
                        return item.map((subItem, index) => {
                          return (
                            <>
                              <Typography variant="body2" key={index}>
                                {checkMathJax(subItem)}
                              </Typography>
                              <br />
                            </>
                          );
                        });
                      }
                      return <></>;
                    })
                  )}

                  {dataValue &&
                    [
                      constants.EXPLAIN_STAND_ALONE,
                      constants.SALUTATION,
                      constants.ANSWER_CYU_MCQ,
                      constants.HISTORY_TITLE,
                      constants.CHANNEL_FETCH_TYPE
                    ].includes(dataValue.type) &&
                    dataValue.botResponse &&
                    (dataValue.botResponse.explainStandAloneBotResponse ||
                      dataValue.botResponse.answerCyuMcqParserBotResponse ||
                      dataValue.botResponse.salutationBotResponse ||
                      dataValue.botResponse.historyTitle ||
                      dataValue.botResponse.channelFetch) && (
                      <>
                        <Typography variant="body2">
                          {dataValue.botResponse.explainStandAloneBotResponse ||
                            dataValue.botResponse.salutationBotResponse ||
                            dataValue.botResponse.answerCyuMcqParserBotResponse ||
                            dataValue.botResponse.historyTitle ||
                            dataValue.botResponse.channelFetch}
                        </Typography>
                      </>
                    )}

                  {dataValue &&
                    filterPromt.includes(dataValue.type) &&
                    (dataValue.type === 'quiz_mcq' || dataValue.type === 'quiz') &&
                    dataValue.botResponse &&
                    dataValue.botResponse.map((data) => {
                      const changedValue = JSON.parse(data);
                      return changedValue.questions.map((value) => {
                        return (
                          <>
                            <Typography variant="body2">
                              Question Id :<b> {`${value.id}`}</b>
                              <br />
                              <br />
                              <span>
                                {'Question : '} {value.question_stem}
                              </span>
                              <br />
                              <br />
                            </Typography>
                            {value.options.map((values) => {
                              return (
                                <Typography variant="body2">
                                  {`${values.id}.`}
                                  {values.distractor}
                                </Typography>
                              );
                            })}
                            <br />
                            {value.options.map((feed) => {
                              return (
                                <Typography variant="body2">
                                  {`Feedback for answer choices : ${feed.id}.`}
                                  {feed.feedback}
                                  <br />
                                  <br />
                                </Typography>
                              );
                            })}
                            <br />
                          </>
                        );
                      });
                    })}

                  {dataValue &&
                    (dataValue.type === 'content_classifier' ||
                      dataValue.type === 'chat_enrichment' ||
                      dataValue.type === constants.SUMMARY_EXPLAIN) &&
                    dataValue.botResponse && (
                      <Typography variant="body2">
                        {checkMathJax(
                          dataValue.type === constants.SUMMARY_EXPLAIN
                            ? dataValue.botResponse.summaryExplainBotResponse
                            : dataValue.botResponse
                        )}
                      </Typography>
                    )}
                  {dataValue &&
                    dataValue.type === 'context_identifier_user_selection' &&
                    dataValue.botResponse &&
                    Array.isArray(dataValue.botResponse) &&
                    dataValue.botResponse.map((data) => {
                      return (
                        <>
                          <Typography variant="body2" fontWeight="bold" textAlign="center">
                            {`Role: ${data.role}`}
                          </Typography>
                          <>
                            <Typography variant="body2">{`Content: ${data.content}`}</Typography>
                            <br />
                          </>
                        </>
                      );
                    })}

                  {dataValue &&
                    (dataValue.type === constants.EXPERTDESK_FREE_RESPONSE ||
                      dataValue.type === constants.EXPERTDESK_MCQ) &&
                    dataValue.botResponse &&
                    dataValue.botResponse.expertDeskMcqBotResponse && (
                      <>
                        <Typography>flashcards</Typography>
                        {renderExpertDeskResponse(dataValue.botResponse.expertDeskMcqBotResponse)}
                      </>
                    )}

                  {dataValue &&
                    [
                      constants.CONTEXT_IDENTIFIER_SUMMARY,
                      constants.SPECIFIC_TOPIC_INTENT_IDENTIFIER,
                      constants.SUMMARY_TOPIC_IDENTIFIER
                    ].includes(dataValue.type) &&
                    dataValue.botResponse &&
                    typeof dataValue.botResponse === 'object' && (
                      <>
                        <Typography variant="body2">{`Role: ${dataValue?.botResponse?.role}`}</Typography>
                        <>
                          <Typography variant="body2">{`Content: ${dataValue.botResponse?.content}`}</Typography>
                          <br />
                        </>
                      </>
                    )}
                </>
              ) : (
                <Typography variant="body2">{rawResponse}</Typography>
              )}
            </>
          }
        />
      ) : (
        <>
          <Box sx={{ marginBottom: '16px' }}>
            <ExpandableCard
              header={customCardHeader(`${tenantTitle} Response`, true)}
              body={
                <>
                  {!isChecked ? (
                    <>
                      {dataValue &&
                        dataValue.type === 'chat_intent' &&
                        dataValue.botResponse &&
                        Array.isArray(dataValue.botResponse) &&
                        customChatIntent(dataValue)}
                      {dataValue &&
                        (dataValue.type === 'quiz_mcq' || dataValue.type === 'quiz') &&
                        dataValue.feedbackContent &&
                        Array.isArray(dataValue.feedbackContent) &&
                        dataValue.feedbackContent.map((val) => {
                          if (item) {
                            if (item && typeof item === 'string') {
                              return checkMathJax(item);
                            } else if (Object.keys(item).length > 0 && !Array.isArray(item)) {
                              return quizObjectIteration(item);
                            } else {
                              return item;
                            }
                          }
                          return <></>;
                        })}
                      {dataValue &&
                        dataValue.type === 'summary' &&
                        Object.keys(dataValue.botResponse).map((val) => {
                          return (
                            val === 'processedSummary' && (
                              <>
                                {dataValue.botResponse[val] &&
                                  dataValue.botResponse[val].map((arr, index) => (
                                    <>
                                      {val === 'processedSummary' && (
                                        <Typography variant="h6">{`Bot Response ${index + 1}`}</Typography>
                                      )}
                                      <Typography variant="body2" key={index}>
                                        {checkMathJax(arr)}
                                      </Typography>
                                    </>
                                  ))}
                                <br />
                              </>
                            )
                          );
                        })}
                      {dataValue &&
                        dataValue.type === 'summary' &&
                        Object.keys(dataValue.botResponse).map((val) => {
                          return (
                            val === 'botSummary' && (
                              <>
                                <Typography variant="h6">Final Summary</Typography>
                                {dataValue.botResponse[val] &&
                                  dataValue.botResponse[val].map((arr, index) => (
                                    <>
                                      <Typography variant="body2" key={index}>
                                        {checkMathJax(arr)}
                                      </Typography>
                                    </>
                                  ))}
                                <br />
                              </>
                            )
                          );
                        })}
                      {dataValue &&
                        dataValue.type === 'flashcards' &&
                        dataValue.botResponse &&
                        dataValue.botResponse.map((data, index) => (
                          <Typography variant="body2" key={index}>
                            {data.question}
                            <br />
                            {data.answer.map((ans, indexKey) => (
                              <Typography variant="body2" key={indexKey}>
                                {checkMathJax(ans)}
                                <br />
                              </Typography>
                            ))}
                            <br />
                          </Typography>
                        ))}
                      {dataValue &&
                        dataValue.type === 'content_filter' &&
                        dataValue.botResponse &&
                        Object.keys(dataValue.botResponse).length > 0 &&
                        Object.keys(dataValue.botResponse).map((key) => {
                          if (key === 'Category_Severity') {
                            return dataValue.botResponse[key].map((data, index) => {
                              return (
                                <>
                                  <Typography variant="body2" key={index}>
                                    Category : {data.Category}
                                  </Typography>
                                  <Typography variant="body2" key={index}>
                                    Severity : {data.Severity}
                                  </Typography>
                                </>
                              );
                            });
                          }
                          return <></>;
                        })}
                      {dataValue &&
                        (dataValue.type === 'explain_stand_alone' ||
                          dataValue.type === 'salutation' ||
                          dataValue.type === constants.ANSWER_CYU_MCQ) &&
                        dataValue.botResponse &&
                        (dataValue.botResponse.explainStandAloneBotResponse ||
                          dataValue.botResponse.salutationBotResponse ||
                          dataValue.botResponse.answerCyuMcqParserBotResponse) && (
                          <>
                            <Typography variant="body2">
                              {dataValue.botResponse.explainStandAloneBotResponse ||
                                dataValue.botResponse.salutationBotResponse ||
                                dataValue.botResponse.answerCyuMcqParserBotResponse}
                            </Typography>
                          </>
                        )}
                      {dataValue &&
                        filterPromt.includes(dataValue.type) &&
                        dataValue.type !== constants.PROBLEM_SOLVE &&
                        dataValue.type !== constants.PROBLEM_SOLVE_RECTIFIER &&
                        dataValue.type !== constants.ANSWER_CYU_MCQ &&
                        dataValue.type !== 'explain_stand_alone' &&
                        dataValue.type !== 'salutation' &&
                        dataValue.type !== 'chat_enrichment' &&
                        dataValue.type !== 'flashcards' &&
                        dataValue.type !== 'quiz_mcq' &&
                        dataValue.type !== 'content_classifier' &&
                        dataValue.type !== 'chat_intent' &&
                        dataValue.type !== 'content_filter' &&
                        dataValue.botResponse &&
                        Array.isArray(dataValue.botResponse) &&
                        dataValue.botResponse.map((data, index) => (
                          <Typography variant="body2" key={index}>
                            {checkMathJax(data)}
                          </Typography>
                        ))}
                      {constants.commonAuditTypesResponse.includes(dataValue.type) &&
                        dataValue.botResponse &&
                        Object.keys(dataValue.botResponse).length > 0 &&
                        Object.values(dataValue.botResponse).map((item) => {
                          if (Array.isArray(item) && item.length > 0) {
                            return item.map((subItem, index) => {
                              return (
                                <>
                                  <Typography variant="body2" key={index}>
                                    {checkMathJax(subItem)}
                                  </Typography>
                                  <br />
                                </>
                              );
                            });
                          }
                          return <></>;
                        })}
                      {dataValue &&
                        (dataValue.type === 'content_classifier' ||
                          dataValue.type === constants.PROBLEM_SOLVE ||
                          dataValue.type === constants.PROBLEM_SOLVE_RECTIFIER) &&
                        dataValue.botResponse && (
                          <Typography variant="body2">{checkMathJax(dataValue.botResponse)}</Typography>
                        )}
                      {dataValue && dataValue.type === 'chat_enrichment' && dataValue.botResponse && (
                        <Typography variant="body2">{checkMathJax(dataValue.botResponse)}</Typography>
                      )}
                    </>
                  ) : (
                    <Typography variant="body2">{rawResponse}</Typography>
                  )}
                </>
              }
            />
          </Box>
          <ExpandableCard
            header={customCardHeader(`${tenantTitle} Response`, true)}
            body={
              <Box sx={{ marginBottom: '16px' }}>
                {customCardHeader(constants.CHAT_PROMPT_CONTENT)}
                {dataValue &&
                  (dataValue.type === 'discuss' || dataValue.type === 'answer' || dataValue.type === 'summary') &&
                  dataValue.content &&
                  dataValue.content.map((val, index) => (
                    <Typography variant="body2" key={index}>
                      {val && val}
                    </Typography>
                  ))}
                <br />
                {filterPromt.includes(dataValue.type) && dataValue.prompt && typeof dataValue.prompt === 'object' ? (
                  dataValue.prompt.map((data) => {
                    return (
                      <>
                        <Typography variant="body2" fontWeight="bold" textAlign="center">
                          {`Role: ${data.role}`}
                        </Typography>
                        {data.prompt && (
                          <>
                            <Typography variant="body2" fontWeight="bold">
                              {constants.CHAT_PROMPT}:
                            </Typography>
                            <Typography variant="body2">{data.prompt.toString().replace(/\n/g, '\\n')}</Typography>
                            <br />
                          </>
                        )}
                      </>
                    );
                  })
                ) : (
                  <Typography variant="body2" fontWeight="bold">
                    {dataValue && dataValue.prompt && dataValue.prompt.toString().replace(/\n/g, '\\n')}
                  </Typography>
                )}
              </Box>
            }
          />
        </>
      )}
      {SubTypeData &&
        SubTypeData.map((typeData) => {
          if (
            typeData &&
            (typeData.type === constants.PROBLEM_SOLVE ||
              typeData.type === constants.PROBLEM_SOLVE_VERIFIER ||
              typeData.type === constants.PROBLEM_SOLVE_RECTIFIER)
          ) {
            return (
              <ExpandableCard
                maxHeight="300px"
                header={`AI Study Tools Response - ${
                  typeData.type === constants.PROBLEM_SOLVE_VERIFIER
                    ? 'Problem Solve Verifier'
                    : typeData.type === constants.PROBLEM_SOLVE_RECTIFIER
                    ? 'Problem Solve Rectifier'
                    : 'Problem Solve'
                }`}
                body={<Typography variant="body2">{checkMathJax(typeData.botResponse)}</Typography>}
              />
            );
          }
          return <></>;
        })}
      {SubTypeData &&
        SubTypeData.map((typeData) => {
          if (typeData && typeData.type === constants.GENERATE_PYTHON) {
            return (
              <>
                <br />
                <ExpandableCard
                  maxHeight="300px"
                  header={constants.GENERATE_PYTHON_AI_RESPONSE}
                  body={
                    <Typography variant="body2" color="text.secondary" textAlign="left">
                      {typeData?.botResponse?.generatePythonResponse &&
                        typeData?.botResponse?.generatePythonResponse.length > 0 &&
                        typeData?.botResponse?.generatePythonResponse.map((item) =>
                          item.content.toString().replaceAll(/\n/g, '\\n')
                        )}
                    </Typography>
                  }
                />
              </>
            );
          }
          return <></>;
        })}
      {dataValue.type !== constants.GENERATE_PYTHON &&
        SubTypeData &&
        SubTypeData.map((typeData) => {
          if (typeData && typeData.type === constants.PROBLEM_SOLVE_FINAL_RESPONSE) {
            return (
              <>
                <br />
                <ExpandableCard
                  maxHeight="300px"
                  header={customCardHeader(`${constants.PROBLEM_SOLVE_FINAL_AI_RESPONSE}`, true)}
                  body={
                    <Typography variant="body2" color="text.secondary" textAlign="left">
                      {typeData?.botResponse?.generatePythonResponse &&
                        typeData?.botResponse?.generatePythonResponse.length > 0 &&
                        typeData?.botResponse?.generatePythonResponse.map((item) =>
                          item.content.toString().replaceAll(/\n/g, '\\n')
                        )}
                    </Typography>
                  }
                />
              </>
            );
          }
          return <></>;
        })}
    </>
  );
};

AIStudyToolResponse.propTypes = {
  isFeedback: PropTypes.bool.isRequired,
  dataValue: PropTypes.object,
  filterPromt: PropTypes.object,
  tenantTitle: PropTypes.string,
  theme: PropTypes.object.isRequired,
  SubTypeData: PropTypes.object,
  aiStudyRawResponse: PropTypes.object,
  problemSolveFinalResponse: PropTypes.object
};

AIStudyToolResponse.defaultProps = {
  filterPromt: [],
  dataValue: {},
  tenantTitle: 'AI Study Tool',
  SubTypeData: {},
  problemSolveFinalResponse: {},
  aiStudyRawResponse: {}
};

export default AIStudyToolResponse;
