import { shapes } from '@greenville/framework';
import { makeStyles } from '@material-ui/core';
import { ChatComponent } from '@vega/aitutor';
import axios from 'axios';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as constants from '../../../common/constants';
import env from '../../../common/env';
import utils from '../../../common/utils';
import chapterList from '../data/chapterList.json';
import GetPiTokenModel from '../models/GetPiTokenModel';

const useStyles = makeStyles({
  heading: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    top: '13%',
    fontSize: '18px',
    fontWeight: '600'
  },
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    height: 'calc(100% - 64px)'
  }
});

const DynamicAIStudyTestComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  const { tenantId, tenantKey, tutorName, expandMode, berlinEnv, message, userId, courseId, productModel, pageId } =
    history.location.state;
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(true);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [tokenExpireTime, setTokenExpireTime] = useState(null);
  const [tokenValue, setTokenValue] = useState('');

  const getPiTokenApiCall = async () => {
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.get(`${env.EVERGREEN_API_BASE_URL}${constants.GET_PI_TOKEN_URL}`, { headers });
    if (response && response.data && response.data.data) {
      const { token, expiresIn } = response.data.data;
      setUserToken(token);
      setTokenExpireTime(expiresIn);
    }
  };

  useEffect(() => {
    const token = utils.getToken();
    setTokenValue(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(tokenValue)) {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 500);
      getPiTokenApiCall();
    }
  }, [tokenValue]);
  /* eslint-disable */

  useEffect(() => {
    if (!tokenExpireTime) return;
    const timeLeft = tokenExpireTime - Date.now() - 59000;
    const timeOutId = setTimeout(() => {
      getPiTokenApiCall();
    }, timeLeft);
    return () => clearTimeout(timeOutId);
  }, [tokenExpireTime]);

  /* eslint-disable */
  const Users = {
    username: 'Vignesh Selvam',
    initials: 'VS',
    userId: userId,
    token: userToken,
    isFirstTimeAITutorUser: false
  };

  const getAuthToken = (callback) => {
    const tokenConfig = {
      id: 'token',
      token: userToken
    };
    return new Promise((resolve) => {
      resolve(callback(tokenConfig.token));
    });
  };

  const handleChatbotOpened = (event) => {
    console.log('handleChatbotOpened callback', event);
  };

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.heading}>{tutorName ? `Welcome to ${tutorName}` : 'Welcome to AI Study Tool'}</div>

      <ChatComponent
        isPageLoaded={isPageLoaded} // Required
        env={berlinEnv} // {env.ENVIRONMENT} // Required
        users={Users} // Required
        channelsVideoEsScore="1"
        theme="default" // Required
        masterTheme="plus" // Required
        locale="en-us" // Optional
        context={{
          bookTitle: 'Campbell Biology, 12e', // Required
          productModel: productModel, // 'EXTERNAL_PVS' Required
          indexId: '0fc5534dbbeaad9b8dbdbdaa9ff4a74f',
          printPageNumber: '5', // Optional
          pageId: pageId, // 'f92ced50-31ca-11ee-85d8-c7c9b8fd0d09',
          bookID: courseId, // 'BRNT-XUF8BS197', // Required
          isPpmTextExtracted: false, // Optional
          secondaryBookId: 'd9096892-1923-4685-ab27-b6c32dd9455d', // Required
          chapterList, // Required
          chapterId: 'cfc05d6e0-31ca-11ee-8eb0-d709f5427093', // Required
          chapterTitle: 'Chapter 4: Carbon and the Molecular Diversity of Life', // Required
          chapterNumber: 19, // Required
          contentType: 'PXE', // Required
          tenantObj: {
            tenantId: tenantId,
            tenantKey: tenantKey
          },
          berlinTenantObject: {
            tenantId: tenantId,
            tenantKey: tenantKey
          }
        }} // Required
        getAuthToken={getAuthToken} // Required
        handleChatbotOpened={handleChatbotOpened}
        openBotContainer={open} // Required
        showChatBotIcon
        showUserHistory
        tutorName={tutorName}
        welcomeMessage={message} // {`Hello, I'm ${tutorName}, a virtual assistant. I can answer questions about Pearson policies. What can I help you with?`}
        expandMode={expandMode}
        toggleBotContainer={() => setOpen(!open)}
      />
    </div>
  );
};

DynamicAIStudyTestComponent.propTypes = {
  getPiToken: shapes.modelOf(GetPiTokenModel).isRequired
};

export default observer(inject('getPiToken')(DynamicAIStudyTestComponent));
