// import { Box, FormControl, IconButton, Menu, MenuItem, Radio, RadioGroup, Typography, AppBar } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import React, { useState } from 'react';
// import { useHistory, Link } from 'react-router-dom';
// import ChatgptConfig from '../../common/config/ChatgptConfig';

// // Define tenant abbreviations with exact tenant IDs from tenantData
// const tenantAbbreviations = {
//   1: 'AS', // AI Study
//   2: 'CH', // Channels
//   '32e81240-7457-11ed-89c4-a117ea7cad6a': 'PVS', // Pearson Virtual Schools
//   '32e81240-7457-11e4': 'R+', // Reader+
//   '32e81240-7457-2222': 'Peri' // Peri
// };

// const ChatUtilityHeader = () => {
//   const history = useHistory();
//   const [tenantVal, setTenantVal] = useState('1'); // Default to "AI Study Tools"
//   const [anchorEl, setAnchorEl] = useState(null);

//   // Ensure initial navigation to default tenant on mount
//   // useEffect(() => {
//   //   history.push('/chatgptutility/aistudytools/cardview');
//   // }, [history]);

//   const handleOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleTenantChange = (event) => {
//     const selectedTenant = event.target.value;
//     setTenantVal(selectedTenant);
//     history.push('/chatgptutility/dashboard');
//     handleClose();
//   };

//   return (
//     <AppBar position="sticky" sx={{ top: 0, zIndex: 1300 }}>
//       <Box
//         component="header"
//         sx={{
//           p: 1,
//           mb: 2,
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'space-between', // Push content to edges
//           color: 'secondary.light',
//           boxShadow: 2,
//           bgcolor: '#333'
//         }}
//       >
//         <Link to="/chatgptutility" title="Click here to go to the home page.">
//           <Box
//             component="img"
//             src="/static/img/pearsonlogo.svg"
//             alt="Title text will come"
//             sx={{ width: 100, height: 40, marginRight: 2, cursor: 'pointer' }}
//           />
//         </Link>
//         {/* Tenant Dropdown on the right */}
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Typography sx={{ color: '#ffffff', fontSize: 16, fontWeight: 'bold', marginRight: 1 }}>
//             {tenantAbbreviations[tenantVal]}
//           </Typography>
//           <IconButton onClick={handleOpen} sx={{ color: '#ffffff' }}>
//             <ArrowDropDownIcon />
//           </IconButton>
//         </Box>

//         <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
//           <FormControl component="fieldset" sx={{ p: 2 }}>
//             <RadioGroup value={tenantVal} onChange={handleTenantChange}>
//               {ChatgptConfig.tenantData.map((item) => (
//                 <MenuItem key={item.tenantId} value={item.tenantId}>
//                   <Radio value={item.tenantId} />
//                   {item.title}
//                 </MenuItem>
//               ))}
//             </RadioGroup>
//           </FormControl>
//         </Menu>
//       </Box>
//     </AppBar>
//   );
// };

// export default ChatUtilityHeader;

import PropTypes from 'prop-types'; // Move this import to the top
import { Box, FormControl, IconButton, Menu, MenuItem, Radio, RadioGroup, Typography, AppBar } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import ChatgptConfig from './config/ChatgptConfig';
import { AIADMIN_PRIMARY_COLOR, AIADMIN_TEXT_SECONDARY } from './ChatGptTheme';

// Define tenant abbreviations with exact tenant IDs from tenantData
const tenantAbbreviations = {
  1: 'AS', // AI Study
  2: 'CH', // Channels
  '32e81240-7457-11ed-89c4-a117ea7cad6a': 'PVS', // Pearson Virtual Schools
  '32e81240-7457-11e4': 'R+', // Reader+
  '32e81240-7457-2222': 'Peri' // Peri
};

const ChatUtilityHeader = ({ onTenantChange }) => {
  const history = useHistory();
  const [tenantVal, setTenantVal] = useState('1'); // Default to "AI Study Tools"
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTenantChange = (event) => {
    const selectedTenant = event.target.value;
    setTenantVal(selectedTenant);
    onTenantChange(selectedTenant); // Notify parent of tenant change
    history.push('/aiadmin');
    handleClose();
  };

  return (
    <AppBar position="sticky" sx={{ bgcolor: 'transparent', boxShadow: 'none', position: 'fixed' }}>
      <Box
        component="header"
        sx={{
          p: 1,
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'secondary.light',
          boxShadow: 2,
          bgcolor: AIADMIN_PRIMARY_COLOR,
          borderBottom: 'none'
        }}
      >
        <Link to="/aiadmin" title="Click here to go to the home page.">
          <Box
            component="img"
            src="/images/pearsonlogo.svg"
            alt="Title text will come"
            sx={{ width: 100, height: 40, marginRight: 2, cursor: 'pointer' }}
          />
        </Link>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: AIADMIN_TEXT_SECONDARY, fontSize: 16, fontWeight: 'bold', marginRight: 1 }}>
            {tenantAbbreviations[tenantVal]}
          </Typography>
          <IconButton onClick={handleOpen} sx={{ color: AIADMIN_TEXT_SECONDARY }}>
            <ArrowDropDownIcon />
          </IconButton>
        </Box>

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <FormControl component="fieldset" sx={{ p: 2 }}>
            <RadioGroup value={tenantVal} onChange={handleTenantChange}>
              {ChatgptConfig.tenantData.map((item) => (
                <MenuItem
                  key={item.tenantId}
                  value={item.tenantId}
                  onClick={() => {
                    handleTenantChange({ target: { value: item.tenantId } });
                  }}
                >
                  <Radio value={item.tenantId} />
                  {item.title}
                </MenuItem>
              ))}
            </RadioGroup>
          </FormControl>
        </Menu>
      </Box>
    </AppBar>
  );
};

ChatUtilityHeader.propTypes = {
  onTenantChange: PropTypes.func.isRequired
};

export default ChatUtilityHeader;
