import MomentUtils from '@date-io/moment';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { Box, Button, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from 'axios';
import * as _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import CustomLoader from '../common/components/CustomLoader';
import CustomDatePicker from '../common/components/CustomDatePicker';
import * as constants from '../../../common/constants';
import env from '../../../common/env';
import utils from '../../../common/utils';
import AutocompleteComponent from '../common/components/Autocomplete';
import AIChartsUtilityModel from '../models/AIChartsUtility';
import AIContentFilterChartsModel from '../models/AIContentFilterCharts';
import AIContentFilterChartsDetailsModel from '../models/AIContentFilterChartsDetails';
import AIFeedbackChartsDetailsModel from '../models/AIFeedbackChartsDetails';
import Listing from '../models/Listing';
import McqSearch from '../models/McqSearch';
import Trends from '../models/Trends';
import AIChartsComponent from './aiCharts/AIChartsComponent';
import AIContentFilterCharts from './aiCharts/contentFilterCharts/AIContentFilterCharts';
import ChatTrendsGrid from './datagrid/ChatTrendsGrid';
import InternalUsersList from '../models/InternalUsersList';
import CDNToken from '../models/CDNToken';
import ButtonComponent from '../common/components/ButtonComponent';
import { getDarkTheme } from '../ChatGptTheme';
import NoRecords from '../common/components/NoRecords';

const commonThemeDark = getDarkTheme();

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

// const themes = createTheme({
//   palette: {
//     primary: {
//       light: '#047a9c',
//       main: '#005d83',
//       dark: '#003558',
//       contrastText: '#ffffff'
//     }
//   },
//   overrides: {
//     MuiButton: {
//       root: {
//         borderRadius: 30
//       }
//     }
//   }
// });

const styles = (theme) => ({
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit',
    minWidth: '195px',
    maxWidth: '195px'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    minWidth: '300px',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  // buttonStyle: {
  //   width: '120px',
  //   margin: '10px'
  // },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  },
  toglebox: {
    border: '1px solid #322c2c2e'
  }
});

@inject(
  'chatgptTrendsUtility',
  'chatgptListingUtility',
  'chatgptUtilityTrendsStatus',
  'ChatGptUtilityListingStatus',
  'mcqSearch',
  'aiChartsUtility',
  'aiContentfilterCharts',
  'aiContentfilterChartsDetails',
  'aiFeedbackChartsDetails',
  'AIChartsUtilityService',
  'AIContentFilterChartsService',
  'aiFeedbackChartsDetailsListService',
  'AIContentFilterChartsDetailsService',
  'internalUsers',
  'cdnToken'
)
@observer
class ChatGptUtilityTrends extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    chatgptTrendsUtility: shapes.modelOf(Trends).isRequired,
    chatgptListingUtility: shapes.modelOf(Listing).isRequired,
    chatgptUtilityTrendsStatus: shapes.state.isRequired,
    ChatGptUtilityListingStatus: shapes.state.isRequired,
    mcqSearch: shapes.modelOf(McqSearch).isRequired,
    aiChartsUtility: shapes.modelOf(AIChartsUtilityModel).isRequired,
    aiContentfilterCharts: shapes.modelOf(AIContentFilterChartsModel).isRequired,
    aiContentfilterChartsDetails: shapes.modelOf(AIContentFilterChartsDetailsModel).isRequired,
    aiFeedbackChartsDetails: shapes.modelOf(AIFeedbackChartsDetailsModel).isRequired,
    AIChartsUtilityService: shapes.modelOf(AIChartsUtilityModel).isRequired,
    AIContentFilterChartsService: shapes.modelOf(AIContentFilterChartsModel).isRequired,
    aiFeedbackChartsDetailsListService: shapes.modelOf(AIFeedbackChartsDetailsModel).isRequired,
    AIContentFilterChartsDetailsService: shapes.modelOf(AIContentFilterChartsDetailsModel).isRequired,
    internalUsers: shapes.modelOf(InternalUsersList).isRequired,
    cdnToken: shapes.modelOf(CDNToken).isRequired
  };

  constructor(props) {
    super(props);
    const { chatgptTrendsUtility, chatgptListingUtility, ChatGptUtilityListingStatus } = props;
    this.defaultSearch = {
      bookId: '',
      startDate: '',
      endDate: ''
    };
    this.state = {
      newRequest: true,
      searchFields: '',
      gptResponse: chatgptTrendsUtility,
      gptListingResponse: {
        chatListDatas: chatgptListingUtility,
        chatListStatus: ChatGptUtilityListingStatus,
        loaderStatusResponse: true
      },
      endDisabled: true,
      showDateFields: false,
      showOutline: '1D',
      setOrder: 'ASC',
      setDirectionValue: '',
      selectRadioOption: false,
      selectListingOption: false,
      toggleBtnValue: 'cluster',
      bookList: [],
      inputValue: '',
      tokenValue: '',
      showCharts: {
        userFeedBackChart: false,
        blockedCountChart: false
      },
      defaultDropdownValue: { title: constants.ALL_BOOKS, bookId: constants.ALL_BOOKS, id: 1 },
      isSearchClicked: false,
      isUserIdExcluded: true
    };
  }

  componentDidMount = () => {
    const { chatgptTrendsUtility, mcqSearch } = this.props;
    if (mcqSearch && mcqSearch.data.length > 0) {
      const resData = _.cloneDeep(toJS(mcqSearch.data));
      resData.unshift({ title: constants.ALL_BOOKS, bookId: constants.ALL_BOOKS, id: 1 });
      this.setState({
        bookList: resData,
        searchFields: { bookId: constants.ALL_BOOKS }
      });
    }
    /* eslint-disable camelcase */
    const temp = window.location.pathname;
    if (temp === '/chatgptutility/aistudytools/trends') {
      this.setState({
        selectRadioOption: false,
        selectListingOption: false
      });
    }

    Framework.getEventManager().on(constants.GET_CHATGPT_LISTING_MODEL_DATA, () => {
      const { chatgptListingUtility } = this.props;
      const { gptListingResponse } = this.state;
      this.setState({
        gptListingResponse: {
          ...gptListingResponse,
          chatListDatas: chatgptListingUtility
        }
      });
    });

    Framework.getEventManager().on(constants.GET_CHATGPT_TRENDS_MODEL_DATA, () => {
      const { data } = chatgptTrendsUtility;
      if (chatgptTrendsUtility) {
        const { newRequest, gptResponse, bookList } = this.state;
        const combineData = [...data];
        bookList.forEach((item) => {
          const alteredValue = item.bookId;
          if (!alteredValue) return;
          combineData.forEach((dataValue, index) => {
            if (dataValue.bookId === alteredValue) {
              combineData[index].title = item.title;
            }
          });
        });
        const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
        /* eslint-enable camelcase */
        if (newRequest) {
          const sortedDate = combineDatatoJS.sort((x, y) => new Date(y.date) - new Date(x.date));
          this.setState({ gptResponse: sortedDate });
        } else {
          const combineDataGpt = [...gptResponse, ...combineDatatoJS];
          const sortedDate = combineDataGpt.sort((x, y) => new Date(y.date) - new Date(x.date));
          this.setState((prev) => ({ gptResponse: [...prev.gptResponse, ...sortedDate] }));
        }
      }
    });
    /* eslint-enable camelcase */
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mcqSearch, internalUsers, cdnToken } = this.props;
    const { tokenValue } = this.state;
    const usersData = JSON.parse(JSON.stringify(toJS(internalUsers)));
    const cdnTokenValue = JSON.parse(JSON.stringify(toJS(cdnToken)));
    const isCDNtokenPresent = utils.getCookieByName('etext-cdn-token');
    if (prevState.tokenValue !== tokenValue) {
      if (_.isEmpty(cdnTokenValue?.name) && !isCDNtokenPresent) {
        this.getCDNTokenData();
      }
      if (mcqSearch && mcqSearch.data.length === 0) {
        this.getBookData();
      }
      if (usersData && usersData?.userId.length === 0) {
        this.getS3InternalUsersList();
      }
    }
  };

  getCDNTokenData = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.get(`${env.EVERGREEN_API_BASE_URL}${constants.GET_CDN_TOKEN_URL}`, { headers });
    if (response && response.data && response.data.data) {
      const cdnTokenVal = response.data.data;
      Framework.getStoreRegistry().getStore('cdnToken').setResponse(cdnTokenVal);
      utils.setCDNTokenInCookie(cdnTokenVal);
    }
  };

  getS3InternalUsersList = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const payload = {
      itemPath: constants.S3_EXCLUDED_USERS_LIST_PATH
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_S3_USERS_LIST_URL}`, payload, {
      headers
    });
    if (response && response.data && response.data.data) {
      Framework.getStoreRegistry().getStore('internalUsers').setResponse(response.data.data[0]);
    }
  };

  getBookData = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_BOOK_DATA_URL}`, {}, { headers });
    if (response && response.data && response.data.data) {
      const resData = _.cloneDeep(response.data.data);
      resData.unshift({ title: constants.ALL_BOOKS, bookId: constants.ALL_BOOKS, id: 1 });
      Framework.getStoreRegistry().getStore('mcqSearch').setResponse(response.data);
      this.setState({
        bookList: resData,
        searchFields: { bookId: constants.ALL_BOOKS }
      });
    }
  };

  onInputChange = (event, newInputValue) => {
    this.setState({
      inputValue: newInputValue
    });
  };

  handleChange = (e) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
  };

  handleToggleBtnChange = (e) => {
    const {
      chatgptListingUtility,
      chatgptTrendsUtility,
      aiChartsUtility,
      aiContentfilterCharts,
      aiContentfilterChartsDetails,
      aiFeedbackChartsDetails
    } = this.props;
    this.setState({
      selectRadioOption: e.target.value,
      toggleBtnValue: e.target.value
    });
    chatgptListingUtility.resetStoreValues();
    chatgptTrendsUtility.resetStoreValues();
    aiChartsUtility.resetStoreValues();
    aiFeedbackChartsDetails.resetStoreValues();
    aiContentfilterCharts.resetStoreValues();
    aiContentfilterChartsDetails.resetStoreValues();
  };

  handleReset = () => {
    const { aiChartsUtility, aiContentfilterCharts, aiContentfilterChartsDetails, aiFeedbackChartsDetails } =
      this.props;
    const { gptListingResponse } = this.state;
    this.setState({
      showOutline: '',
      toggleBtnValue: 'cluster',
      searchFields: {
        ...this.defaultSearch
      },
      gptListingResponse: {
        ...gptListingResponse,
        chatListDatas: []
      },
      inputValue: '',
      isSearchClicked: false
    });
    aiChartsUtility.resetStoreValues();
    aiFeedbackChartsDetails.resetStoreValues();
    aiContentfilterCharts.resetStoreValues();
    aiContentfilterChartsDetails.resetStoreValues();
  };

  handleAscending = (dir, value) => {
    const { gptResponse } = this.state;
    if (dir === 'asc') {
      const sorted = [...gptResponse].sort((a, b) => (a[value] > b[value] ? 1 : -1));
      this.setState({
        ...gptResponse,
        gptResponse: sorted
      });
    } else {
      const sorted = [...gptResponse].sort((a, b) => (a[value] < b[value] ? 1 : -1));
      this.setState({
        ...gptResponse,
        gptResponse: sorted
      });
    }
  };

  handleSearch = (customPayload) => {
    const { searchFields, toggleBtnValue } = this.state;
    const { chatgptTrendsUtility, chatgptListingUtility } = this.props;
    const temp = window.location.pathname;

    if (
      temp === '/chatgptutility/aistudytools/trends/charts' ||
      temp === '/chatgptutility/aistudytools/trends/clustering'
    ) {
      this.setState({
        selectRadioOption: false,
        selectListingOption: true
      });
    } else {
      this.setState({
        selectRadioOption: true,
        selectListingOption: false
      });
    }
    if (customPayload && customPayload.lastKey) {
      this.setState({ newRequest: false });
    } else {
      this.setState({ newRequest: true });
    }
    const payload = { ...searchFields, ...customPayload };
    if (payload.bookId === constants.ALL_BOOKS) {
      delete payload.bookId;
    }
    let endDateChanged = new Date();
    endDateChanged = moment(endDateChanged).format(constants.YYYY_MM_DD_FORMAT);

    if ((payload && payload.startDate) || (payload && payload.bookId)) {
      if (!payload.endDate && payload.startDate) {
        payload.endDate = endDateChanged;
        this.setState({
          searchFields: { ...searchFields, endDate: endDateChanged }
        });
      }
    } else {
      payload.endDate = endDateChanged;
      payload.startDate = endDateChanged;
      this.setState({
        searchFields: { ...searchFields, startDate: endDateChanged, endDate: endDateChanged }
      });
    }
    if (toggleBtnValue === 'cluster') {
      this.setState({
        selectRadioOption: false,
        selectListingOption: true
      });
      chatgptListingUtility.fetch(payload);
    }
    if (toggleBtnValue === 'count') {
      chatgptTrendsUtility.fetch(payload);
    }
    if (toggleBtnValue === 'userFeedBack') {
      this.setState({
        showCharts: {
          userFeedBackChart: true,
          blockedCountChart: false
        },
        isSearchClicked: true
      });
    }
    if (toggleBtnValue === 'blockedCount') {
      this.setState({
        showCharts: {
          userFeedBackChart: false,
          blockedCountChart: true
        },
        isSearchClicked: true
      });
    }
  };

  trendsDataFetch = (value) => {
    const { showDateFields } = this.state;
    if (value === 'custom') {
      this.setState({
        showOutline: value,
        showDateFields: !showDateFields
      });
    }
  };

  handleDateChange = (date, name) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[name] = moment(date).format(constants.YYYY_MM_DD_FORMAT);
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
    const payload = fieldChanged;
    if (payload.startDate) {
      this.setState({
        endDisabled: false
      });
    }
  };

  handleDateclicker = (event, value) => {
    const { searchFields } = this.state;
    const daysvalue = event.target.innerText;
    let startDate = new Date();
    let endDate = new Date();
    switch (daysvalue) {
      case '1d':
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '3d':
        startDate.setDate(startDate.getDate() - 2);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '1w':
        startDate.setDate(startDate.getDate() - 6);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      default:
    }
    startDate = moment(startDate).format(constants.YYYY_MM_DD_FORMAT);
    endDate = moment(endDate).format(constants.YYYY_MM_DD_FORMAT);
    this.setState({
      searchFields: { ...searchFields, startDate, endDate }
    });
  };

  sorting = (value) => {
    const { setOrder } = this.state;
    if (setOrder === 'ASC') {
      this.handleAscending('asc', value);
      this.setState({
        setOrder: 'DSC',
        setDirectionValue: value
      });
    }
    if (setOrder === 'DSC') {
      this.handleAscending('des', value);
      this.setState({
        setOrder: 'ASC',
        setDirectionValue: value
      });
    }
  };

  onAutoCompleteChange = (event, newValue) => {
    const { searchFields } = this.state;
    this.setState({
      searchFields: {
        ...searchFields,
        bookId: newValue !== null ? newValue.bookId : ''
      }
    });
  };

  handleOnSearch = (param) => {
    this.setState({
      isSearchClicked: param
    });
  };

  handleFilterUserChange = (e) => {
    this.setState({
      isUserIdExcluded: e.target.checked
    });
  };

  render() {
    const {
      classes,
      chatgptUtilityTrendsStatus,
      AIChartsUtilityService,
      AIContentFilterChartsService,
      aiFeedbackChartsDetailsListService,
      AIContentFilterChartsDetailsService
    } = this.props;
    const {
      searchFields,
      endDisabled,
      showDateFields,
      showOutline,
      gptResponse,
      gptListingResponse,
      setOrder,
      setDirectionValue,
      selectRadioOption,
      selectListingOption,
      toggleBtnValue,
      bookList,
      inputValue,
      tokenValue,
      showCharts,
      defaultDropdownValue,
      isSearchClicked,
      isUserIdExcluded
    } = this.state;
    const activeSession = utils.getSessionData();
    if (activeSession !== null && tokenValue === '') {
      const token = utils.getToken();
      this.setState({
        tokenValue: token
      });
    }
    const { chatListStatus } = gptListingResponse;

    const {
      background: { darkBlue, paper },
      secondary: { contrastText }
    } = commonThemeDark.palette;

    return (
      <>
        <Box
          style={{
            padding: '13px',
            backgroundColor: '#f8f6fa',
            border: '2px solid #333',
            minWidth: '450px',
            borderRadius: '8px'
          }}
        >
          {/* <Box sx={{ p: 3 }}>
              <Typography
                variant="h5"
                align="center"
                gutterBottom
                spacing={9}
                className={classes.title}
                style={{ p: 2, mt: 2 }}
              >
                Please enter search criteria to fetch the details
              </Typography>
            </Box> */}

          <Box style={{ display: 'flex', flexWrap: 'wrap', columnGap: '35px', rowGap: '10px', paddingLeft: '60px' }}>
            <FormControl sx={{ minWidth: '225px' }} size="small">
              <AutocompleteComponent
                options={bookList}
                onAutoCompleteChange={this.onAutoCompleteChange}
                onInputChange={this.onInputChange}
                stateVal={inputValue}
                errorVal=""
                name="bookId"
                label="Book Id"
                componentDefaultValue={defaultDropdownValue}
              />
            </FormControl>
            <Box
              className={classes.marginBox}
              sx={{ '& button': { m: 31, p: 12 }, minWidth: '300px', maxWidth: '300px' }}
            >
              <Stack direction="row" spacing={1}>
                <Button
                  size="small"
                  variant={showOutline === '1D' ? 'outlined' : 'text'}
                  style={{ color: '#0d0c0c8c' }}
                  onClick={(event) => this.handleDateclicker(event, '1D')}
                >
                  1d
                </Button>
                <Button
                  size="small"
                  style={{ color: '#0d0c0c8c' }}
                  variant={showOutline === '3D' ? 'outlined' : 'text'}
                  onClick={(event) => this.handleDateclicker(event, '3D')}
                >
                  3d
                </Button>
                <Button
                  size="small"
                  style={{ color: '#0d0c0c8c' }}
                  variant={showOutline === '1W' ? 'outlined' : 'text'}
                  onClick={(event) => this.handleDateclicker(event, '1W')}
                >
                  1w
                </Button>
                <Button
                  size="small"
                  variant={showOutline === 'custom' ? 'outlined' : 'text'}
                  style={{ color: '#0d0c0c8c', minWidth: '77px' }}
                  onClick={() => this.trendsDataFetch('custom')}
                >
                  custom
                  <CalendarMonthIcon />
                </Button>
              </Stack>
            </Box>
            {showDateFields && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={materialTheme}>
                  <Box>
                    <CustomDatePicker
                      label="Start Date"
                      dataKey="startDate"
                      value={searchFields.startDate || null}
                      handleDateChange={(date) => this.handleDateChange(date, 'startDate')}
                    />
                  </Box>
                  <Box>
                    <CustomDatePicker
                      label="End Date"
                      dataKey="endDate"
                      value={searchFields.endDate || null}
                      handleDateChange={(date) => this.handleDateChange(date, 'endDate')}
                      disabled={endDisabled}
                    />
                  </Box>
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            )}
          </Box>
          <Box
            style={{
              display: 'flex',
              columnGap: '30px',
              rowGap: '10px',
              paddingLeft: '60px',
              flexWrap: 'wrap',
              alignItems: 'center'
            }}
          >
            <Box>
              <Typography style={{ paddingBottom: '10px', fontWeight: '600', fontSize: '15px' }}>Report By:</Typography>
              <ToggleButtonGroup
                color="primary"
                value={toggleBtnValue}
                exclusive
                onChange={this.handleToggleBtnChange}
                aria-label="Platform"
              >
                <ToggleButton
                  value="cluster"
                  sx={{
                    color: contrastText,
                    '&.Mui-selected': {
                      backgroundColor: toggleBtnValue === 'cluster' ? darkBlue : paper,
                      color: toggleBtnValue === 'cluster' ? paper : contrastText
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: toggleBtnValue === 'cluster' ? darkBlue : '#f1f1f1'
                    },
                    '&:hover': {
                      backgroundColor: toggleBtnValue === 'cluster' ? darkBlue : '#f1f1f1'
                    }
                  }}
                  style={{
                    border: '1px solid #322c2c2e'
                  }}
                >
                  {constants.CLUSTERING}
                </ToggleButton>
                <ToggleButton
                  value="count"
                  sx={{
                    color: contrastText,
                    '&.Mui-selected': {
                      backgroundColor: toggleBtnValue === 'count' ? darkBlue : paper,
                      color: toggleBtnValue === 'count' ? paper : contrastText
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: toggleBtnValue === 'count' ? darkBlue : '#f1f1f1'
                    },
                    '&:hover': {
                      backgroundColor: toggleBtnValue === 'count' ? darkBlue : '#f1f1f1'
                    }
                  }}
                  style={{
                    border: '1px solid #322c2c2e'
                  }}
                >
                  {constants.COUNT}
                </ToggleButton>
                <ToggleButton
                  value="userFeedBack"
                  sx={{
                    color: contrastText,
                    '&.Mui-selected': {
                      backgroundColor: toggleBtnValue === 'userFeedBack' ? darkBlue : paper,
                      color: toggleBtnValue === 'userFeedBack' ? paper : contrastText
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: toggleBtnValue === 'userFeedBack' ? darkBlue : '#f1f1f1'
                    },
                    '&:hover': {
                      backgroundColor: toggleBtnValue === 'userFeedBack' ? darkBlue : '#f1f1f1'
                    }
                  }}
                  style={{
                    border: '1px solid #322c2c2e'
                  }}
                >
                  User Feedback Chart
                </ToggleButton>
                <ToggleButton
                  value="blockedCount"
                  sx={{
                    color: contrastText,
                    '&.Mui-selected': {
                      backgroundColor: toggleBtnValue === 'blockedCount' ? darkBlue : paper,
                      color: toggleBtnValue === 'blockedCount' ? paper : contrastText
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: toggleBtnValue === 'blockedCount' ? darkBlue : '#f1f1f1'
                    },
                    '&:hover': {
                      backgroundColor: toggleBtnValue === 'blockedCount' ? darkBlue : '#f1f1f1'
                    }
                  }}
                  style={{
                    border: '1px solid #322c2c2e'
                  }}
                >
                  Blocked Count Chart
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box style={{ marginTop: '30px' }}>
              <FormControl size="small">
                <FormControlLabel
                  control={
                    <Checkbox onChange={this.handleFilterUserChange} name="excludeUserId" checked={isUserIdExcluded} />
                  }
                  style={{
                    alignItems: 'center'
                  }}
                  labelPlacement="end"
                  label="Filter Internal Users"
                />
              </FormControl>
            </Box>
            <Box>
              <ButtonComponent classes={classes} handleClick={this.handleSearch} />
            </Box>
          </Box>
          {/* <div className={chatgptUtilityTrendsStatus.isPending ? classes.blurStyle : null} /> */}
        </Box>
        <LoadingHandler
          loading={
            chatgptUtilityTrendsStatus.isPending ||
            chatListStatus.isPending ||
            AIChartsUtilityService.isPending ||
            AIContentFilterChartsService.isPending ||
            aiFeedbackChartsDetailsListService.isPending ||
            AIContentFilterChartsDetailsService.isPending
          }
          isError={
            (chatgptUtilityTrendsStatus.isError || chatListStatus.isError) &&
            (chatgptUtilityTrendsStatus.error || chatListStatus.isError) &&
            (chatgptUtilityTrendsStatus.error === 401 ||
              chatListStatus.error === 401 ||
              chatListStatus.error === null) &&
            (chatgptUtilityTrendsStatus.error === 500 || chatListStatus.error === 500 || chatListStatus.error === null)
          }
          content={
            (gptResponse && gptResponse.length > 0) ||
            (gptListingResponse.chatListDatas.data && gptListingResponse.chatListDatas.data.length > 0) ? (
              <Box>
                <br />
                <ChatTrendsGrid
                  chatgptUtilityTrendsData={gptResponse}
                  chatgptUtilityListingData={gptListingResponse}
                  sorting={this.sorting}
                  setOrder={setOrder}
                  setDirectionValue={setDirectionValue}
                  selectRadioOption={selectRadioOption}
                  selectListingOption={selectListingOption}
                  startDate={searchFields.startDate || ''}
                  endDate={searchFields.endDate || ''}
                  isUserIdExcluded={isUserIdExcluded}
                />
              </Box>
            ) : (
              <>
                <br />
                {['count', 'cluster'].includes(toggleBtnValue) ? <NoRecords /> : <></>}
              </>
            )
          }
          loadingContent={<CustomLoader />}
          // errorContent={
          //   <StateErrorDisplay error={chatgptUtilityTrendsStatus.error || chatListStatus.error} showDetails />
          // }
        />
        {showCharts.userFeedBackChart && (
          <AIChartsComponent
            searchFields={searchFields}
            handleOnSearch={this.handleOnSearch}
            isSearchClicked={isSearchClicked}
            isUserIdExcluded={isUserIdExcluded}
          />
        )}
        {showCharts.blockedCountChart && (
          <AIContentFilterCharts
            searchFields={searchFields}
            handleOnSearch={this.handleOnSearch}
            isSearchClicked={isSearchClicked}
            bookList={bookList}
            isUserIdExcluded={isUserIdExcluded}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(ChatGptUtilityTrends);
