import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  InputLabel,
  TextField,
  TextareaAutosize,
  Typography
} from '@mui/material';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomDialog from '../../common/components/CustomDialog';
import CustomSelect from '../../common/components/CustomSelect';
import * as constants from '../../../../common/constants';

const styles = () => ({
  dialogBodyWrapper: {
    width: '100%',
    padding: '10px'
  },
  promptStartWrapper: {
    marginBottom: '16px'
  },
  textAreaSize: {
    width: '90%'
  },

  aiStudyToolcard: {
    height: '485px',
    overflowY: 'auto !important'
  },
  inputCard: { height: '160px', overflowY: 'auto !important' },
  promptCard: { height: '270px', overflowY: 'auto !important' },

  cardHeaderBackground: { background: '#34282808' },
  cardContent: { textAlign: 'start' }
});

const AdminPromptEvaluateDialog = (props) => {
  const { openDialog, handleClose, classes, selectedValue } = props;
  const [showResponse, setShowResponse] = useState(false);
  const handleRun = () => {
    setShowResponse(true);
  };

  const renderPromptStartTextArea = () => {
    return [...selectedValue.promptStart].map((startval, index) => {
      return (
        <Box className={classes.promptStartWrapper}>
          <Chip label="Prompt Start" size="medium" />
          <InputLabel id={index}>Role: {startval.role}</InputLabel>
          <TextareaAutosize
            className={classes.textAreaSize}
            id={index}
            name={`promptStart_${startval.role}`}
            variant="outlined"
            onChange={() => {}}
            minRows={6}
            maxRows={12}
            value={startval.content}
          />
        </Box>
      );
    });
  };

  const renderPromptEndTextArea = () => {
    return [...selectedValue.promptEnd].map((endVal, index) => {
      return (
        <Box>
          <Chip label="Prompt End" size="medium" />
          <InputLabel id={index}>Role: {endVal.role}</InputLabel>
          <TextareaAutosize
            className={classes.textAreaSize}
            id={index}
            name={`promptEnd_${endVal.role}`}
            onChange={() => {}}
            variant="outlined"
            minRows={6}
            maxRows={12}
            value={endVal.content}
          />
        </Box>
      );
    });
  };

  return (
    <Box>
      <CustomDialog
        open={openDialog}
        title={`${constants.EVALUATE} - ${selectedValue.type}`}
        onConfirm={handleRun}
        onCancel={handleClose}
        confirmText={constants.RUN}
        cancelText="Cancel"
        size="lg"
        content={
          <Box className={classes.dialogBodyWrapper}>
            <Grid container spacing={2} style={{ minWidth: 800 }}>
              <Grid item xs={showResponse ? 6 : 12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <CustomSelect
                      label="Select Model"
                      value="promptStatusValue"
                      name="SelectModel"
                      onChange={() => {}}
                      options={[
                        {
                          name: 'GPT3.5',
                          label: 'GPT 3.5'
                        },
                        {
                          name: 'GPT4.0',
                          label: 'GPT 4.0'
                        }
                      ]}
                      style={{
                        minWidth: '200px',
                        alignItems: 'center'
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField label="Text Input" margin="dense" name="text" variant="outlined" />
                  </Grid>
                </Grid>
                <Box>
                  <Box className={classes.promptStartWrapper}>
                    <Typography variant="h6">Prompt String (Start + End)</Typography>
                  </Box>
                  <Box>
                    {renderPromptStartTextArea()}
                    {renderPromptEndTextArea()}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                {showResponse && (
                  <>
                    <Box>
                      <Card className={classes.aiStudyToolcard}>
                        <CardHeader title="AI Response" align="left" />
                        <CardContent>TEST</CardContent>
                      </Card>
                    </Box>
                    <br />
                    <Box>
                      <Card className={classes.inputCard}>
                        <CardHeader
                          title="True Lens Metrics Scores"
                          className={classes.cardHeaderBackground}
                          align="left"
                        />
                        <CardContent className={classes.cardContent}>
                          <TextField label="True Lens Metrics Scores" margin="dense" name="text" variant="outlined" />
                        </CardContent>
                      </Card>
                    </Box>
                    <br />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        }
      />
    </Box>
  );
};

AdminPromptEvaluateDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.object.isRequired
};

export default withStyles(styles)(AdminPromptEvaluateDialog);
