import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { Box, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ArrowBack, ArrowForward, RestartAlt, ContentCopy, Preview } from '@mui/icons-material';
// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Paper, Card, IconButton, TextField, useTheme } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CustomDialog from '../../common/components/CustomDialog';
import CustomTable from '../../common/components/CustomTable';
import CustomTooltip from '../../common/components/CustomToolTip';
import ColumnConfig from '../../config/ColumnConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import UsersPermissions from '../../../users/models/UsersPermissions';
import chatGptAdminPromptsData from '../../models/ChatGptAdminPrompts';
import ChatgptAdmin from '../../models/ChatgptAdmin';
import AdminPromptDialog from './AdminPromptDialog';
import AdminPromptEvaluateDialog from './AdminPromptEvaluateDialog';
import CustomSelect from '../../common/components/CustomSelect';
import CustomDatePicker from '../../common/components/CustomDatePicker';
import ExpandableCard from '../DynamicComponent/dataGrid/ExpandableCard';
import CustomLoader from '../../common/components/CustomLoader';
import NoRecords from '../../common/components/NoRecords';
import CustomButton from '../../common/components/CustomButton';

const styles = () => ({
  searchCardWrapper: {
    marginBottom: '30px',
    padding: '24px',
    alignContent: 'center'
  },
  searchWrapper: {
    display: 'flex',
    gap: '25px',
    flexWrap: 'wrap',
    alignItems: 'center'
  }
});

const AdminPromptComponent = (props) => {
  const {
    chatgptAdminPrompts,
    chatgptAdmin,
    ChatGptGetAdminPromptsStatus,
    ChatGptUtilityAddAdminStatus,
    ChatGptEditAdminPromptsStatus,
    userPermissions,
    language,
    classes
  } = props;
  const history = useHistory();
  const [archived, setArchived] = useState(false);
  const [showCloneIcon, setShowCloneIcon] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [rowItem, setRowItem] = useState('');
  const [textFieldValue, setTextFieldValue] = useState('');
  // const [archiveDialogContent, setArchiveDialogContent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEvaluateDialog, setOpenEvaluateDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [tenantKey, setTenantKey] = useState('');
  const [userToken, setUserToken] = useState('');
  const [apiLoading, setApiLoading] = useState(false);
  const permissionsList = toJS(userPermissions.permissionList.permissions);
  const [promptStatusValue, setpromptStatusValue] = useState('LIVE');
  const [promptTypeValue, setpromptTypeValue] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);

  const promptStatusList = [
    { id: 'Live', value: 'Live' },
    { id: 'Archived', value: 'Archived' },
    { id: 'Draft', value: 'Draft' },
    { id: 'Experiment', value: 'Experiment' }
  ];
  const [types, setTypes] = useState([]);
  const [promptResponseData, setPromptResponseData] = useState([]);
  const getPromptsData = async (tenantIdVal) => {
    setApiLoading(true); // Show the loader

    const headers = {
      Authorization: `Bearer ${userToken}`
    };
    const response = await axios.post(
      `${env.EVERGREEN_API_BASE_URL}${constants.GET_ADMIN_PROMPTS_URL}`,
      { tenantId: tenantIdVal },
      { headers }
    );
    if (response && response.data && response.data.data) {
      const responseData = response.data.data;
      setPromptResponseData(responseData);
      setArchived(true);
      setShowCloneIcon(true);
    }
    setApiLoading(false);
  };

  useEffect(() => {
    const token = utils.getToken();
    setUserToken(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userToken)) {
      if (history.location.pathname === '/aiadmin/channels/promptmgt') {
        setTenantKey('channels');
      }
      if (history.location.pathname === '/aiadmin/aistudytools/promptmgt') {
        setTenantKey('AiStudyTool');
      }
    }
  }, [userToken]);

  useEffect(() => {
    let tenantKeyName = '';
    if (history.location.pathname === '/aiadmin/channels/promptmgt') {
      tenantKeyName = 'channels';
    }
    if (history.location.pathname === '/aiadmin/aistudytools/promptmgt') {
      tenantKeyName = 'AiStudyTool';
    }
    const tenantVal = tenantKeyName === 'AiStudyTool' ? 'aiStudyTools' : 'channels';
    chatgptAdmin.fetchTenantData(tenantVal);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_ADMIN_DATA, () => {
      const { groups } = chatgptAdmin;
      if (groups && groups.length > 0) {
        const typesData = groups.filter((item) => item.name === 'PromptTypes')[0];
        setTypes(typesData);
      }
    });
  }, []);

  const changeToRawText = (promptVal) => {
    const { promptStart, promptEnd } = promptVal;
    const promptStartRawText = promptStart?.map((item) => {
      return { ...item, content: JSON.stringify(item.content, null, 2).slice(1, -1) };
    });
    const promptEndRawText = promptEnd?.map((item) => {
      return { ...item, content: JSON.stringify(item.content, null, 2).slice(1, -1) };
    });
    return { ...promptVal, promptStart: promptStartRawText, promptEnd: promptEndRawText };
  };

  const onRowClick = (val) => {
    setIsEdit(true);
    setOpenDialog(true);
    setSelectedValue(changeToRawText(val));
    setIsExpanded(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setIsExpanded(true);
  };

  const handleAddNewPrompts = () => {
    setOpenDialog(true);
    setSelectedValue({});
    setIsEdit(false);
  };

  const handleTextFieldHandler = (e) => {
    e.preventDefault();
    setTextFieldValue(e.target.value);
  };

  const handleArchivedclick = (rowData) => {
    // setArchiveDialogContent('');
    setRowItem(rowData);
    setOpenArchiveDialog(true);
  };

  const handleArchivedClose = () => {
    setOpenArchiveDialog(false);
    setTextFieldValue('');
  };

  const getNextVersion = (versionValue) => {
    const regex = /^(\d+)\.(\d+)\.(\d+)$/;
    const match = regex.exec(versionValue);
    const majorVersion = parseFloat(match[1]);
    const minorVersion = parseFloat(match[2]);
    const patchVersion = parseFloat(match[3]);
    return `${majorVersion}.${minorVersion}.${patchVersion + 1}`;
  };

  const handleClone = async (item) => {
    if (item.version) {
      const versionData = [...promptResponseData]
        .filter((val) => val.type === item.type)
        .map((d) => ({
          version: d.version
        }));
      const sortedVersionData = versionData.sort((a, b) => {
        const lowNumArr = a.version.split('.').map(Number);
        const highNumArr = b.version.split('.').map(Number);
        /* eslint-disable-next-line */
        for (let i = 0; i < lowNumArr.length; i++) {
          if (lowNumArr[i] !== highNumArr[i]) {
            return highNumArr[i] - lowNumArr[i];
          }
        }
        return 0;
      });
      const payload = {
        type: item.type && item.type.toUpperCase(),
        status: constants.PROMPT_DRAFT_STATUS,
        version: getNextVersion(sortedVersionData[0].version),
        tenantId: tenantKey === 'AiStudyTool' ? env.TENANT_ID.AI_STUDY_TOOLS : env.TENANT_ID.CHANNELS,
        tenantName: tenantKey,
        promptStart: item.promptStart,
        promptEnd: item.promptEnd
      };
      await chatgptAdminPrompts.saveAdminPromptsData(payload);
    }
    handleClose();
  };

  const handleConfirmAction = async () => {
    if (textFieldValue) {
      const payload = {
        ...rowItem,
        type: rowItem.type && rowItem.type.toUpperCase(),
        status: 'LIVE',
        comments: textFieldValue
      };
      await chatgptAdminPrompts.updateAdminPromptsData(payload);
    }
  };

  const handlePromptStatusChange = (event) => {
    setpromptStatusValue(event.target.value);
  };

  const handleInputChange = (event) => {
    setpromptTypeValue(event.target.value);
  };

  const handleEvaluate = (rowData) => {
    setOpenEvaluateDialog(true);
    setSelectedValue(rowData);
  };

  const handleEvaluateClose = () => {
    setOpenEvaluateDialog(false);
    setSelectedValue({});
  };

  const handleSearch = () => {
    setOpenDialog(false);
    setIsEdit(false);
    setSelectedValue({});
    const tenantIdVal = tenantKey === 'AiStudyTool' ? env.TENANT_ID.AI_STUDY_TOOLS : env.TENANT_ID.CHANNELS;
    getPromptsData(tenantIdVal);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const toggleExpandCollapse = () => {
    if (openDialog) {
      setOpenDialog(false);
      setIsEdit(false);
      setSelectedValue({});
    }
    setIsExpanded(!isExpanded); // Toggle expand/collapse
  };

  const handleCellClick = (rowData, columnId) => {
    if (columnId === 'restore') handleArchivedclick(rowData);
    if (columnId === 'clone') handleClone(rowData);
    if (columnId === 'evaluate') handleEvaluate(rowData);
  };

  const getColumnConfig = () => {
    let prepareColumnConfig = [...ColumnConfig.AdminPromptsDataGridColumnNew()];
    if (archived) {
      prepareColumnConfig = [
        ...prepareColumnConfig,
        {
          id: 'restore',
          label: 'Restore',
          columnWidth: '50px',
          isClickableIcon: <RestartAlt />
        }
      ];
    }
    if (showCloneIcon) {
      prepareColumnConfig = [
        ...prepareColumnConfig,
        {
          id: 'clone',
          label: 'Clone',
          columnWidth: '50px',
          isClickableIcon: <ContentCopy />
        }
      ];
      if (promptStatusValue === 'Draft' || promptStatusValue === 'Experiment') {
        prepareColumnConfig = [
          ...prepareColumnConfig,
          {
            id: 'evaluate',
            label: 'Evaluate',
            columnWidth: '50px',
            isClickableIcon: <Preview />
          }
        ];
      }
    }
    return prepareColumnConfig;
  };

  const theme = useTheme();

  return (
    <>
      <Card
        className={classes.searchCardWrapper}
        style={{
          backgroundColor: '#f8f6fa',
          border: `2px solid ${theme.palette.border.darkBlue}`
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          {/* Filters and Search */}
          <Box className={classes.searchWrapper}>
            <Box>
              <CustomSelect
                label="Search by Status"
                styleProp={{ height: '40px', borderRadius: '8px', labelTop: '-6px' }}
                value={promptStatusValue}
                name="promptStatus"
                onChange={handlePromptStatusChange}
                options={promptStatusList.map((status) => ({
                  name: status.id,
                  label: status.value
                }))}
              />
            </Box>
            <Box>
              <CustomSelect
                label="Search By Type"
                styleProp={{ height: '40px', borderRadius: '8px', labelTop: '-6px' }}
                value={promptTypeValue}
                name="promptType"
                onChange={handleInputChange}
                options={
                  types?.properties?.map((item) => ({
                    name: item.id,
                    label: item.display_name
                  })) || []
                }
              />
            </Box>
            <Box>
              <CustomDatePicker
                customStyle={{ maxWidth: '170px' }}
                label="Start Date"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            <Box>
              <CustomDatePicker
                customStyle={{ maxWidth: '170px' }}
                label="End Date"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
            <Box>
              <CustomButton
                label="Search"
                onClick={handleSearch}
                style={{
                  height: '40px',
                  fontSize: '14px',
                  padding: '8px 16px'
                }}
              />
            </Box>
            <Box>
              <CustomTooltip
                content={
                  !permissionsList.includes('admin_can_edit') ? (
                    <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                  ) : (
                    <FormattedMessage {...language.getText('admin.ADD_NEW_PROMPT')} />
                  )
                }
              >
                <span>
                  <CustomButton
                    label="Add New Prompt"
                    onClick={handleAddNewPrompts}
                    disabled={!permissionsList.includes('admin_can_edit')}
                    style={{ height: '40px', width: '180px', fontSize: '12px' }}
                    icon={<AddIcon />}
                  />
                </span>
              </CustomTooltip>
            </Box>
          </Box>

          {/* Add New Prompt Button, positioned fixed in the top-right corner */}
        </Grid>
      </Card>
      <LoadingHandler
        loading={
          apiLoading ||
          ChatGptGetAdminPromptsStatus.isPending ||
          ChatGptUtilityAddAdminStatus.isPending ||
          ChatGptEditAdminPromptsStatus.isPending
        }
        loadingContent={<CustomLoader />}
        content={
          <Grid container spacing={1} style={{ display: 'flex', flexWrap: 'wrap', height: '100%' }}>
            {!(openDialog && !isEdit) && (
              <Grid item xs={isExpanded ? 12 : 6}>
                {promptResponseData.length === 0 ? (
                  <NoRecords />
                ) : (
                  <>
                    <Paper
                      style={{
                        overflowY: 'auto',
                        border: '2px solid',
                        borderColor: theme.palette.background.darkBlue,
                        paddingBottom: promptResponseData.length > 0 && !openDialog ? '50px' : '0',
                        height: '100%'
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: theme.palette.background.darkBlue,
                          color: theme.palette.text.primary,
                          display: 'flex',
                          justifyContent: 'flex-end',
                          borderRadius: '5px 5px 0 0',
                          paddingRight: '10px',
                          borderBottom: '2px solid #fff',
                          height: '40px'
                        }}
                      >
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                          onClick={toggleExpandCollapse}
                        >
                          <IconButton sx={{ color: '#fff' }}>
                            {isExpanded ? <ArrowBack /> : <ArrowForward />}
                          </IconButton>
                          <Box>{isExpanded ? 'Collapse' : 'Expand'}</Box>
                        </Box>
                      </Box>

                      <CustomTable
                        columns={getColumnConfig()}
                        data={promptResponseData}
                        onRowClick={onRowClick}
                        onCellClick={handleCellClick}
                        disableIconBtn={!permissionsList.includes('admin_can_edit')}
                      />
                    </Paper>
                  </>
                )}
              </Grid>
            )}
            {openDialog && (
              <Grid item xs={isEdit ? 6 : 12}>
                <Paper
                  style={{
                    overflowY: 'auto',
                    border: '2px solid',
                    borderColor: theme.palette.background.darkBlue,
                    // paddingBottom: '50px',
                    height: '100%'
                  }}
                >
                  {openDialog ? (
                    <Box style={{ flex: 1, padding: '16px', overflowY: 'auto' }}>
                      <ExpandableCard
                        header={isEdit ? 'Edit Prompt Details' : 'Add New Prompt'}
                        body={
                          <AdminPromptDialog
                            openDialog={openDialog}
                            handleClose={handleClose}
                            selectedValue={selectedValue}
                            isEdit={isEdit}
                            tenantKey={tenantKey}
                            permissionsList={permissionsList}
                            language={language}
                          />
                        }
                        maxHeight={isEdit ? '122vh' : '65vh'} // Adjust height as per your requirements
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        color: theme.palette.text.secondary,
                        fontSize: '16px',
                        paddingTop: '30px'
                      }}
                    >
                      No Data Found
                    </Box>
                  )}
                </Paper>
              </Grid>
            )}
          </Grid>
        }
      />
      {openArchiveDialog && (
        <CustomDialog
          open={openArchiveDialog}
          title={constants.ADMIN_PROMPT_RESTORE_DIALOG_TEXT}
          onConfirm={handleConfirmAction}
          onCancel={handleArchivedClose}
          confirmText="Confirm"
          cancelText="Cancel"
          content={
            <Box sx={{ marginTop: '8px' }}>
              <TextField
                id="archived_text"
                label="Add Your Comments Here"
                multiline
                fullWidth
                rows={2}
                variant="outlined"
                value={textFieldValue}
                onChange={(e) => handleTextFieldHandler(e)}
                required
              />
            </Box>
          }
        />
      )}
      {openEvaluateDialog && (
        <AdminPromptEvaluateDialog
          openDialog={openEvaluateDialog}
          handleClose={handleEvaluateClose}
          selectedValue={selectedValue}
        />
      )}
    </>
  );
};

AdminPromptComponent.propTypes = {
  chatgptAdmin: shapes.modelOf(ChatgptAdmin).isRequired,
  chatgptAdminPrompts: shapes.modelOf(chatGptAdminPromptsData).isRequired,
  ChatGptGetAdminPromptsStatus: shapes.state.isRequired,
  ChatGptUtilityAddAdminStatus: shapes.state.isRequired,
  ChatGptEditAdminPromptsStatus: shapes.state.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  language: shapes.language.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
  inject(
    'chatgptAdmin',
    'chatgptAdminPrompts',
    'ChatGptGetAdminPromptsStatus',
    'ChatGptUtilityAddAdminStatus',
    'ChatGptEditAdminPromptsStatus',
    'userPermissions',
    'language'
  )(observer(AdminPromptComponent))
);
