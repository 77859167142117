import Framework, { shapes } from '@greenville/framework';
import { Box, Button, withStyles, Paper, Grid, IconButton } from '@material-ui/core';
import { Tooltip, useTheme } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import React, { useEffect, useState } from 'react';
// import TableComponent from '../../../../../common/components/Table';
import ColumnConfig from '../../../config/ColumnConfig';
import * as constants from '../../../../../common/constants';
import ChatgptUtilityIESUser from '../../../models/ChatgptIESUser';
import ChatgptUtilityMapping from '../../../models/ChatgptMapping';
import ResponseModel from '../../../models/Response';
import DynamicDialog from './DynamicDialog';
import DynamicListing from './DynamicListing';
import ChatFeedBackComponent from './ChatFeedBackComponent';
import SubTypeResponse from '../../../models/SubTypeModel';
import CustomTable from '../../../common/components/CustomTable';
import UserDetailComponent from '../../../common/components/UserDetailComponent';
import CustomLoader from '../../../common/components/CustomLoader';

const styles = () => ({
  // buttonStyle: {
  //   cursor: 'pointer',
  //   width: '170px',
  //   margin: '10px',
  //   fontSize: '14px',
  //   background: '#005d83 !important'
  // }
});

const DynamicDataGrid = React.memo((props) => {
  const {
    gridData,
    isNextPageKey,
    handleSearch,
    selectedTab,
    clusterState,
    ChatgptUtilityIESUserData,
    chatgptResponseUtility,
    chatgptMappingUtility,
    chatgptUtilityResponseStatus,
    tenantName,
    tenantTitle,
    ChatUtilitySubTypeResponse
  } = props;
  const isFeedback = selectedTab === 1;
  const [filteredArray, setFilteredArray] = useState([]);
  const [userEmailDetails, setUserEmailDetails] = useState({});
  const [userTocDetails, setUserTocDetails] = useState({});
  const [openDetailsView, setOpenDetailsView] = useState(false);
  const [loaderStatusResponse] = useState(true);
  const [dataValue, setDataValue] = useState({});
  const [detailViewLoader, setDetailViewLoader] = useState(false);
  const [rowDialogue, setRowDialogue] = useState({});
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [checkboxSelectedValue, setCheckboxSelectedValue] = useState({
    chat_intent: false,
    content_filter: false,
    explain_stand_alone: false
  });
  const theme = useTheme();

  useEffect(() => {
    const data = gridData && Array.isArray(gridData) && gridData;
    setFilteredArray(data);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        setUserEmailDetails(userData[userData.length - 1]);
      }
    });
    Framework.getEventManager().on(constants.SET_CHATGPT_MAPPING_DATA, () => {
      const { toc } = chatgptMappingUtility;
      if (toc.length > 0) {
        setUserTocDetails(toc);
      }
    });
    let rowDialogProcessed = {};
    let rowProcessed = {};
    Framework.getEventManager().on(constants.GET_CHATGPT_RESPONSE_MODEL_DATA, () => {
      const {
        summary,
        quiz,
        discuss,
        chat_enrichment,
        chat_intent,
        content_classifier,
        flashcards,
        topic,
        summary_enrichment,
        summaryTopic,
        quizValidateIntent,
        quizIntent,
        mcq,
        mcqMisconceptions,
        mcqSkillIdentifier,
        mcqQuestionGeneration,
        quizMcq,
        quizFreeResponse,
        freeResponse,
        mcqDistract,
        dictionarySummary,
        dictionaryQuiz,
        content_filter,
        explainStandalone,
        salutation,
        contentIdentifierUserSelection,
        problemSolve,
        summaryExplain,
        answerCyuMcq,
        problemSolveRectifier,
        expertDeskFreeResponse,
        expertDeskMcq,
        commonAuditData,
        commonIntentIdentifierData
      } = chatgptResponseUtility;
      if (chatgptResponseUtility) {
        const dictionarySummaryData = dictionarySummary && dictionarySummary.length > 0 ? dictionarySummary : [];
        const dictionaryQuizData = dictionaryQuiz && dictionaryQuiz.length > 0 ? dictionaryQuiz : [];
        const summaryData = summary && summary.length > 0 ? summary : [];
        const quizData = quiz && quiz.length > 0 && quiz ? quiz : [];
        const discussData = discuss && discuss.length > 0 && discuss ? discuss : [];
        const chatEnrichmentData = chat_enrichment && chat_enrichment.length > 0 ? chat_enrichment : [];
        const chatIntentData = chat_intent && chat_intent.length > 0 ? chat_intent : [];
        const flashcardsData = flashcards && flashcards.length > 0 ? flashcards : [];
        const topicData = topic && topic.length > 0 ? topic : [];
        const summaryEnrichmentData = summary_enrichment && summary_enrichment.length > 0 ? summary_enrichment : [];
        const summaryTopicData = summaryTopic && summaryTopic.length > 0 ? summaryTopic : [];
        const contentClassifier = content_classifier && content_classifier.length > 0 ? content_classifier : [];
        const quizValidateData = quizValidateIntent && quizValidateIntent.length > 0 ? quizValidateIntent : [];
        const quizIintentData = quizIntent && quizIntent.length > 0 ? quizIntent : [];
        const mcqData = mcq && mcq.length > 0 ? mcq : [];
        const mcqMisconceptionsData = mcqMisconceptions && mcqMisconceptions.length > 0 ? mcqMisconceptions : [];
        const mcqSkillIdentifierData = mcqSkillIdentifier && mcqSkillIdentifier.length > 0 ? mcqSkillIdentifier : [];
        const mcqQuestionGenerationData =
          mcqQuestionGeneration && mcqQuestionGeneration.length > 0 ? mcqQuestionGeneration : [];
        const quizMcqData = quizMcq && quizMcq.length > 0 ? quizMcq : [];
        const quizFreeResponseData = quizFreeResponse && quizFreeResponse.length > 0 ? quizFreeResponse : [];
        const freeResponseData = freeResponse && freeResponse.length > 0 ? freeResponse : [];
        const mcqDistractData = mcqDistract && mcqDistract.length > 0 ? mcqDistract : [];
        const contentFilterData = content_filter && content_filter.length > 0 ? content_filter : [];
        const explainStandaloneData = explainStandalone && explainStandalone.length > 0 ? explainStandalone : [];
        const salutationData = salutation && salutation.length > 0 ? salutation : [];
        const contentIdentifierUserSelectionData =
          contentIdentifierUserSelection && contentIdentifierUserSelection.length > 0
            ? contentIdentifierUserSelection
            : [];
        const problemSolveData = problemSolve && problemSolve.length > 0 ? problemSolve : [];
        const summaryExplainData = summaryExplain && summaryExplain.length > 0 ? summaryExplain : [];
        const answerCyuMcqData = answerCyuMcq && answerCyuMcq.length > 0 ? answerCyuMcq : [];
        const problemSolveRectifierData =
          problemSolveRectifier && problemSolveRectifier.length > 0 ? problemSolveRectifier : [];
        const expertDeskFreeResponseData =
          expertDeskFreeResponse && expertDeskFreeResponse.length > 0 ? expertDeskFreeResponse : [];
        const expertDeskMcqData = expertDeskMcq && expertDeskMcq.length > 0 ? expertDeskMcq : [];
        const commonAuditDataVal = commonAuditData && commonAuditData.length > 0 ? commonAuditData : [];
        const commonIntentIdentifierDataVal =
          commonIntentIdentifierData && commonIntentIdentifierData.length > 0 ? commonIntentIdentifierData : [];
        const combineData = [
          ...commonAuditDataVal,
          ...expertDeskFreeResponseData,
          ...expertDeskMcqData,
          ...problemSolveRectifierData,
          ...problemSolveData,
          ...contentIdentifierUserSelectionData,
          ...salutationData,
          ...explainStandaloneData,
          ...dictionarySummaryData,
          ...dictionaryQuizData,
          ...summaryData,
          ...discussData,
          ...quizData,
          ...chatEnrichmentData,
          ...chatIntentData,
          ...flashcardsData,
          ...topicData,
          ...contentClassifier,
          ...summaryEnrichmentData,
          ...summaryTopicData,
          ...quizValidateData,
          ...quizIintentData,
          ...mcqData,
          ...mcqMisconceptionsData,
          ...mcqSkillIdentifierData,
          ...mcqQuestionGenerationData,
          ...quizMcqData,
          ...quizFreeResponseData,
          ...freeResponseData,
          ...mcqDistractData,
          ...contentFilterData,
          ...summaryExplainData,
          ...answerCyuMcqData,
          ...commonIntentIdentifierDataVal
        ];
        /* eslint-disable no-param-reassign */
        if (combineData && combineData[0] && combineData[0].type === 'chat_intent') {
          try {
            combineData[0].botResponse = JSON.parse(combineData[0].botResponse);
          } catch (err) {
            const { botResponse } = combineData[0];
            combineData[0] = { ...combineData[0], botResponse: botResponse[0] };
          }
        }
        const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
        // const { prompt } = combineDatatoJS[0];
        if (combineDatatoJS[0] && Object.keys(combineDatatoJS[0]).length > 0) {
          rowProcessed = Object.entries(combineDatatoJS[0]).reduce((obj, [key, value]) => {
            if (key !== null && value !== null) {
              obj[key] = value;
            }
            return obj;
          }, {});
          if (rowProcessed.serverDateTime && isFeedback) {
            delete rowProcessed.serverDateTime;
          }
          if (rowDialogue && Object.keys(rowDialogue).length > 0) {
            rowDialogProcessed = Object.entries(rowDialogue).reduce((obj, [key, value]) => {
              if (key !== null && value !== null) {
                obj[key] = value;
              }
              return obj;
            }, {});
          }

          if (rowProcessed.type === 'chat_intent') {
            const { botResponse } = rowProcessed;
            let processBotResponse = '';
            try {
              processBotResponse = [JSON.parse(botResponse)];
            } catch (err) {
              const splitTwoText = botResponse.split('[BAD-GPT]');
              const normGPT = JSON.parse(splitTwoText[0].split('[NORM-GPT]')[1]);
              const badGPT = JSON.parse(splitTwoText[1]);
              processBotResponse = [normGPT, badGPT];
            }
            rowProcessed.botResponse = processBotResponse;
          }
          if (rowProcessed.type === constants.CONTEXT_IDENTIFIER_USER_SELECTION) {
            const { botResponse } = rowProcessed;
            rowProcessed.botResponse = [botResponse];
          }

          const rowChangedValue = {
            ...rowDialogProcessed,
            ...rowProcessed
          };

          if (isFeedback) {
            rowChangedValue.feedBackType = rowDialogProcessed.type || rowProcessed?.type;
          }

          /* eslint-enable camelcase */
          setDataValue(rowChangedValue);
        }
      }
      setDetailViewLoader(false);
    });
  }, [
    gridData,
    isNextPageKey,
    chatgptResponseUtility,
    rowDialogue,
    userEmailDetails,
    userTocDetails,
    ChatgptUtilityIESUserData
  ]);

  const handleRequestTabValue = (requestId, bookId) => {
    setCheckboxSelectedValue({
      chat_intent: true,
      content_filter: true,
      explain_stand_alone: true
    });
    ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: 'chat_intent' });
    ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: 'content_filter' });
    ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: 'explain_stand_alone' });
  };

  const handleEvent = (params) => {
    const { requestId, bookId, type } = params;
    const reqParam = { requestId, bookId };
    setCheckboxSelectedValue({
      chat_intent: false,
      content_filter: false,
      explain_stand_alone: false
    });
    ChatUtilitySubTypeResponse.resetStoreValues();
    setRowDialogue(params);
    setOpenDetailsView(true);
    setDetailViewLoader(true);
    chatgptResponseUtility.fetch(isFeedback ? reqParam : { ...reqParam, type });

    handleRequestTabValue(requestId, bookId); // Need to add Condition based on type
  };

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  // const onColumnClick = (val) => {
  //   sorting(val);
  // };
  const onRowClick = (val) => {
    if (isExpanded) toggleCollapse();
    handleEvent(val);
  };
  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((data) => data.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const handleToolTipAction = () => {
    handleSearch();
  };

  /* eslint-disable no-param-reassign */
  const handlePageChange = (e) => {
    e.preventDefault();
    let lastKey = {};
    if (isNextPageKey && isNextPageKey.ExclusiveStartKey) {
      const startKey = isNextPageKey.ExclusiveStartKey;
      lastKey = Object.entries(startKey).reduce((obj, [key, value]) => {
        if (key !== null && value !== null) {
          obj[key] = value;
        }
        return obj;
      }, {});
    }
    handleSearch({ lastKey: { ExclusiveStartKey: lastKey } });
  };

  const getColumnConfig = () => {
    const bookIdName = { bookId: tenantName !== 'Reader+' ? 'Course Id' : 'Book Id' };
    if (selectedTab === 0) {
      // return ColumnConfig.CommonDataGridColumn(bookIdName);
      return [
        {
          id: 'serverDateTime',
          label: `${constants.DATE_TIME} (GMT)`,
          columnWidth: '190px'
        },
        {
          id: 'bookId',
          label: 'Book Id',
          columnWidth: '160px'
        },
        {
          id: 'type',
          label: constants.TYPE,
          columnWidth: '100px'
        },
        {
          id: 'question',
          label: constants.GPT_TRENDS_USER_INPUT,
          columnWidth: '100px'
        },
        {
          id: 'userId',
          label: constants.USER_ID,
          columnWidth: '100px'
        }
      ];
    }
    if (selectedTab === 1) {
      return ColumnConfig.CommonFeedbackColumn(bookIdName);
    }
    if (selectedTab === 2) {
      if (clusterState === 'count') {
        return ColumnConfig.CommonTrendsDataGridColumn(bookIdName);
      }
    }
    return [];
  };
  return (
    <>
      {selectedTab === 0 || selectedTab === 1 || (selectedTab === 2 && clusterState === 'count') ? (
        <Box>
          <Grid container spacing={1} style={{ display: 'flex', flexWrap: 'wrap', height: '100%' }}>
            <Grid item xs={12} md={isExpanded ? 12 : 5}>
              <Paper
                style={{
                  overflowY: 'auto',
                  border: '2px solid',
                  borderColor: theme.palette.background.darkBlue,
                  // paddingBottom: '50px',
                  height: '100%'
                }}
              >
                <Box
                  sx={{
                    bgcolor: theme.palette.background.darkBlue,
                    color: theme.palette.text.primary,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    borderRadius: '5px 5px 0 0',
                    borderBottom: '2px solid #fff'
                  }}
                >
                  <Box
                    onClick={toggleCollapse}
                    sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', mx: '16px' }}
                  >
                    <IconButton color="#fff" title="Click Here to expand table">
                      {isExpanded ? <ArrowBack /> : <ArrowForward />}
                    </IconButton>
                    <Box>{isExpanded ? 'Collapse' : 'Expand'}</Box>
                  </Box>
                </Box>
                <CustomTable
                  columns={getColumnConfig()}
                  data={filteredArray}
                  paginationEnabled={false}
                  onRowClick={onRowClick}
                  mouseOverColumnId="userId"
                  onCellMouseOver={hanldeGETUserId}
                  toolTipBodyContent={
                    <UserDetailComponent handleToolTipAction={handleToolTipAction} userDetails={userEmailDetails} />
                  }
                />

                {isNextPageKey &&
                  isNextPageKey.ExclusiveStartKey &&
                  isNextPageKey.ExclusiveStartKey.userId &&
                  isNextPageKey.ExclusiveStartKey.userId.S !== null && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                      <Button
                        size="small"
                        color="primary"
                        // className={classes.buttonStyle}
                        variant="contained"
                        onClick={(e) => handlePageChange(e)}
                      >
                        <Tooltip title="Load next set of data">Load More</Tooltip>
                      </Button>
                    </Box>
                  )}
              </Paper>
            </Grid>
            {!isExpanded && dataValue && (
              <>
                <Grid item xs={12} md={4}>
                  <Paper
                    padding={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    style={{
                      border: '2px solid',
                      borderColor: theme.palette.background.darkBlue,
                      padding: '8px',
                      height: '85vh',
                      overflow: 'auto',
                      scrollbarWidth: 'thin'
                    }}
                  >
                    {!detailViewLoader && openDetailsView ? (
                      <DynamicDialog
                        dataValue={dataValue}
                        // userTocDetails={userTocDetails}
                        filterPromt={constants.FILTER_PROMPT}
                        isFeedback={isFeedback}
                        loaderStatusResponse={loaderStatusResponse}
                        chatgptUtilityResponseStatus={chatgptUtilityResponseStatus}
                        tenantTitle={tenantTitle}
                        // checkboxSelectedValue={checkboxValue}
                      />
                    ) : (
                      <CustomLoader />
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Paper
                    padding={3}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    style={{
                      border: '2px solid',
                      borderColor: theme.palette.background.darkBlue,
                      padding: '16px 0',
                      height: '85vh',
                      overflow: 'auto',
                      scrollbarWidth: 'thin'
                    }}
                  >
                    {!detailViewLoader && openDetailsView ? (
                      <ChatFeedBackComponent dataValue={dataValue} checkboxSelectedValue={checkboxSelectedValue} />
                    ) : (
                      <CustomLoader />
                    )}
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      ) : (
        <Paper>
          <Grid item xs={12}>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
              <br />
              <DynamicListing listingData={gridData} tenantName={tenantName} tenantTitle={tenantTitle} />
            </Box>
          </Grid>
        </Paper>
      )}
    </>
  );
});

DynamicDataGrid.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  gridData: PropTypes.array.isRequired,
  // classes: PropTypes.object.isRequired,
  // handleAscending: PropTypes.func.isRequired,
  isNextPageKey: PropTypes.object,
  selectedTab: PropTypes.string,
  clusterState: PropTypes.string.isRequired,
  chatgptResponseUtility: shapes.modelOf(ResponseModel).isRequired,
  chatgptUtilityResponseStatus: shapes.state.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  chatgptMappingUtility: shapes.modelOf(ChatgptUtilityMapping).isRequired,
  tenantName: PropTypes.string,
  tenantTitle: PropTypes.string,
  ChatUtilitySubTypeResponse: shapes.modelOf(SubTypeResponse).isRequired
};

DynamicDataGrid.defaultProps = {
  isNextPageKey: {},
  selectedTab: '',
  tenantName: '',
  tenantTitle: ''
};

export default withStyles(styles)(
  observer(
    inject(
      'chatgptResponseUtility',
      'chatgptUtilityResponseStatus',
      'ChatgptUtilityIESUserData',
      'chatgptMappingUtility',
      'ChatUtilitySubTypeResponse'
    )(DynamicDataGrid)
  )
);
