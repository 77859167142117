import { types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const ReportDownloadModel = types
  .model('ReportDownload', {
    errorMessage: types.maybeNull(types.string),
    successMessage: types.maybeNull(types.string),
    isReportGenerated: types.boolean
  })
  .actions((self) => ({
    generateReport(payload) {
      Framework.getEventManager().publish(constants.REPORT_DOWNLOAD_SQS_SERVICE, { payload });
    },

    async downloadReport(fileName) {
      try {
        const response = await Framework.getEventManager().publish(
          constants.REPORT_DOWNLOAD_S3_URL.replace('{fileName}', fileName),
          { payload: { fileName } }
        );

        if (response?.data?.signedUrl) {
          window.location.href = response.data.signedUrl;
        }

        self.setResponse({
          successMessage: 'Report downloaded successfully.',
          errorMessage: null,
          isReportGenerated: true
        });
      } catch (error) {
        self.setResponse({
          successMessage: null,
          errorMessage: error.message || 'Failed to download the report.',
          isReportGenerated: false
        });
      }
    }
  }));

export default ReportDownloadModel;
