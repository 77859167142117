import React, { useState, useEffect } from 'react';
import { Box, IconButton, Card } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const StyledCard = styled(Card)(({ fullScreen }) => ({
  width: fullScreen ? '80vw' : '100%',
  minHeight: fullScreen ? '50vh' : 'auto',
  borderRadius: 8,
  boxShadow: fullScreen ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)',
  position: fullScreen ? 'fixed' : 'relative',
  top: fullScreen ? '50%' : 'auto',
  left: fullScreen ? '50%' : 'auto',
  transform: fullScreen ? 'translate(-50%, -50%)' : 'none',
  zIndex: fullScreen ? 1000 : 1,
  transition: 'all 0.3s ease',
  backgroundColor: '#e8f5e9',
  border: '1px solid #333',
  margin: '8px 0'
}));

const BackgroundOverlay = styled('div')(({ isVisible }) => ({
  display: isVisible ? 'block' : 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999 // Ensure the overlay is behind the popup
}));

const ExpandableCard = ({ header, body, maxHeight }) => {
  const [isStandaloneFullScreen, setIsStandaloneFullScreen] = useState(false); // State for expand functionality
  const theme = useTheme();

  const toggleStandaloneFullScreen = () => {
    setIsStandaloneFullScreen(!isStandaloneFullScreen);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      toggleStandaloneFullScreen();
    }
  };

  useEffect(() => {
    if (isStandaloneFullScreen) {
      document.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isStandaloneFullScreen]);

  return (
    <>
      <BackgroundOverlay isVisible={isStandaloneFullScreen} />
      <StyledCard fullScreen={isStandaloneFullScreen}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderBottom: '1px solid #333',
            marginBottom: '8px',
            fontSize: '12px',
            fontWeight: 'bold',
            background: theme.palette.background.darkBlue,
            padding: '0 8px'
          }}
        >
          <Box sx={{ paddingRight: '10px' }}>{header}</Box>
          <IconButton size="small" onClick={toggleStandaloneFullScreen} color="secondary">
            {isStandaloneFullScreen ? <FullscreenExit /> : <Fullscreen />}
          </IconButton>
        </Box>

        <Box
          sx={{
            fontSize: '12px',
            maxHeight: maxHeight || '300px',
            overflowY: 'auto',
            padding: '8px 16px',
            scrollbarWidth: 'thin',
            scrollbarColor: 'gray white'
          }}
        >
          {body}
        </Box>
      </StyledCard>
    </>
  );
};

ExpandableCard.propTypes = {
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  maxHeight: PropTypes.string.isRequired
};

export default ExpandableCard;
