import { Grid, TextField, makeStyles, TextareaAutosize } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  flexBorder: {
    border: '1px solid grey',
    margin: '75px',
    borderRadius: '4px'
  },
  gridColumns: {
    display: 'flex',
    justifyContent: 'center'
  },
  flexContainerBottom: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid grey',
    borderBottom: '1px solid grey',
    padding: '10px'
  },
  ButtonClass: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px'
  },
  textAreaSize: {
    width: '77%'
  },
  labelColumns: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '60px',
    paddingBottom: '10px'
  },
  subLable: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    width: '77%',
    marginTop: '5px',
    marginBottom: '5px !important'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid grey'
  }
});

const DynamicTestFields = () => {
  const history = useHistory();
  const classes = useStyles();
  const [tenant, setTenant] = useState('');
  const [inputValue, setInputValue] = useState({
    tenantId: '',
    tenantKey: '',
    courseId: '',
    pageId: '',
    userId: '',
    message: '',
    tutorName: '',
    berlinEnv: 'qaInactive',
    expandMode: false,
    productModel: ''
  });

  useEffect(() => {
    if (tenant === 'PVS') {
      setInputValue({
        ...inputValue,
        tenantId: '886a344d-54c0-4f87-82ae-3fab16af7e9e',
        tenantKey: '409ffedf-606f-430d-936f-54e996e9fbc7',
        productModel: 'EXTERNAL_PVS'
      });
    } else {
      setInputValue({
        ...inputValue,
        tenantId: '',
        tenantKey: '',
        productModel: ''
      });
    }
  }, [tenant]);

  const handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;
    setInputValue({ ...inputValue, [name]: value });
  };

  const handleTenant = (event) => {
    setTenant(event.target.value);
  };

  const handleSave = () => {
    history.push('/configure/aistudytest', inputValue); // Pass the data via state
  };

  return (
    <>
      <div className={classes.flexBorder}>
        <Box>
          <Grid item xs={16}>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              Configure AI Study Tools
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ width: '100%', top: '13%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid container direction="row" style={{ justifyContent: 'center' }}>
              <Grid item xs={10} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  {/* eslint-disable */}
                  <FormControl sx={{ mt: 1, width: '45%' }} size="small" required>
                    <InputLabel id="tenant">Select Tenant</InputLabel>
                    <Select label="Select Tenant" name="tenant" onChange={handleTenant} value={tenant}>
                      <MenuItem value={'aiStudyTools'}>AI Study Tools</MenuItem>
                      <MenuItem value={'channels'}>Channels</MenuItem>
                      <MenuItem value="PVS">Pearson Virtual School (PVS)</MenuItem>
                      <MenuItem value={'serviceNow'}>Peri</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Tenant ID"
                    style={{ minWidth: '45%' }}
                    margin="dense"
                    name="tenantId"
                    onChange={handleInputChange}
                    value={inputValue.tenantId}
                    variant="outlined"
                    required
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ justifyContent: 'center' }}>
              <Grid item xs={10} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  <TextField
                    label="Tenant Key"
                    style={{ minWidth: '45%' }}
                    margin="dense"
                    name="tenantKey"
                    onChange={handleInputChange}
                    value={inputValue.tenantKey}
                    variant="outlined"
                    required
                  />
                  <TextField
                    label="Product Model"
                    style={{ minWidth: '45%' }}
                    margin="dense"
                    name="productModel"
                    onChange={handleInputChange}
                    value={inputValue.productModel}
                    variant="outlined"
                    required
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ justifyContent: 'center' }}>
              <Grid item xs={10} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  {/* eslint-disable */}
                  <FormControl sx={{ mt: 1, width: '45%' }} size="small" required>
                    <InputLabel id="expandMode">Expand Mode</InputLabel>
                    <Select
                      label="Expand Mode"
                      name="expandMode"
                      onChange={handleInputChange}
                      value={inputValue.expandMode}
                    >
                      <MenuItem value={true}>TRUE</MenuItem>
                      <MenuItem value={false}>FALSE</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ mt: 1, width: '45%' }} size="small" required>
                    <InputLabel id="berlinEnv">Berlin Environment</InputLabel>
                    <Select
                      label="Berlin Environment"
                      name="berlinEnv"
                      onChange={handleInputChange}
                      value={inputValue.berlinEnv}
                    >
                      <MenuItem value={'stage'}>Stage</MenuItem>
                      <MenuItem value={'qaInactive'}>Dev</MenuItem>
                      <MenuItem value={'prod'}>Prod</MenuItem>
                    </Select>
                  </FormControl>
                  {/* eslint-disable */}
                </Box>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ justifyContent: 'center' }}>
              <Grid item xs={10} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  <TextField
                    label="Course ID"
                    style={{ minWidth: '45%' }}
                    margin="dense"
                    name="courseId"
                    onChange={handleInputChange}
                    value={inputValue.courseId}
                    variant="outlined"
                    required
                  />
                  <TextField
                    label="Heading"
                    style={{ minWidth: '45%' }}
                    margin="dense"
                    name="tutorName"
                    onChange={handleInputChange}
                    value={inputValue.tutorName}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ justifyContent: 'center' }}>
              <Grid item xs={10} direction="row">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                  <TextField
                    label="Page ID"
                    style={{ minWidth: '45%' }}
                    margin="dense"
                    name="pageId"
                    onChange={handleInputChange}
                    value={inputValue.pageId}
                    variant="outlined"
                  />
                  <TextField
                    label="User ID"
                    style={{ minWidth: '45%' }}
                    margin="dense"
                    name="userId"
                    onChange={handleInputChange}
                    value={inputValue.userId}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container direction="row" style={{ justifyContent: 'center' }}>
            <Grid item xs={12} direction="row">
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <InputLabel className={classes.subLable}>Welcome Message</InputLabel>
                <TextareaAutosize
                  className={classes.textAreaSize}
                  name="message"
                  onChange={handleInputChange}
                  variant="outlined"
                  minRows={6}
                  maxRows={12}
                  value={inputValue.message}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <br />
        <div className={classes.flexContainerBottom}>
          <div className={classes.ButtonClass}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSave()}
                sx={{ background: '#005d83' }}
                disabled={[
                  tenant,
                  inputValue.tenantId,
                  inputValue.tenantKey,
                  inputValue.courseId,
                  inputValue.productModel
                ].includes('')}
              >
                LAUNCH
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicTestFields;
