import Framework, { BaseService } from '@greenville/framework';
import axios from 'axios';
import * as constants from '../../../common/constants';

export default class ReportS3Service extends BaseService {
  constructor() {
    super(constants.REPORT_DOWNLOAD_SERVICE, 'ReportS3Service');
  }

  async getSignedUrl(fileName) {
    const response = await axios.get(`/api/reports/download/${fileName}`);
    return this.handleResponse(response);
  }

  handleResponse(response) {
    if (response && response.data && response.data.signedUrl) {
      return response.data.signedUrl;
    }
    throw new Error('Download link not found.');
  }

  handleError(error) {
    throw new Error(error.response?.data?.message || 'Download link not found.');
  }

  async downloadReport(fileName) {
    const signedUrl = await this.getSignedUrl(fileName);

    if (signedUrl) {
      // If signed URL is available, download the report
      const a = document.createElement('a');
      a.href = signedUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      const notification = {
        open: true,
        type: 'success',
        message: 'Report downloaded successfully.'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    } else {
      const errorMessage = signedUrl || 'Failed to download the report.';
      const notification = {
        open: true,
        type: 'error',
        message: errorMessage
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    }
  }
}
