import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TextField, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import * as constants from '../../../../common/constants';
import { AIADMIN_PRIMARY_COLOR } from '../../ChatGptTheme';
import CustomTooltip from './CustomToolTip';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: AIADMIN_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: AIADMIN_PRIMARY_COLOR
      },
      current: {
        color: AIADMIN_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: AIADMIN_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: AIADMIN_PRIMARY_COLOR
        }
      },
      current: {
        color: AIADMIN_PRIMARY_COLOR
      }
    }
  }
});

function CustomDatePicker({ handleDateChange, value, label, dataKey, disabled, customStyle, styleProp }) {
  const theme = useTheme();
  const defaultStyleObj = { minWidth: '32%', marginTop: 'inherit' };
  const tooltipContent = `Pick ${label}`;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          TextFieldComponent={(props) => (
            <TextField
              {...props}
              sx={{
                '& label': {
                  top: styleProp.labelTop
                },
                '& .MuiInputLabel-root': {
                  color: disabled ? 'gray' : theme.palette.primary.main
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme.palette.primary.main
                },
                '& .MuiOutlinedInput-root': {
                  padding: '8px',
                  height: styleProp.height || '40px',
                  maxWidth: styleProp.width || '175px',
                  border: 'none',
                  '& fieldset': {
                    borderColor: theme.palette.primary.main
                  },
                  '&:hover fieldset': {
                    border: disabled ? 'none' : '2px solid gray'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main
                  }
                }
              }}
            />
          )}
          format={constants.MM_DD_YYYY_FORMAT}
          autoOk
          size="small"
          variant="inline"
          style={customStyle || defaultStyleObj}
          inputVariant="outlined"
          disableFuture
          value={value}
          label={label}
          onChange={(date) => handleDateChange(date, dataKey)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            component: (props) => (
              <CustomTooltip content={tooltipContent} bgColor="#333">
                <span {...props} />
              </CustomTooltip>
            )
          }}
          disabled={disabled}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

CustomDatePicker.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  customStyle: PropTypes.object,
  styleProp: PropTypes.object
};
CustomDatePicker.defaultProps = {
  disabled: false,
  customStyle: {},
  styleProp: {}
};

export default CustomDatePicker;
