import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Typography, TextField, Autocomplete, MenuItem, Box, useTheme } from '@mui/material';
import * as constants from '../../../../common/constants';

const useStyles = makeStyles(() => ({
  subTitle: {
    fontSize: '0.8rem',
    paddingTop: '2px'
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const AutocompleteComponent = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const [componentDefaultValue, setComponentDefaultValue] = useState({});
  const {
    options,
    onAutoCompleteChange,
    stateVal,
    errorVal,
    name,
    label,
    onInputChange,
    componentDefaultValue,
    styleProp
  } = props;
  const filterOptions = (selectOptions, state) => {
    const newOptions = [];
    if (state?.inputValue) {
      const typedValue = state?.inputValue?.toLowerCase();
      options.forEach((element) => {
        if (element?.title?.toLowerCase().includes(typedValue) || element?.bookId?.toLowerCase().includes(typedValue))
          newOptions.push({
            author: element?.author,
            bookId: element?.bookId,
            id: element?.id,
            status: element?.status,
            title: element?.title.trim()
          });
      });
    } else {
      selectOptions.forEach((element) => {
        element?.title?.trim();
        newOptions.push({
          author: element?.author,
          bookId: element?.bookId,
          id: element?.id,
          status: element?.status,
          title: element?.title.trim()
        });
      });
    }
    newOptions.sort((a, b) => a?.title?.localeCompare(b?.title));
    newOptions.forEach((item, i) => {
      if (item?.title === 'All Books') {
        newOptions.splice(i, 1);
        newOptions.unshift(item);
      }
    });
    return newOptions;
  };
  return (
    <Autocomplete
      // popupIcon=""
      styleProp
      defaultValue={componentDefaultValue}
      classes={classes}
      name={name}
      options={options}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      filterOptions={filterOptions}
      // autoComplete
      // filterSelectedOptions
      // noOptionsText="Please type here to search"
      getOptionLabel={(option) => (option.title ? option.title : '')}
      renderOption={(prop, option) => (
        <>
          <MenuItem {...prop}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {option.title}
              {option.bookId && option.bookId !== constants.ALL_BOOKS && (
                <Typography variant="body2" color="textSecondary">
                  ({option.bookId})
                </Typography>
              )}
            </Box>
          </MenuItem>
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            minWidth: '175px',
            '& label': {
              top: styleProp.labelTop
            },
            '& .MuiInputLabel-root': {
              color: theme.palette.primary.main
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette.primary.main
            },
            '& .MuiOutlinedInput-root': {
              padding: '8px',
              height: styleProp.height,
              border: 'none',
              '& fieldset': {
                borderColor: 'black'
              },
              '&:hover fieldset': {
                border: '2px solid gray'
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main
              }
            }
          }}
          name={name}
          label={label}
          variant="outlined"
          size="small"
          value={stateVal}
          inputProps={{ ...params.inputProps, value: stateVal }}
          InputLabelProps={stateVal ? { ...params.InputLabelProps, shrink: true } : { ...params.InputLabelProps }}
        />
      )}
      onChange={(event, newValue) => onAutoCompleteChange(event, newValue)}
      onInputChange={(event, newInputValue) => onInputChange(event, newInputValue)}
      error={errorVal}
      style={{ minWidth: '32%' }}
    />
  );
};

AutocompleteComponent.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onAutoCompleteChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  stateVal: PropTypes.string.isRequired,
  errorVal: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  componentDefaultValue: PropTypes.string,
  styleProp: PropTypes.object
};

AutocompleteComponent.defaultProps = {
  componentDefaultValue: null,
  styleProp: {}
};

export default AutocompleteComponent;
