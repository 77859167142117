import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';

const CustomSelect = ({
  label,
  value,
  name,
  onChange,
  options,
  styleProp,
  error = false,
  helperText = '',
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event);
  };

  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={`${label}-label`} style={{ top: styleProp.labelTop }}>
        {label}
      </InputLabel>
      <Select
        style={{ height: styleProp.height, borderRadius: styleProp.borderRadius, minWidth: '175px' }}
        labelId={`${label}-label`}
        value={selectedValue}
        name={name}
        onChange={handleChange}
        label={label}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300
            }
          }
        }}
        {...props}
      >
        <MenuItem value="" disabled>
          <em>{label}</em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.name}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

// Prop validation with PropTypes
CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  styleProp: PropTypes.object
};

// Default values for optional props
CustomSelect.defaultProps = {
  error: false,
  helperText: '',
  value: '',
  styleProp: {}
};

export default CustomSelect;
