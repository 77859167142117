import React from 'react';
import { Box, Typography, ThemeProvider, CssBaseline } from '@mui/material';
import { getDarkTheme } from '../../ChatGptTheme';

const NoRecords = () => {
  const theme = getDarkTheme();
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            textAlign: 'center',
            mt: 2
          }}
        >
          <Typography>Please search to fetch data...</Typography>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default NoRecords;
