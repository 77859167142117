import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { useTheme, Box, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Loader from '../../../../../common/Loader';
import * as constants from '../../../../../common/constants';
import SubTypeResponse from '../../../models/SubTypeModel';
import DynamicPromt from './DynamicPrompt';
import AIStudyToolResponse from './AIStudyToolResponse';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '60vh',
    maxWidth: '80%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  aiStudyToolcard: {
    height: '435px',
    overflowY: 'auto !important'
  },
  inCreaseAiStudyToolcard: {
    overflowY: 'auto !important',
    height: '585px'
  },
  promptCard: {
    overflowY: 'auto !important',
    maxHeight: '235px'
  },
  minimizedPromptCard: {
    overflowY: 'auto !important',
    height: '130px'
  },
  chatIntentCard: {
    overflowY: 'auto !important',
    height: '200px',
    fontSize: '12px'
  },
  subTypeModel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const DynamicDialog = ({
  dataValue,
  filterPromt,
  chatgptUtilityResponseStatus,
  isFeedback,
  loaderStatusResponse,
  ChatUtilitySubTypeResponse,
  chatgptUtilitySubTypeResponseStatus,
  tenantTitle
}) => {
  const { SubTypeData } = ChatUtilitySubTypeResponse;
  const [checkRectifierData, setCheckRecitifierData] = useState({});
  const theme = useTheme();
  useEffect(() => {
    if (!_.isEmpty(dataValue) && !chatgptUtilitySubTypeResponseStatus.isPending) {
      const { requestId, bookId, type } = dataValue;
      if (type && type === constants.PROBLEM_SOLVE_RECTIFIER) {
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_VERIFIER });
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE });
      }
      if (type && type === constants.PROBLEM_SOLVE) {
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_VERIFIER });
        Framework.getEventManager().on(constants.SET_PROBLEM_SOLVE_DATA, () => {
          if (SubTypeData.length > 0) {
            const findValue = SubTypeData.find(
              (data) =>
                data.bookId === bookId && data.requestId === requestId && data.type === constants.PROBLEM_SOLVE_VERIFIER
            );
            if (findValue) {
              setCheckRecitifierData(findValue);
            }
          }
        });
      }
    }
  }, [dataValue, chatgptUtilitySubTypeResponseStatus]);

  useEffect(() => {
    if (!_.isEmpty(checkRectifierData) && checkRectifierData.botResponse === 'False') {
      const { requestId, bookId } = dataValue;
      ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_RECTIFIER });
    }
  }, [checkRectifierData]);
  const renderResponsePrompts = () => {
    if (dataValue && dataValue.prompts && dataValue.prompts.length > 0) {
      return dataValue.prompts.map((data) => {
        return <Typography variant="body2">{`${data.name} Version = ${data.version} (${data.status})`}</Typography>;
      });
    }
    return <></>;
  };
  return (
    <>
      <Box>
        <Box>
          <LoadingHandler
            loading={chatgptUtilityResponseStatus.isPending || chatgptUtilitySubTypeResponseStatus.isPending}
            isError={
              (chatgptUtilityResponseStatus.isError &&
                chatgptUtilityResponseStatus.error &&
                chatgptUtilityResponseStatus.error.status === 401 &&
                chatgptUtilityResponseStatus.error.status === 500) ||
              (chatgptUtilitySubTypeResponseStatus.isError &&
                chatgptUtilitySubTypeResponseStatus.error &&
                chatgptUtilitySubTypeResponseStatus.error.status === 401 &&
                chatgptUtilitySubTypeResponseStatus.error.status === 500)
            }
            content={<></>}
            loadingContent={<Loader loaderStatusResponse={loaderStatusResponse} />}
            errorContent={
              <StateErrorDisplay
                error={chatgptUtilityResponseStatus.error || chatgptUtilitySubTypeResponseStatus.error}
                showDetails
              />
            }
          />
        </Box>
        <Box>
          <DynamicPromt
            isFeedback={isFeedback}
            dataValue={dataValue}
            filterPromt={filterPromt}
            renderResponsePrompts={renderResponsePrompts}
            ChatUtilitySubTypeResponse={ChatUtilitySubTypeResponse}
          />
          <AIStudyToolResponse
            isFeedback={isFeedback}
            dataValue={dataValue}
            filterPromt={filterPromt}
            SubTypeData={SubTypeData}
            tenantTitle={tenantTitle}
            theme={theme}
          />
        </Box>
      </Box>
    </>
  );
};

DynamicDialog.propTypes = {
  isFeedback: PropTypes.bool.isRequired,
  loaderStatusResponse: PropTypes.bool.isRequired,
  chatgptUtilityResponseStatus: shapes.state.isRequired,
  dataValue: PropTypes.object,
  filterPromt: PropTypes.object,
  tenantTitle: PropTypes.string,
  chatgptUtilitySubTypeResponseStatus: shapes.state.isRequired,
  ChatUtilitySubTypeResponse: shapes.modelOf(SubTypeResponse).isRequired
};

DynamicDialog.defaultProps = {
  filterPromt: [],
  dataValue: {},
  tenantTitle: ''
};

export default withStyles(styles)(
  inject(
    'chatgptUtilityResponseStatus',
    'ChatUtilitySubTypeResponse',
    'chatgptUtilitySubTypeResponseStatus'
  )(observer(DynamicDialog))
);
