import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Typography, Button, Divider, Chip, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { CardHeader } from '@material-ui/core';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import SubTypeResponse from '../../../models/SubTypeModel';
import ChatSubTypeResponse from '../../datagrid/ChatSubTypeResponse';

const ChatFeedBackComponent = ({ dataValue, ChatUtilitySubTypeResponse }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!dataValue.feedback) {
      setTabValue(1); // open request tabs by defaults
    }
  }, []);
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={tabValue} onChange={handleChange} aria-label="Feedback and Request tabs">
        <Tab label="Feedback" style={{ fontSize: '12px', fontWeight: 'bold' }} />
        <Tab label="Request" style={{ fontSize: '12px', fontWeight: 'bold' }} />
      </Tabs>

      {/* Feedback Tab Content */}
      {tabValue === 0 &&
        (dataValue.feedback ? (
          <Box sx={{ padding: 2 }}>
            <Card style={{ overflowY: 'auto', minHeight: '225px' }}>
              <CardHeader title="Feedback and Comments" style={{ background: '#34282808' }} align="left" />
              <CardContent>
                <Box display="flex" gap={2} mt={2}>
                  {dataValue?.feedback?.toUpperCase() === 'LIKE' ? (
                    <Button
                      variant="outlined"
                      startIcon={<ThumbUpIcon />}
                      color="primary"
                      sx={{
                        '&:hover': {
                          backgroundColor: 'transparent',
                          borderColor: 'primary.main',
                          color: 'primary.main'
                        }
                      }}
                    >
                      Like
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      startIcon={<ThumbDownIcon />}
                      sx={{
                        color: '#f44336',
                        backgroundColor: 'transparent',
                        borderColor: '#f44336',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          borderColor: '#f44336',
                          color: '#f44336'
                        }
                      }}
                    >
                      Dislike
                    </Button>
                  )}
                </Box>
                <Divider sx={{ my: 2 }} />
                {dataValue?.comments && (
                  <>
                    <Typography variant="body2" color="text.primary">
                      Comment:
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                      <Chip key={dataValue?.comments} label={dataValue?.comments} color="default" clickable />
                    </Box>
                  </>
                )}

                {dataValue && dataValue.reasonForDisLike && dataValue.reasonForDisLike.length > 0 ? (
                  <>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body2" fontWeight="bold">
                      Reason for Dislike
                    </Typography>
                    {dataValue.reasonForDisLike.map((val) => (
                      <Typography variant="body2">{val}</Typography>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </CardContent>
            </Card>
          </Box>
        ) : (
          <Box sx={{ padding: 2 }}>No records founds!</Box>
        ))}

      {/* Request Tab Content */}
      {tabValue === 1 && <ChatSubTypeResponse ChatUtilitySubTypeResponse={ChatUtilitySubTypeResponse} />}
    </Box>
  );
};

ChatFeedBackComponent.defaultProps = {
  // checkboxSelectedValue: {}
};

ChatFeedBackComponent.propTypes = {
  dataValue: PropTypes.object.isRequired,
  ChatUtilitySubTypeResponse: shapes.modelOf(SubTypeResponse).isRequired
  // checkboxSelectedValue: PropTypes.object
};

export default inject('ChatUtilitySubTypeResponse')(observer(ChatFeedBackComponent));
