/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  Button,
  Box
} from '@mui/material';
import { AIADMIN_PRIMARY_COLOR } from '../../ChatGptTheme';
import CustomTooltip from './CustomToolTip';

export function getNestedValueFromObj(rowObj, path) {
  const keys = typeof path === 'string' && path.includes('.') ? path.split('.') : [path];
  let isEffectiveFeedBack = false;
  return keys.reduce((acc, key) => {
    let data = '';
    if (acc && acc[key] !== undefined) {
      if (Array.isArray(acc[key])) {
        if (key === 'feedbacks') {
          const result = {};
          acc[key].forEach((item) => {
            Object.keys(item).forEach((objKey) => {
              if (item[objKey] !== null && result[objKey] === undefined) {
                result[objKey] = item[objKey];
              }
            });
          });
          data = result;
          isEffectiveFeedBack = true;
        } else {
          data = { obj: acc[key][0] };
          data = data.obj;
        }
      } else {
        if (isEffectiveFeedBack) {
          data = { feedbackVal: acc[key] };
        } else {
          data = acc[key];
        }
      }
    } else {
      data = undefined;
    }
    return data;
  }, rowObj);
}

// Utility function for sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CustomTable = ({
  columns,
  data,
  onCellClick,
  onRowClick,
  paginationEnabled,
  mouseOverColumnId,
  onCellMouseOver,
  toolTipBodyContent,
  perPage,
  disableIconBtn,
  enableRoundedHeader,
  tableHeight
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(perPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = stableSort(data, getComparator(order, orderBy));

  const paginatedData = paginationEnabled
    ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : sortedData;

  const handleCellClick = (row, columnId) => {
    if (onCellClick) {
      onCellClick(row, columnId);
    }
  };

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const getBorderRadius = (columnsdata, column) => {
    let borderRadius = 'none';
    if (column.id === columnsdata[0].id) borderRadius = '10px 0 0 0';
    if (column.id === columnsdata[columnsdata.length - 1].id) borderRadius = '0 10px 0 0';
    return borderRadius;
  };

  const getBackgroundColor = (cellElement) => {
    let cellBgColor = 'none';
    let feedBackVal = cellElement?.feedbackVal;
    if (feedBackVal) {
      cellBgColor = '';

      if (feedBackVal < 3 && feedBackVal > 0) {
        cellBgColor = '#F8B0B0';
      }
      if (feedBackVal >= 3 && feedBackVal < 5) {
        cellBgColor = '#FFEB99';
      }
      if (feedBackVal === 5) {
        cellBgColor = '#A8E6A1';
      }
    }
    return cellBgColor;
  };

  const baseStyle = {
    overflow: 'auto',
    scrollbarWidth: 'thin',
    maxHeight: '76vh',
    transition: 'all 0.4s ease'
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <TableContainer
        sx={{
          ...baseStyle
        }}
      >
        <Table>
          <TableHead>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  width: column.columnWidth || 'auto',
                  borderRadius: enableRoundedHeader ? getBorderRadius(columns, column) : 'none'
                }}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => (
              <TableRow
                // hover
                role="checkbox"
                tabIndex={0}
                key={index}
                onClick={() => onRowClick && handleRowClick(row)} // Use onRowClick callback to determine if row is clickable
                sx={{
                  '&:hover': {
                    backgroundColor: '#e3f2fd',
                    transition: 'background-color 0.3s ease'
                  },
                  '&:focus': {
                    backgroundColor: '#e3f2fd'
                  },
                  '&:focus .MuiTableCell-root': {
                    color: '#000'
                  }
                }}
              >
                {columns.map((column) => {
                  const { isTextTruncated, isClickableButton, isClickableLink, isClickableIcon } = column;
                  const cellContent = getNestedValueFromObj(row, column.id);

                  let cellElement;

                  // Handle displaying the content
                  if (isClickableLink) {
                    cellElement = (
                      <Box
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering row click when button is clicked
                          handleCellClick(row, column.id);
                        }}
                        sx={{
                          textDecoration: 'underline',
                          color: '#1976d2',
                          padding: 0,
                          minWidth: 'auto',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        {cellContent}
                      </Box>
                    );
                  } else if (isClickableButton) {
                    cellElement = (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ padding: '4px 8px' }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering row click when button is clicked
                          handleCellClick(row, column.id);
                        }}
                      >
                        {column.label}
                      </Button>
                    );
                  } else if (isClickableIcon) {
                    cellElement = (
                      <Box
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering row click when button is clicked
                          if (!disableIconBtn) handleCellClick(row, column.id);
                        }}
                        sx={{ cursor: disableIconBtn ? 'not-allowed' : 'pointer' }}
                      >
                        {isClickableIcon}
                      </Box>
                    );
                  } else {
                    cellElement = cellContent;
                  }

                  return (
                    <TableCell
                      key={column.id}
                      onClick={() => (isClickableLink || isClickableButton) && handleCellClick(row, column.id)}
                      onMouseOver={() =>
                        mouseOverColumnId === column.id && onCellMouseOver && onCellMouseOver(row[column.id])
                      }
                      sx={{
                        cursor: isClickableLink || isClickableButton || onRowClick ? 'pointer' : 'default',
                        width: column.columnWidth || 'auto',
                        maxWidth: isTextTruncated ? '200px' : 'none',
                        overflow: isTextTruncated ? 'hidden' : 'visible',
                        textOverflow: isTextTruncated ? 'ellipsis' : 'clip',
                        whiteSpace: isTextTruncated ? 'nowrap' : 'normal',
                        padding: '6px',
                        backgroundColor: getBackgroundColor(cellElement)
                      }}
                    >
                      {mouseOverColumnId === column.id && toolTipBodyContent ? (
                        <CustomTooltip content={toolTipBodyContent} bgColor="#ffffff">
                          <span>{cellElement}</span>
                        </CustomTooltip>
                      ) : (
                        <span>{cellElement?.feedbackVal ? cellElement.feedbackVal : cellElement}</span>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {paginationEnabled && (
        <TablePagination
          rowsPerPageOptions={[100, 200, 300, 400, 500]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiIconButton-root': {
              color: AIADMIN_PRIMARY_COLOR, // Custom color for the pagination action buttons (Next/Previous)
              '&:hover': {
                backgroundColor: 'rgba(26, 32, 44, 0.1)' // Custom hover effect for buttons
              }
            }
          }}
        />
      )}
    </Box>
  );
};

// PropTypes validation
CustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      isClickableLink: PropTypes.bool,
      isClickableButton: PropTypes.bool,
      columnWidth: PropTypes.string, // Define columnWidth as a string (e.g., '200px', 'auto')
      isTextTruncated: PropTypes.bool // Flag for text truncation,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCellClick: PropTypes.func,
  onRowClick: PropTypes.func,
  paginationEnabled: PropTypes.bool,
  mouseOverColumnId: PropTypes.string, // Specify which column should have the mouseover enabled
  onCellMouseOver: PropTypes.func,
  toolTipBodyContent: PropTypes.shape({
    userId: PropTypes.string,
    info: PropTypes.string
  }), // The user information to be displayed in the tooltip
  perPage: PropTypes.number,
  disableIconBtn: PropTypes.bool,
  enableRoundedHeader: PropTypes.bool
};

CustomTable.defaultProps = {
  onCellClick: () => {},
  onRowClick: () => {},
  paginationEnabled: true,
  mouseOverColumnId: null, // No column enabled for mouseover by default
  onCellMouseOver: () => {},
  toolTipBodyContent: null, // Default to no user information
  perPage: 100,
  disableIconBtn: false,
  enableRoundedHeader: false
};

export default CustomTable;

// Example usage

// const tempcolumns = [
//   { id: 'name', label: 'Name', columnWidth: '200px', isClickableLink: true, isTextTruncated: true },
//   { id: 'age', label: 'Age', columnWidth: '100px' }, // Not truncated
//   { id: 'city', label: 'City', columnWidth: '150px', isClickableButton: true },
//   { id: 'bio', label: 'Bio', columnWidth: '200px', isTextTruncated: true } // Truncated
// ];

// const tempTabledata = [
//   {
//     name: 'John Doe',
//     age: 28,
//     city: 'New York',
//     bio: 'Web Developer with a passion for coding and design. Loves building apps and exploring new technologies.'
//   }
// ];

// // Function that will handle cell clicks
// const handleCellClick = (row, columnId) => {
//   alert(`Cell clicked: ${columnId} with value "${row[columnId]}"`);
// };
// const handleRowClick = (row) => {
//   console.log('Row clicked: ', row);
// };

// <CustomTable columns={tempcolumns} data={tempTabledata} onCellClick={handleCellClick} onRowClick={handleRowClick} paginationEnabled={true} mouseOverColumnId="name" onCellMouseOver={handleCellMouseOver} toolTipBodyContent={toolTipBodyContent} />;
